<form (ngSubmit)="onSubmit()" #siteForm="ngForm">

  <h1>WORKOUT CREATOR</h1>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
    <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch">
      <h5 fxFlex="nogrow">MAIN INFO</h5>
      <mat-card fxFlex>
        <div *ngIf="__workout.id !== 0" fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px"
          fxLayoutAlign="end start">
          <h3 class="color-white">#{{__workout.id}}</h3>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
          <div fxLayout="column" fxFlex="50%">
          
            <mat-form-field>
              <mat-select #language placeholder="Language" (selectionChange)="languageChanged()" [(ngModel)]="currentLanguage" 
              [compareWith]="compare" name="language">
                <mat-option *ngFor="let language of languages" [value]="language">
                  {{language.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxFlex="50%">
            <!-- PROGRAM-->
            <mat-form-field>
              <mat-select #workoutProgram placeholder="Program" (selectionChange)="programChanged()" 
                [(ngModel)]="__workout.program" name="timelineProgram" required>
              <mat-option *ngFor="let program of programs" [value]="program.id">
                {{program.name}}
              </mat-option>
              </mat-select>
              <mat-hint class="form-error-message" *ngIf="formSubmitted && !__workout.program">
                Program is required
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayout.xs="column">
          <!-- NAME -->
          <mat-form-field>
            <input matInput #workoutName placeholder="Name" [value]="currentNameLanguageRes.value" [(ngModel)]="currentNameLanguageRes.value" name="workoutName"
              required>
            <mat-hint class="form-error-message" *ngIf="formSubmitted && nameErrorForm">
              Name is required
            </mat-hint>
          </mat-form-field>
          
        </div>
        <div fxLayout="row" fxLayout.xs="column">
          <!-- DESCRIPTION -->
          <mat-form-field fxFill>
            <textarea matInput #workoutDescription placeholder="Description" [value]="currentDescLanguageRes.value"
              [(ngModel)]="currentDescLanguageRes.value" name="workoutDescription" required></textarea>
            <mat-hint class="form-error-message" *ngIf="formSubmitted && descErrorForm">
              Description is required
            </mat-hint>
          </mat-form-field>
          
        </div>
        <div fxLayout="row" fxLayout.xs="column">
          <!-- DESCRIPTION  -->
          <mat-form-field fxFill>
            <textarea matInput #workoutLongDescription placeholder="Long description" [value]="currentLongDescLanguageRes.value"
              [(ngModel)]="currentLongDescLanguageRes.value" name="workoutLongDescription" required></textarea>
            <mat-hint class="form-error-message" *ngIf="formSubmitted && longDescErrorForm">
              Long description is required
            </mat-hint>
          </mat-form-field>
         
        </div> 
      </mat-card>

    </div>

    <div fxLayout="column" fxFlex="25" fxFlexAlign="stretch" class="posrel"> 

      <h5 fxFlex="nogrow">TRIGGERS</h5>
      <mat-card fxFlex>     
        <div fxLayout="column" fxFlex="100">
          <mat-checkbox name="trigger3s" (change)="changeTrigger(this.triggerList[0])" [(ngModel)]="this.triggerList[0].checked"> {{this.triggerList[0].name}} </mat-checkbox>  
          <mat-checkbox name="trigger5s" (change)="changeTrigger(this.triggerList[1])" [(ngModel)]="this.triggerList[1].checked"> {{this.triggerList[1].name}} </mat-checkbox>  
        </div>
      </mat-card>
     
    </div>

    <div fxLayout="column" fxFlex="25" fxFlexAlign="stretch" class="posrel"> 

      <h5 fxFlex="nogrow">IMAGE</h5>
      <mat-card fxFlex>
        <image-uploader [imageName]="__workout.image" [mediaUrl]="this.mediaUrl"
            (selectedImageEvent)="imageSelected($event)" (deletedImageEvent)="imageRemoved()" [formField]="" fxFill>
          </image-uploader>
      </mat-card>

    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" >
    <mat-toolbar class="mat-buttons-toolbar">
      <mat-toolbar-row>
        <span class="mat-spacer"></span>
        <button mat-flat-button type="submit" class="prama-button full-button medium-button">
          <mat-icon>save</mat-icon> SAVE
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>

  <div>
    <h5>WORKOUT PHASES</h5>
    <div class="timeline-container">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxLayoutAlign="space-between start">

        <mat-card class="card-phase">
          <h2>WARM UP</h2>
          <div class="phase-status-ok" [class.phase-status-fail]="__workout.sessionWarmUp===null">
            <mat-icon>done</mat-icon>
          </div>

          <!-- </div> -->
          <div fxLayout="column">
            <mat-form-field>
              <mat-select #warmups (selectionChange)="changeWelcome()" [(ngModel)]="__workout.sessionWarmUp" name="warmups" required>
                <mat-option [value]="0">--</mat-option>
                <mat-option *ngFor="let session of warmupSessions" [value]="session.id">
                  {{session.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-card>


        <mat-card class="card-phase">
          <h2>SESSION</h2>
          <div class="phase-status-ok"  [class.phase-status-fail]="__workout.sessionExecution===null">
            <mat-icon>done</mat-icon>
          </div>

          <!-- </div> -->
          <div fxLayout="column">
            <mat-form-field>
              <mat-select #sessions (selectionChange)="changeSession()" [(ngModel)]="__workout.sessionExecution"
                name="sessions" required>
                <mat-option [value]="0">--</mat-option>
                <mat-option *ngFor="let session of workoutSessions" [value]="session.id">
                  {{session.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-card>


        <mat-card class="card-phase">
          <h2>COOLDOWN</h2>
          <div class="phase-status-ok"  [class.phase-status-fail]="__workout.sessionCooldown===null">
            <mat-icon>done</mat-icon>
          </div>

          <!-- </div> -->
          <div fxLayout="column">
            <mat-form-field>
              <mat-select #cooldowns (selectionChange)="changeCooldown()" [(ngModel)]="__workout.sessionCooldown"
                name="cooldowns" required>
                <mat-option [value]="0">--</mat-option>
                <mat-option *ngFor="let session of cooldownSessions" [value]="session.id">
                  {{session.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-card>

      </div>
    </div>
  </div>
</form>