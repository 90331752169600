<div class="level-container" [ngSwitch]="level.value">
  <div clas *ngSwitchCase="1">
    <span title={{level.description}} class="grey fa" *ngFor="let value of [1,2,3,4,5]" [ngClass]="{'fa-square': value <= level.value, 'fa-square-o black': value > level.value}"></span>
  </div>
  <div *ngSwitchCase="2">
    <span title={{level.description}} class="blue fa" *ngFor="let value of [1,2,3,4,5]" [ngClass]="{'fa-square': value <= level.value, 'fa-square-o black': value > level.value}"></span>
  </div>
  <div *ngSwitchCase="3">
    <span title={{level.description}} class="green fa" *ngFor="let value of [1,2,3,4,5]" [ngClass]="{'fa-square': value <= level.value, 'fa-square-o black': value > level.value}"></span>
  </div>
  <div *ngSwitchCase="4">
    <span title={{level.description}} class="orange fa" *ngFor="let value of [1,2,3,4,5]" [ngClass]="{'fa-square': value <= level.value, 'fa-square-o black': value > level.value}"></span>
  </div>
  <div *ngSwitchCase="5">
    <span title={{level.description}} class="red fa" *ngFor="let value of [1,2,3,4,5]" [ngClass]="{'fa-square': value <= level.value, 'fa-square-o black': value > level.value}"></span>
  </div>
</div>
