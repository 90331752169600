import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { TagsService } from '../tags.service';

import { of } from "rxjs";

@Injectable()
export class TagsResolver implements Resolve<Observable<string>> {

    constructor(private tagsService: TagsService) { }

    resolve() {
        return this.tagsService.getTags().catch(() => {
            return of('error');
        });
    }

}

