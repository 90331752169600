<div fxLayout="column" fxFlex="100" fxFlexAlign="space-between stretch" fxFill>

  <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
      <h4 fxFlex="50">Music scheduler</h4>
  </div>
    
  <table #packageTable class="scheduler-table">
    <thead>
      <tr class="header-row">
        <th class="line">
          <div class="top"> PROGRAM </div>
          <div class="bottom"> INTERVAL </div>
        </th>
        <th *ngFor="let program of programs"> {{program.name}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let interval of intervals; let i = index" [class]="isCurrentInterval(interval, i) ? 'stand-out-row' : ''"  [id]="'Interval' + i">
        <th class="stand-out-cell">
          
          <span>
              <span class="month">{{interval.dateFrom | date:'MMM'}}</span>
              <span class="year">{{interval.dateFrom | date:'yyyy'}}</span>
              <!-- FROM {{interval.dateFrom | date:'MM/yyyy'}}  TO {{interval.dateTo | date:'MM/yyyy'}} -->
          </span>
        </th>

        <td *ngFor="let program of programs">

          <ng-container *ngTemplateOutlet="packageCtx; context:getContext(interval, program)">
          </ng-container>
          
          <ng-template #packageCtx let-ctx="ctx">
            <ng-container *ngIf="ctx.package">
              {{ctx.package.name}}  
              <button mat-button *ngIf="canEdit(ctx.interval)" (click)="setIntervalProgramPackage(ctx, packageSelector)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-button *ngIf="canEdit(ctx.interval)" (click)="deleteIntervalProgramPackage(ctx)">
                <mat-icon>clear</mat-icon>
              </button>
            </ng-container>
            <ng-container *ngIf="!ctx.package">
              <button mat-button *ngIf="canEdit(ctx.interval)"  (click)="setIntervalProgramPackage(ctx, packageSelector)">
                <mat-icon>add_circle_outline</mat-icon>
              </button>
            </ng-container>
          </ng-template>

        </td>
      </tr>
    </tbody>
  </table>
</div>



<ng-template #packageSelector >
  <div class="modal-search-exercise">
    <div class="modal-body">
      <button type="button" class="floatRight" mat-flat-button class="prama-transparent-button" (click)="modalRef.hide()">
        <mat-icon>clear</mat-icon>
      </button>
      <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" class="w-100">
        <div *ngFor="let package of this.packagesSearch" class="exercise-search-container nopadding nomargin">
          <mat-card class="nopadding selector-card" fxLayout="row" fxLayoutAlign="space-between stretch" 
            (click)="this.selectPackage(package)" [ngClass]="{'exercise-selected': (this.currentPackage && package.id === this.currentPackage.id)}">
            <div class="margin10">
              {{ package.id }} - {{ package.name }}
              <p class="subtitle">Used {{ package.intervals.length }} times</p>            
            </div>
            <div class="mat-spacer"></div>
          </mat-card>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="stretch stretch" fxLayoutGap="20px" fxFill class="w-100">
        <mat-toolbar class="mat-buttons-toolbar w-100">
          <mat-toolbar-row>
            <span class="mat-spacer"></span>
            <button mat-flat-button class="prama-button full-button" (click)="packageSelected()">
              <mat-icon>check</mat-icon> SELECT
            </button>
          </mat-toolbar-row>
        </mat-toolbar>
      </div>
    </div>
  </div>
</ng-template>