import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";

import { CONFIG } from "../../assets/config";
import { ENDPOINTS } from "../../assets/endpoints";

@Injectable()
export class TagsService {

  private urlGetTags: string;

  constructor(private http: HttpClient) {
    this.urlGetTags = CONFIG.serverUrl + ENDPOINTS.tags.get;
  }

  public getTags(): Observable<any> {
    return this.http.get(this.urlGetTags);
  }

}
