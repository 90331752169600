import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import { of } from "rxjs";
import { SharedContentService } from '../shared-content.service';


@Injectable()
export class SharedContentResolver implements Resolve<Observable<string>> {

  constructor(private sharedContentService: SharedContentService) { }

  resolve(route: ActivatedRouteSnapshot) {
    let workoutId = route.paramMap.get('workoutId') === 'new' ? '0' : route.paramMap.get('workoutId');
        
    return this.sharedContentService.getSharedContent(Number(workoutId)).catch(() => {
      return of('error');
    });
  }
}

