import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { IProgram } from "../../interfaces/models/i-program";
import { ITimeline, ITimelineObjects, ICountdown, ITimelineWelcome, ITimelineExplanation, ITimelineWarmUp, ITimelinePositioningGt, ITimelinePositioningFinisher, ITimelineResultsGt, ITimelineResultsFinisher, ITimelineCooldown, ITimelineEnding, ITimelineSession } from "../../interfaces/models/i-timeline";
import { IPlaylist } from "../../interfaces/models/i-playlist";
import { IMood } from "../../interfaces/models/i-mood";

import { CONFIG } from "../../../assets/config";
import { ENUMS } from "../../../assets/enums";

import { TimelineService } from "../../services/timeline.service";
import { NotificationService } from "../../services/notification.service";
import { PlaylistsService } from "../../services/playlists.service";
import { ISession } from "../../interfaces/models/i-session";
import { DynamicFormComponent } from "../dynamic-form/dynamic-form.component";


@Component({
  selector: "timelines-form",
  templateUrl: "./timeline-form.component.html",
  styleUrls: ["./timeline-form.component.css"]
})
export class TimelineFormComponent implements OnInit {

  isNew: Boolean = false;
  program: IProgram;
  currentProgramPlaylists: IPlaylist[];
  currentProgramSessions: ISession[];

  newWelcome: ITimelineWelcome = {
    id: 0,
    name: "",
    moodId: 0,
    playListId: 0,
    welcomeTime: 5,
    welcomeAudio: "",
    phaseId: ENUMS.timeline.musicPhases.welcome
  };

  newExplanation: ITimelineExplanation = {
    id: 0,
    name: "",
    moodId: 0,
    playListId: 0,
    coverTime: 30,
    benefitsTime: 60,
    summaryTime: 180,
    duration: 0,
    phaseId: ENUMS.timeline.musicPhases.explanation
  };

  newWarmUp: ITimelineWarmUp = {
    id: 0,
    name: "",
    moodId: 0,
    playListId: 0,
    duration: 180,
    countdown: null,
    phaseId: ENUMS.timeline.musicPhases.warmup
  };

  newPositioningGt: ITimelinePositioningGt = {
    id: 0,
    name: "",
    moodId: 0,
    playListId: 0,
    countdown: null,
    stationTime: 5,
    allStationsTime: 30,
    goToStationsTime: 30,
    phaseId: ENUMS.timeline.musicPhases.gt_positioning
  };

  newPositioningFinisher: ITimelinePositioningFinisher = {
    id: 0,
    name: "",
    moodId: 0,
    playListId: 0,
    stationTime: 5,
    allStationsTime: 30,
    phaseId: ENUMS.timeline.musicPhases.finisher_positioning
  };

  newResultsGt: ITimelineResultsGt = {
    id: 0,
    name: "",
    moodId: 0,
    playListId: 0,
    duration: 0,
    congratulationsTime: 5,
    congratulationsMessage: "",
    performanceTime: 0,
    phaseId: ENUMS.timeline.musicPhases.gt_rest
  };

  newResultsFinisher: ITimelineResultsFinisher = {
    id: 0,
    name: "",
    moodId: 0,
    playListId: 0,
    duration: 0,
    phaseId: ENUMS.timeline.musicPhases.finisher_results
  };

  newCooldown: ITimelineCooldown = {
    id: 0,
    name: "",
    moodId: 0,
    playListId: 0,
    duration: 180,
    countdown: null,
    phaseId: ENUMS.timeline.musicPhases.cooldown
  };

  newEnding: ITimelineEnding = {
    id: 0,
    name: "",
    moodId: 0,
    playListId: 0,
    duration: 60,
    message: "",
    phaseId: ENUMS.timeline.musicPhases.ending
  };

  newSession: ITimelineSession = {
    id: 0,
    program: {
      id: 0
    },
    workoutMode: {
      id: 0,
      name: null
    },
    triggers: []
  }

  newFinisher: ITimelineSession = {
    id: 0,
    program: {
      id: 0
    },
    workoutMode: {
      id: 0,
      name: null
    }
  }

  questionsWelcome: any[];
  questionsExplanation: any[];
  questionsWarmUp: any[];
  questionsPositioningGt: any[];
  questionsPositioningFinisher: any[];
  questionsResultsGt: any[];
  questionsResultsFinisher: any[];
  questionsCooldown: any[];
  questionsEnding: any[];
  questionsGt: any[];
  questionsFinisher: any[];

  objectsAvailables: ITimelineObjects = {
    countdown: null,
    mood: null,
    playlist: null,
    program: null,
    workoutMode: null,
    explanation: null,
    welcome: null,
    warmup: null,
    positioningGt: null,
    resultGt: null,
    positioningFinisher: null,
    resultFinisher: null,
    cooldown: null,
    ending: null,
    session: null,
    finisher: null,
    finisherMode: null
  };

  timeline: ITimeline = {
    id: 0,
    name: null,
    description: "",
    image: "",
    creationDate: new Date(),
    lastUpdate: new Date(),
    welcome: this.newWelcome,
    explanation: this.newExplanation,
    warmUp: this.newWarmUp,
    positioningGt: this.newPositioningGt,
    positioningFinisher: this.newPositioningFinisher,
    resultsGt: this.newResultsGt,
    resultsFinisher: this.newResultsFinisher,
    coolDown: this.newCooldown,
    ending: this.newEnding,
    session: this.newSession,
    finisher: this.newFinisher
  }

  allFinishers: ISession[];

  DEFAULT_WELCOME = {
    1: 3,
    2: 4,
    3: 3,
    4: 3,
    5: 3,
    6: 3
  };
  DEFAULT_WARMUP = {
    1: 4,
    2: 4,
    3: 4,
    4: 4,
    5: 4,
    6: 4
  };
  DEFAULT_POSITIONING_GT = {
    1: 7,
    2: 7,
    3: 7,
    4: 7,
    5: 7,
    6: 7
  };
  DEFAULT_POSITIONING_FINISHER = {
    1: 7,
    2: 7,
    3: 7,
    4: 7,
    5: 7,
    6: 7
  };
  DEFAULT_RESULTS_GT = {
    1: 7,
    2: 7,
    3: 7,
    4: 7,
    5: 7,
    6: 7
  };
  DEFAULT_RESULTS_FINISHER = {
    1: 7,
    2: 7,
    3: 7,
    4: 7,
    5: 7,
    6: 7
  };
  DEFAULT_COOLDOWN = {
    1: 4,
    2: 4,
    3: 4,
    4: 4,
    5: 4,
    6: 4
  };
  DEFAULT_ENDING = {
    1: 3,
    2: 3,
    3: 3,
    4: 3,
    5: 3,
    6: 3
  };
  DEFAULT_SESSION = {
    1: 2,
    2: 2,
    3: 2,
    4: 2,
    5: 2,
    6: 2
  };
  DEFAULT_FINISHER = {
    1: 1220,
    2: 1220,
    3: 1220,
    4: 1220,
    5: 1220,
    6: 1220
  };

  formSubmitted = false;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private timelineService: TimelineService,
    private notificationService: NotificationService,
    private playlistsService: PlaylistsService
  ) {


  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {

    if (this.route.snapshot.data.timeline === 'new') {
      this.isNew = true;
    } else {
      this.timeline = this.route.snapshot.data.timeline;
    }

    this.objectsAvailables.program = this.route.snapshot.data.programs;
    this.objectsAvailables.workoutMode = this.route.snapshot.data.workoutModesAvailable;
    this.objectsAvailables.playlist = this.route.snapshot.data.playlists;
    this.objectsAvailables.mood = this.route.snapshot.data.moods;
    this.objectsAvailables.countdown = this.route.snapshot.data.countdowns;
    this.objectsAvailables.welcome = this.route.snapshot.data.welcomes;
    this.objectsAvailables.explanation = this.route.snapshot.data.explanations;
    this.objectsAvailables.warmup = this.route.snapshot.data.warmups;
    this.objectsAvailables.positioningGt = this.route.snapshot.data.positionings;
    this.objectsAvailables.resultGt = this.route.snapshot.data.results;
    this.objectsAvailables.positioningFinisher = this.route.snapshot.data.positionings;
    this.objectsAvailables.resultFinisher = this.route.snapshot.data.results;
    this.objectsAvailables.cooldown = this.route.snapshot.data.cooldowns;
    this.objectsAvailables.ending = this.route.snapshot.data.endings;
    this.objectsAvailables.session = this.route.snapshot.data.sessions;
    this.objectsAvailables.finisherMode = this.timelineService.getTimelineFinisherModes();

    this.allFinishers = this.route.snapshot.data.finishers;

    if(this.isNew) {
      this.timeline.finisher.finisherType = this.objectsAvailables.finisherMode[0].id;
    }
    this.objectsAvailables.finisher = this.allFinishers.filter(x => x.finisherType == this.timeline.finisher.finisherType);

    for (let session of this.objectsAvailables.session) {
      session.program = this.objectsAvailables.program.find(x => x.id === Number(session.program));
      session.workoutMode = this.objectsAvailables.workoutMode.find(x => x.id === Number(session.workoutMode));
    }

    for (let session of this.objectsAvailables.finisher) {
      session.program = this.objectsAvailables.program.find(x => x.id === Number(session.program));
      session.workoutMode = this.objectsAvailables.workoutMode.find(x => x.id === Number(session.workoutMode));
    }

    this.program = this.objectsAvailables.program.find(x => x.id == this.timeline.session.program.id)
    this.changeProgram();

  }

  changeWelcome() {
    this.questionsWelcome = this.timelineService.getWelcomeQuestions(
      this.timeline.welcome,
      this.currentProgramPlaylists.filter(x => x.idPhase == ENUMS.timeline.musicPhases.welcome),
      this.objectsAvailables.mood);
  }

  changeExplanation() {
    this.questionsExplanation = this.timelineService.getExplanationQuestions(
      this.timeline.explanation,
      this.currentProgramPlaylists.filter(x => x.idPhase == ENUMS.timeline.musicPhases.explanation),
      this.objectsAvailables.mood);
  }

  changeWarmUp() {
    this.questionsWarmUp = this.timelineService.getWarmUpQuestions(
      this.timeline.warmUp,
      this.currentProgramPlaylists.filter(x => x.idPhase == ENUMS.timeline.musicPhases.warmup),
      this.objectsAvailables.mood,
      this.objectsAvailables.countdown);
  }

  changePositioningGt() {
    this.questionsPositioningGt = this.timelineService.getPositioningGtQuestions(
      this.timeline.positioningGt,
      this.currentProgramPlaylists.filter(x => x.idPhase == ENUMS.timeline.musicPhases.gt_positioning),
      this.objectsAvailables.mood,
      this.objectsAvailables.countdown);
  }

  changePositioningFinisher() {
    this.questionsPositioningFinisher = this.timelineService.getPositioningFinisherQuestions(
      this.timeline.positioningFinisher,
      this.currentProgramPlaylists.filter(x => x.idPhase == ENUMS.timeline.musicPhases.finisher_positioning),
      this.objectsAvailables.mood,
      this.objectsAvailables.countdown);
  }

  changeResultsGt() {
    this.questionsResultsGt = this.timelineService.getResultsGtQuestions(
      this.timeline.resultsGt,
      this.currentProgramPlaylists.filter(x => x.idPhase == ENUMS.timeline.musicPhases.gt_results),
      this.objectsAvailables.mood);
  }

  changeResultsFinisher() {
    this.questionsResultsFinisher = this.timelineService.getResultsFinisherQuestions(
      this.timeline.resultsFinisher,
      this.currentProgramPlaylists.filter(x => x.idPhase == ENUMS.timeline.musicPhases.finisher_results),
      this.objectsAvailables.mood);
  }

  changeCooldown() {
    this.questionsCooldown = this.timelineService.getCooldownQuestions(
      this.timeline.coolDown,
      this.currentProgramPlaylists.filter(x => x.idPhase == ENUMS.timeline.musicPhases.cooldown),
      this.objectsAvailables.mood,
      this.objectsAvailables.countdown);
  }

  changeEnding() {
    this.questionsEnding = this.timelineService.getEndingQuestions(
      this.timeline.ending,
      this.currentProgramPlaylists.filter(x => x.idPhase == ENUMS.timeline.musicPhases.ending),
      this.objectsAvailables.mood);
  }

  changeGt() {
    this.questionsGt = this.timelineService.getGtQuestions(
      this.timeline.session,
      this.currentProgramSessions
    );
  }

  changeFinisher() {
    this.questionsFinisher = this.timelineService.getFinisherQuestions(
      this.timeline.finisher,
      this.objectsAvailables.finisher
    );
  }

  finisherModeChanged() {
    this.objectsAvailables.finisher = this.allFinishers.filter(x => x.finisherType === this.timeline.finisher.finisherType);
    this.changeFinisher();
  }

  changeProgram() {

    if (this.program == undefined) return;

    this.currentProgramPlaylists = this.objectsAvailables.playlist.filter(x => x.idProgram == this.program.id);
    this.currentProgramSessions = this.objectsAvailables.session.filter(x => x.program !== undefined && x.program.id == this.program.id);

    this.timeline.welcome = this.timeline.welcome === undefined ? this.objectsAvailables.welcome.find(x => x.id === this.DEFAULT_WELCOME[this.program.id]) : this.timeline.welcome;
    this.timeline.warmUp = this.timeline.warmUp === undefined ? this.objectsAvailables.warmup.find(x => x.id === this.DEFAULT_WARMUP[this.program.id]) : this.timeline.warmUp;
    this.timeline.positioningGt = this.timeline.positioningGt === undefined ? this.objectsAvailables.positioningGt.find(x => x.id === this.DEFAULT_POSITIONING_GT[this.program.id]) : this.timeline.positioningGt;
    this.timeline.positioningFinisher = this.timeline.positioningFinisher === undefined ? this.objectsAvailables.positioningFinisher.find(x => x.id === this.DEFAULT_POSITIONING_FINISHER[this.program.id]) : this.timeline.positioningFinisher;
    this.timeline.resultsGt = this.timeline.resultsGt === undefined ? this.objectsAvailables.resultGt.find(x => x.id === this.DEFAULT_RESULTS_GT[this.program.id]) : this.timeline.resultsGt;
    this.timeline.resultsFinisher = this.timeline.resultsFinisher === undefined ? this.objectsAvailables.resultFinisher.find(x => x.id === this.DEFAULT_RESULTS_FINISHER[this.program.id]) : this.timeline.resultsFinisher;
    this.timeline.coolDown = this.timeline.coolDown === undefined ? this.objectsAvailables.cooldown.find(x => x.id === this.DEFAULT_COOLDOWN[this.program.id]) : this.timeline.coolDown;
    this.timeline.ending = this.timeline.ending === undefined ? this.objectsAvailables.ending.find(x => x.id === this.DEFAULT_ENDING[this.program.id]) : this.timeline.ending;
    this.timeline.session = this.timeline.session === undefined ? this.objectsAvailables.session.find(x => x.id === this.DEFAULT_SESSION[this.program.id]) : this.timeline.session;
    this.timeline.finisher = this.timeline.finisher === undefined ? this.objectsAvailables.finisher.find(x => x.id === this.DEFAULT_FINISHER[this.program.id]) : this.timeline.finisher;

    this.changeWelcome();
    this.changeExplanation();
    this.changeWarmUp();
    this.changePositioningGt();
    this.changePositioningFinisher();
    this.changeResultsGt();
    this.changeResultsFinisher();
    this.changeCooldown();
    this.changeEnding();
    this.changeGt();
    this.changeFinisher();

  }

  loadPlaylists() {
    if (this.timeline) {
      this.playlistsService.getPlaylists(this.program.id).subscribe(
        response => {
          if (response) {
            this.currentProgramPlaylists = response;
          }
        }, error => {
          this.notificationService.notify("An error occurred while retrieving playlists",
            ENUMS.notification.types.error, ENUMS.notification.positions.custom_responsive);
        }
      );
    }
  }

  // Aux function for comparison of combobox objects
  compare(val1, val2) {
    if (val1 !== undefined && val2 !== undefined && val1 !== null && val2 !== null) {
      return val1.id === val2.id;
    } else {
      return false;
    }
  }


  // Added as method. maybe we would prevent go back when there are pending changes
  goBack() {
    this.router.navigate(["/timelines"]);
  }

  changeValueEvent(event, model, timelineObject): void {
    this.changeValue(event, model, timelineObject);
  }

  changeValue(jsonString, jsonObject1, timelineObject): void {

    this.timeline[timelineObject] = jsonObject1;

    var jsonObject2 = JSON.parse(jsonString);
    var keys = Object.keys(jsonObject1);

    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      if (jsonObject2[key] != undefined && jsonObject1[key] != jsonObject2[key]) {
        if (typeof jsonObject1[key] !== 'object') {
          jsonObject1[key] = jsonObject2[key];
        } else {
          jsonObject1[key] = this.objectsAvailables[key].find(x => x.id == jsonObject2[key]);
        }
      }
    }
  }

  @ViewChild('timelineWelcome') timelineWelcome: DynamicFormComponent;
  @ViewChild('timelineExplanation') timelineExplanation: DynamicFormComponent;
  @ViewChild('timelineWarmUp') timelineWarmUp: DynamicFormComponent;
  @ViewChild('timelinePositioningGt') timelinePositioningGt: DynamicFormComponent;
  @ViewChild('timelineGt') timelineGt: DynamicFormComponent;
  @ViewChild('timelinePositioningFinisher') timelinePositioningFinisher: DynamicFormComponent;
  @ViewChild('timelineFinisher') timelineFinisher: DynamicFormComponent;
  @ViewChild('timelineResultsGt') timelineResultsGt: DynamicFormComponent;
  @ViewChild('timelineResultsFinisher') timelineResultsFinisher: DynamicFormComponent;
  @ViewChild('timelineCooldown') timelineCooldown: DynamicFormComponent;
  @ViewChild('timelineEnding') timelineEnding: DynamicFormComponent;
  onSubmit() {
    this.formSubmitted = true;
    if (this.isFormValid()) {

      this.timeline.session.workoutMode = this.objectsAvailables.session.find(x => x.id === Number(this.timeline.session.id)).workoutMode;
      this.timeline.session.program = this.objectsAvailables.session.find(x => x.id === Number(this.timeline.session.id)).program;

      if (this.isNew) {
        this.createTimeline();
      } else {
        this.saveTimeline();
      }
    }
    else
      this.notificationService.notify("Invalid timeline. Complete every required fields",
        ENUMS.notification.types.error, ENUMS.notification.positions.custom_responsive);

  }

  isFormValid(): boolean {

    if (this.program == undefined) return false;

    return this.timelineWelcome.onSubmit() &&
      this.timelineExplanation.onSubmit() &&
      this.timelineWarmUp.onSubmit() &&
      this.timelinePositioningGt.onSubmit() &&
      this.timelineGt.onSubmit() &&
      this.timelinePositioningFinisher.onSubmit() &&
      this.timelineFinisher.onSubmit() &&
      this.timelineResultsGt.onSubmit() &&
      this.timelineResultsFinisher.onSubmit() &&
      this.timelineCooldown.onSubmit() &&
      this.timelineEnding.onSubmit();
  }




  public saveTimeline() {
    this.timelineService.putTimeline(this.timeline).subscribe(
      result => {
        //if (result) {
        this.notificationService.notify("Timeline saved successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
        //this.router.navigate(["/sessions"]);
        //}
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public createTimeline() {
    this.timelineService.postTimeline(this.timeline).subscribe(
      result => {
        //if (result) {
        this.notificationService.notify("Timeline created successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
        this.router.navigate(["/timelines"]);
        //}
      },
      error => {
        console.log('Error', error);
      }
    );
  }


}
