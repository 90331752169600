import { IFormFieldsSprintSequence } from "../interfaces/models/sprint-sequence/i-form-fields-sprint-sequence";
import { IFormField } from "../interfaces/models/i-form-field";
import { IFormProgram } from "../interfaces/models/i-form-program";
import { ISequenceConfiguratorTile } from "../interfaces/models/sequence/form/i-sequence-configurator-tile";
import { ISprintSequenceConfiguratorFormFields } from "../interfaces/models/sprint-sequence/i-sprint-sequence-configurator-form-fields";
import { IColor } from "../interfaces/models/i-color";
import { CONFIG } from "../../assets/config";

export class SequenceSprintHelper {
  static getFormFields(): IFormFieldsSprintSequence {

    const fields: IFormFieldsSprintSequence = {
      idField: {
        name: "id-input",
        nameToShow: "ID",
        placeHolder: "ID",
        visible: false,
        disabled: true,
        required: false,
        error: false,
        errorMessage: "",
        minValue: undefined
      },
      sequenceTypeField: {
        name: "sequence-type-input",
        nameToShow: "Type",
        placeHolder: "",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Sequence type is required",
        minValue: undefined
      },
      tileFromField: {
        name: "tile-init-index-input",
        nameToShow: "Tile from",
        placeHolder: "Tile from",
        visible: false,
        disabled: false,
        required: false,
        error: false,
        errorMessage: "",
        minValue: 2
      },
      numberOfTimesField: {
        name: "reps-in-tile-input",
        nameToShow: "Nº of times",
        placeHolder: "Nº of times",
        visible: false,
        disabled: false,
        required: false,
        error: false,
        errorMessage: "",
        minValue: undefined
      },
      tile1Field: {
        name: "tile1-input",
        nameToShow: "Tile 1",
        placeHolder: "Tile 1",
        visible: false,
        disabled: false,
        required: false,
        error: false,
        errorMessage: "",
        minValue: 1
      },
      tile2Field: {
        name: "tile2-input",
        nameToShow: "Tile 2",
        placeHolder: "Tile 2",
        visible: false,
        disabled: false,
        required: false,
        error: false,
        errorMessage: "",
        minValue: 1
      },
      colorModeField: {
        name: "color-mode-input",
        nameToShow: "Color mode",
        placeHolder: "",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Color mode is required",
        minValue: 1
      },
      responseTypeField: {
        name: "response-type-input",
        nameToShow: "Response type",
        placeHolder: "",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Response type is required",
        minValue: 1
      },
      timeField: {
        name: "time-input",
        nameToShow: "Time (ms)",
        placeHolder: "",
        visible: false,
        disabled: false,
        required: false,
        error: false,
        errorMessage: "Time required",
        minValue: 0
      },
      colorPickerField: {
        name: "color-picker-input",
        nameToShow: "Color",
        placeHolder: "",
        visible: false,
        disabled: false,
        required: false,
        error: false,
        errorMessage: "Color required",
        minValue: undefined
      }
    };
    return fields;
  }


  static getAvailableValues(): ISprintSequenceConfiguratorFormFields {
    const values: ISprintSequenceConfiguratorFormFields = {
      sequenceTypes: [],
      colorTypes: [
        { id: 0, name: "-", pickable: false, multiple: false, defaultColor: undefined },
        { id: 1, name: "Fixed", pickable: true, multiple: false, defaultColor: undefined },
        { id: 2, name: "Random", pickable: false, multiple: false, defaultColor: { id: 1, red: 0, blue: 0, green: 0, hex: "#ff0000" } },
        { id: 3, name: "Alternative", pickable: false, multiple: false, defaultColor: undefined }
      ],
      responseTypes: [
        { id: 0, name: "-", responseByTime: false },
        { id: 1, name: "Press", responseByTime: false },
        { id: 2, name: "Release", responseByTime: false },
        { id: 3, name: "Time", responseByTime: true },
        { id: 4, name: "None", responseByTime: false }
      ],
      presetColors: [
        { id: 1, red: 0, blue: 0, green: 0, hex: "#ff0000" },
        { id: 2, red: 0, blue: 0, green: 0, hex: "#3c98ff" },
      ]
    };

    return values;
  }



  static getColorTiles(colors: IColor[]): ISequenceConfiguratorTile[] {
    const tiles = [];
    for (let index = 0; index < colors.length; index++) {
      tiles.push({
        id: index,
        tileNumber: 1,
        color: colors[index],
        selected: false
      });
    }

    return tiles;
  }


  static validateTextField(field: IFormField, inputText: string) {
    field.error = false;
    if (field.required && (!inputText || inputText === "")) {
      field.error = true;
    }
  }

  static validateNumberField(field: IFormField, inputNumber: number) {
    field.error = false;
    if (field.required && (!inputNumber || inputNumber === 0)) {
      field.error = true;
    }
  }

  static validateArrayField(field: IFormField, elements) {
    field.error = false;
    if (field.required && (!elements || elements.length < field.minValue)) {
      field.error = true;
    }
  }
}




