import { SharedContent, NodeItem } from './../../../interfaces/models/shared-content';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'shared-content',
  templateUrl: './shared-content.component.html',
  styleUrls: ['./shared-content.component.scss']
})
export class SharedContentComponent implements OnInit {

  @Input() sharedContent: SharedContent;

  public selectedChain: NodeItem = null;
  public selectedGym: NodeItem = null;
  public userFilterText: string = "";

  constructor() { }

  ngOnInit(): void {

  }

  public selectChain(chain: NodeItem, $event) {
    this.selectedChain = chain;
    $event.preventDefault();
    $event.stopPropagation();
  }
  public selectGym(gym: NodeItem, $event) {
    this.selectedGym = gym;
    $event.preventDefault();
    $event.stopPropagation();
  }

  public getFilteredByParentId(itemList: NodeItem[], parentId: number){
    return itemList.filter(item => item.parent === parentId);
  }

  public onFilterChange(value){
    this.userFilterText = value;
  }

  public getFilteredByFilterString(itemList: NodeItem[]){
    return itemList.filter(item => this.stringHas(item.name, this.userFilterText));
  }

  public stringHas(string: string, substring: string){
    return string.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(substring.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
  }

  // Devuelve true si el item tiene que mostrarse como checked o no, dependiendo de si se encuentra en la lista
  public isChecked(item: NodeItem, items: number[]){
    return items.indexOf(item.id) > -1;
  }

  // Inserta el item en el listado o lo borra si existe
  public toggleChecked(item: NodeItem, itemList: number[], $event){
    if($event.checked){
      itemList.push(item.id);
    }else{
      let index = itemList.indexOf(item.id);
      itemList.splice(index, 1);
    }
  }

  // Filtra el listado por checked o no
  public getCheckedItems(status: boolean, itemList: NodeItem[], items: number[]){
    switch(status){
      case true:
        return itemList.filter(item => items.includes(item.id));
        break;
      case false:
        return itemList.filter(item => !items.includes(item.id));
        break;
    }
  }

  public getCountChildItemsText(parentId: number, itemList: NodeItem[], items: number[]){
    let text = "";
    let totalList = itemList.filter(item => item.parent === parentId);
    let checkedList = this.getCheckedItems(true, totalList, items);
    text = checkedList.length + "/" + totalList.length;
    return text;
  }
  

}
