import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { PlaylistsService } from '../playlists.service';

import { of } from "rxjs";


@Injectable()
export class PlaylistsAvailableResolver implements Resolve<Observable<string>> {

    constructor(private playlistsService: PlaylistsService) { }

    resolve() {
        return this.playlistsService.getPlaylists(0).catch(() => {
            return of('error');
        });
    }

}

