import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ProgramService } from '../program.service';

import { of } from "rxjs";
import { PlaylistsService } from '../playlists.service';
import { MusicService } from '../music-service';


@Injectable()
export class MusicProgramPackagesResolver implements Resolve<Observable<string>> {

    constructor(private musicService: MusicService) { }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('programId') !== 'new') {
            return this.musicService.getMusicProgramPackages(Number(route.paramMap.get('programId'))).catch(() => {
                return of('error');
            });
        } 
    }

}

