<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
  <div fxLayout="column" fxFlex="100" fxFlexAlign="stretch">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h4 fxFlex="50">{{ categoryList?.length }} categories</h4>
        <div>
          <mat-form-field>
            <input #filter matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            <button mat-button *ngIf="filter.value" matSuffix mat-icon-button aria-label="Clear" (click)="filter.value=''; applyFilter('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <button  class="uk-button prama-button medium-button ml20"  (click)="openCreateCategory()">
              <i class="fa fa-plus-circle faSize"></i>
          </button>         
        </div>
    </div>

    <mat-table #categoryTable [dataSource]="dataSource" matSort class="session-table">     
      <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="90%"> Name </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="90%">
            <div *ngIf="editMode != element.id">
              {{element.fullpath}}
            </div>
            <div *ngIf="editMode == element.id">
              <mat-form-field *ngIf="element.id == 0" class="w400 mr20">

                <mat-label>Parent</mat-label>
                <mat-select #categoryParent placeholder="Parent"
                  [compareWith]="compare" [(ngModel)]="element.idParent" name="categoryParent" required class="w400">
                  <mat-option [value]="0">None</mat-option>
                  <mat-option *ngFor="let category of categoryList" [value]="category.id">
                    {{category.fullpath}}
                  </mat-option>
                </mat-select>

              </mat-form-field>
              <mat-form-field class="w400" >
                <input matInput type="text" [(ngModel)]="element.name" name="element?.name" placeholder="name" required class="w400">
              </mat-form-field>
            </div>
          </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="table-column-short"> Actions </mat-header-cell>
        <mat-cell *matCellDef="let element" class="table-column-short">
          <button mat-icon-button (click)="openEditor(element)" *ngIf="editMode != element.id && canModify">
            <mat-icon title="Edit">mode_edit</mat-icon>
          </button>
          <button mat-icon-button (click)="saveCategory(element)" *ngIf="editMode == element.id && !newMode && canModify">
            <mat-icon title="Save">save</mat-icon>
          </button>
          <button mat-icon-button (click)="createCategory(element)" *ngIf="element.id == 0 && newMode && canCreate">
            <mat-icon title="Save">check</mat-icon>
          </button>
          <button mat-icon-button (click)="modalRemoveCategoryOpen(element)" *ngIf="element.id == editMode && !newMode && canDelete && canDeleteCategory(element)">
            <mat-icon title="Delete">delete</mat-icon>
          </button>
          <button mat-icon-button (click)="resetForm($event, true)" *ngIf="element.id == editMode">
            <mat-icon title="Cancel">close</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </div>
</div>
