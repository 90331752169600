import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpEventType } from "@angular/common/http";

import { Observable } from "rxjs/Observable";
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CONFIG } from "../../assets/config";
import { ENDPOINTS } from "../../assets/endpoints";
import { ISongsFilter } from "../interfaces/models/i-songs-filter";
import { ISongHash } from "../interfaces/i-song-hash";
import { ISong } from "../interfaces/models/i-song";
import { IMusicPackageDco } from "../interfaces/models/i-music-package";
import { IMusicPackageInterval } from "../interfaces/models/i-music-interval";
import { IMusicPackagePhase, IMusicPackagePhaseDco } from "../interfaces/models/i-music-package-phase";


@Injectable()
export class MusicService {

  private urlGetProviders: string;
  private urlPostProviders: string;
  private urlPutProviders: string;
  private urlDelProviders: string;

  private urlGetSongs: string;
  private urlPostSongs: string;
  private urlPutSong: string;
  private urlDelSong: string;

  private urlGetMusicPhases: string;
  private urlGetMusicTimeIntervals: string;

  private urlGetMusicPackages: string;

  private urlGetMusicProgramPackages: string;
  private urlPostMusicProgramPackage: string;
  private urlPutMusicProgramPackage: string;
  private urlDelMusicProgramPackage: string;

  private urlPostMusicPackageInterval: string;
  private urlDelMusicPackageInterval: string;

  private urlPutMusicProgramPackagePhase: string;

  private urlPostMusicFile: string;
  private urlPostReadMusic: string;

  public songsFilterList: ISongsFilter[] = [];
  public songsFilterSearch: string = '';

  constructor(private http: HttpClient) {
    this.urlGetProviders = CONFIG.serverUrl + ENDPOINTS.musicProviders.get;
    this.urlPostProviders = CONFIG.serverUrl + ENDPOINTS.musicProviders.post;
    this.urlPutProviders = CONFIG.serverUrl + ENDPOINTS.musicProviders.put;
    this.urlDelProviders = CONFIG.serverUrl + ENDPOINTS.musicProviders.delete;

    this.urlGetSongs = CONFIG.serverUrl + ENDPOINTS.songs.get;
    this.urlPostSongs = CONFIG.serverUrl + ENDPOINTS.songs.post;
    this.urlPutSong = CONFIG.serverUrl + ENDPOINTS.songs.put;
    this.urlDelSong = CONFIG.serverUrl + ENDPOINTS.songs.delete;
    
    this.urlPostMusicFile = CONFIG.serverUrl + ENDPOINTS.songsUpload.post;
    this.urlPostReadMusic = CONFIG.serverUrl + ENDPOINTS.songsRead.post;
    this.urlGetMusicPhases = CONFIG.serverUrl + ENDPOINTS.musicPhases.get;

    this.urlGetMusicPackages = CONFIG.serverUrl + ENDPOINTS.musicPackages.get;

    this.urlGetMusicProgramPackages = CONFIG.serverUrl + ENDPOINTS.musicProgramPackages.get;
    this.urlPostMusicProgramPackage = CONFIG.serverUrl + ENDPOINTS.musicProgramPackages.post;
    this.urlPutMusicProgramPackage = CONFIG.serverUrl + ENDPOINTS.musicProgramPackages.put;
    this.urlDelMusicProgramPackage = CONFIG.serverUrl + ENDPOINTS.musicProgramPackages.delete;

    this.urlGetMusicTimeIntervals = CONFIG.serverUrl + ENDPOINTS.musicIntervals.get;

    this.urlPostMusicPackageInterval = CONFIG.serverUrl + ENDPOINTS.musicPackageInterval.post;
    this.urlDelMusicPackageInterval = CONFIG.serverUrl + ENDPOINTS.musicPackageInterval.delete;

    this.urlPutMusicProgramPackagePhase = CONFIG.serverUrl + ENDPOINTS.musicPackagePhase.put;
  }


  public getProviders(): Observable<any> {
    return this.http.get(this.urlGetProviders);
  }


  public createProvider(provider): Observable<any> { 
    return this.http.post(this.urlPostProviders, provider);
  }


  public updateProvider(provider): Observable<any> { 
    return this.http.put(this.urlPutProviders, provider);
  }


  public deleteProvider(provider): Observable<any>  {
    const url = this.urlDelProviders + '/'+provider.id;
    return this.http.delete(url);
  }


  public getMusicPhases(): Observable<any> {
    return this.http.get(this.urlGetMusicPhases);
  }


  public getSongs(): Observable<any> {
    return this.http.get(this.urlGetSongs);
  }


  public getProgramPhaseSongs(): Observable<any> {
    return this.http.get(this.urlGetProviders);
  }


  public postReadMusic(hash: ISongHash) {
    return this.http.post(this.urlPostReadMusic, hash);
  }


  public postSongs(hash: ISongHash) {
    return this.http.post(this.urlPostSongs, hash);
  }


  public putSong(song: ISong) {
    return this.http.put(this.urlPutSong, song);
  }


  public deleteSong(song: ISong) {
    let url = this.urlDelSong.replace(":id", song.id.toString());
    return this.http.delete(url);
  }


  public getMusicProgramPackages(programId: number): Observable<any>  {
    let url = this.urlGetMusicProgramPackages.replace(":programId", programId.toString());
    return this.http.get(url);
  }

  public getMusicPackages(): Observable<any>  {
    return this.http.get(this.urlGetMusicPackages);
  }
  
  
  public postMusicFile(form) {
    return this.http.post<any>(this.urlPostMusicFile, form, {
        reportProgress: true,
        observe: 'events'
      }).pipe(map((event) => {
        console.debug("MS : " + HttpEventType[event.type]);
        switch (event.type) {
          case HttpEventType.Sent:
            return { status: 'sent', message: undefined };
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return { status: 'progress', message: progress };
          case HttpEventType.ResponseHeader:
            return { status: 'responseHeader', message: "" };
          case HttpEventType.DownloadProgress:
            return { status: 'download', message: "" };
          case HttpEventType.Response:
            return { status: 'response', message: event.body };
          default:
            return { status: 'error', message: event };
        }
      })
    );
  }

  public createMusicPackage(musicPackage: IMusicPackageDco) {
    return this.http.post(this.urlPostMusicProgramPackage, musicPackage);
  }

  public updateMusicPackage(musicPackage: IMusicPackageDco) {
    return this.http.put(this.urlPutMusicProgramPackage, musicPackage);
  }

  public deleteMusicPackage(packageId: number) {
    let url = this.urlDelMusicProgramPackage.replace(":packageId", packageId.toString());
    return this.http.delete(url);
  }

  public updateMusicPackagePhase(phase: IMusicPackagePhaseDco) {
    return this.http.put(this.urlPutMusicProgramPackagePhase, phase);
  }

  resetSongsFilters(filterField) {
    for (const filterCheck of this.songsFilterList.filter(x => x.field === filterField)) {
      filterCheck.checked = false;
    }
  }

  public getMusicIntervals(): Observable<any>  {
    return this.http.get(this.urlGetMusicTimeIntervals);
  }

  public createMusicPackageInterval(packageInterval: IMusicPackageInterval) {
    return this.http.post(this.urlPostMusicPackageInterval, packageInterval);
  }

  public deleteMusicPackageInterval(packageInterval: IMusicPackageInterval) {
    return this.http.request('delete', this.urlDelMusicPackageInterval, { body: packageInterval })
  }

}
