import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";

import { ISession } from "../interfaces/models/i-session";
// import { SessionMapper } from "./mappers/session-mapper";

import { Cacheable } from 'ngx-cacheable';

@Injectable()
export class WorkoutService {

  private urlGetWorkouts: string;
  
  constructor(private http: HttpClient) {
    this.urlGetWorkouts = CONFIG.serverUrl + ENDPOINTS.workouts.get;
  
  }

  //@Cacheable()
  public getWorkouts(): Observable<any> {
    return this.http.get(this.urlGetWorkouts);
  }
}
