import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";

import { ISession } from "../interfaces/models/i-session";
// import { SessionMapper } from "./mappers/session-mapper";

import { Cacheable } from 'ngx-cacheable';
import { IWorkout } from "../interfaces/models/i-workout";

@Injectable()
export class HomeFitnessWorkoutService {

  private urlGetWorkout: string;
  private urlGetWorkouts = CONFIG.serverUrl + ENDPOINTS.homeFitnessWorkouts.get;
  
  private urlPostWorkout = CONFIG.serverUrl + ENDPOINTS.homeFitnessWorkout.post;
  private urlPutWorkout = CONFIG.serverUrl + ENDPOINTS.homeFitnessWorkout.put;
  private urlDisableWorkout = CONFIG.serverUrl + ENDPOINTS.homeFitnessWorkout.delete;

  constructor(private http: HttpClient) {
  }

  //@Cacheable()
  public getWorkouts(): Observable<any> {
    return this.http.get(this.urlGetWorkouts);
  }


  @Cacheable()
  public getWorkout(workoutId: number): Observable<any> {
    this.urlGetWorkout = CONFIG.serverUrl + ENDPOINTS.homeFitnessWorkout.get
    this.urlGetWorkout = this.urlGetWorkout.replace(":id", workoutId.toString());
    return this.http.get(this.urlGetWorkout);
  }

  public putWorkout(workout: IWorkout): Observable<any> {
    const data = workout;
    return this.http.put(this.urlPutWorkout, data);
  }

  public postWorkout(workout: IWorkout): Observable<any> {
    const data = workout;
    return this.http.post(this.urlPostWorkout, data);
  }

  public deleteWorkout(workout: IWorkout): Observable<any> {
    let url = this.urlDisableWorkout.replace(":id", workout.id.toString());
    return this.http.put(url, null);
  }
}
