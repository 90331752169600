<div class="uk-grid-small" uk-grid>
  <div class="uk-width-2-5">

    <!-- STEP TYPE  -->
    <div class="uk-margin" *ngIf="this.formFields.sequenceTypeField.visible">
      <span class="uk-form-label yellow">{{ this.formFields.sequenceTypeField.nameToShow }}</span>
      <span class="uk-form-label error-message"  *ngIf="this.formFields.sequenceTypeField.error">{{ this.formFields.sequenceTypeField.errorMessage }}</span>
      <div class="uk-form-controls">
        <select class="uk-select prama-input"
          [id]="this.formFields.sequenceTypeField.name"
          [name]="this.formFields.sequenceTypeField.name"
          [required]="this.formFields.sequenceTypeField.required"
          [disabled]="this.formFields.sequenceTypeField.disabled"
          [(ngModel)]="this.formValues.stepTypeId"
          (change)="sequenceTypeChanged()">
          <option value="0">-</option>
          <option *ngFor="let type of this.availableValues.sequenceTypes" [value]="type.id">{{type.name}}</option>
        </select>
      </div>
    </div>

    <!-- COLOR TYPES -->
    <div class="uk-margin" *ngIf="this.formFields.colorModeField.visible">
      <span class="uk-form-label yellow">{{ this.formFields.colorModeField.nameToShow }}</span>
      <span class="uk-form-label error-message"  *ngIf="this.formFields.colorModeField.error">{{ this.formFields.colorModeField.errorMessage }}</span>
      <div class="uk-form-controls">
        <select class="uk-select prama-input"
          [id]="this.formFields.colorModeField.name"
          [name]="this.formFields.colorModeField.name"
          [required]="this.formFields.colorModeField.required"
          [disabled]="this.formFields.colorModeField.disabled"
          [(ngModel)]="this.formValues.colorTypeId"
          (change)="colorModeChanged()">
          <option *ngFor="let colorType of this.availableValues.colorTypes" [value]="colorType.id">{{colorType.name}}</option>
        </select>
      </div>
    </div>

    <!-- COLOR PICKER -->
    <sequence-color-picker *ngIf="this.formFields.colorPickerField.visible"
      [multiple]="false"
      [colors]="this.availableValues.presetColors"
      [selectedColor]="this.currentColor"
      [colorTiles]="this.colorTiles"
      (selectedColorEvent)="colorSelected($event)">
    </sequence-color-picker>

    <!-- TILE FROM-->
    <div class="uk-margin" *ngIf="this.formFields.tileFromField.visible">
      <span class="uk-form-label yellow">{{ this.formFields.tileFromField.nameToShow }}</span>
      <span class="uk-form-label error-message"  *ngIf="this.formFields.tileFromField.error">{{ this.formFields.tileFromField.errorMessage }}</span>
      <div class="uk-form-controls">
        <span class="number-wrapper">
            <input type="number" class="uk-input prama-input" [min]="this.formFields.tileFromField.minValue" [value]="this.formValues.tileFrom"  [(ngModel)]="this.formValues.tileFrom" />
        </span>
      </div>
    </div>

    <!-- NUMBER OF TIMES -->
    <div class="uk-margin" *ngIf="this.formFields.numberOfTimesField.visible">
      <span class="uk-form-label yellow">{{ this.formFields.numberOfTimesField.nameToShow }}</span>
      <span class="uk-form-label error-message"  *ngIf="this.formFields.numberOfTimesField.error">{{ this.formFields.numberOfTimesField.errorMessage }}</span>
      <div class="uk-form-controls">
        <span class="number-wrapper">
          <input type="number" class="uk-input prama-input" [min]="this.formFields.numberOfTimesField.minValue" [value]="this.formValues.numberOfTimes" [(ngModel)]="this.formValues.numberOfTimes" />
        </span>
      </div>
    </div>

    <div class="uk-margin" *ngIf="this.formFields.tile1Field.visible && this.formFields.tile2Field.visible">
      <!-- TILE1 -->
      <div class="halfWidth floatLeft clearLeft mb15">
        <span class="uk-form-label yellow">{{ this.formFields.tile1Field.nameToShow }}</span>
        <span class="uk-form-label error-message"  *ngIf="this.formFields.tile1Field.error">{{ this.formFields.tile1Field.errorMessage }}</span>
        <div class="uk-form-controls">
          <span class="number-wrapper">
            <input type="number" class="uk-input prama-input" [min]="this.formFields.tile1Field.minValue" [value]="this.formValues.tile1" [(ngModel)]="this.formValues.tile1" />
          </span>
        </div>
      </div>
       <!-- TILE2 -->
      <div class="halfWidth floatLeft mb15">
        <span class="uk-form-label yellow">{{ this.formFields.tile2Field.nameToShow }}</span>
        <span class="uk-form-label error-message"  *ngIf="this.formFields.tile2Field.error">{{ this.formFields.tile2Field.errorMessage }}</span>
        <div class="uk-form-controls">
          <span class="number-wrapper">
            <input type="number" class="uk-input prama-input" [min]="this.formFields.tile2Field.minValue" [value]="this.formValues.tile2" [(ngModel)]="this.formValues.tile2" />
          </span>
        </div>
      </div>
    </div>

    <!-- RESPONSE TYPES -->
    <div class="uk-margin" *ngIf="this.formFields.responseTypeField.visible">
      <span class="uk-form-label yellow">{{ this.formFields.responseTypeField.nameToShow }}</span>
      <span class="uk-form-label error-message"  *ngIf="this.formFields.responseTypeField.error">{{ this.formFields.responseTypeField.errorMessage }}</span>
      <div class="uk-form-controls">
        <select class="uk-select prama-input"
          [id]="this.formFields.responseTypeField.name"
          [name]="this.formFields.responseTypeField.name"
          [required]="this.formFields.responseTypeField.required"
          [disabled]="this.formFields.responseTypeField.disabled"
          [(ngModel)]="this.formValues.responseTypeId"
          (change)="responseTypeChanged()">
            <option *ngFor="let responseType of this.availableValues.responseTypes" [value]="responseType.id">{{responseType.name}}</option>
        </select>
      </div>
    </div>

    <!-- RESPONSE TIME -->
    <div class="uk-margin" *ngIf="this.formFields.timeField.visible">
      <span class="uk-form-label yellow">{{ this.formFields.timeField.nameToShow }}</span>
      <span class="uk-form-label error-message" *ngIf="this.formFields.timeField.error">{{ this.formFields.timeField.errorMessage }}</span>
      <div class="uk-form-controls">
        <input class="uk-input prama-input" type="number" step="50"
          [id]="this.formFields.timeField.name"
          [name]="this.formFields.timeField.name"
          [required]="this.formFields.timeField.required"
          [disabled]="this.formFields.timeField.disabled"
          [min]="this.formFields.timeField.minValue"
          [placeholder]="this.formFields.timeField.placeHolder"
          [(ngModel)]="this.formValues.responseTime">
      </div>
    </div>
    <div class="uk-width-1-1">
        <button class="uk-button uk-width-1-4 prama-button floatRight ml-3 mr0" title="Save" (click)="validateSequence()"><i class="fa fa-floppy-o faSize"></i></button>
        <button class="uk-button uk-width-1-4 prama-button floatRight" title="Cancel" (click)="goBack()"><i class="fa fa-share fa-flip-horizontal faSize"></i></button>
      </div>
  </div>
</div>
