// NO SE USA DE MOMENTO

import { Input } from '@angular/core';
import { Directive } from '@angular/core';
import { EBlock } from '../interfaces/models/block-editor';

@Directive({
  selector: '[editorBlock]'
})
export class EditorBlockDirective {
  @Input() editorBlock: EBlock;

  constructor() {
    console.log(this.editorBlock);
   }

}
