import { Component, OnInit, Input } from "@angular/core";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.css"]
})
export class SpinnerComponent implements OnInit {

  @Input() textToShow: string;

  constructor() { }

  ngOnInit() {
  }

}
