import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { StationService } from '../station.service';

import { of } from "rxjs";

@Injectable()
export class StationsResolver implements Resolve<Observable<string>> {

    constructor(private stationService: StationService) { }

    resolve() {
        return this.stationService.getStations().catch(() => {
            return of('error');
        });
    }

}

