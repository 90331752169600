import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { IColor } from "../../interfaces/models/i-color";
import { ISequenceTile } from "../../interfaces/models/sequence/i-sequence-tile";
import { ISequenceConfiguratorTile } from "../../interfaces/models/sequence/form/i-sequence-configurator-tile";
import { IFormField } from "../../interfaces/models/i-form-field";

@Component({
  selector: "sequence-combo",
  templateUrl: "./sequence-combo.component.html",
  styleUrls: ["./sequence-combo.component.css"]
})
export class SequenceComboComponent implements OnInit {

  @Input() multiple: boolean;
  @Input() color: IColor;
  @Input() stationId: number;
  @Input() tiles: ISequenceConfiguratorTile[];
  @Input() stationTilesField: IFormField;

  @Output() tileSelected: EventEmitter<number> = new EventEmitter<number>();
  @Output() tileDeselected: EventEmitter<number> = new EventEmitter<number>();

  constructor() { 
    console.log("sequence combo constructor");
  }

  ngOnInit() {
    console.log("sequence combo oninit");
    console.log(this.tiles);
    console.log(this.stationTilesField);
    console.log(this.stationId);
    console.log(this.color);
    console.log(this.multiple);
    console.log(this.tileSelected);
    console.log(this.tileDeselected);
  }


  tileClicked(tile: ISequenceConfiguratorTile) {
    if (tile.selected &&  tile.color && tile.color.hex !== this.color.hex) {
      tile.color = this.color;
      this.tileSelected.emit(tile.id);
    } else if (tile.selected && tile.color && tile.color.hex === tile.color.hex) {
      tile.selected = false;
      tile.color = undefined;
      this.tileDeselected.emit(tile.id);
    } else {
      if (this.color !== undefined) {
        if (this.multiple) {
          tile.selected = true;
          tile.color = this.color;
        } else {
          this.tiles.forEach(x => {
            x.selected = false;
            x.color = undefined;
          });
          tile.selected = true;
          tile.color = this.color;
        }
        this.tileSelected.emit(tile.id);
      }
    }
  }


  toggleTile() {

  }
}
