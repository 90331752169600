import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';

import { NotificationService } from '../../services/notification.service';
import { BlocksWorkoutDto } from '../../interfaces/models/blocks-workout-dto';


@Component({
  selector: 'workout-list',
  templateUrl: './workout-list.component.html',
  styleUrls: ['./workout-list.component.css']
})
export class WorkoutListComponent implements OnInit {

  canCreate: boolean;
  canModify: boolean;
  canDelete: boolean;

  headers = {
    name: "Name",
    actions: "Actions"
  };

  workoutList: BlocksWorkoutDto[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns = ['name'];
  dataSource = new MatTableDataSource;


  constructor(public route: ActivatedRoute, private router: Router, 
    public dialog: MatDialog,
    private notificationService: NotificationService) { 

    this.workoutList = this.route.snapshot.data.workouts;

    // roles
    this.canModify = true;
    this.canCreate = true;
    this.canDelete = true;
  }

  
  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });   
  }


  ngAfterViewInit() {
    this.updateTableData();   
  }


  updateTableData(){
    this.dataSource = new MatTableDataSource(this.workoutList);    
  }


  scrollTableToTop() {
    try {
      const matTable= document.getElementById('workoutTable');
      matTable.scrollTop = 0;
    } catch(err) 
    {
      console.log(err);
    }      
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

}
