import { IFormField } from "../interfaces/models/i-form-field";
import { IFormFieldsExercise } from "../interfaces/models/i-form-fields-exercise";
import { IFormProgram } from "../interfaces/models/i-form-program";
import { IExerciseForm } from "../interfaces/models/i-exercise-form";
import { IStation } from "../interfaces/models/i-station";
import { IStationType } from "../interfaces/models/i-station-type";
import { CONFIG } from "../../assets/config";


export class ExerciseFormHelper {
  static getFormFields(): IFormFieldsExercise {

    const fields: IFormFieldsExercise = {
      idField: {
        name: "exercise-id-input",
        nameToShow: "Exercise ID",
        placeHolder: "ID",
        visible: true,
        disabled: true,
        required: false,
        error: false,
        errorMessage: "",
        minValue: undefined
      },
      nameField: {
        name: "exercise-name-input",
        nameToShow: "Exercise Name",
        placeHolder: "Name",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Exercise name required",
        minValue: undefined
      },
      stationField: {
        name: "exercise-station-input",
        nameToShow: "Station",
        placeHolder: "Station",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Station required",
        minValue: undefined
      },
      playersField: {
        name: "exercise-players-input",
        nameToShow: "Number of players",
        placeHolder: "players",
        visible: true,
        disabled: true,
        required: false,
        error: false,
        errorMessage: "Number of players required",
        minValue: undefined
      },
      imageField: {
        name: "exercise-image-input",
        nameToShow: "Image",
        placeHolder: "",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Image required",
        minValue: undefined
      },
      exerciseTypeField: {
        name: "exercise-type-input",
        nameToShow: "Type",
        placeHolder: "type",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Exercise type required",
        minValue: undefined
      },
      exerciseSkillField: {
        name: "exercise-skill-input",
        nameToShow: "Skill",
        placeHolder: "skill",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Exercise skill required",
        minValue: undefined
      },
      cardioLevelField: {
        name: "exercise-cardiolevel-input",
        nameToShow: "Cardio",
        placeHolder: "cardio",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Cardio level required",
        minValue: undefined
      },
      strengthLevelField: {
        name: "exercise-strengthlevel-input",
        nameToShow: "Strength",
        placeHolder: "strength",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Strength level required",
        minValue: undefined
      },
      complexityLevelField: {
        name: "exercise-complexitylevel-input",
        nameToShow: "Complexity",
        placeHolder: "complexity",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Complexity level required",
        minValue: undefined
      },
      intensityLevelField: {
        name: "exercise-intensitylevel-input",
        nameToShow: "Intensity",
        placeHolder: "intensity",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Intensity level required",
        minValue: undefined
      },
      videoField: {
        name: "exercise-video-input",
        nameToShow: "Video",
        placeHolder: "video",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Video required",
        minValue: undefined
      },
      tagsField: {
        name: "exercise-tags-input",
        nameToShow: "Tags",
        placeHolder: "tags",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "At least two tags required",
        minValue: 2
      },
      equipmentField: {
        name: "exercise-equipment-input",
        nameToShow: "Equipment",
        placeHolder: "equipment",
        visible: true,
        disabled: false,
        required: false,
        error: false,
        errorMessage: "",
        minValue: undefined
      },
      programsField: {
        name: "exercise-programs-input",
        nameToShow: "Program",
        placeHolder: "program",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "At least one program must be selected",
        minValue: 1
      },
      programField: {
        name: "exercise-program-input",
        nameToShow: "Program",
        placeHolder: "program",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Program required",
        minValue: 1
      },
      muscularGroup1Field: {
        name: "exercise-musculargroup1-input",
        nameToShow: "Muscular group",
        placeHolder: "muscular group",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Muscular group required",
        minValue: undefined
      },
      muscularGroup2Field: {
        name: "exercise-musculargroup2-input",
        nameToShow: "Muscular group (secondary)",
        placeHolder: "muscular group secondary",
        visible: true,
        disabled: false,
        required: false,
        error: false,
        errorMessage: "",
        minValue: undefined
      },
      fundamentalMovement1Field: {
        name: "exercise-fundamentalmovement1-input",
        nameToShow: "Fundamental movement",
        placeHolder: "fundamental movement",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Fundamental movement required",
        minValue: undefined
      },
      fundamentalMovement2Field: {
        name: "exercise-fundamentalmovement2-input",
        nameToShow: "Fundamental movement (secondary)",
        placeHolder: "fundamental movement secondary",
        visible: true,
        disabled: false,
        required: false,
        error: false,
        errorMessage: "",
        minValue: undefined
      },
      disciplineField: {
        name: "exercise-discipline-input",
        nameToShow: "Discipline",
        placeHolder: "discipline",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "",
        minValue: undefined
      },
      weightMaleMinField: {
        name: "exercise-weightmalemin-input",
        nameToShow: "Min weight (male)",
        placeHolder: "min weight male",
        visible: true,
        disabled: false,
        required: false,
        error: false,
        errorMessage: "",
        minValue: undefined
      },
      weightMaleMaxField: {
        name: "exercise-weightmalemax-input",
        nameToShow: "Max weight (male)",
        placeHolder: "max weight male",
        visible: true,
        disabled: false,
        required: false,
        error: false,
        errorMessage: "",
        minValue: undefined
      },
      weightFemaleMinField: {
        name: "exercise-weightfemalemin-input",
        nameToShow: "Min weight (female)",
        placeHolder: "min weight female",
        visible: true,
        disabled: false,
        required: false,
        error: false,
        errorMessage: "",
        minValue: undefined
      },
      weightFemaleMaxField: {
        name: "exercise-weightfemalemax-input",
        nameToShow: "Max weight (female)",
        placeHolder: "max weight female",
        visible: true,
        disabled: false,
        required: false,
        error: false,
        errorMessage: "",
        minValue: undefined
      }
    };
    return fields;
  }


  static isSupportedImage(mimeType: string, extension: string): boolean {
    let supported = false;

    mimeType = mimeType.toLowerCase();
    extension = extension.toLowerCase();

    if ( CONFIG.supportedImageFormats.some( format => format.extension.toLowerCase() === extension
          && format.mimeType.toLowerCase() === mimeType)) {
      supported = true;
    }

    return supported;
  }

  static isSupportedVideo(mimeType: string, extension: string): boolean {
    let supported = false;
    mimeType = mimeType.toLowerCase();
    extension = extension.toLowerCase();

    if ( CONFIG.supportedVideoFormats.some( format => format.extension.toLowerCase() === extension
        && format.mimeType.toLowerCase() === mimeType)) {
      supported = true;
    }

    return supported;
  }

  static validateTextField(field: IFormField, inputText: string) {
    field.error = false;
    if (field.required && (!inputText || inputText === "")) {
      field.error = true;
    }
  }

  static validateNumberField(field: IFormField, inputNumber: number) {
    field.error = false;
    if (field.required && (!inputNumber || inputNumber === 0)) {
      field.error = true;
    }
  }

  static validateArrayField(field: IFormField, elements) {
    field.error = false;
    if (field.required && (!elements || elements.length < field.minValue)) {
      field.error = true;
    }
  }
}




