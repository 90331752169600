import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ProgramService } from '../program.service';

import { of } from "rxjs";
import { PlaylistsService } from '../playlists.service';


@Injectable()
export class PlaylistSongsResolver implements Resolve<Observable<string>> {

    constructor(private playlistsService: PlaylistsService) { }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('phaseId') !== null && route.paramMap.get('phaseId') !== undefined) {
            return this.playlistsService.getPlaylistSongs(Number(route.paramMap.get('phaseId'))).catch(() => {
                return of('error');
            });
        } 
    }
}

