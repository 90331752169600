import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { WorkoutService } from '../workout.service';

import { of } from "rxjs";


@Injectable()
export class WorkoutsResolver implements Resolve<Observable<string>> {

    constructor(private workoutService: WorkoutService) { }

    resolve() {
        return this.workoutService.getWorkouts().catch(() => {
            return of('error');
        });
    }

}

