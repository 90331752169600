import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FileDropDirective, FileUploader, FileUploaderOptions } from "ng2-file-upload";

import { ExerciseFormHelper } from "../../helpers/exercise-form-helper";

import { ExerciseService } from "../../services/exercise.service";
import { NotificationService } from "../../services/notification.service";
import { UploaderService } from "../../services/uploader-service";

import { IFormField } from "../../interfaces/models/i-form-field";
import { IMediaTempUploaded } from "../../interfaces/models/i-media-temp-uploaded";

import { CONFIG } from "../../../assets/config";
import { ENUMS } from "../../../assets/enums";
import { ISong } from "../../interfaces/models/i-song";
import { MusicService } from "../../services/music-service";

declare var UIkit: any;
@Component({
  selector: 'music-uploader',
  templateUrl: './music-uploader.component.html',
  styleUrls: ['./music-uploader.component.scss']
})
export class MusicUploaderComponent implements OnInit {

  selectedFileName: string;
  fileSelected = false;
  fileUrl: string = "";
  dropOver: boolean;
  uploading: boolean = false;
  textToShow: string = "";
  currentMode="determinate";
  uploadProgress: number = 0;
  bufferUploadProgress: number = 0;

  @Input() formField: IFormField;
  @Input() editMode: boolean;
  @Input() songs: ISong[];

  @Output() uploadedMusicEvent: EventEmitter<string[]> = new EventEmitter<string[]>();


  constructor(private musicService: MusicService, private notificationService: NotificationService) {
    this.uploading = false;
    this.dropOver = false;
    this.fileSelected = false;
  }


  ngOnInit() {
  }


  dragEnter(event) {
    this.dropOver = true;
    event.preventDefault();
    event.stopPropagation();
  }


  dragLeave(event) {
    this.dropOver = false;
  }


  dragOver(event) {
    this.dropOver = true;
    event.preventDefault();
    event.stopPropagation();
  }


  dragEnd(event) {
  }


  dragStart(event) {
  }


  dropFile(event) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      const mime = file.type;
      const extension = file.name.substring(file.name.lastIndexOf("."));
      this.uploadSelectedFile(file);
    }
    this.dropOver = false;
  }


  selectFile(event) {
    if (event.target.files && event.target.files[0]) {
      this.uploadSelectedFile(event.target.files[0]);
    }
  }


  uploadSelectedFile(file) {
    const formData = new FormData();
    formData.append(file.name, file);

    this.musicService.postMusicFile(formData).subscribe(
      (res) => {
        switch (res.status) {
          case "sent":
            this.uploading = true;
            break;
          case "progress":
            if(res.message > this.uploadProgress /* && res.message%4 == 0*/) {
              this.uploadProgress = Number(res.message);
              this.bufferUploadProgress = this.uploadProgress+5;
              if(res.message == 100){
                this.currentMode = "indeterminate";
              }
            }
            break;
          case "response":
            this.uploading = false;
            this.uploadedMusicEvent.emit(res.message[0]);
            break;
          case "error":
            // notify to user 
            this.uploading = false;
            console.log(res.message);
            break;
        }
      },
      (err) => {
         console.log(err)
      }
    );
  }


  removeSelection() {
  
  }
}
