import { Injectable } from "@angular/core";

import { ISequenceConfiguratorFormValues } from "../../interfaces/models/sequence/form/i-sequence-configurator-form-values";
import { ISequenceComboBlock } from "../../interfaces/models/sequence/i-sequence-combo-block";
import { ISequenceType } from "../../interfaces/models/sequence/i-sequence-type";
import { ISequenceCombo } from "../../interfaces/models/sequence/i-sequence-combo";
import { ISequenceBlockInput } from "../../interfaces/models/sequence/i-sequence-block-input";
import { ISequenceBlockBehavior } from "../../interfaces/models/sequence/i-sequence-block-behavior";
import { ISequenceBlockCondition } from "../../interfaces/models/sequence/i-sequence-block-condition";
import { ISequenceComboDTO } from "../../interfaces/models/sequence/dto/i-sequence-combo-dto";
import { ISequenceComboBlockDTO } from "../../interfaces/models/sequence/dto/i-sequence-combo-block-dto";
import { ISequenceBlockInputDTO } from "../../interfaces/models/sequence/dto/i-sequence-block-input-DTO";
import { ISequenceBlockBehaviorDTO } from "../../interfaces/models/sequence/dto/i-sequence-block-behavior-dto";
import { ISequenceBlockConditionDTO } from "../../interfaces/models/sequence/dto/i-sequence-block-condition-dto";
import { ISequenceTileDTO } from "../../interfaces/models/sequence/dto/i-sequence-tile-dto";
import { ISequenceTile } from "../../interfaces/models/sequence/i-sequence-tile";
import { ISequenceComboBlockPlayer } from "../../interfaces/models/sequence/i-sequence-combo-block-player";

@Injectable()
export class SequenceMapper {

  constructor() {
  }


  SequenceFormValuesToSequenceBlock(formValues: ISequenceConfiguratorFormValues) {

  }


  SequenceComboDTOToSequenceCombo(dto: ISequenceComboDTO): ISequenceCombo {
    const sequenceCombo: ISequenceCombo = {
      id: dto.id,
      players: dto.players,
      exerciseId: dto.exercise,
      exercise: undefined,
      creationDate: dto.creationDate,
      blocks: dto.blocks.map(b => this.SequenceComboBlockDTOToSequenceComboBlock(b))
    };

    sequenceCombo.blocks.forEach(b => {
      b.sequenceCombo = sequenceCombo;
      b.inputs.forEach(i => i.block = b);
      b.conditions.forEach(c => c.comboBlock = b);
      b.behaviors.forEach(bh =>  {
        bh.comboBlock = b;
        bh.tiles.forEach(t => t.blockBehavior = bh.id);
      });
    });

    return sequenceCombo;
  }


  SequenceComboBlockDTOToSequenceComboBlock(dto: ISequenceComboBlockDTO): ISequenceComboBlock {
    const block: ISequenceComboBlock = {
      id: dto.id,
      player: dto.player,
      sequenceComboId: dto.idSequenceCombo,
      sequenceCombo: undefined,
      sequenceTypeId: dto.sequenceType,
      sequenceType: undefined,
      inputs: dto.inputs.map(i => this.SequenceBlockInputDTOToSequenceBlockInput(i)),
      behaviors: dto.behavior.map(b => this.SequenceBlockBehaviorDTOToSequenceBlockBehavior(b)),
      conditions: dto.condition.map(c => this.SequenceBlockConditionDTOToSequenceBlockCondition(c))
    };

    return block;
  }


  SequenceBlockInputDTOToSequenceBlockInput(dto: ISequenceBlockInputDTO): ISequenceBlockInput {
    const input: ISequenceBlockInput = {
      id: dto.id,
      blockId: dto.block,
      block: undefined,
      sourceBlockId: dto.input,
      sourceBlock: undefined
    };

    return input;
  }


  SequenceBlockBehaviorDTOToSequenceBlockBehavior(dto: ISequenceBlockBehaviorDTO): ISequenceBlockBehavior {
    const behavior: ISequenceBlockBehavior = {
      id: dto.id,
      comboBlock: undefined,
      comboBlockId: dto.comboBlock,
      conditionType: undefined,
      conditionTypeId: dto.type,
      colorMode: undefined,
      colorModeId: dto.colorMode,
      responseType: undefined,
      responseTypeId: dto.responseType,
      tile: undefined,
      tileId: dto.tile,
      tiles: dto.tiles.map(t => this.SequenceTileDTOToSequenceTile(t)),
      time: dto.time,
      colors: dto.colors
    };

    return behavior;
  }


  SequenceBlockConditionDTOToSequenceBlockCondition(dto: ISequenceBlockConditionDTO): ISequenceBlockCondition {
    const condition: ISequenceBlockCondition = {
      id: dto.id,
      conditionTypeId: dto.type,
      conditionType: undefined,
      comboBlockId: dto.comboBlock,
      comboBlock: undefined,
      sourceComboBlockId: dto.sourceBlock,
      sourceComboBlock: undefined
    };

    return condition;
  }


  SequenceTileDTOToSequenceTile(dto: ISequenceTileDTO): ISequenceTile {
    const tile: ISequenceTile = {
      id: dto.id,
      tileNumber: dto.tile,
      blockBehavior: dto.blockBehavior
    };
    return tile;
  }


  SequenceComboBlockToSequenceComboBlockPlayer(scb: ISequenceComboBlock): ISequenceComboBlockPlayer {
    const scbp: ISequenceComboBlockPlayer = {
      id: scb.id,
      player: scb.player,
      behaviorOn: scb.behaviors.find( x => x.conditionTypeId === 1),
      sequenceCombo: scb.sequenceCombo,
      sequenceComboId: scb.sequenceComboId,
      sequenceType: scb.sequenceType,
      sequenceTypeId: scb.sequenceTypeId
    };
    return scbp;
  }


  SequenceComboToSequenceComboDTO(sequence: ISequenceCombo): ISequenceComboDTO {
    const dto: ISequenceComboDTO = {
      id: sequence.id,
      exercise: sequence.exerciseId,
      players: sequence.players,
      creationDate: sequence.creationDate,
      blocks: sequence.blocks.map(b => this.SequenceComboBlockToSequenceComboBlockDTO(b))
    };

    return dto;
  }


  SequenceComboBlockToSequenceComboBlockDTO(block: ISequenceComboBlock): ISequenceComboBlockDTO {
    const dto: ISequenceComboBlockDTO  = {
      id: block.id,
      idSequenceCombo: block.sequenceComboId,
      player: block.player,
      sequenceType: block.sequenceTypeId,
      inputs: block.inputs.map( i => this.SequenceBlockInputToSequenceBlockInputDTO(i)),
      behavior: block.behaviors.map( b => this.SequenceBlockBehaviorToSequenceBlockBehaviorDTO(b)),
      condition: block.conditions.map( c => this.SequenceBlockConditionToSequenceBlockConditionDTO(c))
    };

    return dto;
  }


  SequenceBlockInputToSequenceBlockInputDTO(input: ISequenceBlockInput): ISequenceBlockInputDTO {
    const dto: ISequenceBlockInputDTO = {
      id: input.id,
      block: input.blockId,
      input: input.sourceBlockId
    };
    return dto;
  }


  SequenceBlockBehaviorToSequenceBlockBehaviorDTO(behavior: ISequenceBlockBehavior): ISequenceBlockBehaviorDTO {
    const dto: ISequenceBlockBehaviorDTO = {
      id: behavior.id,
      type: behavior.conditionTypeId,
      comboBlock: behavior.comboBlockId,
      colorMode: behavior.colorModeId,
      colors: behavior.colors,
      responseType: behavior.responseTypeId,
      tile: behavior.tileId,
      tiles: behavior.tiles.map( t => this.SequenceTileToSequenceTileDTO(t)),
      time: behavior.time
    };
    return dto;
  }


  SequenceBlockConditionToSequenceBlockConditionDTO(condition: ISequenceBlockCondition): ISequenceBlockConditionDTO {
    const dto: ISequenceBlockConditionDTO = {
      id: condition.id,
      comboBlock: condition.comboBlockId,
      type: condition.conditionTypeId,
      sourceBlock: condition.sourceComboBlockId
    };
    return dto;
  }


  SequenceTileToSequenceTileDTO(dto: ISequenceTile): ISequenceTileDTO {
    const tile: ISequenceTileDTO = {
      id: dto.id,
      tile: dto.tileNumber,
      blockBehavior: dto.blockBehavior
    };
    return tile;
  }
}

