import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ITriggerType } from '../../interfaces/models/i-trigger-type';
import { ITriggerAudio } from '../../interfaces/models/i-trigger-audio';
import { ITriggerMood } from '../../interfaces/models/i-trigger-mood';
import { ITrigger } from '../../interfaces/models/i-trigger';
import { ITimeline, ITimelineObjects } from '../../interfaces/models/i-timeline';
// import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'session-trigger',
  templateUrl: './session-trigger.component.html',
  styleUrls: ['./session-trigger.component.scss']
})
export class SessionTriggerComponent implements OnInit {

  @Input() triggers: number[];

  //triggers: number[];
  triggerList: ITrigger[];
  triggerTypes: ITriggerType[];
  // triggerAudios: ITriggerAudio[];
  // triggerMoods: ITriggerMood[];

  triggerAux: number[];

  constructor(private route: ActivatedRoute) {
    this.triggerList = this.route.snapshot.data.triggerList;
    this.triggerTypes = this.route.snapshot.data.triggerTypes;
    // this.triggerAudios = this.route.snapshot.data.triggerAudios;
    // this.triggerMoods = this.route.snapshot.data.triggerMoods;
    // this.triggers = this.route.snapshot.data.timeline.session.triggers;
    
  }

  ngOnInit() {
    this.triggerAux = [];
    if (!this.triggers) {
      this.triggers = [];
    } else {
      // We create an Aux array to store temp values of triggers in [triggerType] position
      for (let triggerType of this.triggerTypes) {
        this.triggerAux[triggerType.id] = this.getSelectedTrigger(triggerType);
      }
    }
    
    this.handleTriggers(this.triggers);
  }

  getTriggersByType(typeId: number) {
    return this.triggerList.filter(x => x.type === typeId);
  }
  // getAudiosByType(typeId: number){
  //   return this.triggerAudios.filter(x => x.triggerId === typeId);
  // }
  // getMoodsByType(typeId: number){
  //   return this.triggerMoods.filter(x => x.triggerId === typeId);
  // }

  getSelectedTrigger(triggerType: ITriggerType): number {
    if (this.triggers !== undefined && this.triggers !== null) {
      for (let trigger of this.triggers) {
        if (this.triggerList.findIndex(x => x.type === triggerType.id && x.id === trigger) > -1) {
          return trigger;
        }
      }

      // for(let trigger of this.triggerList){
      //   if(this.triggers.findIndex(x => x === trigger.))
      // }
    }
    return 0;
  }

  changeTrigger($event, triggerType?) {
    // if (this.triggers) {
    //   const index = this.triggers.indexOf($event.value, 0);
    //   if (index > -1) {
    //     this.triggers.splice(index, 1);
    //     return;
    //   } 
    // }
    this.triggerAux[triggerType.id] = $event.value;

    //this.triggers.push($event.value);
    this.triggers.length = 0;
    this.triggerAux.forEach((value) => {
      if (value !== 0) {
        this.triggers.push(value);
      }
    });

  }

  // changeTrigger2(triggerType: ITriggerType) {
  changeTrigger2(triggerType: ITriggerType) {

    switch (triggerType.checked) {
      case true:
        const mergedTriggers = this.uniqueElems(this.triggers.concat(triggerType.triggers));
        
        this.triggers.length = 0;
        mergedTriggers.forEach(trigger => {
          this.triggers.push(trigger);
        });

        break;
      case false:
        triggerType.triggers.forEach((trigger) => {
          const index = this.triggers.findIndex(x => x === trigger);
          if (index > -1) {
            this.triggers.splice(index, 1);
          };
        });
        break;
    }

    // const mergedTriggers = this.uniqueElems(this.triggers.concat(triggerType.triggers));
    // this.triggers = mergedTriggers;

  }

  uniqueElems(arrArg) {
    return arrArg.filter((elem, pos, arr) => {
      return arr.indexOf(elem) == pos;
    });
  }

  handleTriggers(tlTriggerTypes: number[]) {
    this.triggerTypes.forEach( (triggerType)=>{
      triggerType.checked =  triggerType.triggers.some( x => tlTriggerTypes.includes(x));
 // if(this.arrayIncludes(this.triggers, triggerType.triggers)){
     //   triggerType.checked = true;
     // }
    });
  }

  // check if the trigger type triggers types contains the timeline triggers types
  triggerTypeContainsTriggers(triggerType: ITriggerType, tlTriggers: ITrigger[]) {
    let included = tlTriggers.some(t => triggerType.triggers.includes(t.type));;

    return included;
    /*
    tlTriggers.forEach((item)=>{
      if(triggerType.triggers.includes(item.type)) {
        included = true;
        break;
      }
    });
    
    return included;
    */
  }

  arrayIncludes(containerArray: any[], containedArray: any[]){
    let included = true;
    containedArray.forEach((item)=>{
      if(containerArray.findIndex(x => x === item) === -1){
        included = false;
      }
    });
    return included;
  }


}
