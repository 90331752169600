export const ENUMS = {
  notification: {
    applicationMessages: {
      connectivityError: "Can't connect with the server",
      tokenExpired: "Your session has expired. Please, log in again",
      needAuth: "You need to sign in to access"
    },
    positions: {
      top_left: "top-left",
      top_center: "top-center",
      top_right: "top-right",
      bottom_left: "bottom-left",
      bottom_center: "bottom-center",
      bottom_right: "bottom-right",
      center: "center",
      custom_responsive: "big vh: bottom-center, small vh: top-center"
    },
    types: {
      warning: "warning",
      error: "danger",
      info: "success"
    }
  },
  keys: {
    session: "ap-session",
    roles: "ap-roles"
  },
  loginStatus: {
    logged: 1,
    expired: 2,
    notLogged: 3,
    invalidCredentials: 4
  },
  moods: {
    types: {
      standBy: 1,
      countDown: 2,
      work: 3,
      rest: 4
    },
    colors: [
      {
        id: 11,
        class: "mood-off",
      },
      {
        id: 1,
        class: "mood-red"
      },
      {
        id: 2,
        class:  "mood-yellow"
      },
      {
        id: 3,
        class:  "mood-green"
      },
      {
        id: 4,
        class:  "mood-blue"
      },
      {
        id: 5,
        class:  "mood-pink"
      },
      {
        id: 6,
        class:  "mood-purple"
      },
      {
        id: 7,
        class:  "mood-bluebeat"
      },
      {
        id: 8,
        class:  "mood-flash"
      },
      {
        id: 9,
        class:  "mood-purpleblue"
      },
      {
        id: 10,
        class:  "mood-tuttifruti"
      }
    ]
  },
  timeline: {
    musicPhases: {
      welcome: 1,
      explanation: 2,
      warmup: 3,
      gt_positioning: 4,
      gt_preparation: 5,
      gt_work: 6,
      gt_rest: 7,
      gt_extrarest: 8,
      gt_results: 9,
      finisher_explanation: 10,
      finisher_positioning: 11,
      finisher_preparation: 12,
      finisher_work: 13,
      finisher_rest: 14,
      finisher_extrarest: 15,
      finisher_results: 16,
      cooldown: 17,
      ending: 18
    },
    phases:  {
      welcome: 0,
      explanation: 1,
      warmup: 2,
      gt_positioning: 3,
      gt_preparation: 4,
      gt_execution: 5,
      gt_results: 6,
      finisher_positioning: 7,
      finisher_preparation: 8,
      finisher_execution: 9,
      finisher_results: 10,
      cooldown: 11,
      ending: 12,
      single_gt_welcome: 13,
      single_gt_positioning: 14,
      single_gt_preparation: 15,
      single_gt_execution: 16,
      single_gt_results: 17,
      gt_positioning_management: 18,
      finisher_summary: 19
    },
  },
  programTypes: {
    program: 0,
    package: 1,
    oldProgram: 2
  },
  finisherTypes: {
    challenge: 0,
    time: 1,
    rounds: 2
  },
  musicPackageStatus: {
    open: 1,
    closed: 2,
    scheduled: 3,
    shared: 4
  },
  homeFitnessSessionTypes:{
    warmup: 2,
    session: 3,
    cooldown: 4
  },
  languageResourceKeys: {
    workoutName: "TABLE_WORKOUT_NAME_30",
    workoutDesc: "TABLE_WORKOUT_DESC_20",
    workoutLongDesc: "TABLE_WORKOUT_LONG_DESC_21"
  },
  roles: {
    creator_user: 43,
    creator_workout_list: 64,
    creator_workout_create: 65,
    creator_workout_modify: 66,
    creator_workout_delete: 67,
    creator_exercise_list: 68,
    creator_exercise_create: 69,
    creator_exercise_modify: 70,
    creator_exercise_delete: 71,
    creator_category_list: 72,
    creator_category_create: 73,
    creator_category_modify: 74,
    creator_category_delete: 75,
    creator_gt_list: 76,
    creator_gt_create: 77,
    creator_gt_modify: 78,
    creator_gt_delete: 79,
    creator_timeline_list: 80,
    creator_timeline_create: 81,
    creator_timeline_modify: 82,
    creator_timeline_delete: 83,
    creator_finisher_list: 84,
    creator_finisher_create: 85,
    creator_finisher_modify: 86,
    creator_finisher_delete: 87,
    creator_program_manager_list: 88,
    creator_program_manager_create: 89,
    creator_program_manager_modify: 90,
    creator_program_manager_delete: 91,
    creator_program_content_list: 92,
    creator_program_content_modify: 93,
    creator_program_basics_list: 94,
    creator_program_basics_modify: 95,
    creator_music: 96,
    creator_homefitness: 97,
    pavigym: 98
  }
};
