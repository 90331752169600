<div class="home">

  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
    <h5 fxFlex="nogrow">MANAGEMENT</h5>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxFlexAlign="stretch" fxFill>
    <!-- PROVIDERS -->
    <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch">
      <mat-card fxFlex>
        <mat-card-header>
          <h5 class="nomargin mb10">Providers</h5>
        </mat-card-header>
        <mat-card-content>

          <div fxLayout="column" fxLayout.xs="column">
            <p>Manage music providers</p>
          </div>
        </mat-card-content>
        <mat-card-actions class="text-right">
          <hr>
          <button mat-flat-button class="prama-button float-right" [routerLink]="['/music/providers']">
            GO TO PROVIDERS <mat-icon>arrow_forward</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
    <!-- PLANNER -->
    <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch">
      <mat-card fxFlex>
        <mat-card-header>
          <h5 class="nomargin mb10">SCHEDULER</h5>
        </mat-card-header>
        <mat-card-content>

          <div fxLayout="column" fxLayout.xs="column">
            <p>Schedule the available package for each program and time interval</p>
          </div>
        </mat-card-content>
        <mat-card-actions class="text-right">
          <hr>
          <button mat-flat-button class="prama-button float-right" [routerLink]="['/music/scheduler']">
            GO TO SCHEDULER <mat-icon>arrow_forward</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
    <h5 fxFlex="nogrow">MUSIC</h5>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxFlexAlign="stretch" fxFill>
    <!-- PROGRAMS AND PACKAGES -->
    <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch">
      <mat-card fxFlex>
        <mat-card-header>
          <h5 class="nomargin mb10">Packages</h5>
        </mat-card-header>
        <mat-card-content>

          <div fxLayout="row">
            <p>Manage music in packages for each program and their phases </p>    
          </div>        
          <a *ngFor="let program of programs" [routerLink]="['/music/packages', program.id ]" class="yellow" >
            <div fxLayout="row" >
              <hr>
              {{program.name | uppercase}}
            </div>
          </a>
          </mat-card-content>
      </mat-card>
    </div>
    <!-- EXPLORER -->
    <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch">
      <mat-card fxFlex>
        <mat-card-header>
          <h5 class="nomargin mb10">EXPLORER</h5>
        </mat-card-header>
        <mat-card-content>

          <div fxLayout="column" fxLayout.xs="column">
            <p>Upload the music for each program and phase. Manage your favourite tracks. </p>
          </div>
        </mat-card-content>
        <mat-card-actions class="text-right">
          <hr>
          <button mat-flat-button class="prama-button float-right" [routerLink]="['/music/songs']">
            GO TO EXPLORER <mat-icon>arrow_forward</mat-icon>
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>