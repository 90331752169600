import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { QuestionBase } from '../../interfaces/models/i-question-base';
import { concat } from 'rxjs/operators';
import { DropdownQuestion } from '../../interfaces/models/i-question-dropdown';

@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html'
})
export class DynamicFormQuestionComponent {
  @Input() question: QuestionBase<any>;
  @Input() form: FormGroup;
  image: string;

  get isValid() { return this.form.controls[this.question.key].valid; }

  ngOnInit() {

    if (this.question.constructor.name === 'DropdownQuestion') {
      var aux: DropdownQuestion = <DropdownQuestion>this.question;
      let option = aux.options.find(x => x.key == this.question.value);
     
      if (option != undefined && option.image != undefined && option.image !== '') {
        this.image = option.image;
      }
    }


  }


  onChange($event: any, options: any) {
    let option = options.find(x => x.key == $event.value);
    this.image = option.image;
  }

}
