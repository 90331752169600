import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { CONFIG } from "../../../assets/config";
import { ENUMS } from "../../../assets/enums";

import { ITimeline, ITimelineActivation, ITimelineProgramFull, IProgramExercises } from "../../interfaces/models/i-timeline";
import { IProgram } from "../../interfaces/models/i-program";
import { TimelineService } from "../../services/timeline.service";
import { NotificationService } from "../../services/notification.service";

import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: "programs-list",
  templateUrl: "./programs-list.component.html",
  styleUrls: ["./programs-list.component.css"]
})
export class ProgramsListComponent implements OnInit {

  mediaUrl: string = CONFIG.sessionMediaURL;

  headers = {
    id: "Id",
    name: "Name",
    thumbnail: " ",
    creationDate: "Creation date",
    lastUpdate: "Last Update",
    actions: ""
  };

  globalObject: ITimelineProgramFull;

  timelines: ITimelineActivation[];
  timelinesActive: ITimelineActivation[] = [];
  timelinesAvailable: ITimelineActivation[] = [];
  exercises: IProgramExercises[];
  programsAvailable: IProgram[];

  currentProgramSelected: number = 1;

  displayedColumnsActive = ['id', 'name', 'status', 'activeFrom', 'activeUntil', 'options'];
  displayedColumnsAvailable = ['id', 'name', 'options'];
  displayedColumnsExercises = ['id', 'name', 'options'];
  dataSourceActive = new MatTableDataSource;
  dataSourceAvailable = new MatTableDataSource;
  dataSourceExercises = new MatTableDataSource;

  DATE_FROM_ALWAYS = new Date("0001-01-01");
  DATE_UNTIL_ALWAYS = new Date("9999-01-01");
  DATE_NULL = new Date(null);
  MS_PER_DAY = 1000 * 60 * 60 * 24;

  constructor(private timelineService: TimelineService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService) {

    //this.timelinesToShow = this.route.snapshot.data.timelines;
    this.programsAvailable = this.route.snapshot.data.programs;
  }

  ngOnInit() {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });

    this.loadData();
  }

  ngAfterViewInit() {
    //this.updateTableData();
  }

  loadData() {
    this.loadProgramSessions(this.currentProgramSelected);
  }

  processData() {
    //this.timelinesActive = this.timelines;

    this.timelinesActive.length = 0;
    this.timelinesAvailable.length = 0;

    var currentTime = new Date();
    currentTime.setHours(0, 0, 0, 0);

    for (const timeline of this.timelines) {

      const activeFrom = new Date(timeline.activeFrom);
      const activeUntil = new Date(timeline.activeUntil);

      if (
        ((activeUntil.getTime() >= currentTime.getTime() && activeUntil.getTime() !== new Date(null).getTime())
          || (activeFrom.getTime() !== new Date(null).getTime()))
        && (activeFrom.getTime() >= currentTime.getTime() || activeUntil.getTime() >= currentTime.getTime())
      ) {
        this.timelinesActive.push(timeline);
      } else {
        //if ((activeUntil.getTime() !== new Date(null).getTime() && activeUntil.getTime() < currentTime.getTime()) || (activeFrom.getTime() === new Date(null).getTime() && activeUntil.getTime() === new Date(null).getTime())) {
        //this.makeAlwaysActive(timeline);
        this.timelinesAvailable.push(timeline);
      }
    }
    this.updateTableData();
  }

  updateTableData() {
    this.dataSourceActive = new MatTableDataSource(this.timelinesActive);
    this.dataSourceAvailable = new MatTableDataSource(this.timelinesAvailable);
    this.dataSourceExercises = new MatTableDataSource(this.exercises);
  }

  changeProgram(programId: number) {
    this.currentProgramSelected = programId;
    this.loadProgramSessions(programId);
  }

  loadProgramSessions(programId: number) {
    this.timelineService.getTimelineByProgram(programId).subscribe(
      result => {
        this.globalObject = result;
        this.timelines = this.globalObject.timelines;
        this.exercises = this.globalObject.exercises;
        this.processData();
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  enableTimeline(timeline: ITimelineActivation) {
    this.timelinesActive.push(timeline);
    this.makeAlwaysActive(timeline);
    this.timelinesAvailable.splice(this.timelinesAvailable.findIndex(x => x === timeline), 1);
    this.updateTableData();
  }

  disableTimeline(timeline: ITimelineActivation) {
    timeline.activeFrom = null;
    timeline.activeUntil = null;
    this.timelinesAvailable.push(timeline);
    this.timelinesActive.splice(this.timelinesActive.findIndex(x => x === timeline), 1);
    this.updateTableData();
    //this.saveProgramTimelines();
  }

  makeAlwaysActive(timeline: ITimelineActivation) {
    //this.clearDate(timeline, 'activeFrom');
    timeline.activeFrom = new Date();
    timeline.activeFrom.setHours(0, 0, 0, 0);
    this.clearDate(timeline, 'activeUntil');
  }

  clearDate(timeline: ITimelineActivation, field: string) {
    timeline[field] = null;
    this.updateDate(timeline);


    // if(field === 'activeFrom'){
    //   timeline[field] = new Date(this.DATE_FROM_ALWAYS);      
    // }
    // if(field === 'activeUntil'){
    //   timeline[field] = new Date(this.DATE_UNTIL_ALWAYS);      
    // }
  }

  updateDate(timeline: ITimelineActivation) {
    if ((timeline.activeFrom === null && timeline.activeUntil === null) 
    || ((timeline.activeFrom !== null && timeline.activeFrom instanceof Date && timeline.activeFrom.getTime() < new Date().getTime()) && (timeline.activeUntil !== null && timeline.activeUntil instanceof Date && timeline.activeUntil.getTime() < new Date().getTime()))) {
      this.disableTimeline(timeline);
    }
  }

  getStatus(timeline: ITimelineActivation) {
    let currentTime = new Date();
    currentTime.setHours(0, 0, 0, 0);

    const activeFrom = new Date(timeline.activeFrom);
    const activeUntil = new Date(timeline.activeUntil);

    if (activeFrom.getTime() !== new Date(null).getTime() && activeUntil.getTime() !== new Date(null).getTime() && activeFrom.getTime() > activeUntil.getTime()) {
      return 'WARNING: Possible date mismatch';
    }

    if (currentTime < activeFrom) {
      return 'Starts in ' + this.dateDiffInDays(currentTime, activeFrom) + ' days';
    }
    if (currentTime > activeFrom && currentTime < activeUntil) {
      return 'Currently active and finishes in ' + this.dateDiffInDays(currentTime, activeUntil) + ' days';
    }

    if (currentTime >= activeFrom && activeUntil.getTime() === this.DATE_NULL.getTime()) {
      let alwaysText = 'Always active';
      if (activeFrom.getTime() !== new Date(null).getTime()) {
        if (activeFrom.getTime() !== currentTime.getTime())
          alwaysText += ' since ' + this.dateDiffInDays(activeFrom, currentTime) + ' days ago';
        else
          alwaysText += ' since today';
      }
      return alwaysText;
      //return 'Always active';
    }
    if (currentTime.getTime() === activeFrom.getTime() && activeUntil.getTime() !== this.DATE_NULL.getTime()) {
      return 'Started today and finishes in ' + this.dateDiffInDays(currentTime, activeUntil) + ' days';
    }
    if (currentTime.getTime() === activeUntil.getTime()) {
      return 'Finishes today';
    }
  }

  dateDiffInDays(a, b) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / this.MS_PER_DAY);
  }

  public saveProgramTimelines() {
    this.globalObject.id = this.currentProgramSelected;
    this.globalObject.timelines.length = 0;
    this.globalObject.timelines = [... this.timelinesActive, ... this.timelinesAvailable];

    // console.log(this.globalObject);
    // console.log(this.timelines);
    // console.log(this.timelinesActive);
    // console.log(this.timelinesAvailable);

    console.log(this.globalObject);
    for (const timeline of this.globalObject.timelines) {
      if (timeline.activeFrom instanceof Date && timeline.activeFrom !== null) {
        if (timeline.activeFrom.getTime() === new Date(null).getTime())
          timeline.activeFrom = null;
        else {
          timeline.activeFrom.setHours(timeline.activeFrom.getHours() + 1);
          timeline.activeFrom = new Date(timeline.activeFrom).toISOString();
        }
      }

      if (timeline.activeUntil instanceof Date && timeline.activeUntil !== null) {
        if (timeline.activeUntil.getTime() === new Date(null).getTime())
          timeline.activeUntil = null;
        else {
          timeline.activeUntil.setHours(timeline.activeUntil.getHours() + 1);
          timeline.activeUntil = new Date(timeline.activeUntil).toISOString();
        }
      }

    }
    console.log(this.globalObject);
    // for (const timeline of this.globalObject.timelines) {
    //   if (timeline.activeFrom instanceof Date && timeline.activeFrom !== null)
    //     timeline.activeFrom.setHours(timeline.activeFrom.getHours() + 1);
    //   if (timeline.activeUntil instanceof Date && timeline.activeUntil !== null)
    //     timeline.activeUntil.setHours(timeline.activeUntil.getHours() + 1);
    // }

    this.timelineService.putProgramTimelines(this.globalObject).subscribe(
      result => {
        this.notificationService.notify("Program saved successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
      },
      error => {
        console.log('Error', error);
      }
    );
    //console.log(this.globalObject);

  }

}
