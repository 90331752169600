import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { BlockEditorService } from '../block-editor.service';

import { of } from "rxjs";


@Injectable()
export class BlockEditorResolver implements Resolve<Observable<string>> {

  constructor(private blockEditorService: BlockEditorService) {}

  resolve(route: ActivatedRouteSnapshot) {
    if (route.paramMap.get('workoutId') !== 'new') {
      return this.blockEditorService.getWorkout(Number(route.paramMap.get('workoutId'))).catch(() => {
        return of('error');
      });
    } else {
      return of('new');
    }
  }
}

