import { Component, OnInit, TemplateRef, Inject, NgZone, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MusicService } from '../../services/music-service';
import { ISong } from '../../interfaces/models/i-song';
import { NotificationService } from '../../services/notification.service';
import { ENUMS } from '../../../assets/enums';

@Component({
    selector: 'app-modal-remove-category',
    templateUrl: 'modal-remove-category.html',
})
export class ModalRemoveCategoryComponent {

    song: ISong;
    @Output() onDelete = new EventEmitter<ISong>();
    
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalRemoveCategoryComponent>,
        private musicService: MusicService,
        private notificationService: NotificationService,
        private zone: NgZone,
        public router: Router) {
        this.song = data.song;
    }

    removeSong(song: ISong) {
        this.deleteSong(song);
        this.dialogRef.close();
    }

    modalRemoveSongCancel() {
        this.dialogRef.close();
    }

    public deleteSong(song?: ISong) {
        const songToDelete = song ? song : this.song;
        this.musicService.deleteSong(song).subscribe(
            result => {
                this.notificationService.notify("Song deleted successfully",
                    ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
                this.onDelete.emit(songToDelete);
            },
            error => {
                console.log('Error', error);
            }
        );
    }
}

