<form (ngSubmit)="onSubmit()" #siteForm="ngForm">

  <h1>EXERCISE CREATOR</h1>

  <!-- EXERCISE INFO -->
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
    <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch">
      <h5 fxFlex="nogrow">MAIN INFO</h5>
      <mat-card fxFlex>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxLayoutAlign="end start">
          <h3 *ngIf="exercise.id !== 0" class="color-white">#{{exercise.id}}</h3>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
          <div fxLayout="column" fxFlex="100">
            <!-- NAME -->
            <mat-form-field>
              <input matInput #exerciseName placeholder="Name" [value]="exercise.name" [(ngModel)]="exercise.name" name="exerciseName"
                required>
              <mat-hint class="form-error-message" *ngIf="formSubmitted && !exercise.name">
                Name is required
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
    </div>

    <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch" class="posrel">

      <h5 fxFlex="nogrow">IMAGE</h5>
      <mat-card fxFlex class="nopadding">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxFill class="w-100">
          <img [src]="this.mediaUrl + this.exercise.image" alt="Exercise image" *ngIf="this.exercise.id && this.exercise.image">
          <img [src]="this.tempMediaUrl + this.exercise.image" alt="Exercise image" *ngIf="!this.exercise.id && this.exercise.image">
          <div class="w-100 text-center" fxLayout="row" fxLayoutAlign="center center" *ngIf="!this.exercise.image">
            <div>Image will be generated from uploaded video</div>
          </div>
        </div>
      </mat-card>

    </div>

  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
    <mat-toolbar class="mat-buttons-toolbar">
      <mat-toolbar-row>
        <span class="mat-spacer"></span>
        <button mat-flat-button type="submit" class="prama-button full-button medium-button">
          <mat-icon>save</mat-icon> SAVE
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>

  <mat-tab-group>
    <mat-tab label="CONFIGURATION">

      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="space-between stretch" fxFill>

        <div fxLayout="row" fxFlex="100" fxFlexAlign="stretch" >

          <!-- EQUIPMENT -->
          <div fxLayout="column" fxFlex="33">
            <h5 fxFlex="nogrow">EQUIPMENT</h5>
            <mat-card fxFlex>
              <div class="exercise-scroll-list">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
                  <div fxLayout="column" fxFlex="100">
                    <div class="group-selection" *ngFor="let equip of equipmentAvailable">
                      <div fxLayout="row" fxLayoutAlign="space-between stretch">
                        <mat-checkbox [value]="equip" [checked]="isChecked(exercise.equipment, equip)" name="equip-{{equip.id}}"
                          (change)="changeItemFromList(exercise.equipment, equip, $event)">
                          {{ equip.name}}
                        </mat-checkbox>
                        <img class="equipment-img" [src]="'/assets/equipment/' + equip.id + '.png'" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="VIDEOS">

      <mat-card fxFlex class="nopadding">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxFill>
          <video-uploader [videoName]="this.exercise.video" [gifName]="this.exercise.thumbnail" (selectedVideoEvent)="videoSelected($event)"
            (selectedGifEvent)="gifSelected($event)" [editMode]="editMode">
          </video-uploader>
        </div>
      </mat-card>

    </mat-tab>
  </mat-tab-group>

</form>