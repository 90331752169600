import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";

import { IStation } from "../interfaces/models/i-station";
import { IStationType } from "../interfaces/models/i-station-type";
import { IExercise } from "../interfaces/models/i-exercise";
import { Category } from "../interfaces/models/category";


@Injectable()
export class CategoriesService {

  private urlGetCategories: string;
  private urlPostCategory: string;
  private urlPutCategory: string;
  private urlDeleteCategory: string;

  constructor(private http: HttpClient) {
    this.urlGetCategories = CONFIG.serverUrl + ENDPOINTS.categories.get;
    this.urlPostCategory = CONFIG.serverUrl + ENDPOINTS.categories.post;
    this.urlPutCategory = CONFIG.serverUrl + ENDPOINTS.categories.put;
    this.urlDeleteCategory = CONFIG.serverUrl + ENDPOINTS.categories.delete;
  }

  public getCategories(): Observable<any> {
    return this.http.get(this.urlGetCategories);
  }

  public convertTreeToList(tree) : Category[] {
    return this.sortListByName(this.flattenDeep(tree));
  }

  public createCategory(category: Category) {
    return this.http.post(this.urlPostCategory, category);
  }

  public updateCategory(category: Category) {
    return this.http.put(this.urlPutCategory, category);
  }

  public deleteCategory(category: Category) {
    let url = this.urlDeleteCategory.replace(":id", category.id.toString());
    return this.http.delete(url);
  }

  private flattenDeep(tree) {
    return tree.reduce((acc, val) => {
      acc = acc.concat(val);
      if(val.categories && val.categories.length>0) 
        acc = acc.concat(this.flattenDeep(val.categories)); 
        
      return acc;
      }, []);
  }

  public sortListByName(list: Category[]) {
    return list.sort((a,b) => (a.fullpath > b.fullpath) ? 1 : ((b.fullpath > a.fullpath) ? -1 : 0))
  }
  

  public searchTree(element, id){
    if(element.id == id){
         return element;
    }else if (element.categories != null){
         var result = null;
         for(let i=0; result == null && i < element.categories.length; i++){
              result = this.searchTree(element.categories[i], id);
              if(result != null)
                return result;
         }
         return result;
    }
    return null;
}
}
