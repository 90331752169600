import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ProgramService } from '../program.service';

import { of } from "rxjs";
import { PlaylistsService } from '../playlists.service';
import { MusicService } from '../music-service';


@Injectable()
export class MusicIntervalsResolver implements Resolve<Observable<string>> {

    constructor(private musicService: MusicService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.musicService.getMusicIntervals();
    }

}

