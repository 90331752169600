<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
  <div fxLayout="column" fxFlex="100" fxFlexAlign="stretch">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h4 fxFlex="50">{{ workoutList?.length }} workouts</h4>
        <div>
          <mat-form-field>
            <input #filter matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
            <button mat-button *ngIf="filter.value" matSuffix mat-icon-button aria-label="Clear" (click)="filter.value=''; applyFilter('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          
          <button [routerLink]="['/blockcreator/editor', 'new']" class="uk-button prama-button medium-button ml20">
              <i class="fa fa-plus-circle faSize"></i>
          </button>      
        </div>
    </div>

    <mat-table #workoutsTable [dataSource]="dataSource" class="session-table">     
      <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef fxFlex="90%"> Name </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="90%">
              {{element.name}}
          </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/blockcreator/editor', row.id ]"></mat-row>
    </mat-table>
  </div>
</div>
