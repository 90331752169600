import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { EquipmentService } from '../equipment.service';

import { of } from "rxjs";

@Injectable()
export class EquipmentResolver implements Resolve<Observable<string>> {

    constructor(private equipmentService: EquipmentService) { }

    resolve() {
        return this.equipmentService.getEquipment().catch(() => {
            return of('error');
        });
    }
} 