import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { IProgram } from '../../interfaces/models/i-program';
import { IPlaylist } from '../../interfaces/models/i-playlist';

@Component({
  selector: 'music-manager-phase',
  templateUrl: './music-manager-phase.component.html',
  styleUrls: ['./music-manager-phase.component.scss']
})
export class MusicManagerPhaseComponent implements OnInit {

  headers = {
    id: "Id",
    name: "Name",
    lastUpdate: "Last update",
  };

  previewImage = "";
  displayedColumns = ['id', 'name', 'lastUpdate'];
  dataSource = new MatTableDataSource;

  playlists: IPlaylist[];
  currentProgram: IProgram;
  
  @ViewChild(MatSort) sort: MatSort;


  constructor(private route: ActivatedRoute, private router: Router) {
    let programId = router.url.substring(router.url.lastIndexOf("/") + 1);  
    this.currentProgram = this.route.snapshot.data.programs.find(x => x.id == programId);

    this.playlists = this.route.snapshot.data.playlists;
    this.playlists.forEach(p => {
      p.playlistName = p.playlistName.replace(programId, this.currentProgram.name.toUpperCase());
    });
  }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    this.updateTableData();    
  }

  updateTableData(){
    this.dataSource.sort = this.sort;
    this.dataSource = new MatTableDataSource(this.playlists);    
  }
}
