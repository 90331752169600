import { Component, OnInit, Input, ViewChildren, ViewChild, SimpleChanges, AfterViewInit, Output, EventEmitter } from "@angular/core";

import { ActivatedRoute } from "@angular/router";
import { ISongsFilter } from "../../interfaces/models/i-songs-filter";
import { ISong } from "../../interfaces/models/i-song";
import { IMusicPhase } from "../../interfaces/models/i-music-phase";
import { IProgram } from "../../interfaces/models/i-program";
import { MusicService } from "../../services/music-service";

@Component({
  selector: 'songs-filter',
  templateUrl: './songs-filter.component.html',
  styleUrls: ['./songs-filter.component.scss']
})
export class SongsFilterComponent implements OnInit {
  programFilterItems = [];
  phaseFilterItems = [];

  songsFilter: ISongsFilter;
  searchTypeTimeout = null;

  filtersApplied: {
    search: ISong[],
    programs: ISong[],
    phases: ISong[]
  };

  @Input() songList: ISong[];
  @Input() filteredSongList: ISong[];

  @Input() programs: IProgram[];
  @Input() phases: IMusicPhase[];

  @Output() filterChange = new EventEmitter();

  @ViewChildren("filter") filters;
  @ViewChild("filtersearch") filtersearch;

  constructor(
    public musicService: MusicService,
    private route: ActivatedRoute) {

    
  }

  ngOnInit() {
    this.createFilters(this.programs, 'programs', "id");
    this.createFilters(this.phases, 'phases', "id", "phase", "name");

    if (!this.filtersApplied) {
      this.initFilters();
    }
  }

  ngAfterViewInit() {
    for (const filterCheck of this.filters._results) {
      filterCheck.nativeElement.checked = false;
    }
    this.applyFilters();
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnChanges(changes: SimpleChanges) {
    //this.applyFilters();
  }

  applyFilters(noInit?) {
    let filtersActive = false;
    let searchActive = false;
    if (!noInit) {
      this.initFilters();
    }

    if (this.filteredSongList !== undefined) {
      this.filteredSongList.length = 0;

      if (this.filters !== undefined) {

        for (const filter of this.musicService.songsFilterList) {
          if (filter.checked) {

            let auxSongs = [];

            if(filter.field === "programs") {
              auxSongs = this.songList.filter(s => s.programs.findIndex( (p: IProgram) =>  p.id === filter.itemId) >= 0);
            }
            if(filter.field === "phases") {
              auxSongs = this.songList.filter(s => s.phases.findIndex( (p: IMusicPhase) => p.id === filter.itemId) >= 0)
            } 
    
            for (const ex of auxSongs) {
              if (this.filtersApplied[filter.field] && !this.filtersApplied[filter.field].includes(ex)) {
                this.filtersApplied[filter.field].push(ex);
              }
            }
            
            if (this.filtersApplied[filter.field] && this.filtersApplied[filter.field].length === 0) {
              this.filtersApplied[filter.field].push(null);
            }
            filtersActive = true;
          }
        }

        // SEARCH INPUT
        const searchValue = this.musicService.songsFilterSearch;
        if (searchValue !== "") {
          var auxList = this.songList.filter(x => x.title.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
          || x.id.toString().indexOf(searchValue) >= 0);

          for (const ex of auxList) {
            if (!this.filtersApplied['search'].includes(ex)) {
              this.filtersApplied['search'].push(ex);
            }
          }
          if (this.filtersApplied['search'].length === 0) {
            this.filtersApplied['search'].push(null);
          }
          searchActive = true;
        }
 
        let includeIt: boolean;

        if (this.songList !== undefined && (filtersActive || searchActive)) {
          for (const song of this.songList) {
            includeIt = true;
            for (const i in this.filtersApplied) {
              if (this.filtersApplied.hasOwnProperty(i) && includeIt) {
                // CHECKBOXES
                if (!this.filtersApplied[i].includes(song) && this.filtersApplied[i].length > 0) {
                  includeIt = false;
                  break;
                }
              }
            }
            if(includeIt)
              this.filteredSongList.push(song);
          }
        }
      }
    }

    if (!filtersActive && !searchActive && this.songList !== undefined) {
      this.filteredSongList.length = 0;
      for (const ex of this.songList) {
        this.filteredSongList.push(ex);
      }
    }
    this.filterChange.emit();
  }

  doSearch() {
    clearTimeout(this.searchTypeTimeout);
    this.searchTypeTimeout = setTimeout(() => {
      this.applyFilters();
    }, 500);
  }

  initFilters() {
    this.filtersApplied = {
      search: [],
      programs: [],
      phases: []
    };
  }

  resetFilters(filterField) {
    this.musicService.resetSongsFilters(filterField);
    this.applyFilters();
  }

  createFilters(itemList: any[], field: string, comparisonField: string = null, textToShowProperty: string = "", textToShowProperty2: string = "") {
    for (let item of itemList) {
      let filter = {
        field: field,
        itemId: item.id,
        name: textToShowProperty === "" ? item.name : item[textToShowProperty][textToShowProperty2],
        checked: false,
        comparisonField: comparisonField
      };
      if (this.musicService.songsFilterList.findIndex(x => x.field === field && x.itemId === item.id) === -1) {
        this.musicService.songsFilterList.push(filter);
      }
    }
  }

  public getFilter(field: string, itemId: number): ISongsFilter {
    let f = this.musicService.songsFilterList.find(x => x.field === field && x.itemId === itemId);
    return this.musicService.songsFilterList.find(x => x.field === field && x.itemId === itemId);
  }

  public getFilterList(field: string): ISongsFilter[] {
    return this.musicService.songsFilterList.filter(x => x.field === field);
  }

  public hasFiltersOf(field: string): boolean {
    for (const filter of this.musicService.songsFilterList.filter(x => x.field === field)) {
      if (filter.checked === true) {
        return true;
      }
    }
    return false;
  }

}
