import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ITag } from "../../interfaces/models/i-tag";

@Component({
  selector: "tag-badge",
  templateUrl: "./tag-badge.component.html",
  styleUrls: ["./tag-badge.component.css"]
})
export class TagBadgeComponent implements OnInit {

  @Input() tag: ITag;
  @Input() canRemove: boolean;
  @Output() onRemove: EventEmitter<ITag> = new EventEmitter<ITag>();

  constructor() { }

  ngOnInit() {
  }

  remove() {
    this.onRemove.emit(this.tag);
  }

}
