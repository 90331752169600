<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
  <div fxLayout="column" fxFlex="100" fxFlexAlign="stretch">

    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h4 fxFlex="50">{{ programPackages?.length }} Packages</h4>
        <div>
          <button class="uk-button prama-button large-button" (click)="openCreatePackage()">
            <i class="fa fa-plus-circle faSize"></i> Create package
          </button>
          <a [routerLink]="['/package', 'new' ]" class="ml10">
            <button class="uk-button prama-button large-button">
              <i class="fa fa-plus-circle faSize"></i> Create program
            </button>
          </a>
        </div>

    </div>

    <mat-table #table id="programsTable" [dataSource]="dataSource" matSort class="session-table">

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"> Id </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="5%">
          {{element.id === 0? '' : element.id}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="25%"> Name </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="25%">
          <div *ngIf="editMode != element.id">
            {{element.name}}
          </div>
          <div *ngIf="editMode == element.id">
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="element.name" name="element?.name" placeholder="name" required>
            </mat-form-field>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="25%"> Description </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="25%">
          <div *ngIf="editMode != element.id">
            {{element.description}}
          </div>
          <div *ngIf="editMode == element.id">
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="element.description" name="element?.description" placeholder="description" required>
            </mat-form-field>
          </div>
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="25%"> Type </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="25%">
          <ng-container>
            {{getTypeName(element.type)}}
          </ng-container>
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="table-column-short"> Actions </mat-header-cell>
        <mat-cell *matCellDef="let package" class="table-column-short">

          <button mat-icon-button (click)="openEditor(package)" *ngIf="package.type == 1 && editMode != package.id && canModify">
            <mat-icon title="Edit">mode_edit</mat-icon>
          </button>
          <button mat-icon-button (click)="savePackage(package)" *ngIf="package.type == 1 && editMode == package.id && !newMode && canModify">
            <mat-icon title="Save">save</mat-icon>
          </button>
          <button mat-icon-button (click)="createPackage(package)" *ngIf="package.type == 1 && package.id == 0 && newMode && canCreate">
            <mat-icon title="Save">check</mat-icon>
          </button>
          <!--
          <button mat-icon-button (click)="modalRemoveProgramPackageOpen(package)" *ngIf="package.type == 1 && package.id == editMode && !newMode && canDelete">
            <mat-icon title="Delete">delete</mat-icon>
          </button>
          -->

          <a *ngIf="package.type == 0" [routerLink]="['/package', package.id ]">
            <button mat-icon-button>
              <mat-icon class="yellow" title="Edit">mode_edit</mat-icon>
            </button>
          </a>
        </mat-cell>
      </ng-container>

      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

    </mat-table>
    
  </div>
</div>
