<div class="loading" *ngIf="loadingService.isLoading()">
  <!-- <div class="loading"> -->
  <!-- <mat-spinner class="center"></mat-spinner> -->
  <div class="lds-roller center">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>