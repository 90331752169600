<div>
    <fieldset class="uk-fieldset">
      <div class="uk-margin">
          <input type="text" [(ngModel)]="filterTextId" (keyup)="filterId()" class="uk-input uk-form-width-medium prama-input" name="filterId" id="filterId" placeholder="Id">
        </div>
      <div class="uk-margin">
          <input type="text" [(ngModel)]="filterTextName" (keyup)="filterName()"  class="uk-input uk-form-width-medium prama-input"  name="filterName" id="filterName" placeholder="Name">
      </div>
      <div class="uk-margin">
        <label>Workout mode</label>
        <div *ngFor="let filterWorkoutItem of workoutmodesFilterItems; let i = index;" >
          <label>
            <input type="checkbox" class="uk-checkbox"
              (change)="filtereWorkoutmodes($event)"
              [(ngModel)]="filterWorkoutItem.checked"
              [value]="filterWorkoutItem.id"> {{ filterWorkoutItem.name }}
          </label>
        </div>
      </div>
      <div class="uk-margin">
        <label>Program</label>
        <div *ngFor="let filterProgramItem of programFilterItems; let i = index;" >
          <label>
            <input type="checkbox" class="uk-checkbox"
              (change)="filterPrograms($event)"
              [(ngModel)]="filterProgramItem.checked"
              [value]="filterProgramItem.id"> {{ filterProgramItem.name }}
          </label>
        </div>
      </div>
    </fieldset>
  </div>