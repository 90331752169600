<div fxLayout="row" fxLayoutAlign="stretch stretch" fxLayoutGap="20px" fxFill>
  <div fxFlex="40%">
    <div fxLayout="column" fxLayoutAlign="stretch stretch" fxLayoutGap="20px" fxFill>
      <exercise-filter [exerciseList]="exerciseList" [exerciseListFiltered]="exerciseListFiltered"
         (filterChange)="goToFirstPage()"></exercise-filter>
    </div>
  </div>
  <div fxFlex="60%" class="filter-results-cont" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" class="w-100" fxFlex>
      <div *ngFor="let exercise of this.exerciseListFiltered | slice: lowValue : highValue"
        class="exercise-search-container nopadding nomargin">
        <mat-card class="nopadding selector-card" fxLayout="row" fxLayoutAlign="space-between stretch"
          (click)="this.selectExercise(exercise.id)"
          [ngClass]="{'exercise-selected': (exercise.id === this.currentExerciseId)}">
          <div class="margin10">
            {{ exercise.name }}
            <span *ngIf="!isBlockCreator">
              <p class="subtitle" *ngIf="exercise.timesUsed == 0">Not used yed</p>
              <p class="subtitle" *ngIf="exercise.timesUsed == 1">Used on 1 session already</p>
              <p class="subtitle" *ngIf="exercise.timesUsed >= 2">Used on {{exercise.timesUsed}} sessions already</p>
            </span>
          </div>
          <div class="mat-spacer"></div>
          <div class="rule-alert">
            <div
              [ngClass]="{'color': exercise.ruleStatus?.ruleAmount > 0, 'color1': exercise.ruleStatus?.ruleAmount == 1, 'color2': exercise.ruleStatus?.ruleAmount == 2, 'color3': exercise.ruleStatus?.ruleAmount >= 3}"
              fxLayout="column" fxLayoutAlign="center center">
              <span *ngIf="exercise.ruleStatus?.ruleAmount > 0"
                [title]="exercise.ruleStatus?.ruleAmount + ' conflicts with rules'">{{ exercise.ruleStatus?.ruleAmount }}</span>
            </div>
          </div>
          <div>
            <img [src]="this.mediaUrl + exercise.image" class="thumb-small" (mouseover)="showPreview(exercise)"
              (mouseleave)="previewImage=''" />
          </div>
        </mat-card>
        <mat-card class="nopadding rule-container">
          <ul>
            <li *ngFor="let rule of exercise.ruleStatus?.rules">{{ rule }}</li>
          </ul>
        </mat-card>
      </div>

    </div>
    <!-- <mat-paginator [pageSizeOptions]="[25, 50, 100, 500]" showFirstLastButtons></mat-paginator> -->
    <div fxFlex fxLayout="column" fxLayoutAlign="end end">
      <mat-paginator [length]="exerciseListFiltered.length" [pageSize]="pageSize"
      (page)="pageEvent = getPaginatorData($event)" class="w-100">
    </mat-paginator>
  </div>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="stretch stretch" fxLayoutGap="20px" fxFill class="w-100">

  <mat-toolbar class="mat-buttons-toolbar w-100">
    <mat-toolbar-row>
      <button mat-flat-button class="prama-button full-button" (click)="cleanStation()" *ngIf="!isBlockCreator">
        <mat-icon>clear</mat-icon> REMOVE CURRENT
      </button>
      <span class="mat-spacer"></span>
      <button mat-flat-button class="prama-button full-button" (click)="exerciseSelected()">
        <mat-icon>check</mat-icon> SELECT
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

</div>