<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
  <div fxLayout="column" fxFlex="100" fxFlexAlign="stretch">

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h4 fxFlex="50">{{ sessionList?.length }} {{sessionTypeListTitle}}</h4>
      <button [routerLink]="[formUrl, 'new']" class="uk-button prama-button medium-button">
        <i class="fa fa-plus-circle faSize"></i>
      </button>
    </div>
    <div class="ml10">
      <mat-table #table [dataSource]="dataSource" matSort class="exercise-table" *ngIf="sessionList?.length !== 0">
              <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"> Id </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="5%">
            {{element.id}}
            <a [routerLink]="[formUrl, element.id ]" class="mat-row-link"></a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="30%"> Name </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="30%">
            <h4>{{element.name}} </h4> <mat-icon *ngIf="element.video" class="yellow">theaters</mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="creationDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Creation date </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.creationDate | date:'medium'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastUpdate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Last update </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.lastUpdate | date:'medium'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxLayoutAlign="center flex-end">
            Actions
          </mat-header-cell>
          <mat-cell *matCellDef="let element" fxLayoutAlign="center flex-end">
            <span>
              <a [routerLink]="[formUrl, element.id ]">
                <button *ngIf="element" type="button" mat-flat-button class="prama-transparent-button nomargin" fxFill>
                  <mat-icon>edit</mat-icon>
                </button>
              </a>
              
              <button *ngIf="element" type="button" (click)="modalRemoveSession(element)" mat-flat-button class="prama-transparent-button nomargin" fxFill>
                <mat-icon>delete</mat-icon>
              </button>
            </span>          
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[25, 50, 100, 500]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  <div class="exercise-preview">
    <img [src]="previewImage" *ngIf="previewImage">
  </div>
</div>