import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { Cacheable } from "ngx-cacheable";

import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";

import { ILanguageCountry } from "../interfaces/models/i-language-country";
import { ILanguageDataField } from "../interfaces/models/i-language-data-field";

import { LangDictionary } from './../interfaces/models/language';
@Injectable()
export class LanguageService {

  public currentLanguage: number;

  private urlGetLanguages: string;
  
  private _languages: ILanguageCountry[];

  constructor(private http: HttpClient) {
    this.urlGetLanguages = CONFIG.serverUrl + ENDPOINTS.languages.get;
    this.currentLanguage = 1;
  }

  createLanguageField(key: string, langs: ILanguageCountry[]) : ILanguageDataField {
    let field: ILanguageDataField = {
      id: 0,
      key: key,
      resources: []
    };

    langs.forEach(lang => {
      field.resources.push( {
        country: lang,
        value: ""
      });
    }); 

    return field;
  }


  completeLanguageField(field: ILanguageDataField, langs: ILanguageCountry[]) {
    langs.forEach(lang => {
      if(field.resources.findIndex(x => x.country.id == lang.id) < 0) {
        field.resources.push( {
          country: lang,
          value: ""
        });
      }
    })
  }

  
  @Cacheable()
  public getLanguages(): Observable<any> {
    return this.http.get(this.urlGetLanguages);
  }


  public getValue(object: LangDictionary) {
    let resource = object.resources.find(resource => resource.language.id === this.currentLanguage);
    if(resource){
      return resource.value;
    }else{
      return 'LANGUAGE_NOT_DEFINED';
    }
  }

  // Devolvemos index del resource correspondiente a -currentLanguage- mentira, forzado a español
  public getLangIndex(object: LangDictionary){
    // let resourceIndex = object.resources.findIndex(resource => resource.language.id === this.currentLanguage);
    let resourceIndex = object.resources.findIndex(resource => resource.language.id === 1);
    return resourceIndex;
  }
}
