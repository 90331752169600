import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { IPostTokenResponse } from "../../interfaces/http/i-post-token-response";
import { ISessionToken } from "../../interfaces/models/i-session-token";
import { ILoginData } from "../../interfaces/i-login-data";

import { NotificationService } from "../../services/notification.service";
import { DataService } from "../../services/data.service";
import { AuthService } from "../../services/auth.service";
import { EventsService } from "../../services/events.service";

import { ENUMS } from "../../../assets/enums";
import { CONFIG } from "../../../assets/config";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;

  errorUser: boolean;
  errorPassword: boolean;
  errorCredentials: boolean;

  constructor(private authService: AuthService,
              private router: Router,
              private notificationService: NotificationService,
              private dataService: DataService,
              private eventsService: EventsService
            ) {
    this.errorPassword = false;
    this.errorUser = false;
    this.errorCredentials = false;
  }


  ngOnInit() {
  }


  login() {
    let correct = true;
    if (!this.username || this.username === "") {
      this.errorUser = true;
      correct = false;
    }
    if (!this.password || this.password === "") {
      this.errorPassword = true;
      correct = false;
    }

    if (correct) {
      const data: ILoginData = {
        email: this.username,
        password: this.password,
        appId: CONFIG.appId
      };
      this.authService.checkLogin(data)
        .subscribe(resp => {
          localStorage.setItem(ENUMS.keys.session, JSON.stringify(resp.session));
          localStorage.setItem(ENUMS.keys.roles, JSON.stringify(resp.roles));
          if(resp){
            this.router.navigate(["/"]);
            this.eventsService.doEvent({key: 'login', value: 'ok'});
          }else{
            localStorage.clear();
            this.router.navigate(["/login"]);
          }      
        }, error => {
          this.errorCredentials = true;
        });
    }
  }
}
