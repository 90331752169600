import { Component, OnInit, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { ENUMS } from '../../../assets/enums';
import { CONFIG } from '../../../assets/config';

import { NotificationService } from '../../services/notification.service';
import { ProgramService } from '../../services/program.service';

import { IProgramPackage } from '../../interfaces/models/i-program-packages';
import { ModalRemoveProgramPackageComponent } from './modal-remove-program-package.component';
import { ITimelineObjects } from '../../interfaces/models/i-timeline';
import { IPlaylist } from '../../interfaces/models/i-playlist';
import { IMusicPhase } from '../../interfaces/models/i-music-phase';
import { IMusicPackage } from '../../interfaces/models/i-music-package';
import { IProgramAmbience, IProgramAmbienceDto } from '../../interfaces/models/i-program';

@Component({
  selector: 'programs-packages-list',
  templateUrl: './programs-packages-list.component.html',
  styleUrls: ['./programs-packages-list.component.scss']
})
export class ProgramsPackagesListComponent implements OnInit {

  canCreate: boolean;
  canModify: boolean;
  canDelete: boolean;
  newMode: boolean;
  editMode: number;

  programPackages: IProgramPackage[];
  playlists: IPlaylist[];
  newPp: IProgramPackage;
  currentProgram: IProgramPackage;

  musicPhases: IMusicPhase[];
  packages: IMusicPackage[];

  filteredPackages: IMusicPackage[];

  filteredProgramAmbience: IProgramAmbienceDto;
  programAmbiences: IProgramAmbience[];
  
  modalRef: BsModalRef;

  headers = {
    id: "Id",
    name: "Name",
    thumbnail: " ",
    desc: "Description",
    workMode: "Description",
    actions: ""
  };

  objectsAvailables: ITimelineObjects = {
    countdown: null,
    mood: null,
    playlist: null,
    program: null,
    workoutMode: null,
    explanation: null,
    welcome: null,
    warmup: null,
    positioningGt: null,
    resultGt: null,
    positioningFinisher: null,
    resultFinisher: null,
    cooldown: null,
    ending: null,
    session: null,
    finisher: null,
    finisherMode: null
  };

  displayedColumns = ['id', 'name', 'description', 'type', 'actions'];
  dataSource = new MatTableDataSource;

  packageTypes = [
    {id: 0, name: 'Program' },
    {id: 1, name: 'Package' },
    ];
    

  constructor(private route: ActivatedRoute, private router: Router, 
    private programService: ProgramService, public dialog: MatDialog,
    private notificationService: NotificationService,
    private modalService: BsModalService) { 

    this.programPackages = this.route.snapshot.data.programs;
    this.objectsAvailables.program = this.route.snapshot.data.programs;
    this.objectsAvailables.workoutMode = this.route.snapshot.data.workoutModesAvailable;
    this.objectsAvailables.playlist = this.route.snapshot.data.playlists;
    this.objectsAvailables.mood = this.route.snapshot.data.moods;
    this.objectsAvailables.countdown = this.route.snapshot.data.countdowns;
    this.objectsAvailables.welcome = this.route.snapshot.data.welcomes;
    this.objectsAvailables.explanation = this.route.snapshot.data.explanations;
    this.objectsAvailables.warmup = this.route.snapshot.data.warmups;
    this.objectsAvailables.positioningGt = this.route.snapshot.data.positionings;
    this.objectsAvailables.resultGt = this.route.snapshot.data.results;
    this.objectsAvailables.positioningFinisher = this.route.snapshot.data.positionings;
    this.objectsAvailables.resultFinisher = this.route.snapshot.data.results;
    this.objectsAvailables.cooldown = this.route.snapshot.data.cooldowns;
    this.objectsAvailables.ending = this.route.snapshot.data.endings;
    this.packages = this.route.snapshot.data.packages;
    this.musicPhases = this.route.snapshot.data.phases;
    this.programAmbiences = this.route.snapshot.data.ambiencePhases;
  }

  @ViewChild('table') private myScrollContainer: ElementRef;

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });

    this.canModify = true;
    this.canCreate = true;
    this.canDelete = false;
  }


  ngAfterViewInit() {    
    this.updateTableData();    
  }


  updateTableData(){
    this.dataSource = new MatTableDataSource(this.programPackages);
  }


  getTypeName(typeId: number) {
    return this.packageTypes.find(x => x.id == typeId).name;
  }


  openEditor(pp: IProgramPackage) {
    this.editMode = pp.id;
    if(this.newMode) {
      this.programPackages = this.programPackages.filter(x => x.id != 0);
    }
    this.dataSource = new MatTableDataSource(this.programPackages);  
  }

  scrollTableToTop() {
    try {
      const matTable= document.getElementById('programsTable');
      matTable.scrollTop = 0;
    } catch(err) 
    {
      console.log(err);
    }      
  }

  
  openCreatePackage() {
    this.editMode = 0;
    if (this.newMode) {
      this.programPackages = this.programPackages.filter(x => x.id != 0);
    }
    this.newMode = true;
    this.newPp = {
      id: 0,
      name: '',
      description: '',
      hasExercises: false,
      hasSessions: false,
      hasTimelines: false,
      image: '',
      isAssigned: false,
      type: ENUMS.programTypes.package
    };
    this.programPackages.unshift(this.newPp);
    this.dataSource = new MatTableDataSource(this.programPackages);

    this.scrollTableToTop();
  }


  createPackage(pp: IProgramPackage) {
    this.programService.createProgramPackage(pp).subscribe(
      result => {
        this.notificationService.notify("Package saved successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
        this.resetForm(event);
      },
      error => {
        console.log('Error', error);
      }
    );
  }


  savePackage(pp: IProgramPackage) {
    this.programService.updateProgramPackage(pp).subscribe(
      result => {
        this.notificationService.notify("Package modified successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
        this.resetForm(event);
      },
      error => {
        console.log('Error', error);
      }
    );
  }


  resetForm(event) {
    event.stopPropagation();
    this.programService.getProgramsByType([ENUMS.programTypes.program, ENUMS.programTypes.package]).subscribe(
      result => {
        this.programPackages = result;
        this.editMode = 0;
        this.newMode = false;
        this.dataSource = new MatTableDataSource(this.programPackages);
      },
      error => {
        console.log('Error');
      }
    );
  }

  modalRemoveProgramPackageOpen(pp: IProgramPackage) {
    this.dialog.open(ModalRemoveProgramPackageComponent, {
      data: {
        pp: pp,
        programPackages: this.programPackages
      }
    });
  }
}
