import { Component, OnInit, Input } from "@angular/core";
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from "@angular/router";
import { ISessionRound } from "../../interfaces/models/i-session-round";
import { IMood } from "../../interfaces/models/i-mood";
import { ISession, ISessionExt } from "../../interfaces/models/i-session";

@Component({
  selector: "session-modes",
  templateUrl: "./session-modes.component.html",
  styleUrls: ["./session-modes.component.css"]
})

export class SessionModesComponent implements OnInit {

  @Input("session") session: ISessionExt;
  @Input("moodsAvailable") moodsAvailable: IMood[];

  DEFAULT_EXTRAREST_TIME: number = 60;
  SERIE_TXT: string = "Serie";
  ROUND_TXT: string = "Round";
  SET_TXT: string = "Set";

  workoutModeTxt = "";
  workoutModeSubTxt = "";
  commonExtraRest = this.DEFAULT_EXTRAREST_TIME;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.session.roundsCount = this.session.rounds.length;
    this.session.seriesCount = 2;

    this.changeSessionMode();

    console.log(this.session);
    console.log(this.moodsAvailable);
  }

  // Returns moods of a round by mode
  getMoodByRound(round: ISessionRound, mode: number): IMood {
    return this.session.moods.filter(x => x.round === round.id && x.mode === mode)[0];
  }

  // Returns total round time
  calculateRoundTime(round: ISessionRound): number {
    if (this.session.workoutMode.id === 1) {
      return this.getExerciseCount() * round.work + (this.getExerciseCount() - 1) * round.rest;
    }
    if (this.session.workoutMode.id >= 2 && this.session.workoutMode.id <= 3 && round.id !== this.session.rounds[this.session.rounds.length - 1].id) {
      return this.getExerciseCount() * round.work + this.getExerciseCount() * round.rest;
    }
    if (round.id === this.session.rounds[this.session.rounds.length - 1].id) {
      return this.getExerciseCount() * round.work;
    }
  }

  // # of exercises, filtered by common stations (wall, floor and combo are the same)
  getExerciseCount() {
    let exerciseCount = this.session.exercises.filter(x => x.station !== 6 && x.station !== 8 && x.station !== 9).length;
    exerciseCount = this.session.exercises.filter(x => x.station === 6 || x.station === 8 || x.station === 9).length > 0 ? exerciseCount + 1 : exerciseCount;
    return exerciseCount;
  }

  // Total time of session
  calculateTotalTime(): number {
    return this.calculateTotalWorkTime() + this.calculateTotalRestTime();
  }

  // Total work time in session
  calculateTotalWorkTime(): number {
    let totalTime = 0;
    for (const round of this.session.rounds) {
      totalTime += this.getExerciseCount() * round.work;
    }
    return totalTime;
  }

  // Total rest time in session
  calculateTotalRestTime(): number {
    let totalTime = 0;
    if (this.session.workoutMode.id === 1) {
      for (const round of this.session.rounds) {
        totalTime += (this.getExerciseCount() - 1) * round.rest;
        if (round.id !== this.session.rounds[this.session.rounds.length - 1].id) {
          totalTime += round.extraRest;
        }
      }
    }
    if (this.session.workoutMode.id >= 2 && this.session.workoutMode.id <= 3) {
      for (const round of this.session.rounds) {
        if (round.id !== this.session.rounds[this.session.rounds.length - 1].id) {
          totalTime += (this.getExerciseCount() * round.rest);
        }
      }
      totalTime += (this.getExerciseCount() - 1) * this.session.rounds[0].extraRest;

    }

    return totalTime;
  }

  // If round amount is changed
  changeRoundNumber($event) {
    // Increasing
    if ($event.target.value > this.session.rounds.length) {
      for (let i = this.session.rounds.length; i < $event.target.value; i++) {
        this.session.rounds.push(JSON.parse(JSON.stringify(this.session.rounds[this.session.rounds.length - 1])));  // Copy without reference
        this.session.rounds[this.session.rounds.length - 2].extraRest = this.DEFAULT_EXTRAREST_TIME;
        this.session.rounds[this.session.rounds.length - 1].extraRest = 0;
      }
    }
    // Decreasing
    if ($event.target.value < this.session.rounds.length && $event.target.value > 0) {
      for (let i = this.session.rounds.length; i > $event.target.value; i--) {
        this.session.rounds.pop();
        this.session.rounds[this.session.rounds.length - 1].extraRest = 0;
      }
    }
  }

  // If session mode is changed
  changeSessionMode() {
    this.session.rounds[this.session.rounds.length - 1].extraRest = 0;

    switch (this.session.workoutMode.id) {
      case 1:
        this.workoutModeTxt = this.ROUND_TXT;
        this.workoutModeSubTxt = this.ROUND_TXT;
        break;
      case 2:
      case 3:
        this.workoutModeTxt = this.SET_TXT;
        this.workoutModeSubTxt = this.SERIE_TXT;
        break;
    }
    this.refreshTimes();
  }

  refreshTimes() {
    if (this.session.workoutMode.id >= 2 && this.session.workoutMode.id <= 3) {
      for (const round of this.session.rounds) {
        round.extraRest = this.commonExtraRest;
      }
    }
  }
}
