import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { ComboSequenceFormHelper } from "../../helpers/combo-sequence-form-helper";
import { PlayerBlocksService } from "../../services/player-blocks.service";

import { ISequenceConfiguratorPlayer } from "../../interfaces/models/sequence/form/i-sequence-configurator-player";
import { ISequenceComboBlock } from "../../interfaces/models/sequence/i-sequence-combo-block";
import { ISequenceComboBlockPlayer } from "../../interfaces/models/sequence/i-sequence-combo-block-player";

@Component({
  selector: "sequence-player",
  templateUrl: "./sequence-player.component.html",
  styleUrls: ["./sequence-player.component.css"],
  providers: [PlayerBlocksService]
})
export class SequencePlayerComponent implements OnInit {
  @Input() player: ISequenceConfiguratorPlayer;
  @Input() currentPlayerId: number;

  @Output() selectedPlayer: EventEmitter<number> = new EventEmitter<number>();
  @Output() editBlockEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() deleteBlockEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() clearBlocksEvent: EventEmitter<number> = new EventEmitter<number>();

  constructor(private blocksService: PlayerBlocksService) {
  }


  ngOnInit() { }


  selectPlayer() {
    this.selectedPlayer.emit(this.player.id);
  }


  getTilesNumbersFromBlock(block: ISequenceComboBlockPlayer) {
    return ComboSequenceFormHelper.getTilesNumbersFromBlock(block);
  }


  getColorStyleFromBlock(block: ISequenceComboBlockPlayer) {
    return ComboSequenceFormHelper.getColorStyleFromBlock(block);
  }


  getColorClassFromBlock(block: ISequenceComboBlockPlayer) {
    return ComboSequenceFormHelper.getColorClassFromBlock(block);
  }


  getResponseIconFromBlock(block: ISequenceComboBlockPlayer) {
    return ComboSequenceFormHelper.getResponseIconFromBlock(block);
  }


  getTime() {
    return new Date().getTime();
  }


  editBlock(blockId) {
    this.editBlockEvent.emit(blockId);
  }


  deleteBlock(blockId) {
    this.deleteBlockEvent.emit(blockId);
  }

  clearBlocks() {
    this.clearBlocksEvent.emit(this.player.id);
  }
}
