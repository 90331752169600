import { SharedContent } from './../interfaces/models/shared-content';
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
// import "rxjs/add/operator/map";
// import "rxjs/add/operator/catch";
// import "rxjs/add/observable/of";
// import "rxjs/add/observable/throw";

import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";
import { Cacheable } from "ngx-cacheable";
import { Workout } from "../interfaces/models/blockworkout";

@Injectable({
  providedIn: 'root'
})
export class SharedContentService {  

  private urlGetSharedContentWorkout: string;
  private urlPutSharedContentWorkout: string;

  constructor(private http: HttpClient) {
    this.urlGetSharedContentWorkout = CONFIG.serverUrl + ENDPOINTS.sharedContent.get; 
    this.urlPutSharedContentWorkout = CONFIG.serverUrl + ENDPOINTS.sharedContent.put;
  }


  @Cacheable()
  public getSharedContent(workoutId: number): Observable<any> {
    let url = this.urlGetSharedContentWorkout.replace(":id", workoutId.toString());
    return this.http.get(url);
  }

  public putSharedContent(sharedContent: SharedContent): Observable<any> {
    const data = sharedContent;
    return this.http.put(this.urlPutSharedContentWorkout, data);
  }

}
