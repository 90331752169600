import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CONFIG } from './../../../assets/config';
import { ENUMS } from './../../../assets/enums';
import { NotificationService } from '../../services/notification.service';
import { ModalHomeFitnessRemoveSession } from './modal-home-fitness-remove-session.component';
import { ISession } from '../../interfaces/models/i-session';

@Component({
  selector: 'home-fitness-session-list',
  templateUrl: './home-fitness-session-list.component.html',
  styleUrls: ['./home-fitness-session-list.component.scss']
})
export class HomeFitnessSessionListComponent implements OnInit {

  public sessionList: ISession[];
  sessionTypeListTitle: string;
  sessionTypeId: number;
  exerciseListRange: number = 0;
  exerciseListAddOnEachScroll: number = 10;

  mediaUrl: string = CONFIG.exercisesMediaURL;
  exercisesMediaURL: string = CONFIG.exercisesMediaURL;
  previewImage = "";

  formUrl: string;

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['id', 'name', 'creationDate', 'lastUpdate', 'actions'];
  dataSource = new MatTableDataSource;

  constructor(private route: ActivatedRoute, private router: Router, private notificationService: NotificationService, public dialog: MatDialog) {
    this.sessionTypeId = this.route.snapshot.data.type;
    this.sessionList = this.route.snapshot.data.sessions.filter(s => s.type === this.sessionTypeId);;
    
    switch(this.sessionTypeId){
      case ENUMS.homeFitnessSessionTypes.warmup:
        this.sessionTypeListTitle = "WARM UP";
        this.formUrl = '/homefitness/warmup';
        break;
      case ENUMS.homeFitnessSessionTypes.session:
        this.sessionTypeListTitle = "SESSION";
        this.formUrl = '/homefitness/session';
        break;
      case ENUMS.homeFitnessSessionTypes.cooldown:
        this.sessionTypeListTitle = "COOLDOWN";
        this.formUrl = '/homefitness/cooldown';
        break;
      default: break;
    }

  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });    
  }

  ngAfterViewInit() {
     this.updateTableData();    
  }

  
  updateTableData(){
    this.dataSource.sort = this.sort;
    this.dataSource = new MatTableDataSource(this.sessionList);    
    this.dataSource.paginator = this.paginator;
  }

  
  public modalRemoveSession(session: ISession) {
    let modal = this.dialog.open(ModalHomeFitnessRemoveSession, {
      data: {
        session: session,
        canDelete: true
      }
    });
    
    modal.componentInstance.onDelete.subscribe( val => {
      this.sessionList = this.sessionList.filter( x => x.id != val.id);
      this.updateTableData();
    })
  }

}
