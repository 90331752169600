import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs/rx";
// import "rxjs/observable/combineLatest";

import { NotificationService } from "./notification.service";
import { ExerciseService } from "./exercise.service";
import { EquipmentService } from "./equipment.service";
import { StationService } from "./station.service";
import { LevelService } from "./level.service";
import { ProgramService } from "./program.service";
import { StatusService } from "./status.service";
import { ComboSequenceService } from "./combo-sequence.service";
import { WorkoutModeService } from "./workoutmode.service";
import { MoodService } from "./mood.service";

import { ILoginStatus } from "../interfaces/i-login-status";

import { CONFIG } from "../../assets/config";
import { ENUMS } from "../../assets/enums";
import { AuthService } from "./auth.service";



@Injectable()
export class DataService {

  private _loaded: boolean;

  constructor(private notificationService: NotificationService,
    private exerciseService: ExerciseService,
    private equipmentService: EquipmentService,
    private stationService: StationService,
    private levelService: LevelService,
    private programsService: ProgramService,
    private statusService: StatusService,
    private workoutModeService: WorkoutModeService,
    private sequenceService: ComboSequenceService,
    private moodService: MoodService,
    private authService: AuthService) {
      this._loaded = false;
  }

  // loadAppData(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this.authService.validateOrRefreshToken().subscribe(resp => {
  //       if (resp.valid) {
  //         Observable.combineLatest(
  //             this.equipmentService.getEquipment(),
  //             this.stationService.getStations(),
  //             this.stationService.getStationTypes(),
  //             this.levelService.getLevels(),
  //             this.levelService.getIntensityLevels(),
  //             this.levelService.getComplexityLevels(),
  //             this.exerciseService.getExerciseTypes(),
  //             this.exerciseService.getExerciseMuscularGroups(),
  //             this.exerciseService.getExerciseSkills(),
  //             this.exerciseService.getExerciseDisciplines(),
  //             this.programsService.getPrograms(),
  //             this.statusService.getStatus(),
  //             this.sequenceService.getSequenceTypes(),
  //             this.sequenceService.getSequenceColorModes(),
  //             this.sequenceService.getSequenceResponseTypes(),
  //             this.workoutModeService.getWorkoutModes(),
  //             this.moodService.getMoods()
  //           )
  //           .subscribe(
  //             (data: boolean[]) => {
  //               if (CONFIG.traceLoads) {
  //                 console.log("-------- DATASERVICE-------------------DATA LOADED--------------------------");
  //               }

  //               if (data.every( x => x === true)) {
  //                 resolve(resp);
  //               } else {
  //                 //reject("Error loading data");
  //                 resolve(resp);
  //               }
  //             }, error => {
  //               reject(error);
  //             }
  //           );
  //         } else {
  //           resolve(resp);
  //         }
  //       });
  //     });
  // }
}
