import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { TriggersService } from '../triggers.service';

import { of } from "rxjs";

@Injectable()
export class TriggersResolver implements Resolve<Observable<string>> {

    constructor(private triggersService: TriggersService) { }

    resolve() {
        return this.triggersService.getTriggers().catch(() => {
            return of('error');
        });
    }
}

@Injectable()
export class TriggerTypesResolver implements Resolve<Observable<string>> {

    constructor(private triggersService: TriggersService) { }

    resolve() {
        return this.triggersService.getTriggerTypes().catch(() => {
            return of('error');
        });
    }
}


@Injectable()
export class HomeTriggerTypesResolver implements Resolve<Observable<string>> {

    constructor(private triggersService: TriggersService) { }

    resolve() {
        return this.triggersService.getHomeTriggerTypes().catch(() => {
            return of('error');
        });
    }
}



@Injectable()
export class TriggerMoodsResolver implements Resolve<Observable<string>> {

    constructor(private triggersService: TriggersService) { }

    resolve() {
        return this.triggersService.getMoods().catch(() => {
            return of('error');
        });
    }
}

@Injectable()
export class TriggerAudiosResolver implements Resolve<Observable<string>> {

    constructor(private triggersService: TriggersService) { }

    resolve() {
        return this.triggersService.getAudios().catch(() => {
            return of('error');
        });
    }
}


