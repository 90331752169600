import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";

import { ISession } from "../interfaces/models/i-session";
// import { SessionMapper } from "./mappers/session-mapper";

import { Cacheable } from 'ngx-cacheable';

@Injectable()
export class HomeFitnessSessionService {

  private urlGetSession: string;
  private urlGetSessions = CONFIG.serverUrl + ENDPOINTS.homeFitnessSessions.get;
  
  private urlPostSession = CONFIG.serverUrl + ENDPOINTS.homeFitnessSession.post;
  private urlPutSession = CONFIG.serverUrl + ENDPOINTS.homeFitnessSession.put;
  private urlDisableSession = CONFIG.serverUrl + ENDPOINTS.homeFitnessSession.delete;

  constructor(private http: HttpClient) {
  }

  //@Cacheable()
  public getSessions(): Observable<any> {
    return this.http.get(this.urlGetSessions);
  }


  @Cacheable()
  public getSession(sessionId: number): Observable<any> {
    this.urlGetSession = CONFIG.serverUrl + ENDPOINTS.session.get
    this.urlGetSession = this.urlGetSession.replace(":id", sessionId.toString());
    return this.http.get(this.urlGetSession);
  }

  public putSession(session: ISession): Observable<any> {
    const data = session;
    return this.http.put(this.urlPutSession, data);
  }

  public postSession(session: ISession): Observable<any> {
    const data = session;
    return this.http.post(this.urlPostSession, data);
  }

  public deleteSession(session: ISession): Observable<any> {
    let url = this.urlDisableSession.replace(":id", session.id.toString());
    return this.http.put(url, null);
  }
}
