import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { SessionService } from '../session.service';

import { of } from "rxjs";


@Injectable()
export class SessionsResolver implements Resolve<Observable<string>> {

  constructor(private sessionService: SessionService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.sessionService.getSessions().catch(() => {
      return of('error');
    });
  }
}

