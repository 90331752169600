import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, AfterViewInit, ViewChild } from "@angular/core";

import { ExerciseSearchHelper } from "../../helpers/exercise-search-helper";

import { IExercise } from "../../interfaces/models/i-exercise";
import { ISession } from "../../interfaces/models/i-session";
import { IFormFieldExerciseSearch } from "../../interfaces/models/i-form-fields-exercise-search";

import { CONFIG } from "../../../assets/config";
import { ActivatedRoute } from "@angular/router";

import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: "exercise-search",
  templateUrl: "./exercise-search.component.html",
  styleUrls: ["./exercise-search.component.css"]
})
export class ExerciseSearchComponent implements OnInit {

  @Input() currentStationId: number;
  @Input() exercises: IExercise[];
  
  @Input() isBlockCreator: boolean = false;

  exerciseList: IExercise[];
  exerciseListFiltered: IExercise[];

  formFields: IFormFieldExerciseSearch;

  currentExerciseId: number;
  currentExercise: IExercise;


  @Output() exerciseFound: EventEmitter<IExercise> = new EventEmitter<IExercise>();
  @Output() searchCancelled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() stationCleaned: EventEmitter<number> = new EventEmitter<number>();

  spinnerImageURL: string;
  currentExerciseImageURL: string;
  imageReady: boolean;

  mediaUrl: string = CONFIG.exercisesMediaURL;
  previewImage = "";

  pageEvent: PageEvent;
  pageIndex: number = 0;
  pageSize: number = 10;
  lowValue: number = 0;
  highValue: number = 10;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private route: ActivatedRoute) {
    this.exerciseList = [];
    this.exerciseListFiltered = [];
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnChanges(changes: SimpleChanges) {
    this.resetSearch();
    this.exerciseList = this.exercises;
  }

  ngOnInit() {
    this.loadData();
    this.exerciseList = this.exercises;
  }

  loadData() {
    this.spinnerImageURL = "../../assets/loading.gif";
    this.formFields = ExerciseSearchHelper.getFormFields();
    this.currentExerciseId = 0;
  }

  selectExercise(id: number) {
    this.currentExerciseId = id;
    this.currentExercise = this.exercises.find(x => x.id === id);
  }

  showImage() {
    this.imageReady = true;
  }

  cancelSearch() {
    this.resetSearch();
    this.searchCancelled.emit(true);
  }

  exerciseSelected() {
    if (this.currentExercise) {
      this.exerciseFound.emit(this.currentExercise);
      this.resetSearch();
    }
  }

  resetSearch() {
    this.currentExercise = undefined;
    this.currentExerciseId = 0;
    this.currentExerciseImageURL = undefined;
  }

  showPreview(exercise: IExercise) {
    this.previewImage = (this.mediaUrl + exercise.thumbnail).toString();
  }

  getPaginatorData($event) {    
    if ($event.pageIndex === this.pageIndex + 1) {
      this.lowValue = this.lowValue + this.pageSize;
      this.highValue = this.highValue + this.pageSize;
    }
    else if ($event.pageIndex === this.pageIndex - 1) {
      this.lowValue = this.lowValue - this.pageSize;
      this.highValue = this.highValue - this.pageSize;
    }
    this.pageIndex = $event.pageIndex;

    return $event;
  }

  goToFirstPage(){
    this.pageIndex = 0;
    this.paginator.pageIndex = 0;
    this.lowValue = 0;    
    this.highValue = 10;    
  }

  cleanStation(){
    this.stationCleaned.emit(this.currentStationId);
  }

}
