import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { of, from,  BehaviorSubject, Subject } from 'rxjs';
import "rxjs/add/operator/mergeMap";
import 'rxjs/add/observable/of';

import { ISessionToken } from "../interfaces/i-session-token";
import { ILoginData } from "../interfaces/i-login-data";

import { CONFIG } from "../../assets/config";
import { ENDPOINTS } from "../../assets/endpoints";
import { ENUMS } from "../../assets/enums";

@Injectable()
export class AuthService {
  private urlLogin =  CONFIG.serverUrl + ENDPOINTS.token.post;
  private urlValidateToken = CONFIG.serverUrl + ENDPOINTS.validateToken.post;
  private urlRefreshToken = CONFIG.serverUrl + ENDPOINTS.refreshToken.post;

  public token: ISessionToken;

  constructor(public http: HttpClient) { }


  public checkLogin(data: ILoginData): Observable<any> {
    return this.http.post(this.urlLogin, data);
  }

  public validateOrRefreshToken(): Observable<any> {
    return this.validToken().mergeMap( resp => this.refreshToken(resp));
  }


  private validToken(): Observable<any> {
    const result = {
      valid: false,
      expired: false
    };
    const token: ISessionToken = JSON.parse(localStorage.getItem(ENUMS.keys.session));
    if (!token || !token.token || token.token === "") {
      return Observable.of(result);
    } else {
      return this.http.post(this.urlValidateToken, token);
    }
  }


  private refreshToken(validationData): Observable<any> {
    if (!validationData.valid && validationData.expired) {
      const result = {
        valid: false,
        expired: false
      };
      const token: ISessionToken = JSON.parse(localStorage.getItem(ENUMS.keys.session));
      const data = { memberID: token.user, refreshToken: token.refreshToken };
      return this.http.post(this.urlRefreshToken, data);

    } else {
      return Observable.of(validationData);
    }
  }


  public saveSession(session) {
    localStorage.clear();
    localStorage.setItem(ENUMS.keys.session, JSON.stringify(session.session));
    localStorage.setItem(ENUMS.keys.roles, JSON.stringify(session.roles));
  }


  public isLogged() {
    const token: ISessionToken = JSON.parse(localStorage.getItem(ENUMS.keys.session));
    const roles: number[] = JSON.parse(localStorage.getItem(ENUMS.keys.roles));

    if (token && token != null && roles && roles != null) {
      return true;
    }
    return false;
  }


  public closeSession(): Observable<boolean> {
    localStorage.clear();
    return Observable.of(true);
  }


  public isInRole(requiredRole: number) {
    const roles: number[] = JSON.parse(localStorage.getItem(ENUMS.keys.roles));
    if (roles && roles !== null) {
      return roles.some(x => x === requiredRole);
    }

    return false;
  }

  public getUserId() {
    const token: ISessionToken = JSON.parse(localStorage.getItem(ENUMS.keys.session));
    return token.userId;
  }

}
