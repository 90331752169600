import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { MoodService } from '../mood.service';

import { of } from "rxjs";

@Injectable()
export class MoodsAvailableResolver implements Resolve<Observable<string>> {

    constructor(private moodsService: MoodService) { }

    resolve() {
        return this.moodsService.getMoods().catch(() => {
            return  of('error');
        });
    }

}

