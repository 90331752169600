<form (ngSubmit)="onSubmit()" #siteForm="ngForm">

  <h1>SESSION CREATOR</h1>

  <!-- SESSION INFO -->
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
    <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch">
      <h5 fxFlex="nogrow">MAIN INFO</h5>
      <mat-card fxFlex>
        <div *ngIf="__session.id !== 0" fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxLayoutAlign="end start">
          <h3 class="color-white">#{{__session.id}}</h3>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
          <div fxLayout="column" fxFlex="50%">
            <!-- NAME -->
            <mat-form-field>
              <input matInput #sessionName placeholder="Name" [value]="__session.name" [(ngModel)]="__session.name"
                name="sessionName" required>
              <mat-hint class="form-error-message" *ngIf="formSubmitted && !__session.name">
                Name is required
              </mat-hint>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxFlex="50%">
            <!-- FINISHER TYPE -->
            <mat-form-field>
              <mat-select placeholder="Mode" (selectionChange)="changeFinisherType(false)"

               [(ngModel)]="__session.finisherType" name="finisherType" required>
                <mat-option *ngFor="let type of finisherTypes" [value]="type.id">
                  {{type.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
          <!-- DESCRIPTION -->
          <mat-form-field fxFill>
            <textarea matInput #sessionDescription placeholder="Description" [value]="__session.description"
              [(ngModel)]="__session.description" name="sessionDescription" required></textarea>
            <mat-hint class="form-error-message" *ngIf="formSubmitted && !__session.description">
              Description is required
            </mat-hint>
          </mat-form-field>
        </div>
      </mat-card>

    </div>
    <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch" class="posrel">

      <h5 fxFlex="nogrow">IMAGE</h5>
      <mat-card fxFlex class="nopadding">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxFill>
          <image-uploader [imageName]="this.__session.image" [mediaUrl]="this.mediaUrl" (selectedImageEvent)="imageSelected($event)"
            (deletedImageEvent)="imageRemoved()" [formField]="" fxFill>
          </image-uploader>
        </div>
      </mat-card>

    </div>
  </div>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
    <mat-toolbar class="mat-buttons-toolbar">
      <mat-toolbar-row>
        <span class="mat-spacer"></span>
        <button mat-flat-button type="submit" class="prama-button full-button medium-button">
          <mat-icon>save</mat-icon> SAVE
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>

  <mat-horizontal-stepper #stepper>

    <!-- STEP: MOODS -->
    <mat-step>
      <ng-template matStepLabel>MOODS
        <div class="form-error-message" *ngIf="formSubmitted && moodsError">
          Moods are required
        </div>
      </ng-template>

      <div fxLayout="row" fxLayout.lg="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
        <div fxFlex="{{__session.finisherType===2? '50%':'100%'}}" fxLayout="row" fxLayoutGap="20px" fxFlexAlign="stretch" class="moods-containter">

          <div fxFlex="50%">
            <mat-card class="nopadding" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-between stretch">
              <div fxFlex="30%" class="mood-image" [style.backgroundImage]="'url('+ getMoodImageFullPath(getMoodByRoundAndMode(null, 2)) +')'"></div>
              <div class="padding30" fxFlex>
                <h6>Countdown</h6>
                <mat-form-field fxFill>
                  <mat-select (selectionChange)="globalMoodChange($event)" name="select-mood-2" [(ngModel)]="getMoodByRoundAndMode(null, 2).mood"
                    required>
                    <mat-option [value]="11"> - </mat-option>
                    <mat-option *ngFor="let mood of moodsAvailable" [value]="mood.id">
                      <img class="select-item-img" [src]="'assets/moods/' + mood.id + '.png'" />
                      {{mood.moodName}}
                    </mat-option>
                  </mat-select>
                  <mat-hint class="form-error-message" *ngIf="formSubmitted && (!getMoodByRoundAndMode(null, 2).mood || getMoodByRoundAndMode(null, 2).mood === 11)">
                    Mood is required
                  </mat-hint>
                </mat-form-field>
              </div>
            </mat-card>
          </div>

          <div fxFlex="50%">
            <mat-card class="nopadding" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-between stretch">
              <div fxFlex="30%" class="mood-image" [style.backgroundImage]="'url('+ getMoodImageFullPath(getMoodByRoundAndMode(null, 3)) +')'"></div>
              <div class="padding30" fxFlex>
                <h6>Work</h6>
                <mat-form-field fxFill>
                  <mat-select (selectionChange)="globalMoodChange($event)" name="select-mood-3" [(ngModel)]="getMoodByRoundAndMode(null, 3).mood"
                    required>
                    <mat-option [value]="11"> - </mat-option>
                    <mat-option *ngFor="let mood of moodsAvailable" [value]="mood.id">
                      <img class="select-item-img" [src]="'assets/moods/' + mood.id + '.png'" />
                      {{mood.moodName}}
                    </mat-option>
                  </mat-select>
                  <mat-hint class="form-error-message" *ngIf="formSubmitted && (!getMoodByRoundAndMode(null, 3).mood || getMoodByRoundAndMode(null, 3).mood === 11)">
                    Mood is required
                  </mat-hint>
                </mat-form-field>
              </div>
            </mat-card>
          </div>

        </div>
        <div fxFlex="50%" fxLayout="row" fxLayoutGap="20px" fxFlexAlign="stretch" class="moods-containter" *ngIf="__session.finisherType === 2">

          <div fxFlex="50%">
            <mat-card class="nopadding" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-between stretch">
              <div fxFlex="30%" class="mood-image" [style.backgroundImage]="'url('+ getMoodImageFullPath(getMoodByRoundAndMode(null, 4)) +')'"></div>
              <div class="padding30" fxFlex>
                <h6>Rest</h6>
                <mat-form-field fxFill>
                  <mat-select (selectionChange)="globalMoodChange($event)" name="select-mood-4" [(ngModel)]="getMoodByRoundAndMode(null, 4).mood"
                    required>
                    <mat-option [value]="11"> - </mat-option>
                    <mat-option *ngFor="let mood of moodsAvailable" [value]="mood.id">
                      <img class="select-item-img" [src]="'/assets/moods/' + mood.id + '.png'" />
                      {{mood.moodName}}
                    </mat-option>
                  </mat-select>
                  <mat-hint class="form-error-message" *ngIf="formSubmitted && (!getMoodByRoundAndMode(null, 4).mood || getMoodByRoundAndMode(null, 4).mood === 11)">
                    Mood is required
                  </mat-hint>
                </mat-form-field>
              </div>
            </mat-card>
          </div>

          <div fxFlex="50%">
            <mat-card class="nopadding" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-between stretch">
              <div fxFlex="30%" class="mood-image" [style.backgroundImage]="'url('+ getMoodImageFullPath(getMoodByRoundAndMode(null, 1)) +')'"></div>
              <div class="padding30" fxFlex>
                <h6>StandBy</h6>
                <mat-form-field fxFill>
                  <mat-select (selectionChange)="globalMoodChange($event)" name="select-mood-1" [(ngModel)]="getMoodByRoundAndMode(null, 1).mood"
                    required>
                    <mat-option [value]="11"> - </mat-option>
                    <mat-option *ngFor="let mood of moodsAvailable" [value]="mood.id">
                      <img class="select-item-img" [src]="'assets/moods/' + mood.id + '.png'" />
                      {{mood.moodName}}
                    </mat-option>
                  </mat-select>
                  <mat-hint class="form-error-message" *ngIf="formSubmitted && (!getMoodByRoundAndMode(null, 1).mood || getMoodByRoundAndMode(null, 1).mood === 11)">
                    Mood is required
                  </mat-hint>
                </mat-form-field>
              </div>
            </mat-card>
          </div>

        </div>
      </div>
    </mat-step>

    <!-- STEP: PLAYLISTS -->
    <mat-step>
      <ng-template matStepLabel>PLAYLISTS
        <div class="form-error-message" *ngIf="formSubmitted && playlistError">
          Playlists are required
        </div>
      </ng-template>

      <div fxLayout="row" fxLayout.lg="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
        <div fxFlex="{{__session.finisherType===2? '50%':'100%'}}" fxLayout="row" fxLayoutGap="20px" fxFlexAlign="stretch" class="moods-containter">

          <div fxFlex="50%">
            <mat-card class="nopadding">
              <div class="padding30">
                <h6>Countdown</h6>
                <mat-form-field fxFill>
                  <mat-select (selectionChange)="globalPlaylistChange($event)" name="select-playlist-2" [(ngModel)]="__session.playlists[getPlaylistIndexByMode(2)].playlist"
                    required fxFill>
                    <mat-option [value]="0"> - </mat-option>
                    <mat-option *ngFor="let playlist of currentProgramPlaylists" [value]="playlist.idPlaylist">
                      {{playlist.playlistName}}
                    </mat-option>
                  </mat-select>
                  <mat-hint class="form-error-message" *ngIf="formSubmitted && !__session.playlists[getPlaylistIndexByMode(2)].playlist">
                    Playlist is required
                  </mat-hint>
                </mat-form-field>
              </div>
            </mat-card>
          </div>

          <div fxFlex="50%">
            <mat-card class="nopadding">
              <div class="padding30">
                <h6>Work</h6>
                <mat-form-field fxFill>
                  <mat-select (selectionChange)="globalPlaylistChange($event)" name="select-playlist-3" [(ngModel)]="__session.playlists[getPlaylistIndexByMode(3)].playlist"
                    required fxFill>
                    <mat-option [value]="0"> - </mat-option>
                    <mat-option *ngFor="let playlist of currentProgramPlaylists" [value]="playlist.idPlaylist">
                      {{playlist.playlistName}}
                    </mat-option>
                  </mat-select>
                  <mat-hint class="form-error-message" *ngIf="formSubmitted && !__session.playlists[getPlaylistIndexByMode(3)].playlist">
                    Playlist is required
                  </mat-hint>
                </mat-form-field>
              </div>
            </mat-card>
          </div>

        </div>
        <div fxFlex="50%" fxLayout="row" fxLayoutGap="20px" fxFlexAlign="stretch" class="moods-containter" *ngIf="__session.finisherType === 2">

          <div fxFlex="50%">
            <mat-card class="nopadding">
              <div class="padding30">
                <h6>Rest</h6>
                <mat-form-field fxFill>
                  <mat-select (selectionChange)="globalPlaylistChange($event)" name="select-playlist-4" [(ngModel)]="__session.playlists[getPlaylistIndexByMode(4)].playlist"
                    required fxFill>
                    <mat-option [value]="0"> - </mat-option>
                    <mat-option *ngFor="let playlist of currentProgramPlaylists" [value]="playlist.idPlaylist">
                      {{playlist.playlistName}}
                    </mat-option>
                  </mat-select>
                  <mat-hint class="form-error-message" *ngIf="formSubmitted && !__session.playlists[getPlaylistIndexByMode(4)].playlist">
                    Playlist is required
                  </mat-hint>
                </mat-form-field>
              </div>
            </mat-card>
          </div>

          <div fxFlex="50%">
            <mat-card class="nopadding">
              <div class="padding30">
                <h6>Extra Rest</h6>
                <mat-form-field fxFill>
                  <mat-select (selectionChange)="globalPlaylistChange($event)" name="select-playlist-5" [(ngModel)]="__session.playlists[getPlaylistIndexByMode(5)].playlist"
                    required fxFill>
                    <mat-option [value]="0"> - </mat-option>
                    <mat-option *ngFor="let playlist of currentProgramPlaylists" [value]="playlist.idPlaylist">
                      {{playlist.playlistName}}
                    </mat-option>
                  </mat-select>
                  <mat-hint class="form-error-message" *ngIf="formSubmitted && !__session.playlists[getPlaylistIndexByMode(5)].playlist">
                    Playlist is required
                  </mat-hint>
                </mat-form-field>
              </div>
            </mat-card>
          </div>

        </div>
      </div>
    </mat-step>

    <!-- STEP: WORKOUT EXERCISES -->
    <mat-step *ngIf="__session.finisherType !== 1">
      <ng-template matStepLabel>Exercises
        <div class="form-error-message" *ngIf="formSubmitted && !exercisesFilled()">
          Exercises are required
        </div>
      </ng-template>

      <div class="margin10">
        <mat-table #table [dataSource]="dataSource" matSort class="station-table">
          <ng-container matColumnDef="stationIcon">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"></mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="5%">
              <img [src]="'/assets/stations/' + element.station.id + '.png'" />
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="stationName">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10%"> Station </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="10%">
              <h4>{{element.station.name}}</h4>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="exerciseName">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="20%"> Exercise selected </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="20%">
              <span [title]="element.exercise?.id">{{element.exercise?.name}} </span>
              <a target="_blank" *ngIf="element.exercise" [routerLink]="['/exercise/'+element.exercise?.id]"
                routerLinkActive="router-link-active">
                <mat-icon class="internal-link">visibility</mat-icon>
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="exerciseIntensity">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Intensity </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element.exercise?.intensityLevel?.name}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="exerciseComplexity">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Complexity </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element.exercise?.complexityLevel?.name}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="exerciseDiscipline">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Discipline </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element.exercise?.discipline?.name}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="exerciseMuscularGroup">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Muscular Groups </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span>{{getExerciseByStationId(element.station.id)?.muscularGroup1?.name}}</span>
              <span *ngFor="let mg of getExerciseByStationId(element.station.id)?.muscularGroup2; let i = index">
                <span *ngIf="mg.idMuscularGroup">, </span> {{mg.idMuscularGroup?.name}}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="exerciseFundamentalMovement">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Fundamental Movements </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="getExerciseByStationId(element.station.id)?.fundamentalMovement1">{{getExerciseByStationId(element.station.id)?.fundamentalMovement1?.name}}
                ({{getExerciseByStationId(element.station.id)?.fundamentalMovement1?.idCategory}})</span>
              <span *ngFor="let fm of getExerciseByStationId(element.station.id)?.fundamentalMovement2; let i = index">
                <span *ngIf="fm.fundamentalMovement">, </span> {{fm.fundamentalMovement?.name}}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="exerciseImage">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxLayout="column" fxLayoutAlign="center flex-end">
              Preview
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="exercise-gif" fxLayout="column" fxLayoutAlign="flex-end flex-end">
              <div *ngIf="element.exercise" class="session-exercise-image" [ngStyle]="{'background-image': 'url(' + exercisesMediaURL + element.exercise.thumbnail + ')'}">
                <!-- <img *ngIf="element.exercise" [src]="exercisesMediaURL + element.exercise.thumbnail" /> -->
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="stationOptions">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxLayout="column" fxLayoutAlign="center center" fxFlex="5%">
            </mat-header-cell>
            <mat-cell *matCellDef="let element" class="exercise-gif" fxLayout="column" fxLayoutAlign="center center"
              fxFlex="5%">

              <!--  <div fxLayout="row" fxFlex="50" fxFlexAlign="center center">
                <button type="button" mat-flat-button class="prama-round-button">
                <mat-icon>edit</mat-icon>
              </button>
              </div>-->
              <div fxLayout="column" fxFlexAlign="space-between stretch" fxFill>
                <button *ngIf="element.exercise" type="button" mat-flat-button class="prama-transparent-button nomargin"
                  (click)="searchExercise(element.station.id, $event); openSearchModal(searchExerciseModal)" fxFill>
                  <mat-icon>edit</mat-icon>
                </button>
                <!-- <button *ngIf="element.exercise" type="button" mat-flat-button class="prama-transparent-button" (click)="removeExercise(element.station.id)">
                  <mat-icon class="yellow">delete</mat-icon>
                </button> -->
                <button *ngIf="!element.exercise"
                  type="button" mat-flat-button class="prama-transparent-button nomargin" 
                  (click)="searchExercise(element.station.id, $event); openSearchModal(searchExerciseModal)"
                  fxFill>
                  <mat-icon>add_circle_outline</mat-icon>
                </button>
              </div>

            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" fxLayoutAlign="space-between stretch"></mat-row>
        </mat-table>
      </div>

    </mat-step>

    <!-- STEP: WORKOUT ROUNDS -->
    <mat-step>
      <ng-template matStepLabel> {{__session.finisherType === 2 ? 'Rounds' : 'Time'}}</ng-template>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
        <mat-toolbar class="mat-buttons-toolbar workout-toolbar">
          <mat-toolbar-row *ngIf="__session.finisherType === 2">
            <button mat-flat-button type="button" class="prama-button full-button" fxLayout="row" fxLayoutGap="5px"
              (click)="changeTotalRounds(-1)" [disabled]="__totalRounds <= 1">
              <mat-icon>remove_circle_outline</mat-icon>
              <span>REMOVE ROUND</span>
            </button>
            <button mat-flat-button type="button" class="prama-button full-button" fxLayout="row" fxLayoutGap="5px"
              (click)="changeTotalRounds(+1)">
              <mat-icon>add_circle_outline</mat-icon>
              <span>ADD ROUND</span>
            </button>
            <span class="mat-spacer"></span>
            <div class="time-box">
              <h4>{{ __totalRounds }}</h4>
              <h6 *ngIf="__session.workoutMode?.id === 0">ROUNDS</h6>
              <h6 *ngIf="__session.workoutMode?.id === 1">ROUNDS</h6>
              <h6 *ngIf="__session.workoutMode?.id === 2">SETS</h6>
              <h6 *ngIf="__session.workoutMode?.id === 3">SUPERSETS</h6>
            </div>
            <div class="time-box">
              <h4>{{ getExerciseCount() }}</h4>
              <h6>USED STATIONS</h6>
            </div>
            <div class="time-box">
              <h4>{{calculateTotalWorkTime() | timeToMinutes}}</h4>
              <h6>TOTAL WORK TIME</h6>
            </div>
            <div class="time-box">
              <h4>{{calculateTotalRestTime() | timeToMinutes}}</h4>
              <h6>TOTAL REST TIME</h6>
            </div>
            <div class="time-box">
              <h4>{{calculateTotalTime() | timeToMinutes}}</h4>
              <h6>TOTAL SESSION TIME</h6>
            </div>
          </mat-toolbar-row>
        </mat-toolbar>
      </div>

      <div class="sessions-container">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxLayoutAlign="start start">
          <div *ngFor="let round of this.__session.rounds; let i = index" fxLayout="row">
            <mat-card class="card-phase card-rest">
              <div fxLayout="column">
                <div fxFill>
                  <h2 class="floatLeft" fxLayout="row" fxLayoutGap="5px">
                    <mat-icon>av_timer</mat-icon>
                    <span>
                      <span *ngIf="__session.workoutMode?.id === 0">ROUND</span>
                      <span *ngIf="__session.workoutMode?.id === 1">ROUND</span>
                      <span *ngIf="__session.workoutMode?.id === 2">SET</span>
                      <span *ngIf="__session.workoutMode?.id === 3">SUPERSET</span>
                      <span> {{i+1}}</span>
                    </span>
                  </h2>
                  <h2 class="floatRight color-white">{{calculateRoundTime(round) | timeToMinutes}}</h2>
                </div>

                <!-- WORK -->
                <div>

                  <mat-divider class="mat-divider-timeline"></mat-divider>
                  <h6>
                    <!-- <mat-icon>directions_run</mat-icon> -->
                    <span>Work</span>
                  </h6>

                  <mat-form-field fxFill>
                    <input matInput type="number" [(ngModel)]="round.work" name="roundWork{{i}}" min="0" placeholder="Time"
                      step="5" required>
                  </mat-form-field>

                  <div *ngIf="__session.finisherType === 2">
                    <mat-form-field class="floatLeft w-80">
                      <mat-select placeholder="Mood" [(ngModel)]="getMoodByRoundAndMode(round.id, 3).mood" name="round-{{round.id}}-mood-{{3}}">
                        <mat-option value="0"> - </mat-option>
                        <mat-option *ngFor="let mood of moodsAvailable" [value]="mood.id">
                          {{mood.moodName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="mood-preview mt-1 ml-1 floatRight"><img [src]="'assets/moods/' + getMoodImageName(getMoodByRoundAndMode(round.id, 3))" /></div>
                  </div>

                  <mat-form-field fxFill *ngIf="__session.finisherType === 2">
                    <mat-select placeholder="Playlist" [(ngModel)]="getPlaylistByRoundAndMode(round.id, 3).playlist" name="round-{{round.id}}-playlist-{{3}}">
                      <mat-option value="0"> - </mat-option>
                      <mat-option *ngFor="let playlist of currentProgramPlaylists" [value]="playlist.idPlaylist">
                        {{playlist.playlistName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <!-- REST -->
                <div *ngIf="__session.finisherType === 2">
                  <mat-divider class="mat-divider-timeline"></mat-divider>
                  <h6>
                    <!-- <mat-icon>weekend</mat-icon> -->
                    <span>Rest</span>
                  </h6>

                  <mat-form-field fxFill>
                    <input matInput type="number" [(ngModel)]="round.rest" name="roundRest{{i}}" min="0" placeholder="Time"
                      step="5" required>
                  </mat-form-field>

                  <div>
                    <mat-form-field class="floatLeft w-80">
                      <mat-select placeholder="Mood" [(ngModel)]="getMoodByRoundAndMode(round.id, 4).mood" name="round-{{round.id}}-mood-{{4}}">
                        <mat-option value="0"> - </mat-option>
                        <mat-option *ngFor="let mood of moodsAvailable" [value]="mood.id">
                          {{mood.moodName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="mood-preview mt-1 ml-1 floatRight"><img [src]="'assets/moods/' + getMoodImageName(getMoodByRoundAndMode(round.id, 4))" /></div>
                  </div>

                  <mat-form-field fxFill>
                    <mat-select placeholder="Playlist" [(ngModel)]="getPlaylistByRoundAndMode(round.id, 4).playlist" name="round-{{round.id}}-playlist-{{4}}">
                      <mat-option value="0"> - </mat-option>
                      <mat-option *ngFor="let playlist of currentProgramPlaylists" [value]="playlist.idPlaylist">
                        {{playlist.playlistName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>
              </div>

            </mat-card>
            <mat-card *ngIf="round.extraRest && __session.workoutMode.id == 1" class="card-phase">
              <div fxLayout="column">
                <div fxLayout="column">
                  <div fxFill>
                    <h2 class="floatLeft" fxLayout="row" fxLayoutGap="10px">
                      <mat-icon>hotel</mat-icon>
                      <span>EXTRA REST</span>
                    </h2>
                    <h2 class="floatRight color-white">{{round.extraRest | timeToMinutes}}</h2>
                  </div>

                  <mat-divider class="mat-divider-timeline"></mat-divider>
                  <h6>
                    <!-- <mat-icon>weekend</mat-icon> -->
                    <span>Extra rest</span>
                  </h6>

                  <mat-form-field fxFill>
                    <input matInput type="number" [(ngModel)]="round.extraRest" name="roundExtraRest{{i}}" min="0"
                      placeholder="Time" step="5" required>
                  </mat-form-field>

                  <div>
                    <mat-form-field class="floatLeft w-80">
                      <mat-select placeholder="Mood" [(ngModel)]="getMoodByRoundAndMode(round.id, 5).mood" name="round-{{round.id}}-mood-{{5}}">
                        <mat-option value="0"> - </mat-option>
                        <mat-option *ngFor="let mood of moodsAvailable" [value]="mood.id">
                          {{mood.moodName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="mood-preview mt-1 ml-1 floatRight"><img [src]="'assets/moods/' + getMoodImageName(getMoodByRoundAndMode(round.id, 5))" /></div>
                  </div>

                  <mat-form-field fxFill>
                    <mat-select placeholder="Playlist" [(ngModel)]="getPlaylistByRoundAndMode(round.id, 5).playlist"
                      name="round-{{round.id}}-playlist-{{5}}">
                      <mat-option value="0"> - </mat-option>
                      <mat-option *ngFor="let playlist of currentProgramPlaylists" [value]="playlist.idPlaylist">
                        {{playlist.playlistName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>

            <mat-card *ngIf="round == __session.rounds[__session.rounds.length-1] && __session.workoutMode.id >= 2 && __session.workoutMode.id <= 3"
              class="card-phase">
              <div fxLayout="column">
                <div fxLayout="column">
                  <div fxFill>
                    <h2 class="floatLeft" fxLayout="row" fxLayoutGap="10px">
                      <mat-icon>hotel</mat-icon>
                      <span>REST BETWEEN SETS</span>
                    </h2>
                    <!-- <h2 class="floatRight color-white">{{round.extraRest | timeToMinutes}}</h2> -->
                  </div>

                  <mat-divider class="mat-divider-timeline"></mat-divider>
                  <h6>
                    <!-- <mat-icon>weekend</mat-icon> -->
                    <span>Extra rest</span>
                  </h6>

                  <mat-form-field fxFill>
                    <input matInput type="number" [(ngModel)]="__commonExtraRest" name="commonExtraRest" min="0"
                      (change)="refreshTimes()" placeholder="Time" step="5" required>
                  </mat-form-field>

                  <div>
                    <mat-form-field class="floatLeft w-80">
                      <mat-select placeholder="Mood" [(ngModel)]="getMoodByRoundAndMode(round.id, 5).mood" name="round-{{round.id}}-mood-{{5}}">
                        <mat-option value="0"> - </mat-option>
                        <mat-option *ngFor="let mood of moodsAvailable" [value]="mood.id">
                          {{mood.moodName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="mood-preview mt-1 ml-1 floatRight"><img [src]="'assets/moods/' + getMoodImageName(getMoodByRoundAndMode(round.id, 5))" /></div>
                  </div>

                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>




    </mat-step>

  </mat-horizontal-stepper>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
    <mat-toolbar class="mat-buttons-toolbar">
      <mat-toolbar-row>
        <button mat-flat-button (click)="stepperGoPrevious()" class="prama-button full-button" [disabled]="isFirstStep()"
          type="button">
          <mat-icon>arrow_back_ios</mat-icon> PREVIOUS
        </button>
        <span class="mat-spacer"></span>
        <button mat-flat-button (click)="stepperGoNext()" class="prama-button full-button" [disabled]="isLastStep()"
          type="button">
          <mat-icon>arrow_forward_ios</mat-icon> NEXT
        </button>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>

  <ng-template #searchExerciseModal>
    <div class="modal-search-exercise">
      <div class="modal-body">
        <button type="button" class="floatRight" mat-flat-button class="prama-transparent-button" (click)="modalRef.hide()">
          <mat-icon>clear</mat-icon>
        </button>
        <exercise-search [exercises]="exercisesToSearch" [currentStationId]="currentStationId" (exerciseFound)="exerciseSelected($event); closeSearchModal();"
          (searchCancelled)="searchCancelled()">
        </exercise-search>
      </div>
    </div>
  </ng-template>
</form>
