
import { Component, OnInit, ViewChild, AfterViewInit, TemplateRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { MatTableDataSource } from "@angular/material/table";
import { MatStepper } from "@angular/material/stepper";

import { IProgram } from "../../interfaces/models/i-program";
import { IEquipment } from "../../interfaces/models/i-equipment";
import { ISession } from "../../interfaces/models/i-session";
import { NotificationService } from "../../services/notification.service";

import { CONFIG } from "../../../assets/config";
import { ENUMS } from "../../../assets/enums";

import { LanguageService } from "../../services/language.service";
import { HomeFitnessWorkoutService } from "../../services/home-fitness-workout.service";

import { IWorkout } from "../../interfaces/models/i-workout";
import { ITrigger } from "../../interfaces/models/i-trigger";
import { ILanguageCountry } from "../../interfaces/models/i-language-country";
import { ILanguageDataField } from "../..//interfaces/models/i-language-data-field";
import { ILanguageResource } from "../../interfaces/models/i-language-resource";
import { ITriggerType } from "../../interfaces/models/i-trigger-type";


@Component({
  selector: 'home-fitness-workout-form',
  templateUrl: './home-fitness-workout-form.component.html',
  styleUrls: ['./home-fitness-workout-form.component.scss']
})
export class HomeFitnessWorkoutFormComponent implements OnInit {
 
  @ViewChild('siteForm') siteForm: any;
  @ViewChild('stepper') stepper: MatStepper;

  __workout: IWorkout = {
    id: 0,
    name: null,
    description: null,
    image: null,
    creationDate: new Date(),
    lastUpdate: new Date(),
    activeUntil: new Date(2150,0,1,0,0,0,0),
    activeFrom: new Date(2020,0,1,0,0,0,0),
    longDescription: null,
    program: null,
    sessionCooldown: null,
    sessionExecution: null,
    sessionWarmUp: null,
    status: 1,
    triggers: []
  };
    
  programs: IProgram[];
  sessions: ISession[];
  languages: ILanguageCountry[];
  program: number;

  currentLanguage: ILanguageCountry;
  currentNameLanguageRes: ILanguageResource;
  currentDescLanguageRes: ILanguageResource;
  currentLongDescLanguageRes: ILanguageResource;

  warmupSessions: ISession[];
  workoutSessions: ISession[];
  cooldownSessions: ISession[];

  nameErrorForm: boolean;
  descErrorForm: boolean;
  longDescErrorForm: boolean;

  triggerList: ITriggerType[];

  isNew: Boolean = false;

  dataLoaded: boolean;
  editingSession: boolean;

  mediaUrl: string = CONFIG.workoutMediaURL;

  modalRef: BsModalRef;

  formSubmitted = false;


  constructor(private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private workoutService: HomeFitnessWorkoutService,  
    private languageService: LanguageService,
    private modalService: BsModalService
  ) {

    this.programs = this.route.snapshot.data.programs;
    this.sessions = this.route.snapshot.data.hfSessions;

    this.languages = this.route.snapshot.data.languages;
    this.triggerList = this.route.snapshot.data.triggerTypes;

    this.currentLanguage = this.languages[0];

    this.warmupSessions = this.sessions.filter( x => x.type === ENUMS.homeFitnessSessionTypes.warmup);
    this.workoutSessions = this.sessions.filter( x => x.type === ENUMS.homeFitnessSessionTypes.session);
    this.cooldownSessions = this.sessions.filter( x => x.type === ENUMS.homeFitnessSessionTypes.cooldown);
  
  }


  ngOnInit() {
    this.loadData();
  }


  ngAfterViewInit() {
  }


  loadData() {  
     
    if (this.route.snapshot.data.workout === 'new') {
      this.isNew = true;

      this.__workout.name = this.languageService.createLanguageField(ENUMS.languageResourceKeys.workoutName, this.languages);
      this.__workout.description = this.languageService.createLanguageField(ENUMS.languageResourceKeys.workoutDesc, this.languages);
      this.__workout.longDescription = this.languageService.createLanguageField(ENUMS.languageResourceKeys.workoutLongDesc, this.languages);

    } else {
      this.__workout = this.route.snapshot.data.workout;

      this.languageService.completeLanguageField(this.__workout.name, this.languages);
      this.languageService.completeLanguageField(this.__workout.description, this.languages);
      this.languageService.completeLanguageField(this.__workout.longDescription, this.languages);

      this.triggerList[0].checked = this.__workout.triggers.includes(45);
      this.triggerList[1].checked = this.__workout.triggers.includes(46);

    }
  
    this.languageChanged();
  }


  // Copy an array to another with no reference loss
  assignArray(origin: any[], dest: any[]) {
    origin.length = 0;
    for (const item of dest) {
      origin.push(item);
    }
  }

  programChanged() {
    //this.__workout.program = this.program;
  }

  languageChanged() {
    this.currentNameLanguageRes = this.__workout.name.resources.find(x => x.country.id == this.currentLanguage.id);
    this.currentDescLanguageRes = this.__workout.description.resources.find(x => x.country.id == this.currentLanguage.id);
    this.currentLongDescLanguageRes = this.__workout.longDescription.resources.find(x => x.country.id == this.currentLanguage.id);

  }

  onSubmit() {
    let formError: boolean = false;
    this.formSubmitted = true;


    if (!this.siteForm.form.valid) {
      window.scroll(0, 0);
    } else {
      if (this.isNew) {
        this.createWorkout();
      } else {
        this.saveWorkout();
      }
    }
  }



  public saveWorkout() {

    // Clean temp properties
    let sendWorkout: IWorkout;
    sendWorkout = Object.assign({}, this.__workout);

    console.log(JSON.stringify(sendWorkout));

    this.workoutService.putWorkout(sendWorkout).subscribe(
      result => {
        this.notificationService.notify("Workout saved successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
      },
      error => {
        console.log('Error', error);
      }
    );
  }


  public createWorkout() {
    // Clean temp properties
    let sendWorkout: IWorkout;
    sendWorkout = Object.assign({}, this.__workout);
    console.log(JSON.stringify(sendWorkout));
    this.workoutService.postWorkout(sendWorkout).subscribe(
      result => {
        this.notificationService.notify("Session created successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
        this.router.navigate(['/homefitness/workouts']);
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  // Aux function for comparison of combobox objects
  compare(val1, val2) {
    if (val1 !== undefined && val2 !== undefined && val1 !== null && val2 !== null) {
      if(val1.id == val2.id) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // Added as method. maybe we would prevent go back when there are pending changes
  goBack() {
    this.router.navigate(["/sessions"]);
  }

  
  imageSelected($event) {
    this.__workout.image = $event;
  }

  changeTrigger(triggerType: ITriggerType) {
    switch (triggerType.checked) {
      case true:
        this.__workout.triggers.push(triggerType.triggers[0]);
        break;
      case false:
        this.__workout.triggers = this.__workout.triggers.filter(x => x != triggerType.triggers[0]);
        break;
    }
  }

  imageRemoved() {

  }


  changeWelcome() {

  }


  changeSession() {

  }

  changeCooldown() {
    
  }
}

