import { Injectable } from "@angular/core";
import { ENUMS } from "../../assets/enums";

declare var UIkit: any;

@Injectable()
export class NotificationService {

  private _currentAppMessages: string[];

  constructor() {
    this._currentAppMessages = [];
  }

  notify(message: string, type: string, position: string) {
    if (this._currentAppMessages.find( x => x === message)) {
      return;
    } else {
      // check if the message is a default message to not repeat notification
      Object.keys(ENUMS.notification.applicationMessages).forEach( prop => {
        if (message === ENUMS.notification.applicationMessages[prop].toString()) {
          this._currentAppMessages.push(message);
        }
      });
    }

    if (position === ENUMS.notification.positions.custom_responsive) {
      if (window.screen.width < 1200) {
        UIkit.notification(message, {status: type, pos: ENUMS.notification.positions.top_center});
      } else {
        UIkit.notification(message, {status: type, pos: ENUMS.notification.positions.bottom_center});
      }
    } else {
      UIkit.notification(message, {status: type, pos: position});
    }

  }
}
