<!--<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
  <mat-toolbar class="mat-buttons-toolbar">
    <mat-toolbar-row>
      <span class="mat-spacer"></span>
      <button mat-flat-button class="prama-button full-button medium-button" (click)="saveProgram()">
        <mat-icon>save</mat-icon> SAVE
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

-->
  
<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
  <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h5 fxFlex="nogrow" class="yellow">PROGRAM INFO </h5>
      <div>
        <button mat-flat-button class="prama-button full-button medium-button" (click)="saveProgram()">
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </div>
    <mat-card fxFlex>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
        <div class="info-fields">
          <!-- NAME -->
          <div fxLayout="column" fxFlex="49%">
            <mat-form-field>
              <input matInput #programName placeholder="Program name" [(ngModel)]="programPackage.name" name="programName" required>
            </mat-form-field>
          </div>
          <!-- DESCRIPTION -->
          <div fxLayout="column" fxFlex="49%" class="ml20">
            <mat-form-field>
              <input matInput #programDescription placeholder="Description" [(ngModel)]="programPackage.description" name="programDescription">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column">
        <div class="image-fields mt15">
          <image-uploader [imageName]="programPackage.image" [mediaUrl]="mediaUrl" (selectedImageEvent)="programImageSelected($event)"
            (deletedImageEvent)="programImageRemoved()" [formField]="" fxFill>
          </image-uploader>
        </div>
      </div>
    </mat-card>

  </div>
  <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch" class="posrel">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h5 fxFlex="nogrow">AMBIENCE INFO </h5>
      <div>
        <button mat-flat-button class="prama-button full-button medium-button" (click)="saveProgram()">
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </div>
    <mat-card fxFlex class="nopadding">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
        <div class="info-fields">       
          <!-- Ambience enabled -->
          <div fxLayout="column" fxFlex="50%">
            <mat-checkbox class="mt20 ml20" [(ngModel)]="programAmbience.enableAmbience" (change)="enableAmbienceChanged()" name="enableAmbience">
              Enable ambience
            </mat-checkbox>
          </div>
          <!-- Ambience name -->
          <div fxLayout="column" fxFlex="50%">
            <mat-form-field>
              <input matInput #programAmbienceName placeholder="Ambience name" [value]="programAmbience.ambienceName"
              [(ngModel)]="programAmbience.ambienceName" name="ambienceName" required>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column">
        <div class="image-fields  mt15">
          <image-uploader [imageName]="programAmbience.ambienceImage" [mediaUrl]="mediaUrl" (selectedImageEvent)="ambienceImageSelected($event)"
            (deletedImageEvent)="ambienceImageRemoved()" [formField]="" fxFill>
          </image-uploader>
        </div>
      </div>
    </mat-card>

    <div class="card-disabled h100" *ngIf="ambienceDisabled" (click)="$event.stopPropagation()">
      <div class="card-disabled-message">
        To enable the ambience you have to set the default phases first
      </div>
    </div>
  </div>
</div>

<div>
  <div class="card-disabled h30" *ngIf="phasesDisabled" (click)="$event.stopPropagation()">
    <div class="card-disabled-message">
      To enable the default phases you have to set the music of this program first
    </div>
  </div>

  <div>
    <div fxLayout="row">
      <h5 fxFlex="nogrow">AMBIENCE DEFAULT PHASES</h5>
      <div class="ml50">
        <button mat-flat-button class="prama-button full-button medium-button" (click)="saveAmbiencePhases()">
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </div>
    <div class="timeline-container">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxLayoutAlign="space-between start">

        <mat-card class="card-phase">
          <h2>WELCOME</h2>
          <p>The screen where all users are shown</p>
          <div fxLayout="column">
            <mat-form-field>
              <mat-select #welcomes placeholder="Preset" (selectionChange)="changeWelcome()" [(ngModel)]="programAmbience.welcome.phase"
                [compareWith]="compare" name="welcomes" required>
                <mat-option [value]="newWelcome">New welcome</mat-option>
                <mat-option *ngFor="let welcome of objectsAvailable.welcome" [value]="welcome">
                  {{welcome.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-dynamic-form #timelineWelcome name="timelineWelcome" [questions]="questionsWelcome" (valueChange)="changeValueEvent($event, newWelcome, 'welcome')"></app-dynamic-form>
          </div>
        </mat-card>

        <mat-card class="card-phase">
          <h2>EXPLANATION</h2>
          <p>It's the screen with lots of info</p>
          <div fxLayout="column">
            <mat-form-field class="w-100">
              <mat-select #explanations placeholder="Preset" (selectionChange)="changeExplanation()" [(ngModel)]="programAmbience.explanation.phase"
                [compareWith]="compare" name="explanations" required>
                <mat-option [value]="newExplanation">New explanation</mat-option>
                <mat-option *ngFor="let explanation of objectsAvailable.explanation" [value]="explanation">
                  {{explanation.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-dynamic-form #timelineExplanation name="timelineExplanation" [questions]="questionsExplanation"
              (valueChange)="changeValueEvent($event, newExplanation, 'explanation')"></app-dynamic-form>
          </div>
        </mat-card>

        <mat-card class="card-phase">
          <h2>WARM-UP</h2>
          <div fxLayout="column">
            <mat-form-field class="w-100">
              <mat-select #warmUps placeholder="Preset" (selectionChange)="changeWarmUp()" [(ngModel)]="programAmbience.warmUp.phase"
                [compareWith]="compare" name="warmUps" required>
                <mat-option [value]="newWarmUp">New warm-up</mat-option>
                <mat-option *ngFor="let warmUp of objectsAvailable.warmup" [value]="warmUp">
                  {{warmUp.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-dynamic-form #timelineWarmUp name="timelineWarmUp" [questions]="questionsWarmUp" (valueChange)="changeValueEvent($event, newWarmUp, 'warmUp')"></app-dynamic-form>
          </div>
        </mat-card>

        <mat-card class="card-phase">
          <h2>GT POSITIONING</h2>
          <p>Here users are revealed where they will start on</p>
          <div fxLayout="column">
            <mat-form-field class="w-100">
              <mat-select #positioningsGt placeholder="Preset" (selectionChange)="changePositioningGt()" [(ngModel)]="programAmbience.gtPositioning.phase"
                [compareWith]="compare" name="positioningsGt" required>
                <mat-option [value]="newPositioningGt">New GT positioning</mat-option>
                <mat-option *ngFor="let positioningGt of objectsAvailable.positioningGt" [value]="positioningGt">
                  {{positioningGt.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-dynamic-form #timelinePositioningGt name="timelinePositioningGt" [questions]="questionsPositioningGt"
              (valueChange)="changeValueEvent($event, newPositioningGt, 'gtPositioning')"></app-dynamic-form>
          </div>
        </mat-card>

        <mat-card class="card-phase">
          <h2>GT RESULTS</h2>
          <p>Results for the previous GT are shown here</p>
          <div fxLayout="column">
            <mat-form-field class="w-100">
              <mat-select #resultsGt placeholder="Preset" (selectionChange)="changeResultsGt()" [(ngModel)]="programAmbience.gtResults.phase"
                [compareWith]="compare" name="resultsGt" required>
                <mat-option [value]="newResultsGt">New GT results</mat-option>
                <mat-option *ngFor="let resultsGt of objectsAvailable.resultGt" [value]="resultsGt">
                  {{resultsGt.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-dynamic-form #timelineResultsGt name="timelineResultsGt" [questions]="questionsResultsGt"
              (valueChange)="changeValueEvent($event, newResultsGt, 'gtResults')"></app-dynamic-form>
          </div>
        </mat-card>

        <mat-card class="card-phase">
          <h2>FINISHER POSITIONS</h2>
          <p>Users on their marks for finisher</p>
          <div fxLayout="column">
            <mat-form-field class="w-100">
              <mat-select #positioningsFinisher placeholder="Preset" (selectionChange)="changePositioningFinisher()"
                [(ngModel)]="programAmbience.finisherPositioning.phase" [compareWith]="compare" name="positioningsFinisher" required>
                <mat-option [value]="newPositioningFinisher">New finisher positioning</mat-option>
                <mat-option *ngFor="let positioning of objectsAvailable.positioningFinisher" [value]="positioning">
                  {{positioning.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-dynamic-form #timelinePositioningFinisher name="timelinePositioningFinisher" [questions]="questionsPositioningFinisher"
              (valueChange)="changeValueEvent($event, newPositioningFinisher, 'finisherPositioning')"></app-dynamic-form>
          </div>
        </mat-card>

        <mat-card class="card-phase">
          <h2>FINISHER RESULTS</h2>
          <p>Results for the finisher are shown here</p>
          <div fxLayout="column">
            <mat-form-field class="w-100">
              <mat-select #resultsFinisher placeholder="Preset" (selectionChange)="changeResultsFinisher()" [(ngModel)]="programAmbience.finisherResults.phase"
                [compareWith]="compare" name="resultsFinisher" required>
                <mat-option [value]="newResultsFinisher">New finisher results</mat-option>
                <mat-option *ngFor="let result of objectsAvailable.resultFinisher" [value]="result">
                  {{result.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-dynamic-form #timelineResultsFinisher name="timelineResultsFinisher" [questions]="questionsResultsFinisher"
              (valueChange)="changeValueEvent($event, newResultsFinisher, 'finisherResults')"></app-dynamic-form>
          </div>
        </mat-card>

        <mat-card class="card-phase">
          <h2>COOLDOWN</h2>
          <p>The cooldown phase</p>
          <div fxLayout="column">
            <mat-form-field class="w-100">
              <mat-select #cooldown placeholder="Preset" (selectionChange)="changeCooldown()" [(ngModel)]="programAmbience.cooldown.phase"
                [compareWith]="compare" name="cooldown" required>
                <mat-option [value]="newCooldown">New cooldown</mat-option>
                <mat-option *ngFor="let cooldown of objectsAvailable.cooldown" [value]="cooldown">
                  {{cooldown.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-dynamic-form #timelineCooldown name="timelineCooldown" [questions]="questionsCooldown" (valueChange)="changeValueEvent($event, newCooldown, 'cooldown')"></app-dynamic-form>
          </div>
        </mat-card>

        <mat-card class="card-phase">
          <h2>ENDING</h2>
          <p>The ending screen</p>
          <div fxLayout="column">
            <mat-form-field class="w-100">
              <mat-select #ending placeholder="Preset" (selectionChange)="changeEnding()" [(ngModel)]="programAmbience.ending.phase"
                [compareWith]="compare" name="ending" required>
                <mat-option [value]="newEnding">New ending</mat-option>
                <mat-option *ngFor="let ending of objectsAvailable.ending" [value]="ending">
                  {{ending.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-dynamic-form #timelineEnding name="timelineEnding" [questions]="questionsEnding" (valueChange)="changeValueEvent($event, newEnding, 'ending')"></app-dynamic-form>
          </div>
        </mat-card>

      </div>
    </div>
  </div>
</div>

