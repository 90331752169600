<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
  <div fxLayout="column" fxFlex="20" fxFlexAlign="stretch">
    <exercise-filter [exerciseList]="exercisesToShow" [exerciseListFiltered]="exerciseList" (filterChange)="updateTableData()"></exercise-filter>
  </div>
  <div fxLayout="column" fxFlex="80" fxFlexAlign="stretch">

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h4 fxFlex="50">{{ exerciseList?.length }} exercises</h4>
      <button (click)="createExercise()" class="uk-button prama-button medium-button">
        <i class="fa fa-plus-circle faSize"></i>
      </button>
    </div>
    <div class="ml10">
      <mat-table #table [dataSource]="dataSource" matSort class="exercise-table" *ngIf="exerciseList?.length !== 0">
        <ng-container matColumnDef="stationIcon">
          <mat-header-cell *matHeaderCellDef fxFlex="5%"></mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="5%">
            <img [src]="'/assets/stations/' + element.station?.id + '.png'" />
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"> Id </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="5%">
            <!-- <img [src]="'/assets/stations/' + element.station.id + '.png'" /> -->
            {{element.id}}
            <a (click)="editExercise(element)" class="mat-row-link"></a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="30%"> Name </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="30%">
            <h4>{{element.name}}</h4>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="program">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Program </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.program?.name}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="intensityLevel">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Intensity </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.intensityLevel?.name}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="complexityLevel">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Complexity </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.complexityLevel?.name}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="muscularGroup1">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Muscular Group </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>{{element.muscularGroup1?.name}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="fundamentalMovement1">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Fundamental Movement </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span *ngIf="element.fundamentalMovement1">{{element.fundamentalMovement1?.name}}
              ({{element.fundamentalMovement1?.idCategory}})</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="exerciseImage">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxLayout="column" fxLayoutAlign="center flex-end">
            Preview
          </mat-header-cell>
          <mat-cell *matCellDef="let element" class="exercise-gif" fxLayout="column" fxLayoutAlign="flex-end flex-end">
            <img *ngIf="element" [src]="exercisesMediaURL + element.image" (mouseover)="showPreview(element)"
              (mouseleave)="previewImage=''" style="z-index: 95" />
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[25, 50, 100, 500]" showFirstLastButtons></mat-paginator>
    </div>
  </div>
  <div class="exercise-preview">
    <img [src]="previewImage" *ngIf="previewImage">
  </div>
</div>