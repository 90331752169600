import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  spinner: boolean = false;

  public isLoading(){
    return this.spinner;
  }

  constructor() { }

  public showSpinner(){
    this.spinner = true;
  }

  public hideSpinner(){
    this.spinner = false;
  }
}
