import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { IEquipment } from "../../interfaces/models/i-equipment";
import { IProgram } from "../../interfaces/models/i-program";
import { IStation } from "../../interfaces/models/i-station";
import { IIntensityLevel } from "../../interfaces/models/i-intensity-level";
import { ITag } from "../../interfaces/models/i-tag";
import { IExercise } from "../../interfaces/models/i-exercise";

import { ExerciseService } from "../../services/exercise.service";
import { StationService } from "../../services/station.service";
import { NotificationService } from "../../services/notification.service";

import { ENUMS } from "../../../assets/enums";
import { CONFIG } from "../../../assets/config";
import { IExerciseSkill } from "../../interfaces/models/i-exercise-skill";
import { IExerciseMuscularGroup } from "../../interfaces/models/i-exercise-muscular-group";
import { IExerciseFundamentalMovement } from "../../interfaces/models/i-exercise-fundamental-movement";
import { IExerciseDiscipline } from "../../interfaces/models/i-exercise-discipline";
import { IComplexityLevel } from "../../interfaces/models/i-complexity-level";
import { LoadingService } from "../../services/loading.service";

@Component({
  selector: "exercise-detail",
  templateUrl: "./exercise-detail.component.html",
  styleUrls: ["./exercise-detail.component.css"]
})
export class ExerciseDetailComponent implements OnInit {

  @ViewChild('siteForm') siteForm: any;

  exercise: IExercise = {
    id: 0,
    name: "",
    description: "",
    station: null,
    players: 1,
    creationDate: new Date(),
    sensorColor: "",
    video: "",
    thumbnail: "",
    image: "",
    equipment: [],
    tags: [],
    muscularGroup1: null,
    muscularGroup2: [],
    intensityLevel: null,
    complexityLevel: null,
    skills: null,
    discipline: null,
    fundamentalMovement1: null,
    fundamentalMovement2: [],
    weightMaleMin: 0,
    weightMaleMax: 0,
    weightFemaleMin: 0,
    weightFemaleMax: 0,
    programs: [],
    program: null,
    oldProgram: null,
    alwaysVisible: false,
    lastUpdate: new Date(),
    reps: 0,
    competitive: false
  };

  packagesSelected: IProgram[];
  
  
  isNew: Boolean = false;
  
  stationsAvailable: IStation[] = [];
  playersAvailable: number[] = [];
  
  complexityLevelsAvailable: IComplexityLevel[] = [];
  intensityLevelsAvailable: IIntensityLevel[] = [];
  exerciseSkillsAvailable: IExerciseSkill[] = [];
  exerciseMuscularGroupsAvailable: IExerciseMuscularGroup[] = [];
  exerciseFundamentalMovementsAvailable: IExerciseFundamentalMovement[] = [];
  exerciseDisciplinesAvailable: IExerciseDiscipline[] = [];
  equipmentAvailable: IEquipment[] = [];
  programsAvailable: IProgram[] = [];
  oldProgramsAvailable: IProgram[] = [];
  packagesAvailable: IProgram[] = [];

  currentTags: ITag[] = [];
  tagsAvailable: ITag[] = [];

  mediaUrl: string = CONFIG.exercisesMediaURL;
  tempMediaUrl: string = CONFIG.tempMediaUrl;

  inputTag: string;
  newTagsIndex: number;

  sequenceAvailable: boolean;

  editMode: boolean;

  formSubmitted = false;

  constructor(private router: Router, private route: ActivatedRoute,
    private exerciseService: ExerciseService,
    private stationsService: StationService,
    private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.loadData();
  }


  loadData() {
    this.stationsAvailable = this.route.snapshot.data.stations;
    this.tagsAvailable = this.route.snapshot.data.tags;
    this.exerciseSkillsAvailable = this.route.snapshot.data.skills;
    this.intensityLevelsAvailable = this.route.snapshot.data.intensityLevels;
    this.complexityLevelsAvailable = this.route.snapshot.data.complexityLevels;
    this.exerciseMuscularGroupsAvailable = this.route.snapshot.data.muscularGroups;
    this.exerciseFundamentalMovementsAvailable = this.route.snapshot.data.fundamentalMovements;
    this.exerciseDisciplinesAvailable = this.route.snapshot.data.disciplines;
    this.equipmentAvailable = this.route.snapshot.data.equipment;
    this.programsAvailable = this.route.snapshot.data.programs.filter((x: IProgram )=> x.type == ENUMS.programTypes.program);
    this.packagesAvailable = this.route.snapshot.data.programs.filter((x: IProgram )=> x.type == ENUMS.programTypes.package);
    this.oldProgramsAvailable = this.route.snapshot.data.oldPrograms;

    this.currentTags = [];

    this.editMode = true; // Forced to true, originally false

    if (this.route.snapshot.data.exercise === 'new') {
      this.isNew = true;
      this.editMode = true;
    } else {
      this.exercise = this.route.snapshot.data.exercise;
      this.packagesSelected = this.packagesAvailable.filter( x => this.exercise.programs.includes(x.id));
      this.exercise.programs = this.programsAvailable.filter( x => this.exercise.programs.includes(x.id)).map(x => x.id);
    }

    // If is an interactive station, show button to load sequence
    if (this.exercise.station !== null) {
      this.sequenceAvailable = this.exercise.station.type === 2 ? true : false;
    }
  }

  removeTag(tag: ITag) {
    this.currentTags = this.currentTags.filter(t => t.id !== tag.id);
  }

  videoSelected(videoName: string) {
    if (videoName && videoName !== "") {
      this.exercise.video = videoName;

     // this.exerciseService.getExerciseThumbnailFromVideo(videoName).subscribe(resp => {
     //   console.log(resp);
     // });
    }
  }

  gifSelected(gifName: string) {
    if (gifName && gifName !== "") {
      this.exercise.thumbnail = gifName;
      this.exercise.image = gifName.replace('.gif', '.png');
    }
  }

  imageSelected(imageName: string) {
    if (imageName && imageName !== "") {
      this.exercise.image = imageName;
    }
  }


  goToSequence() {
    if (this.exercise.station.symbol === "F") {
      this.router.navigate(["/sequence/combo/", this.exercise.id]);
    } else if (this.exercise.station.symbol === "G") {
      this.router.navigate(["/sequence/sprint/", this.exercise.id]);
    }
  }


  onSubmit() {
    this.formSubmitted = true;
    this.exercise.programs = [];

    if(this.packagesSelected && this.packagesSelected.length > 0) {
      this.exercise.programs.push(... this.packagesSelected.map(x => x.id));
    }

    if(this.exercise.program && this.exercise.program.id != 0) {
      this.exercise.programs.push(this.exercise.program.id);
    }
    
    if (!this.siteForm.form.valid) {
      window.scroll(0, 0);
    } else {
      if (this.isNew) {
        this.createExercise();
      } else {
        this.saveExercise();
      }
    }
  }

  public saveExercise() {
    this.exerciseService.putExercise(this.exercise).subscribe(
      result => {
        //if (result) {
        this.notificationService.notify("Exercise saved successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
        //}
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  public createExercise() {
    this.exerciseService.postExercise(this.exercise).subscribe(
      result => {
        //if (result) {
        this.notificationService.notify("Exercise created successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
        this.router.navigate(["/exercises"]);
        //}
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  private isChecked(list: any, item: any) {
    return list.filter(it => (it.id === item.id)).length > 0;
  }

  // If checkbox is changed turn it on/off depending on current status and add/remove from list
  // Also if option? = 'remove' always removes from list. Useful i.e: remove item in FundamentalMovement2 that is selected in FundamentalMovement
  changeItemFromList(itemList: any[], item: any, $event, option?) {
    if (option !== undefined && option === 'remove') {
      if (itemList.find(x => x.id === item.id)) {
        this.removeByKey(itemList, { key: 'id', value: item.id });
      }
    } else {
      if (itemList.find(x => x.id === item.id)) {
        this.removeByKey(itemList, { key: 'id', value: item.id });
      } else {
        itemList.push(item);
      }
    }
  }

  public removeByKey(array, params) {
    array.some(function (item, index) {
      return (array[index][params.key] === params.value) ? !!(array.splice(index, 1)) : false;
    });
    return array;
  }

  isValid(object: any) {
    if (object !== null && object !== undefined && object.id !== undefined) {
      if (object.id === 0 || object.id === null) {
        return false;
      }
    } else {
      if (object === 0 || object === "" || object === null) {
        return false;
      }
    }
    return true;
  }

  // Aux function for comparison of combobox objects
  compare(val1, val2) {
    if (val1 !== undefined && val2 !== undefined && val1 !== null && val2 !== null) {
      return val1.id === val2.id;
    } else {
      return false;
    }
  }
}
