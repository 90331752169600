import { Injectable } from "@angular/core";
import { ISprintSequence } from "../../interfaces/models/sprint-sequence/i-sprint-sequence";
import { ISprintSequenceDTO } from "../../interfaces/models/sprint-sequence/i-sprint-sequence-dto";
import { ISprintSequenceConfiguratorFormValues } from "../../interfaces/models/sprint-sequence/i-sprint-sequence-configurator-form-values";

@Injectable()
export class SprintSequenceMapper {


  constructor() {
  }


  SprintSequenceDTOToSprintSequence(dto: ISprintSequenceDTO): ISprintSequence {
    const sequence: ISprintSequence = {
      colorMode: undefined,
      colorModeId: dto.colorMode,
      creationDate: dto.creationDate,
      exercise: undefined,
      exerciseId: dto.exercise,
      id: dto.id,
      responseType: undefined,
      responseTypeId: dto.responseType,
      sequenceType: undefined,
      sequenceTypeId: dto.sequenceType,
      time: dto.time,
      tile1: dto.tile1,
      tile2: dto.tile2,
      tileInitIndex: dto.tileInitIndex,
      repsInTile: dto.repsInTile,
      colors: []
    };

    if (dto && dto.colors) {
      for (let index = 0; index < dto.colors.length; index++) {
        const element = dto.colors[index];
        sequence.colors.push( { id: index + 1, hex: element, red: 0, blue: 0, green: 0 });
      }
    }

    return sequence;
  }


  SprintSequenceToSprintSequenceDTO(sequence: ISprintSequence): ISprintSequenceDTO {
    const dto: ISprintSequenceDTO = {
      colorMode: sequence.colorModeId,
      creationDate: sequence.creationDate,
      exercise: sequence.exerciseId,
      id: sequence.id,
      responseType: sequence.responseTypeId,
      sequenceType: sequence.sequenceTypeId,
      time: sequence.time,
      tile1: sequence.tile1,
      tile2: sequence.tile2,
      tileInitIndex: sequence.tileInitIndex,
      repsInTile: sequence.repsInTile,
      colors: sequence.colors.map( c => c.hex)
    };

    return dto;
  }

  SprintSequenceToSprintSequenceFormValues(sequence: ISprintSequence): ISprintSequenceConfiguratorFormValues {
    const values: ISprintSequenceConfiguratorFormValues = {
      colorType: undefined,
      colorTypeId: sequence.colorModeId,
      colors: sequence.colors,
      responseTime: sequence.time,
      responseType: undefined,
      responseTypeId: sequence.responseTypeId,
      stepType: undefined,
      stepTypeId: sequence.sequenceTypeId,
      numberOfTimes: sequence.repsInTile,
      tile1: sequence.tile1,
      tile2: sequence.tile2,
      tileFrom: sequence.tileInitIndex
    };

    return values;
  }

}
