import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ColorPickerService, Rgba } from "ngx-color-picker";

import { IColor } from "../../interfaces/models/i-color";
import { ISequenceConfiguratorTile } from "../../interfaces/models/sequence/form/i-sequence-configurator-tile";

@Component({
  selector: "sequence-color-picker",
  templateUrl: "./sequence-color-picker.component.html",
  styleUrls: ["./sequence-color-picker.component.css"]
})
export class SequenceColorPickerComponent implements OnInit {
  @Input() strSelectedColor: string;
  @Input() selectedColor: IColor;
  @Input() multiple: boolean;
  @Input() colors: IColor[];
  @Input() colorTiles: ISequenceConfiguratorTile[];

  testTile: ISequenceConfiguratorTile;

  @Output() selectedColorEvent: EventEmitter<IColor> = new EventEmitter<IColor>();

  constructor(private cpService: ColorPickerService) {
  }

  ngOnInit() {
    this.selectedColor = this.colors[0];
  }


  public onChangeColor(color: string): Rgba {
    const hsva = this.cpService.stringToHsva(color);

    const rgba = this.cpService.hsvaToRgba(hsva);
    console.log(rgba);
    return rgba;
  }

  public onEventLog(event: string, data: any) {
    console.log(event, data);
  }

  selectedColorTile(tile: ISequenceConfiguratorTile) {

    for (let index = 0; index < this.colorTiles.length; index++) {
      this.colorTiles[index].selected = false;
    }
    tile.selected = true;

    this.selectedColor = tile.color;
    this.selectedColorEvent.emit(tile.color);
  }

  selectColor(color: IColor) {
    this.selectedColor = color;
    // this.testTile.color = color;
    this.selectedColorEvent.emit(color);

  }
}
