import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class EventsService {

  private eventSource = new Subject<string>();

  event$ = this.eventSource.asObservable();

  public doEvent(params) {
    this.eventSource.next(params);
  }

}
