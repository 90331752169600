import { IFormField } from "../interfaces/models/i-form-field";
import { IFormFieldsSequence } from "../interfaces/models/sequence/form/i-form-fields-sequence";
import { ISequenceCombo } from "../interfaces/models/sequence/i-sequence-combo";
import { ISequenceComboBlock } from "../interfaces/models/sequence/i-sequence-combo-block";
import { ISequenceComboBlockPlayer } from "../interfaces/models/sequence/i-sequence-combo-block-player";
import { ISequenceBlockBehavior } from "../interfaces/models/sequence/i-sequence-block-behavior";
import { ISequenceBlockCondition } from "../interfaces/models/sequence/i-sequence-block-condition";
import { ISequenceConfiguratorTile } from "../interfaces/models/sequence/form/i-sequence-configurator-tile";
import { ISequenceConfiguratorFormValues } from "../interfaces/models/sequence/form/i-sequence-configurator-form-values";
import { ISequenceConfiguratorFormFields } from "../interfaces/models/sequence/form/i-sequence-configurator-form-fields";
import { IColor } from "../interfaces/models/i-color";


import * as _ from "lodash";

export class ComboSequenceFormHelper {
  static getEmptySequence(): ISequenceCombo {
    const seq: ISequenceCombo = {
      id: 0,
      exerciseId: 0,
      exercise: undefined,
      players: 2,
      creationDate: new Date(),
      blocks: [
        this.getEmptyBlock()
      ]
    };
    return seq;
  }

  static getAvailableValues(): ISequenceConfiguratorFormFields {
    const values: ISequenceConfiguratorFormFields = {
      stepTypes: [
        { id: 0, name: "-", multiple: false, description: "" },
        { id: 1, name: "Fixed", multiple: false, description: "Fixed light" },
        { id: 2, name: "Single", multiple: false, description: "Single tile" },
        { id: 3, name: "Random", multiple: true, description: "Single tile in a range" },
        { id: 4, name: "Delay", multiple: false, description: "Interval without any tile on" },
        { id: 15, name: "Simultaneous", multiple: true, description: "Multiple tiles must be triggered at the same time"},
      ],
      colorTypes: [
        { id: 0, name: "-", pickable: false, multiple: false, defaultColor: undefined },
        { id: 1, name: "Fixed", pickable: true, multiple: false, defaultColor: undefined},
        { id: 2, name: "Random", pickable: false, multiple: false, defaultColor: { id: 1, red: 0, blue: 0, green: 0, hex: "#ff0000" } }
      ],
      responseTypes: [
        { id: 0, name: "-", responseByTime: false },
        { id: 1, name: "Press", responseByTime: false },
        { id: 2, name: "Release", responseByTime: false },
        { id: 3, name: "Time", responseByTime: true },
        { id: 4, name: "None", responseByTime: false }
      ],
      presetColors: [
        { id: 1, red: 0, blue: 0, green: 0, hex: "#ff0000" },
        { id: 2, red: 0, blue: 0, green: 0, hex: "#3c98ff" },
      ]
    };

    return values;
  }


  static getDefaultBlockConditions(): ISequenceBlockCondition[] {
    const conditions: ISequenceBlockCondition[] = [{
        id: 0,
        comboBlockId: 0,
        sourceComboBlockId: 0,
        conditionType: { id: 0, name: "OFF"},
        conditionTypeId: 0,
        sourceComboBlock: undefined,
        comboBlock: undefined,
      }, {
        id: 0,
        comboBlockId: 0,
        sourceComboBlockId: 0,
        conditionType: { id: 1, name: "ON"},
        conditionTypeId: 1,
        sourceComboBlock: undefined,
        comboBlock: undefined,
      }
    ];
    return conditions;
  }


  static getColorTiles(colors: IColor[]): ISequenceConfiguratorTile[] {
    const tiles = [];
    for (let index = 0; index < colors.length; index++) {
      tiles.push({
        id: index,
        tileNumber: 1,
        color: colors[index],
        selected: false
      });
    }

    return tiles;
  }


  static copyBlockConditionsFromBlock(block: ISequenceComboBlock): ISequenceBlockCondition[] {

    const conditionOFF = block.conditions.find(x => x.conditionTypeId === 0);
    const conditionON = block.conditions.find(x => x.conditionTypeId === 1);

    const conditions: ISequenceBlockCondition[] = [{
        id: 0,
        comboBlockId: 0,
        sourceComboBlockId: 0,
        conditionType: { id: 0, name: "OFF"},
        conditionTypeId: 0,
        sourceComboBlock: undefined,
        comboBlock: undefined,
      }, {
        id: 0,
        comboBlockId: 0,
        sourceComboBlockId: 0,
        conditionType: { id: 1, name: "ON"},
        conditionTypeId: 1,
        sourceComboBlock: undefined,
        comboBlock: undefined,
      }
    ];

    return conditions;
  }


  static getEmptyBlock(): ISequenceComboBlock {
    const block: ISequenceComboBlock = {
        id: 0,
        player: 0,
        sequenceType: undefined,
        sequenceTypeId: 0,
        sequenceCombo: undefined,
        sequenceComboId: 0,
        inputs: [],
        behaviors: this.getDefaultBlockBehaviors(),
        conditions: this.getDefaultBlockConditions()
      };

    return block;
  }


  static copyBlock(blockToCopy: ISequenceComboBlock): ISequenceComboBlock {
    const block: ISequenceComboBlock = {
        id: 0,
        player: 0,
        sequenceType: undefined,
        sequenceTypeId: blockToCopy.id,
        sequenceCombo: undefined,
        sequenceComboId: blockToCopy.sequenceComboId,
        inputs: [],
        behaviors: this.copyBlockBehaviorsFromBlock(blockToCopy),
        conditions: this.copyBlockConditionsFromBlock(blockToCopy)
      };

    return block;
  }


  static copyBlockBehaviorsFromBlock(block: ISequenceComboBlock): ISequenceBlockBehavior[] {
    const behaviorOFF = block.behaviors.find(x => x.conditionTypeId === 0);
    const behaviorON = block.behaviors.find(x => x.conditionTypeId === 1);

    const behaviors: ISequenceBlockBehavior[] = [
      this.copyBehavior(behaviorOFF),
      this.copyBehavior(behaviorON)
    ];


    return behaviors;
  }


  static copyBehavior(bh: ISequenceBlockBehavior): ISequenceBlockBehavior {
    const beh: ISequenceBlockBehavior = {
      id: 0,
      conditionTypeId: bh.conditionTypeId,
      conditionType: undefined,
      comboBlockId: bh.comboBlockId,
      comboBlock: undefined,
      colorModeId: bh.colorModeId,
      colorMode: undefined,
      time: bh.time,
      responseTypeId: bh.responseTypeId,
      responseType: undefined,
      tileId: 0,
      tile: undefined,
      tiles: [],
      colors: _.cloneDeep(bh.colors),
    };
    return beh;
  }


  static getDefaultBlockBehaviors(): ISequenceBlockBehavior[] {
    const behaviors: ISequenceBlockBehavior[] = [
      this.getEmptyBehavior(0),
      this.getEmptyBehavior(1)
    ];

    return behaviors;
  }


  static getEmptyBehavior(conditionTypeId): ISequenceBlockBehavior {
    const beh: ISequenceBlockBehavior = {
      id: 0,
      conditionTypeId: conditionTypeId,
      conditionType: undefined,
      comboBlockId: 0,
      comboBlock: undefined,
      colorModeId: 0,
      colorMode: undefined,
      time: 0,
      responseTypeId: 0,
      responseType: undefined,
      tileId: 0,
      tile: undefined,
      tiles: [],
      colors: [],
    };
    return beh;
  }


  static getFormFields(): IFormFieldsSequence {
    const fields: IFormFieldsSequence = {
      idField: {
        name: "sequence-id-input",
        nameToShow: "Exercise ID",
        placeHolder: "ID",
        visible: true,
        disabled: true,
        required: false,
        error: false,
        errorMessage: "",
        minValue: undefined
      },
      stepTypeField: {
        name: "step-type-input",
        nameToShow: "Step type",
        placeHolder: "step type",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Step type required",
        minValue: undefined
      },
      colorTypeField: {
        name: "color-type-input",
        nameToShow: "Color type",
        placeHolder: "type",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Color type required",
        minValue: undefined
      },
      colorPickerField: {
        name: "color-input",
        nameToShow: "Color picker",
        placeHolder: "pick a color",
        visible: false,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "At least one color required",
        minValue: undefined
      },
      responseTypeField: {
        name: "response-type-input",
        nameToShow: "Response type",
        placeHolder: "response type",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Response type required",
        minValue: undefined
      },
      timeField: {
        name: "time-input",
        nameToShow: "Time (milliseconds)",
        placeHolder: "response type",
        visible: false,
        disabled: false,
        required: false,
        error: false,
        errorMessage: "Time required",
        minValue: 0
      },
      stationTilesField: {
        name: "tiles-input",
        nameToShow: "Tiles picker",
        placeHolder: "type",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Tile required",
        minValue: 1
      }
    };
    return fields;
  }


  static validateTextField(field: IFormField, inputText: string) {
    field.error = false;
    if (field.required && (!inputText || inputText === "")) {
      field.error = true;
    }
  }


  static validateNumberField(field: IFormField, inputNumber: number) {
    field.error = false;
    if (field.required && (!inputNumber || inputNumber === 0)) {
      field.error = true;
    }
  }


  static validateArrayField(field: IFormField, elements) {
    field.error = false;
    if (field.required && (!elements || elements.length < field.minValue)) {
      field.error = true;
    }
  }


  static getTilesNumbersFromBlock(block: ISequenceComboBlockPlayer) {
    let tilesString = "";

    if (block.sequenceTypeId !== 4) {
      const bh = block.behaviorOn;
      if (bh.tileId !== 0) {
        tilesString = bh.tileId.toString();
      } else {
        tilesString = "[" + bh.tiles.map( x => x.tileNumber).toString() + "]";
        const aux = tilesString.split(",");
        tilesString = aux.join(", ");
      }
    }

    return tilesString;
  }


  static getColorStyleFromBlock(block: ISequenceComboBlockPlayer) {
    let style: string = "";
    if (block.sequenceTypeId !== 4) {
      const bh = block.behaviorOn;

      if (bh.colorModeId !== 2) {
        style = bh.colors[0].color;
      }
      return style;
    }
  }

  static getColorClassFromBlock(block: ISequenceComboBlockPlayer) {
    let c: string = "";
    if (block.sequenceTypeId !== 4) {
      const bh = block.behaviorOn;
      if (bh.colorModeId === 2) {
        c = `color-random`;
      }
    }
    return c;
  }

  static getResponseIconFromBlock(block: ISequenceComboBlockPlayer) {
    let strIcon = "";
    const bh = block.behaviorOn;
    if (block.sequenceTypeId === 4) {
      strIcon = `<i class="fa fa-clock-o" title="Time"></i> ${bh.time}ms`;
    } else {
      switch (bh.responseTypeId) {
        case 1:
          strIcon = `<i class="fa fa-arrow-down" title="Press"></i>`;
          break;
        case 2:
          strIcon = `<i class="fa fa-arrow-up" title="Release"></i>`;
          break;
        case 3:
          strIcon = `<i class="fa fa-clock-o" title="Time"></i> ${bh.time}ms`;
          break;
        case 4:
          strIcon = `<i class="fa fa-times" title="None"></i>`;
          break;
      }
    }

    return strIcon;
  }
}
