export const BLOCK_CONFIG= {
    /////////////////////////////////////////////////////
    // COMMON CONSTANTS
    /////////////////////////////////////////////////////
    
    DRAWER_MIN_DURATION: 600,
    CHANNEL_HEADER_WIDTH: 200,
    // Timeline Labels
    LABEL_DEFAULT_DURATION: 10, // Cada X segundos se muestra labels de tiempo en timeline
    LABEL_DEFAULT_START_TIME: 0,
    // Timeline Grid
    GRID_BLOCK_DEFAULT_DURATION: 5, // Duracion en segundos de cada bloque
    GRID_BLOCK_DEFAULT_START_TIME: 0,
    GRID_BLOCK_MIN_DURATION: 5,
    GRID_BLOCK_SEPARATOR_1: 5, // Cada X segundos se muestra separador1 en grid del timeline, que coincida con LABEL_DEFAULT_DURATION  
    GRID_BLOCK_SEPARATOR_2: 60, // Cada X segundos se muestra separador2 en grid del timeline
    // Timeline Zoom
    GRID_ZOOM_LEVEL_MULT: 5, // Cada nivel de zoom se multiplica por GRID_ZOOM_LEVEL_MULT
    GRID_ZOOM_MIN_LEVEL: 1, // Antes 0.5
    GRID_ZOOM_MAX_LEVEL: 5,
    GRID_ZOOM_DEFAULT: 2,
    // Timeline Snap
    GRID_SNAP_INITIAL_X: 5, // Pixels de ancho de cada bloque (1 bloque es 1 segundo)
    GRID_SNAP_INITIAL_Y: 60,
    GRID_BLOCK_RESIZE_SNAP_X: 5, // Multiplicador de snap de resize. Al hacer resize, cuantos segundos desplaza horizontalmente. Debería coincidir con GRID_BLOCK_DEFAULT_DURATION

    BLOCK_VERTICAL_OFFSET_INSIDE_CHANNEL: 10, // Margen superior de bloque dentro de channel
    BLOCK_VERTICAL_OFFSET_TAG: 30, // Margen superior adicional de cada subnivel de un bloque tag
    MIN_BLOCK_SHOW_TITLE: 15, // Tamaño mínimo de un bloque en segundos para que se muestre icono + titulo, en caso de ser menor se mostrara solo icono
    TIME_INCREASE_ON_TIMELINE_GROW: 60, // Tiempo que aumenta el timeline cuando un block se acerca al borde
    THRESHOLD_TIMELINE_GROW: 60, // Margen hsata el tiempo total, en donde incrementa el timeline si un block entra en el

    /////////////////////////////////////////////////////
    // STANDARD BLOCK LIST
    /////////////////////////////////////////////////////
    blockList: [
        {
            id: 1,
            type: 1,
            name: {
                id: 0,
                key: '',
                resources: [
                    {
                        language: {
                            id: 1,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Exercise'
                    },
                    {
                        language: {
                            id: 2,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Exercise'
                    }
                ]
            },
            initTime: 0,
            duration: 45,
            positionX: 0,
            positionY: 0,
            parentElement: null,
            style: "exercise",
            icon: "weight-lifter",
            item: {
                id: 0,
                name: "-"
            }
        },
        {
            id: 2,
            type: 3,
            name: {
                id: 0,
                key: '',
                resources: [
                    {
                        language: {
                            id: 1,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Rest'
                    },
                    {
                        language: {
                            id: 2,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Rest'
                    }
                ]
            },
            initTime: 0,
            duration: 15,
            positionX: 0,
            positionY: 0,
            parentElement: null,
            style: "rest",
            icon: "sleep",
            item: {
                id: 0,
                behaviour: 0,
                text: "",
                image: "https://creator.pramacloud.com/public/rest_image.png",
                idPosition: 0
            }
        },
        {
            id: 3,
            type: 3,
            name: {
                id: 0,
                key: '',
                resources: [
                    {
                        language: {
                            id: 1,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Countdown'
                    },
                    {
                        language: {
                            id: 2,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Countdown'
                    }
                ]
            },
            initTime: 0,
            duration: 10,
            positionX: 0,
            positionY: 0,
            parentElement: null,
            style: "countdown",
            icon: "history",
            item: {
                id: 0,
                behaviour: 1,
                text: "",
                image: null,
                idPosition: 0
            }
        },
        {
            id: 4,
            type: 7,
            name: {
                id: 0,
                key: '',
                resources: [
                    {
                        language: {
                            id: 1,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Explanation'
                    },
                    {
                        language: {
                            id: 2,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Explanation'
                    }
                ]
            },
            initTime: 0,
            duration: 60,
            positionX: 0,
            positionY: 0,
            parentElement: null,
            style: "explanation",
            icon: "bullhorn-outline"
        },
        {
            id: 5,
            type: 7,
            name: {
                id: 0,
                key: '',
                resources: [
                    {
                        language: {
                            id: 1,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'HR List'
                    },
                    {
                        language: {
                            id: 2,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'HR List'
                    }
                ]
            },
            initTime: 0,
            duration: 15,
            positionX: 0,
            positionY: 0,
            parentElement: null,
            style: "hrlist",
            icon: "heart-plus-outline"
        },
        {
            id: 6,
            type: 7,
            name: {
                id: 0,
                key: '',
                resources: [
                    {
                        language: {
                            id: 1,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'HR Chart'
                    },
                    {
                        language: {
                            id: 2,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'HR Chart'
                    }
                ]
            },
            initTime: 0,
            duration: 15,
            positionX: 0,
            positionY: 0,
            parentElement: null,
            style: "hrchart",
            icon: "heart-multiple-outline"
        },
        {
            id: 7,
            type: 7,
            name: {
                id: 0,
                key: '',
                resources: [
                    {
                        language: {
                            id: 1,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Welcome'
                    },
                    {
                        language: {
                            id: 2,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Welcome'
                    }
                ]
            },
            initTime: 0,
            duration: 30,
            positionX: 0,
            positionY: 0,
            parentElement: null,
            style: "welcome",
            icon: "human-greeting"
        },
        /////////////////////////////////////////////////////
        // TAG BLOCKS LIST
        /////////////////////////////////////////////////////
        {
            id: 1,
            type: 6,
            order: 1,
            initTime: 0,
            name: {
                id: 0,
                key: '',
                resources: [
                    {
                        language: {
                            id: 1,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Phase'
                    },
                    {
                        language: {
                            id: 2,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Phase'
                    }
                ]
            },
            duration: 30,
            positionX: 0,
            positionY: 0,
            parentElement: null,
            style: "tag",
            icon: "tag",
            item: {
                id: 0,
                type: 1,
                value: ""
            }
        },
        {
            id: 2,
            type: 6,
            order: 6,
            initTime: 0,
            name: {
                id: 0,
                key: '',
                resources: [
                    {
                        language: {
                            id: 1,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'CurrentExercise'
                    },
                    {
                        language: {
                            id: 2,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'CurrentExercise'
                    }
                ]
            },
            duration: 30,
            positionX: 0,
            positionY: 0,
            parentElement: null,
            style: "tag",
            icon: "tag",
            item: {
                id: 0,
                type: 2,
                value: "1"
            }
        },
        {
            id: 3,
            type: 6,
            order: 5,
            initTime: 0,
            name: {
                id: 0,
                key: '',
                resources: [
                    {
                        language: {
                            id: 1,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'TotalExercises'
                    },
                    {
                        language: {
                            id: 2,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'TotalExercises'
                    }
                ]
            },
            duration: 30,
            positionX: 0,
            positionY: 0,
            parentElement: null,
            style: "tag",
            icon: "tag",
            item: {
                id: 0,
                type: 3,
                value: "1"
            }
        },
        {
            id: 4,
            type: 6,
            order: 4,
            initTime: 0,
            name: {
                id: 0,
                key: '',
                resources: [
                    {
                        language: {
                            id: 1,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'CurrentRound'
                    },
                    {
                        language: {
                            id: 2,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'CurrentRound'
                    }
                ]
            },
            duration: 30,
            positionX: 0,
            positionY: 0,
            parentElement: null,
            style: "tag",
            icon: "tag",
            item: {
                id: 0,
                type: 4,
                value: "1"
            }
        },
        {
            id: 5,
            type: 6,
            order: 3,
            initTime: 0,
            name: {
                id: 0,
                key: '',
                resources: [
                    {
                        language: {
                            id: 1,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'TotalRounds'
                    },
                    {
                        language: {
                            id: 2,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'TotalRounds'
                    }
                ]
            },
            duration: 30,
            positionX: 0,
            positionY: 0,
            parentElement: null,
            style: "tag",
            icon: "tag",
            item: {
                id: 0,
                type: 5,
                value: "1"
            }
        },
        {
            id: 6,
            type: 6,
            order: 2,
            initTime: 0,
            name: {
                id: 0,
                key: '',
                resources: [
                    {
                        language: {
                            id: 1,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'WorkoutMode'
                    },
                    {
                        language: {
                            id: 2,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'WorkoutMode'
                    }
                ]
            },
            duration: 30,
            positionX: 0,
            positionY: 0,
            parentElement: null,
            style: "tag",
            icon: "tag",
            item: {
                id: 0,
                type: 6,
                value: ""
            }
        }
    ],

    /////////////////////////////////////////////////////
    // MUSIC BLOCKS LIST
    /////////////////////////////////////////////////////

    blockMusicList: [
        {
            id: 1,
            type: 1,
            name: {
                id: 0,
                key: '',
                resources: [
                    {
                        language: {
                            id: 1,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Execution'
                    },
                    {
                        language: {
                            id: 2,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Execution'
                    }
                ]
            },
            initTime: 0,
            duration: 30,
            positionX: 0,
            positionY: 0,
            parentElement: null,
            style: "music",
            icon: "music"
        },
        {
            id: 2,
            type: 1,
            name: {
                id: 0,
                key: '',
                resources: [
                    {
                        language: {
                            id: 1,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Rest'
                    },
                    {
                        language: {
                            id: 2,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Rest'
                    }
                ]
            },
            initTime: 0,
            duration: 15,
            positionX: 0,
            positionY: 0,
            parentElement: null,
            style: "music",
            icon: "music"
        },
        {
            id: 3,
            type: 1,
            name: {
                id: 0,
                key: '',
                resources: [
                    {
                        language: {
                            id: 1,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Countdown'
                    },
                    {
                        language: {
                            id: 2,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Countdown'
                    }
                ]
            },
            initTime: 0,
            duration: 10,
            positionX: 0,
            positionY: 0,
            parentElement: null,
            style: "music",
            icon: "music"
        },
        {
            id: 4,
            type: 1,
            name: {
                id: 0,
                key: '',
                resources: [
                    {
                        language: {
                            id: 1,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Welcome'
                    },
                    {
                        language: {
                            id: 2,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Welcome'
                    }
                ]
            },
            initTime: 0,
            duration: 10,
            positionX: 0,
            positionY: 0,
            parentElement: null,
            style: "music",
            icon: "music"
        },
        {
            id: 5,
            type: 1,
            name: {
                id: 0,
                key: '',
                resources: [
                    {
                        language: {
                            id: 1,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Explanation'
                    },
                    {
                        language: {
                            id: 2,
                            name: null,
                            cultureInfo: null
                        },
                        value: 'Explanation'
                    }
                ]
            },
            initTime: 0,
            duration: 10,
            positionX: 0,
            positionY: 0,
            parentElement: null,
            style: "music",
            icon: "music"
        }
    ]

}