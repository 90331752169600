<h1>PROGRAMS CONFIGURATION</h1>

<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
  <mat-toolbar class="mat-buttons-toolbar">
    <mat-toolbar-row>
      <span class="mat-spacer"></span>
      <button mat-flat-button (click)="saveProgramTimelines()" class="prama-button full-button medium-button">
        <mat-icon>save</mat-icon> SAVE
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
</div>



<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
  <div fxLayout="column" fxFlex="15" fxFlexAlign="stretch">
    <mat-card class="filter-options-cont padding30" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-between stretch"
      fxFill>
      <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" class="w-100">

        <h5>Programs</h5>
        <ul class="w-100">
          <label *ngFor="let program of programsAvailable; let i = index;" class="w-100" (click)="changeProgram(program.id)">
            <input type="checkbox" class="uk-checkbox" [checked]="currentProgramSelected === program.id" [disabled]="currentProgramSelected === program.id">
            {{ program.name }}
          </label>
        </ul>
      </div>

    </mat-card>
  </div>

  <div fxLayout="column" fxLayout.xs="column" fxFlex="85" fxLayoutGap="20px" fxFlexAlign="stretch" class="program-timelines-container">
    <mat-tab-group>
      <mat-tab label="TIMELINES">
        <div class="margin10">

          <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" class="nomargin timeline-cont-max-height">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="nomargin">
              <h4 fxFlex="50">{{ timelinesActive?.length }} active timelines</h4>
            </div>

            <mat-table #table [dataSource]="dataSourceActive" matSort class="timeline-table nomargin">

              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"> Id </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="5%">
                  {{element.id}}
                </mat-cell>
              </ng-container>             

              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="40%"> Name </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="40%">
                  <h4>{{element.name}}</h4>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="20%"> Status </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="20%">
                  <span class="status-text">{{getStatus(element)}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="activeFrom">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="15%"> Active from </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="15%">
                  <mat-form-field>
                    <input matInput [matDatepicker]="picker" placeholder="-" [(ngModel)]="element.activeFrom" disabled
                      (dateChange)="updateDate(element)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                  </mat-form-field>
                  <button type="button" mat-flat-button class="clear-date-button" (click)="clearDate(element, 'activeFrom')">
                    <mat-icon class="yellow">clear</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="activeUntil">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="15%"> Active until </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="15%">
                  <mat-form-field>
                    <input matInput [matDatepicker]="picker" placeholder="-" [(ngModel)]="element.activeUntil" disabled
                      (dateChange)="updateDate(element)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                  </mat-form-field>
                  <button type="button" mat-flat-button class="clear-date-button" (click)="clearDate(element, 'activeUntil')">
                    <mat-icon class="yellow">clear</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="options">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"> </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="5%">
                  <button type="button" mat-flat-button class="prama-transparent-button" (click)="disableTimeline(element)">
                    <mat-icon class="yellow">clear</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumnsActive; sticky: true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsActive;"></mat-row>
            </mat-table>
            <!-- <mat-paginator [pageSizeOptions]="[10, 250, 1000]" showFirstLastButtons></mat-paginator> -->
          </div>
          <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" class="nomargin timeline-cont-max-height">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="nomargin">
              <h4 fxFlex="50">{{ timelinesAvailable?.length }} available timelines</h4>
            </div>

            <mat-table #table [dataSource]="dataSourceAvailable" matSort class="timeline-table nomargin">

              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"> Id </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="5%">
                  {{element.id}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="90%"> Name </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="90%">
                  <h4>{{element.name}}</h4>
                </mat-cell>
              </ng-container>


              <ng-container matColumnDef="options">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"> </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="5%">
                  <button type="button" mat-flat-button class="prama-transparent-button" (click)="enableTimeline(element)">
                    <mat-icon class="yellow">add</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumnsAvailable; sticky: true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsAvailable;"></mat-row>
            </mat-table>
            <!-- <mat-paginator [pageSizeOptions]="[10, 250, 1000]" showFirstLastButtons></mat-paginator> -->
          </div>
        </div>
      </mat-tab>
      <mat-tab label="EXERCISES">
        <div class="margin10">
          <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" class="nomargin timeline-cont-max-height">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="nomargin">
              <h4 fxFlex="50">{{ exercises?.length }} exercises on this program</h4>
            </div>
            <mat-table #table [dataSource]="dataSourceExercises" matSort class="timeline-table nomargin">

              <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"> Id </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="5%">
                  {{element.id}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="40%"> Name </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="40%">
                  <h4>{{element.name}}</h4>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="options">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"> </mat-header-cell>
                <mat-cell *matCellDef="let element" fxFlex="5%">

                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumnsExercises; sticky: true"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsExercises;"></mat-row>
            </mat-table>
            <!-- <mat-paginator [pageSizeOptions]="[10, 250, 1000]" showFirstLastButtons></mat-paginator> -->
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>