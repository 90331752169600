import { Injectable } from '@angular/core';
import { SessionService } from '../session.service';
import { HomeFitnessSessionService } from '../home-fitness-session.service';

@Injectable({
  providedIn: 'root'
})
export class HomeFitnessSessionsResolver {

  constructor(private sessionService: HomeFitnessSessionService) { }

    resolve() {
        return this.sessionService.getSessions();
    }
}
