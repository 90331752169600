import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from '@angular/router';
import { IProgram } from '../../interfaces/models/i-program';

@Component({
  selector: 'music-manager-program',
  templateUrl: './music-manager-program.component.html',
  styleUrls: ['./music-manager-program.component.scss']
})
export class MusicManagerProgramComponent implements OnInit {

  headers = {
    id: "Id",
    name: "Name",
  };

  previewImage = "";
  displayedColumns = ['id', 'name'];
  dataSource = new MatTableDataSource;

  programsList: IProgram[];

  constructor(private route: ActivatedRoute, private router: Router) {
    this.programsList = this.route.snapshot.data.programs;
    //this.exercisesToShow = this.exercisesToShow.slice(0, 40); // Limit to 40 for debugging
  }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    // for (const ex of this.exercisesToShow) {
    //   this.exerciseList.push(ex);
    // }
    // this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
    this.updateTableData();    
  }

  updateTableData(){
    this.dataSource = new MatTableDataSource(this.programsList);    
  }

}
