import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs/rx";
import { timer, combineLatest } from 'rxjs';
import "rxjs/observable/combineLatest";
import "rxjs/add/observable/of";

import { ISequenceCombo } from "../../interfaces/models/sequence/i-sequence-combo";
import { IExercise } from "../../interfaces/models/i-exercise";

import { ComboSequenceService } from "../combo-sequence.service";
import { ExerciseService } from "../exercise.service";
import { NotificationService } from "../notification.service";

import { CONFIG } from "../../../assets/config";


@Injectable()
export class ComboSequenceConfiguratorResolver implements Resolve<ISequenceCombo> {

  constructor(private notificationService: NotificationService,
    private exerciseService: ExerciseService,
    private sequenceService: ComboSequenceService,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<ISequenceCombo> {

    if (CONFIG.traceLoads) {
      console.log("---- SEQUENCE FORM RESOLVER -------------- LOADING DATA--------------------------");
    }

    const param = route.params["exerciseId"];
    const paramId = Number(param);
    if (!param) {
      this.router.navigate(["/exercises"]);
    } else if (isNaN(paramId) || paramId === 0) {
      return Observable.of(null);
    } else {
      return Observable
        .combineLatest(
          this.exerciseService.getExercise(paramId),
          this.sequenceService.getSequenceCombo(paramId)
        )
        .map( (data: [IExercise, ISequenceCombo]) => {
          if (CONFIG.traceLoads) {
            console.log("------------------------------DATA LOADED--------------------------");
          }
          console.log(data);

          const sequence: ISequenceCombo = {
            id: data[1].id,
            exercise: data[0],
            exerciseId: data[0].id,
            players: data[0].players,
            blocks: data[1].blocks,
            creationDate: data[1].creationDate
          };

          return sequence;
        });
    }
  }
}
