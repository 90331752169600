import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";

import { IProgram, IProgramAmbienceDto, IProgramAmbience } from "../interfaces/models/i-program";
import { IProgramPackage } from "../interfaces/models/i-program-packages";

@Injectable()
export class ProgramService {

  private urlGetPrograms: string;
  private urlGetOldPrograms: string;

  private urlGetPackagesManagement: string;
  private urlPostPackagesManagement: string;
  private urlPutPackagesManagement: string;

  private urlGetProgramAmbiencePhases: string;

  private urlPutProgramAmbience: string;
  private urlPutProgramAmbiencePhases: string;

  private urlGetProgramTypes: string;
  private urlGetProgramsByType: string;

  private _programs: IProgram[];

  constructor(private http: HttpClient) {
    this.urlGetPrograms = CONFIG.serverUrl + ENDPOINTS.programs.get;
    this.urlGetOldPrograms = CONFIG.serverUrl + ENDPOINTS.oldPrograms.get;
    this.urlGetPackagesManagement = CONFIG.serverUrl + ENDPOINTS.packagesManagement.get;
    this.urlPostPackagesManagement = CONFIG.serverUrl + ENDPOINTS.packagesManagement.post;
    this.urlPutPackagesManagement = CONFIG.serverUrl + ENDPOINTS.packagesManagement.put;
    this.urlGetProgramTypes = CONFIG.serverUrl + ENDPOINTS.programTypes.get;
    this.urlGetProgramsByType = CONFIG.serverUrl + ENDPOINTS.programsByType.get;
    this.urlGetProgramAmbiencePhases = CONFIG.serverUrl + ENDPOINTS.programsAmbience.get;
    this.urlPutProgramAmbience = CONFIG.serverUrl + ENDPOINTS.programsAmbience.put;
    this.urlPutProgramAmbiencePhases = CONFIG.serverUrl + ENDPOINTS.programsAmbiencePhases.put;
  }

  public programs() {
    return this._programs;
  }

  public getPrograms(): Observable<any> {
    return this.http.get(this.urlGetPrograms);
  }

  public getProgramsByType(programTypes: number[]): Observable<any>  {
    var url = this.urlGetProgramsByType +"?types="+ programTypes.join("&types=")
    return this.http.get(url);
  }

  public getOldPrograms(): Observable<any> {
    return this.http.get(this.urlGetOldPrograms);
  }

  public getPackagesManagement(): Observable<any> {
    return this.http.get(this.urlGetPackagesManagement);
  }

  public getProgramAmbiencePhases(): Observable<any> {
    return this.http.get(this.urlGetProgramAmbiencePhases);
  }

  public createProgramPackage(pp: IProgramPackage): Observable<any> {
    return this.http.post(this.urlPostPackagesManagement, pp);
  }

  public updateProgramAmbience(p: IProgramAmbience) {
    return this.http.put(this.urlPutProgramAmbience, p);
  }

  public updateProgramAmbiencePhases(p: IProgramAmbience) {
    return this.http.put(this.urlPutProgramAmbiencePhases, p);
  }

  public updateProgramPackage(pp: IProgramPackage): Observable<any> {
    return this.http.put(this.urlPutPackagesManagement, pp);
  }

  public getProgramTypes() : Observable<any> {
    return this.http.get(this.urlGetProgramTypes);
  }
}
