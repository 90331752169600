import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ProgramService } from '../program.service';

import { of } from "rxjs";


@Injectable()
export class PackagesManagementResolver implements Resolve<Observable<string>> {

    constructor(private programService: ProgramService) { }

    resolve() {
        return this.programService.getPackagesManagement().catch(() => {
            return of('error');
        });
    }

}

