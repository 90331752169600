import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";

import { IEquipment } from "../interfaces/models/i-equipment";

import { Cacheable } from 'ngx-cacheable';

@Injectable()
export class EquipmentService {

  private urlGetEquipment: string;

  private _equipment: IEquipment[];

  constructor(private http: HttpClient) {
    this.urlGetEquipment = CONFIG.serverUrl + ENDPOINTS.equipment.get;
  }

  public equipment() {
    return this._equipment;
  }

  // getEquipment() {
  //   const url = CONFIG.serverUrl + ENDPOINTS.equipment.get;
  //   return new Promise((resolve, reject) => {
  //     if (this._equipment && this._equipment.length > 0) {
  //       if (CONFIG.traceLoads) {
  //         console.log("equipment load ok - cache");
  //       }
  //       return resolve(true);
  //     }

  //     this.http.get(url)
  //       .catch((error) => {
  //         return Observable.throw(error);
  //       })
  //       .subscribe(
  //         (response: IEquipment[]) => {
  //           if (CONFIG.traceLoads) {
  //             console.log("equipment load ok");
  //           }ñ
  //           this._equipment = response;
  //           resolve(true);
  //         },
  //         error => {
  //           if (CONFIG.traceLoads) {
  //             console.log("equipment load fail");
  //           }
  //           reject(error);
  //         }
  //       );
  //    });
  // }

  @Cacheable()
  public getEquipment(): Observable<any> {
    /*return this.http.get(this.urlGetEquipment);*/

    
    return this.http.get(this.urlGetEquipment).map( (equipment: IEquipment[]) => {
      equipment = equipment.filter(e => e.id !== 1); // remove Dumbbell 
      this._equipment = equipment;
      return equipment;
    });
     
  }
}
