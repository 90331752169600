import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { Router, UrlTree, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET } from "@angular/router";
import { ENUMS } from '../../../../assets/enums';
import { AuthService } from '../../../services/auth.service';
import { LoadingService } from '../../../services/loading.service';
import { EventsService } from '../../../services/events.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewChecked{

  showHeader: boolean = true;

  accessExercises: boolean;
  accessGroupTrainingSessions: boolean;
  accessGroupTrainingFinishers: boolean;
  accessGroupTrainingTimelines: boolean;
  accessGroupTrainingWorkouts: boolean;
  accessProgramManager: boolean;
  accessProgramContent: boolean;
  accessProgramBasics: boolean;
  accessMusic: boolean;
  accessHomeFitness: boolean;
  accessCategories: boolean;
  
  pavigym: boolean;

  constructor(private router: Router, 
    private loadingService: LoadingService, 
    private authService: AuthService,
    private eventsService: EventsService) {}

  ngOnInit(): void {
    this.checkPermissions();

    this.eventsService.event$.subscribe((params: any) => {
      if (params.value === 'ok' && (params.key === 'logout' || params.key === 'login')) {
        this.checkPermissions();
      }
    });
  }
  
  ngAfterViewChecked(): void {
    this.showHeader = document.location.pathname === '/login' ? false : true;
  }

  logout() {
    localStorage.clear();
    this.eventsService.doEvent({key: 'logout', value: 'ok'});
    this.router.navigate(["/login"]);
    
  }

  checkPermissions() {
    this.pavigym = this.authService.isInRole(ENUMS.roles.pavigym);
    this.accessExercises = this.authService.isInRole(ENUMS.roles.creator_exercise_list);
    this.accessGroupTrainingSessions = this.authService.isInRole(ENUMS.roles.creator_gt_list);
    this.accessGroupTrainingFinishers = this.authService.isInRole(ENUMS.roles.creator_finisher_list);
    this.accessGroupTrainingTimelines = this.authService.isInRole(ENUMS.roles.creator_timeline_list);
    this.accessGroupTrainingWorkouts = this.authService.isInRole(ENUMS.roles.creator_workout_list);
    this.accessProgramManager = this.authService.isInRole(ENUMS.roles.creator_program_manager_list);
    this.accessProgramContent = this.authService.isInRole(ENUMS.roles.creator_program_content_list);
    this.accessProgramBasics = this.authService.isInRole(ENUMS.roles.creator_program_basics_list);
    this.accessMusic = this.authService.isInRole(ENUMS.roles.creator_music);
    this.accessHomeFitness = this.authService.isInRole(ENUMS.roles.creator_homefitness);
    this.accessCategories = this.authService.isInRole(ENUMS.roles.creator_category_list);
  }
}
