<h1 mat-dialog-title>Edit block</h1>
<div mat-dialog-content class="dialog-content">
  <h2>Common options</h2>
  <mat-divider class="dialog-divider"></mat-divider>
  <div fxLayout="column" fxLayoutAlign="stretch">
    <div fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="space-between stretch">
      <mat-form-field>
        <mat-label>Start time</mat-label>
        <input matInput [(ngModel)]="data.block.initTime" type="number" step="5" min="0">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Duration</mat-label>
        <input matInput [(ngModel)]="data.duration" type="number" step="5" min="5">
      </mat-form-field>
    </div>
    <!-- <exercise-search [exercises]="exercisesToSearch" [currentStationId]="currentStationId"
      (exerciseFound)="exerciseSelected($event); closeSearchModal();" (searchCancelled)="searchCancelled()" (stationCleaned)="cleanStation($event); closeSearchModal()">
    </exercise-search> -->
  </div>

  <mat-divider class="dialog-divider-blank"></mat-divider>
  <h2>Block options</h2>
  <mat-divider class="dialog-divider"></mat-divider>
  <div fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="space-between stretch">
    <mat-checkbox class="dialog-label">Wait for user at start</mat-checkbox>
    <mat-checkbox class="dialog-label">Wait for user at finish</mat-checkbox>
  </div>

  <exercise-search [exercises]="data.exercises">
  </exercise-search>
</div>

<mat-divider class="dialog-divider-blank"></mat-divider>

<div fxLayout="row" fxLayoutAlign="stretch stretch" fxLayoutGap="20px" fxFill class="w-100">

  <mat-toolbar class="mat-buttons-toolbar w-100">
    <mat-toolbar-row>
      <button mat-flat-button class="prama-button full-button" (click)="onDeleteClick()">
        <mat-icon>delete</mat-icon> Delete
      </button>
      <span class="mat-spacer"></span>
      <button mat-flat-button class="prama-button full-button" (click)="onNoClick()">
        <mat-icon>clear</mat-icon> Cancel
      </button>
      <button mat-flat-button class="prama-button full-button" [mat-dialog-close]="data.animal" cdkFocusInitial>
        <mat-icon>check</mat-icon> Ok
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

</div>
