import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { LevelService } from '../level.service';

import { of } from "rxjs";

@Injectable()
export class IntensityLevelsResolver implements Resolve<Observable<string>> {

    constructor(private levelService: LevelService) { }

    resolve() {
        return this.levelService.getIntensityLevels().catch(() => {
            return of('error');
        });
    }
}