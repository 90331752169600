import { Component, OnInit, ViewChild } from '@angular/core';

import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

import { ActivatedRoute, Router } from '@angular/router';
import { IProgram } from '../../interfaces/models/i-program';
import { IPlaylist } from '../../interfaces/models/i-playlist';
import { ISong } from '../../interfaces/models/i-song';
import { SongsService } from '../../services/song-service';
import { IMusicPhase } from '../../interfaces/models/i-music-phase';
import { IMusicProvider } from '../../interfaces/models/i-music-provider';
import { MusicService } from '../../services/music-service';
import { CONFIG } from '../../../assets/config';
import { NotificationService } from '../../services/notification.service';
import { ENUMS } from '../../../assets/enums';
import { ModalRemoveSongComponent } from './modal-remove-song.component';
import { IMusicPackage } from '../../interfaces/models/i-music-package';


@Component({
  selector: 'music-manager-songs',
  templateUrl: './music-manager-songs.component.html',
  styleUrls: ['./music-manager-songs.component.scss']
})
export class MusicManagerSongsComponent implements OnInit {

  headers = {
    id: "Id",
    name: "Name",
    lastUpdate: "Last update",
    user: "User",
    status: "Status",
    actions: "Actions"
  };

  previewImage = "";
  displayedColumns = ['id', 'player', 'title', 'usedTimes', 'duration', 'provider', 'program', 'phase', 'status', 'favourite', 'actions'];
  dataSource = new MatTableDataSource;

  playlist: IPlaylist;
  program: IProgram;
  songs: ISong[] = [];
  songsToShow: ISong[];
  programs: IProgram[];
  phases: IMusicPhase[];
  providers: IMusicProvider[];
  packages: IMusicPackage[];

  editMode: number = 0;
  playingSongId: number = 0;
  playingSong: ISong;
  currentPlayingSongUrl: string = "";
  playerUrl: string;

  emptyProvider: IMusicProvider = {id: -1, name: 'None', website: ''};
  player: HTMLAudioElement = new Audio();
  
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  constructor(private route: ActivatedRoute, private router: Router, private musicService: MusicService,
    private songService: SongsService, private notificationService: NotificationService, public dialog: MatDialog) {

    this.songsToShow = this.route.snapshot.data.songs;
    this.programs = this.route.snapshot.data.programs;
    this.providers = this.route.snapshot.data.providers;
    this.phases = this.route.snapshot.data.phases;
    this.packages = this.route.snapshot.data.packages;
    this.songsToShow.filter(x => !x.provider || x.provider == null).forEach(x => {
      x.provider = this.emptyProvider;
      x.usedTimes = this.calculateSongUsedTimes(x.id);
    });
    this.providers.splice(0, null, this.emptyProvider);
  }

  ngOnInit() {
    
  }

  private calculateSongUsedTimes(songId: number) : number {
    let amount = this.packages.filter(p => p.phases.some(x => x.songs.some(s => s.id === songId))).length;
    return amount;
  }

  ngAfterViewInit() {
    this.updateTableData();    
  }

  updateTableData() {
    this.editMode = 0;
    this.dataSource.sort = this.sort;
    this.dataSource = new MatTableDataSource(this.songs);    
    this.dataSource.paginator = this.paginator;
  }


  songStatusChanged(event: MatSlideToggleChange) {
    let song = this.songs.find((x: ISong) => x.id == Number(event.source.id));
    song.active = event.checked;
    this.songService.updateSong(song).subscribe(response => {
      console.log(response);
    });
  }

  toggleSongFav(song: ISong) {
    song.isFavourite = !song.isFavourite;
  }

  toggleSongStatus(song: ISong) {
    song.active = !song.active;
  }

  playSong(song: ISong) {
    this.playingSongId = song.id;
    this.playingSong = song;
    this.currentPlayingSongUrl = CONFIG.mediaUrl + `/songs/${song.programs[0].id}/${song.phases[0].id}/${song.fileName}`;
    
    this.player.src = this.currentPlayingSongUrl;
    this.player.load();
    this.player.play();
  }

  stopSong(song: ISong) {
    this.playingSongId = 0;
    this.playingSong = null;
    this.currentPlayingSongUrl = "";
    this.player.pause();
  }
  
  private openEditor(song: ISong) {
    this.editMode = song.id;
    //this.updateTableData();
  }

  public resetEditForm(event) {
    event.stopPropagation();
  }

  private saveSong(song: ISong) {
    if(song.provider.id === -1) {
      song.provider = null;
    }

    this.musicService.putSong(song).subscribe(
      result => {
        this.notificationService.notify("Song modified successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
          this.updateTableData();      
      },
      error => {
        console.error('Error', error);
      },
      () => {
        if(!song.provider || song.provider == null) {
          song.provider = this.emptyProvider;
        }
      }
    );
  }

  resetForm(event) {
    event.stopPropagation();
    this.musicService.getProviders().subscribe(
      result => {
        this.editMode = 0;
        this.songs = result;
        this.updateTableData();
      },
      error => {
        console.log('Error');
      }
    );
  }

  // Aux function for comparison of combobox objects
  compare(val1, val2) {
    if (val1 !== undefined && val2 !== undefined && val1 !== null && val2 !== null) {
      return val1.id === val2.id;
    } else {
      return false;
    }
  }

  private modalRemoveSong(song: ISong) {

    let restrictedPackages = this.packages.filter( p => p.status.id === ENUMS.musicPackageStatus.scheduled ||
                                               p.status.id === ENUMS.musicPackageStatus.shared);


    let selected = restrictedPackages.some(x => x.phases.some(y => y.songs.some(s => s.id === song.id)));



    let modal = this.dialog.open(ModalRemoveSongComponent, {
      data: {
        song: song,
        canDelete: !selected
      }
    });
    
    modal.componentInstance.onDelete.subscribe( val => {
      this.songs = this.songs.filter( x => x.id != val.id);
      this.updateTableData();
    })
  }
}
