

<div>
  <table>
    <!-- <thead>
      <tr>
        <th>Round</th>
        <th>Work</th>
        <th>Rest</th>
        <th>Total</th>
      </tr>
    </thead> -->
    <tbody>
      <tr *ngFor="let round of session.rounds; let i = index">
        <td colspan="4">
          <table>
            <tr>
              <td>{{this.workoutModeSubTxt}} {{i + 1}}</td>
              <td>
                <input type="number" min="10" step="5" [(ngModel)]="round.work">
                <select [(ngModel)]="getMoodByRound(round, 3).mood">
                  <option *ngFor="let mood of moodsAvailable" [value]="mood.id">{{mood.name}}</option>
                </select>
              </td>
              <td>
                <input type="number" min="10" step="5" [(ngModel)]="round.rest">
                <select [(ngModel)]="getMoodByRound(round, 4).mood">
                  <option *ngFor="let mood of moodsAvailable" [value]="mood.id">{{mood.name}}</option>
                </select>
              </td>
              <td>{{calculateRoundTime(round) | timeToMinutes}}</td>
            </tr>
            <tr *ngIf="round.extraRest && session.workoutMode.id == 1">
              <td colspan="2"></td>
              <td>
                <input type="number" min="10" step="5" [(ngModel)]="round.extraRest" (change)="refreshTimes()">
                <select [(ngModel)]="getMoodByRound(round, 5).mood">
                  <option *ngFor="let mood of moodsAvailable" [value]="mood.id">{{mood.name}}</option>
                </select>
              </td>
              <td>{{round.extraRest | timeToMinutes}}</td>
            </tr>
            <tr *ngIf="round == session.rounds[session.rounds.length-1] && session.workoutMode.id >= 2 && session.workoutMode.id <= 3">
              <td colspan="2"></td>
              <td>
                <input type="number" min="10" step="5" [(ngModel)]="commonExtraRest" (change)="refreshTimes()">
              </td>
              <td *ngIf="session.workoutMode.id == 1">{{round.extraRest | timeToMinutes}}</td>
              <td *ngIf="session.workoutMode.id >= 2 && session.workoutMode.id <= 3">{{(getExerciseCount()-1) *
                round.extraRest | timeToMinutes}}</td>
            </tr>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  <p>Total time for {{getExerciseCount()}} exercise<span *ngIf="getExerciseCount() > 1">s</span></p>
  <p>Work: {{calculateTotalWorkTime() | timeToMinutes}}</p>
  <p>Rest: {{calculateTotalRestTime() | timeToMinutes}}</p>
  <p>Total: {{calculateTotalTime() | timeToMinutes}}</p>
</div>






<div class="uk-margin">
  <div class="uk-grid-match" uk-grid>
    <div class="uk-width-1-2@l">
      <!-- WORKOUT MODE AND ROUNDS NUMBER -->
      <div class="uk-margin">
        <span class="uk-form-label yellow">Workout mode</span>
        <!-- <span class="uk-form-label error-message" *ngIf=" this.formFields.workoutModeField.error">{{ this.formFields.workoutModeField.errorMessage }}</span> -->
        <table class="auxTable">
          <tr>
            <td>
              <label class="container">
                Rounds
                <input type="radio" name="__sessionWorkoutmode" [value]="1" [(ngModel)]="session.workoutMode.id"
                  (change)="changeSessionMode()">
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Sets
                <input type="radio" name="__sessionWorkoutmode" [value]="2" [(ngModel)]="session.workoutMode.id"
                  (change)="changeSessionMode()">Sets
                <span class="checkmark"></span>
              </label>
              <label class="container">
                Super sets
                <input type="radio" name="__sessionWorkoutmode" [value]="3" [(ngModel)]="session.workoutMode.id"
                  (change)="changeSessionMode()">
                <span class="checkmark"></span>
              </label>
            </td>
            <td>
              <span class="number-wrapper">
                  <input type="number" min="1" [(ngModel)]="session.roundsCount" (change)="changeRoundNumber($event)" /> rounds
              </span>

              <!-- <label>
                <input type="checkbox" class="uk-checkbox" id="input-reps" name="input-reps" [value]="this.sessionValues.enableReps"
                  [(ngModel)]="this.sessionValues.enableReps" (change)="repsModeChanged()"> Enable reps
              </label> -->
            </td>
          </tr>
        </table>
      </div>
    </div>

  </div>
</div>
<!-- <div>
  <div>
    <table class="prama-round-table" *ngIf="this.sessionValues.selectedWorkoutModeId !== 0">
      <thead>
        <tr>
          <th> {{ this.sessionValues.selectedWorkoutMode.nameToShow }} </th>
          <th class="text-left">Work time</th>
          <th class="text-left">Rest time</th>
          <th class="text-left">Total time</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of this.sessionValues.sessionRows; let i = index">
          <td [ngClass]="{'extraRestCell': row.extraRest}">{{row.name}}</td>
          <td>
            <div class="uk-flex uk-flex-between">
              <div class="uk-width-1-1 width100 uk-margin-right">
                <input type="number" *ngIf="!row.extraRest" class="uk-input prama-input text-left" min="10" step="5"
                  [id]="'input-work-' + row.id" [name]="'input-work-' + row.id" [value]="row.work" [(ngModel)]="row.work"
                  (change)="calculateRowWorkTime(row.id)" />
              </div>

              <div class="uk-width-1-1" *ngIf="!row.extraRest">
                <select class="uk-select prama-input floatLeft w-80 margin-aux" [name]="'mood-work-' + row.id" (change)="moodChanged(4)"
                  [value]="this.sessionValues.moods[getMoodIndexByRound(row.id)].moodId" [(ngModel)]="this.sessionValues.moods[getMoodIndexByRound(row.id)].moodId">
                  <option value="0"> - </option>
                  <option *ngFor="let mood of moodsAvailable" [value]="mood.id">{{mood.name}}</option>
                </select>
                <div class="mood-preview mt-1 ml-1 floatLeft" [ngClass]="this.currentRestMoodPreviewClass"> </div>
              </div>

            </div>
          </td>
          <td>
            <div class="uk-flex uk-flex-between">
              <div class="uk-width-1-1 width100 uk-margin-right">
                <input type="number" class="uk-input prama-input" min="10" step="5" *ngIf="(this.sessionValues.selectedWorkoutModeId !== 2) || (this.sessionValues.selectedWorkoutModeId === 2 && i+1 !== this.sessionValues.sessionRows.length-1)"
                  [id]="'input-rest-' + row.id" [name]="'input-rest-' + row.id" [value]="row.rest" [(ngModel)]="row.rest"
                  (change)="calculateRowRestTime(row.id, i === this.sessionValues.sessionRows.length-2)" />
              </div>

              <div class="uk-width-1-1">
                <select class="uk-select prama-input floatLeft w-80 margin-aux" name="select-mood-rest" (change)="moodChanged(4)"
                  [value]="this.sessionValues.moods[3].moodId" [(ngModel)]="this.sessionValues.moods[3].moodId">
                  <option value="0"> - </option>
                  <option *ngFor="let mood of moodsAvailable" [value]="mood.id">{{mood.name}}</option>
                </select>
                <div class="mood-preview mt-1 ml-1 floatLeft" [ngClass]="this.currentRestMoodPreviewClass"> </div>
              </div>

            </div>
          </td>
          <td class="center-text">
            <label>{{ row.time | timeToMinutes }}</label>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="footerRound">
          <td>Total time for {{ this.stationsAmount }} stations</td>
          <td>{{ this.sessionValues.totalWorkTime | timeToMinutes }}</td>
          <td>{{ this.sessionValues.totalRestTime | timeToMinutes }}</td>
          <td>{{ this.sessionValues.totalTime | timeToMinutes }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</div> -->