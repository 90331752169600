import { Component, OnInit, TemplateRef, Inject, NgZone, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

import { ENUMS } from '../../../assets/enums';
import { ISession } from '../../interfaces/models/i-session';

import { HomeFitnessSessionService } from '../../services/home-fitness-session.service';
import { NotificationService } from '../../services/notification.service';

@Component({
    selector: 'app-modal-home-fitness-remove-session',
    templateUrl: 'modal-home-fitness-remove-session.html',
})
export class ModalHomeFitnessRemoveSession {
    message: string;
    title: string;
    session: ISession;
    canDelete: boolean;

    @Output() onDelete = new EventEmitter<ISession>();
    
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalHomeFitnessRemoveSession>,
        private hfSessionService: HomeFitnessSessionService,
        private notificationService: NotificationService,
        private zone: NgZone,
        public router: Router) {

        this.canDelete = data.canDelete;
        this.session = data.session;

        switch(this.session.type){
            case ENUMS.homeFitnessSessionTypes.warmup:
              this.title = "WARM UP";
              break;
            case ENUMS.homeFitnessSessionTypes.session:
              this.title = "SESSION";
              break;
            case ENUMS.homeFitnessSessionTypes.cooldown:
              this.title = "COOLDOWN";
              break;
            default: break;
          }

        if(this.canDelete) {
            this.message = "You cannot undo this action. Would you continue?";
        } else {
            this.message = "You cannot delete a session included on a scheduled or shared package.";
        }
        
        this.session = data.session;
    }

    removeSession(session: ISession) {
        this.deleteSession(session);
        this.dialogRef.close();
    }

    modalRemoveSessionCancel() {
        this.dialogRef.close();
    }

    public deleteSession(session?: ISession) {
        const sessionToDelete = session ? session : this.session;
        this.hfSessionService.deleteSession(session).subscribe(
            result => {
                this.notificationService.notify("Session deleted successfully",
                    ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
                this.onDelete.emit(session);
            },
            error => {
                console.log('Error', error);
            }
        );
    }
}

