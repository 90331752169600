import { Injectable } from '@angular/core';
import { SessionService } from '../session.service';
import { HomeFitnessSessionService } from '../home-fitness-session.service';
import { HomeFitnessWorkoutService } from '../home-fitness-workout.service';
import { ActivatedRouteSnapshot } from '@angular/router';

import { of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HomeFitnessWorkoutResolver {

  constructor(private workoutService: HomeFitnessWorkoutService) { }

    resolve(route: ActivatedRouteSnapshot) {
      if (route.paramMap.get('workoutId') !== 'new') {
        return this.workoutService.getWorkout(Number(route.paramMap.get('workoutId'))).catch(() => {
          return of('error');
        });
      } else {
        return of('new');
      }
    }

    
}
