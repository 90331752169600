import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Observable";

import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";

import { ISession } from "../interfaces/models/i-session";

import { Cacheable } from 'ngx-cacheable';

@Injectable()
export class BlockSessionService {

  private urlGetSession: string;
  private urlGetSessions: string;
  
  private urlGetExecutionModes: string;

  private urlPostSession = CONFIG.serverUrl + ENDPOINTS.session.post;
  private urlPutSession = CONFIG.serverUrl + ENDPOINTS.session.put;

  constructor(private http: HttpClient) {
    this.urlGetSession = CONFIG.serverUrl + ENDPOINTS.session.get;
    this.urlGetSessions = CONFIG.serverUrl + ENDPOINTS.sessions.get;
    this.urlGetExecutionModes = CONFIG.serverUrl + ENDPOINTS.executionModes.get;
  }

  //@Cacheable()
  public getSessions(): Observable<any> {
    return this.http.get(this.urlGetSessions);
  }

  public getExecutionModes(): Observable<any> {
    return this.http.get(this.urlGetExecutionModes);
  }

  @Cacheable()
  public getSession(sessionId: number): Observable<any> {
    this.urlGetSession = CONFIG.serverUrl + ENDPOINTS.session.get
    this.urlGetSession = this.urlGetSession.replace(":id", sessionId.toString());
    return this.http.get(this.urlGetSession);
  }

  public putSession(session: ISession): Observable<any> {
    const data = session;
    return this.http.put(this.urlPutSession, data);
  }

  public postSession(session: ISession): Observable<any> {
    const data = session;
    return this.http.post(this.urlPostSession, data);
  }
}
