<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
    <div fxLayout="column" fxFlex="20" fxFlexAlign="stretch">
      <songs-filter [songList]="songsToShow" [filteredSongList]="songs" [programs]="programs" 
        [phases]="phases" (filterChange)="updateTableData()"></songs-filter>
    </div>
    <div fxLayout="column" fxFlex="80" fxFlexAlign="stretch">
  
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex="60">
          <h4 fxFlex="20">{{ songs?.length }} songs </h4>
          <div *ngIf="playingSongId !== 0">
            <mat-icon>play_arrow</mat-icon> 
            NOW PLAYING - {{playingSong?.title}}
            <button mat-icon-button (click)="stopSong(song)">
              <mat-icon>stop</mat-icon>
            </button>
          </div>
        </div>
        <button [routerLink]="['/music/songs/upload']" class="uk-button prama-button medium-button right">
          <i class="fa fa-plus-circle faSize"></i>
        </button>
      </div>

      <div class="ml10">
        <mat-table #table [dataSource]="dataSource" matSort class="exercise-table" *ngIf="songs?.length !== 0">
            
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"> Id </mat-header-cell>
            <mat-cell *matCellDef="let song" fxFlex="5%">
              {{song.id}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="player">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="3%"> </mat-header-cell>
            <mat-cell *matCellDef="let song" fxFlex="3%">
              <button mat-icon-button (click)="playSong(song)" *ngIf="playingSongId !== song.id">
                <mat-icon>play_arrow</mat-icon>
              </button>
              <button mat-icon-button (click)="stopSong(song)" *ngIf="playingSongId === song.id">
                <mat-icon>stop</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Title </mat-header-cell>
            <mat-cell *matCellDef="let song">
              <h4 [title]="song.title">{{song.title}}</h4>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="usedTimes">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"> Used times </mat-header-cell>
            <mat-cell *matCellDef="let song" fxFlex="5%">
            {{  song.usedTimes}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="duration">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"> Secs </mat-header-cell>
            <mat-cell *matCellDef="let song" fxFlex="5%">
              {{song.duration}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="provider">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10%"> provider </mat-header-cell>
            <mat-cell *matCellDef="let song" fxFlex="10%">
              <ng-container *ngIf="editMode != song.id">
                {{song.provider?.name}}
              </ng-container>
              <ng-container *ngIf="editMode == song.id">
                <mat-select [(ngModel)]="song.provider" name="providers" placeholder="PROVIDER" [compareWith]="compare">
                  <mat-option *ngFor="let provider of providers" [value]="provider" >{{provider.name}}</mat-option>
                </mat-select>
              </ng-container>
            </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="program">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10%"> Program </mat-header-cell>
            <mat-cell *matCellDef="let song" fxFlex="10%">
              <span *ngFor="let program of song.programs; let last = last;">
                {{program.name}}<span *ngIf="!last"> | </span>
              </span>
            </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="phase">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10%"> Phase </mat-header-cell>
            <mat-cell *matCellDef="let song" fxFlex="10%">
              <span *ngFor="let musicPhase of song.phases; let last = last;">
                {{musicPhase.phase.name}}<span *ngIf="!last"> | </span>
              </span>
            </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"> Status </mat-header-cell>
            <mat-cell *matCellDef="let song" fxFlex="5%">
              <mat-slide-toggle [ngClass]="{'disabled-element': editMode !== song.id}"
                [checked]="song.active">
            </mat-slide-toggle>
            </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="favourite">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="3%"> fav </mat-header-cell>
            <mat-cell *matCellDef="let song" fxFlex="3%" fxFlexAlign="center center">
              <button mat-icon-button (click)="toggleSongFav(song)" *ngIf="editMode === song.id">
                <mat-icon *ngIf="song.isFavourite">star</mat-icon>
                <mat-icon *ngIf="!song.isFavourite">star_border</mat-icon>
              </button>
              <div class="yellow" *ngIf="editMode != song.id">
                <mat-icon *ngIf="song.isFavourite">star</mat-icon>
                <mat-icon *ngIf="!song.isFavourite">star_border</mat-icon>
              </div>
            </mat-cell>
          </ng-container>
  
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8%"> Actions </mat-header-cell>
            <mat-cell *matCellDef="let song" fxFlex="8%">
              <button mat-icon-button (click)="openEditor(song)" *ngIf="editMode != song.id">
                <mat-icon title="Edit">mode_edit</mat-icon>
              </button>
              <button mat-icon-button (click)="saveSong(song)" *ngIf="editMode == song.id">
                <mat-icon title="Save">save</mat-icon>
              </button>
              <button mat-icon-button (click)="modalRemoveSong(song)" *ngIf="editMode == song.id">
                <mat-icon title="Delete">delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          
          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[15, 25, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>