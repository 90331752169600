import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';

import { MatTableDataSource } from "@angular/material/table";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';

import { IMusicPhase } from '../../interfaces/models/i-music-phase';
import { IProgram } from '../../interfaces/models/i-program';
import { IMusicProvider } from '../../interfaces/models/i-music-provider';
import { IMusicPackage, IMusicPackageDco } from '../../interfaces/models/i-music-package';
import { ISong, ISongSearch } from '../../interfaces/models/i-song';
import { IMusicPackagePhase, IMusicPackagePhaseDco } from '../../interfaces/models/i-music-package-phase';

import { MusicService } from '../../services/music-service';
import { NotificationService } from '../../services/notification.service';
import { ENUMS } from '../../../assets/enums';
import { ModalRemoveMusicPackageComponent } from './modal-remove-music-package.component';


@Component({
  selector: 'music-manager-packages',
  templateUrl: './music-manager-packages.component.html',
  styleUrls: ['./music-manager-packages.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      state('open', style({ transform: 'rotate(-180deg)' })),
      state('closed', style({ transform: 'rotate(0deg)' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MusicManagerPackagesComponent implements OnInit {

  canCreate: boolean = true;
  canModify: boolean = true;
  canDelete: boolean = true;
  newMode: boolean;
  editMode: number

  musicPhases: IMusicPhase[];
  staticMusicPhases: IMusicPhase[];

  public staticPackages: IMusicPackage[];
  packages: IMusicPackage[];

  providers: IMusicProvider[];
  program: IProgram;

  // Static phases
  spDisplayedColumns = ['name', 'lastUpdate', 'songs', 'actions'];
  spDataSource = new MatTableDataSource;

  // Package list
  displayedColumns = ['name', 'lastUse', 'status', 'actions', 'panelActions'];
  dataSource = new MatTableDataSource;
  expandedElement: IMusicPackage | null;

  expandedElementId: number;

  songs: ISong[];
  newMusicPackage: IMusicPackage;
  modalRef: BsModalRef;

  searchSongs: ISongSearch[];
  searchPhase: IMusicPackagePhase;

  constructor(private route: ActivatedRoute, private router: Router, 
    private musicService: MusicService, private notificationService: NotificationService,
    private modalService: BsModalService, public dialog: MatDialog) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.program = this.route.snapshot.data.programs.find(x => x.id == params["programId"]);
    });

    this.musicPhases = this.route.snapshot.data.phases.filter(p => !p.isStatic);
    this.staticMusicPhases = this.route.snapshot.data.phases.filter(p => p.isStatic);

    this.packages = this.route.snapshot.data.packages.filter(p => !p.isStatic);
    this.staticPackages = this.route.snapshot.data.packages.filter(p => p.isStatic);

    this.providers = this.route.snapshot.data.providers;
    this.songs = this.route.snapshot.data.songs.filter(s => {
      return s.programs && s.programs.some(p => p.id == this.program.id);
    });

    this.updateStaticPhasesTableData();
    this.updateTableData();
  }

  
  updateStaticPhasesTableData() {
    this.spDataSource = new MatTableDataSource(this.staticPackages.length > 0 ? this.staticPackages[0].phases : []);
  }


  updateTableData() {
    this.dataSource = new MatTableDataSource(this.packages);
  }


  toggleExpandedElement(element) {

    if(element)
      this.expandedElement = this.expandedElement === element ? null : element
  }


  openCreateMusicPackage() {
    this.editMode = 0;
    if (this.newMode) {
      this.packages = this.packages.filter(x => x.id != 0);
    }
    this.newMode = true;
    this.newMusicPackage = {
      id: 0,
      name: '',
      creationDate: undefined,
      lastUpdate: undefined,
      isStatic: false,
      status: {id: 1, name:"OPEN", description: ''},
      intervals: [],
      phases: this.musicPhases.map( phase => {
        return {
          id: 0,
          packageId: 0,
          phase: phase,
          program: this.program,
          songs: []
        }}),
        programs: [this.program]
    };

    this.packages.unshift(this.newMusicPackage);
    this.dataSource = new MatTableDataSource(this.packages);

    this.toggleExpandedElement(this.newMusicPackage);
  }

  openEdit(element) {
    this.newMode = false;
    this.editMode = element.id;
    if(this.newMode) {
      this.packages = this.packages.filter(x => x.id != 0);
    }
    this.dataSource = new MatTableDataSource(this.packages);
  }

  createMusicPackage(nPackage: IMusicPackage) {
    let nPackageDco: IMusicPackageDco = {
      id: nPackage.id,
      status: nPackage.status.id,
      creationDate: nPackage.creationDate,
      lastUpdate: nPackage.lastUpdate,
      intervals: [],
      isStatic: false,
      name: nPackage.name,
      phases: nPackage.phases.map( p => {
        return {
          id: p.id,
          packageId: p.packageId,
          phaseId: p.phase.id,
          programId: p.program.id,
          songs: p.songs.map(x => x.id)}
      }),
      programs: [this.program.id]
    }; 
    
    this.musicService.createMusicPackage(nPackageDco).subscribe(
      result => {
        this.notificationService.notify("Music package saved successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
        this.resetForm(event);
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  saveMusicPackage(nPackage: IMusicPackage) {
    let nPackageDco: IMusicPackageDco = {
      id: nPackage.id,
      status: nPackage.status.id,
      creationDate: nPackage.creationDate,
      lastUpdate: nPackage.lastUpdate,
      intervals: nPackage.intervals.map( i => {
        return {
          packageId: nPackage.id,
          programId: this.program.id,
          intervalId: i.id
        }
      })
      ,
      isStatic: false,
      name: nPackage.name,
      phases: nPackage.phases.map( p => {
        return {
          id: p.id,
          packageId: p.packageId,
          phaseId: p.phase.id,
          programId: p.program.id,
          songs: p.songs.map(x => x.id)}
      }),
      programs: [this.program.id]
    }; 
    
    this.musicService.updateMusicPackage(nPackageDco).subscribe(
      result => {
        this.notificationService.notify("Music package saved successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
        this.resetForm(event);
      },
      error => {
        console.log('Error', error);
        this.notificationService.notify("Error saving package",
          ENUMS.notification.types.error, ENUMS.notification.positions.custom_responsive);
      }
    );
  }

  resetForm(event) {
    event.stopPropagation();
    this.expandedElementId =  this.expandedElement ? this.expandedElement.id : -1;
    this.musicService.getMusicProgramPackages(this.program.id).subscribe(
      result => {
        this.packages = result.filter((p: IMusicPackage) => !p.isStatic);
        this.staticPackages = result.filter((p: IMusicPackage) => p.isStatic);

        this.editMode = 0;
        this.newMode = false;
        
        this.updateStaticPhasesTableData();
        this.updateTableData();
        
        let xx = this.packages.find(x => x.id === this.expandedElementId);
        this.toggleExpandedElement(xx)
      },
      error => {
        console.log('Error');
      }
    );
  }

  modalRemoveMusicPackage(packageToDelete) {
    let modal = this.dialog.open(ModalRemoveMusicPackageComponent, {
      data: {
        package: packageToDelete
      }
    });
    
    modal.componentInstance.onDelete.subscribe( val => {
      this.packages = this.packages.filter(p => !val.id);
      this.updateTableData();
    })
  }


  openSongsModal(packagePhase: IMusicPackagePhase, template) {
    this.searchSongs = [];
    this.songs.forEach(s => {
      if(s.phases && s.phases.some(x => x.id == packagePhase.phase.id)) {
        this.searchSongs.push( {...s, selected: packagePhase.songs.some(ps => ps.id == s.id)});
      }
    });
    this.searchPhase = packagePhase;
    this.modalRef = this.modalService.show(template);
  }


  savePhase(phase: IMusicPackagePhase) {


    let dcoPhase: IMusicPackagePhaseDco = {
      id: phase.id,
      packageId: phase.packageId,
      phaseId: phase.phase.id,
      programId: phase.program.id,
      songs: phase.songs.map(x => x.id)
    }

    this.musicService.updateMusicPackagePhase(dcoPhase).subscribe(
      result => {
        this.resetForm(event);
      },
      error => {
        console.log('Error', error);
        this.notificationService.notify("Error saving phase",
          ENUMS.notification.types.error, ENUMS.notification.positions.custom_responsive);
      }, 
      ()  => {
        this.cancelSearch();
      }
    );

  }

  checkPackageStatus(packageId) {
    let cpackage = this.packages.find(x => x.id === packageId);

    if(cpackage.phases.every(x => x.phase.neededSongs === x.songs.length))
    {
      //cpackage.status = {};
    }
    else
    {

    }

  }


  cancelSearch() {
    this.modalRef.hide()
  }
}