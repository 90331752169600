import { Component, OnInit, TemplateRef, Inject, NgZone, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MusicService } from '../../services/music-service';
import { ISong } from '../../interfaces/models/i-song';
import { NotificationService } from '../../services/notification.service';
import { ENUMS } from '../../../assets/enums';
import { IMusicPackage } from '../../interfaces/models/i-music-package';

@Component({
    selector: 'app-modal-remove-music-package',
    templateUrl: 'modal-remove-music-package.html',
})
export class ModalRemoveMusicPackageComponent {

    packageToDelete: IMusicPackage;
    @Output() onDelete = new EventEmitter<IMusicPackage>();
    
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalRemoveMusicPackageComponent>,
        private musicService: MusicService,
        private notificationService: NotificationService,
        private zone: NgZone,
        public router: Router) {
        this.packageToDelete = data.package;
    }

    removePackage() {
        this.musicService.deleteMusicPackage(this.packageToDelete.id).subscribe(
            result => {
                this.notificationService.notify("Package deleted successfully",
                    ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
                this.onDelete.emit(this.packageToDelete);
            },
            error => {
                this.notificationService.notify("Error deleting package",
                    ENUMS.notification.types.error, ENUMS.notification.positions.custom_responsive);
                console.log('Error', error);
            }
        );
    }

    modalRemovePackageCancel() {
        this.dialogRef.close();
    }

    public deletePackage(cpackage) {
        
    }
}

