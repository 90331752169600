<div [@panelAnim]="panelStatus" class="expandable-info">
  <!-- <form [formGroup]="form"> -->
  <div *ngFor="let question of questions">
    <app-question [question]="question" [form]="form"></app-question>
  </div>
  <!-- </form> -->
</div>

<div class="expand-button" (click)="togglePanel()">
  <mat-icon *ngIf="panelStatus=='closed'">keyboard_arrow_down</mat-icon>
  <mat-icon *ngIf="panelStatus=='opened'">keyboard_arrow_up</mat-icon>
</div>