import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ExerciseService } from '../exercise.service';

import { of } from "rxjs";


@Injectable()
export class ExercisesResolver implements Resolve<Observable<string>> {

    constructor(private exerciseService: ExerciseService) { }

    resolve() {
        return this.exerciseService.getExercises2().catch(() => {
            return of('error');
        });
    }

}

