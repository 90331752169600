import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";

import { ILevel } from "../interfaces/models/i-level";
import { IIntensityLevel } from "../interfaces/models/i-intensity-level";
import { IComplexityLevel } from "../interfaces/models/i-complexity-level";

import { Cacheable } from 'ngx-cacheable';

@Injectable()
export class LevelService {

  private urlGetIntensityLevels: string;
  private urlGetComplexityLevels: string;

  private _levels: ILevel[];
  private _intensityLevels: IIntensityLevel[];
  private _complexityLevels: IComplexityLevel[];

  constructor(private http: HttpClient) {
    this.urlGetIntensityLevels = CONFIG.serverUrl + ENDPOINTS.intensityLevels.get;
    this.urlGetComplexityLevels = CONFIG.serverUrl + ENDPOINTS.complexityLevels.get;
  }

  public levels() {
    return this._levels;
  }
  public intensityLevels() {
    return this._intensityLevels;
  }
  public complexityLevels() {
    return this._complexityLevels;
  }

  getLevels() {
    const url = CONFIG.serverUrl + ENDPOINTS.levels.get;
    return new Promise((resolve, reject) => {
      if (this._levels && this._levels.length > 0) {
        if (CONFIG.traceLoads) {
          console.log("levels load ok - cache");
        }
        return resolve(true);
      }
      this.http.get(url)
        .catch((error) => Observable.throw(error))
        .subscribe(
          (response: ILevel[]) => {
            if (CONFIG.traceLoads) {
              console.log("levels load ok");
            }
            this._levels = response;
            resolve(true);
          },
          error => {
            if (CONFIG.traceLoads) {
              console.log("levels load fail");
            }
            reject(error);
          }
        );
    });
  }

  // getIntensityLevels() {
  //   const url = CONFIG.serverUrl + ENDPOINTS.intensityLevels.get;
  //   return new Promise((resolve, reject) => {
  //     if (this._intensityLevels && this._intensityLevels.length > 0) {
  //       if (CONFIG.traceLoads) {
  //         console.log("intensityLevels load ok - cache");
  //       }
  //       return resolve(true);
  //     }
  //     this.http.get(url)
  //       .catch((error) => Observable.throw(error))
  //       .subscribe(
  //         (response: IIntensityLevel[]) => {
  //           if (CONFIG.traceLoads) {
  //             console.log("intensityLevels load ok");
  //           }
  //           this._intensityLevels = response;
  //           resolve(true);
  //         },
  //         error => {
  //           if (CONFIG.traceLoads) {
  //             console.log("intensityLevels load fail");
  //           }
  //           reject(error);
  //         }
  //       );
  //   });
  // }

  // getComplexityLevels() {
  //   const url = CONFIG.serverUrl + ENDPOINTS.complexityLevels.get;
  //   return new Promise((resolve, reject) => {
  //     if (this._complexityLevels && this._complexityLevels.length > 0) {
  //       if (CONFIG.traceLoads) {
  //         console.log("complexityLevels load ok - cache");
  //       }
  //       return resolve(true);
  //     }
  //     this.http.get(url)
  //       .catch((error) => Observable.throw(error))
  //       .subscribe(
  //         (response: IComplexityLevel[]) => {
  //           if (CONFIG.traceLoads) {
  //             console.log("complexityLevels load ok");
  //           }
  //           this._complexityLevels = response;
  //           resolve(true);
  //         },
  //         error => {
  //           if (CONFIG.traceLoads) {
  //             console.log("complexityLevels load fail");
  //           }
  //           reject(error);
  //         }
  //       );
  //   });
  // }

  @Cacheable()
  public getIntensityLevels(): Observable<any> {
    return this.http.get(this.urlGetIntensityLevels);
  }

  @Cacheable()
  public getComplexityLevels(): Observable<any> {
    return this.http.get(this.urlGetComplexityLevels);
  }
}
