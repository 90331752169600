import { Component, OnInit, AfterViewInit } from '@angular/core';
import { IMusicProvider } from '../../interfaces/models/i-music-provider';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { MatTableDataSource } from "@angular/material/table";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MusicService } from '../../services/music-service';
import { ModalRemoveProviderComponent } from './modal-remove-provider.component';
import { NotificationService } from '../../services/notification.service';
import { ENUMS } from '../../../assets/enums';

@Component({
  selector: 'music-manager-providers',
  templateUrl: './music-manager-providers.component.html',
  styleUrls: ['./music-manager-providers.component.scss']
})
export class MusicManagerProvidersComponent implements OnInit {

  canCreate: boolean;
  canModify: boolean;
  canDelete: boolean;
  newMode: boolean;
  editMode: number;

  providers: IMusicProvider[];
  newProvider: IMusicProvider;
  headers = {
    id: "Id",
    name: "Name",
    thumbnail: " ",
    desc: "Description",
    workMode: "Description",
    actions: ""
  };

  displayedColumns = ['id', 'name', 'website', 'actions'];
  dataSource = new MatTableDataSource;

  constructor(private route: ActivatedRoute, private router: Router, 
    private musicService: MusicService, public dialog: MatDialog,
    private notificationService: NotificationService) { 
    this.providers = this.route.snapshot.data.providers;

  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });

    this.canModify = true;
    this.canCreate = true;
    this.canDelete = true;
  }

  ngAfterViewInit() {    
    this.updateTableData();    
  }

  updateTableData(){
    this.dataSource = new MatTableDataSource(this.providers);
  }

  openEditor(provider: IMusicProvider) {
    this.editMode = provider.id;
    if(this.newMode) {
      this.providers = this.providers.filter(x => x.id != 0);
    }
    this.dataSource = new MatTableDataSource(this.providers);
  }
  
  openCreate() {
    this.editMode = 0;
    if (this.newMode) {
      this.providers = this.providers.filter(x => x.id != 0);
    }
    this.newMode = true;
    this.newProvider = {
      id: 0,
      name: '',
      website: ''
    };
    this.providers.unshift(this.newProvider);
    this.dataSource = new MatTableDataSource(this.providers);
  }


  createProvider(provider: IMusicProvider) {
    this.musicService.createProvider(provider).subscribe(
      result => {
        this.notificationService.notify("Provider saved successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
        this.resetForm(event);
      },
      error => {
        console.log('Error', error);
      }
    );
  }


  saveProvider(provider: IMusicProvider) {
    this.musicService.updateProvider(provider).subscribe(
      result => {
        this.notificationService.notify("Provider modified successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
        this.resetForm(event);
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  resetForm(event) {
    event.stopPropagation();
    this.musicService.getProviders().subscribe(
      result => {
        this.providers = result;
        this.editMode = 0;
        this.newMode = false;
        this.dataSource = new MatTableDataSource(this.providers);
      },
      error => {
        console.log('Error');
      }
    );
  }

  modalRemoveProviderOpen(provider: IMusicProvider) {
    
    this.dialog.open(ModalRemoveProviderComponent, {
      data: {
        provider: provider,
        providers: this.providers
      }
    });
    
  }

}
