<div uk-grid class="sequence-header">
  <div class="uk-width-3-5@s">
    <h4>Exercise sequence - <span class="exerciseTitle">{{ this.sequence.exercise.name }}</span></h4>
  </div>
  <div class="uk-width-2-5@s">
    <button class="uk-button prama-button floatRight" title="Save sequence" (click)="saveSequence()"><i class="fa fa-floppy-o faSize"></i></button>
    <button class="uk-button prama-button floatRight mr15" title="Return to exercise" (click)="goBack()"><i class="fa fa-share fa-flip-horizontal faSize"></i></button>
  </div>
</div>
<div uk-grid>
  <div class="uk-width-3-5@m">
    <div class="uk-grid-small" uk-grid>
      <!--<legend *ngIf="this.editingBlock" > Edit step </legend>
      <legend *ngIf="!this.editingBlock" > New step</legend>-->
      <div class="uk-width-2-5">
        <!-- STEP TYPE  -->
        <div class="uk-margin" *ngIf="this.formFields.stepTypeField.visible">
          <span class="uk-form-label yellow">{{ this.formFields.stepTypeField.nameToShow }}</span>
          <span class="uk-form-label error-message"  *ngIf="this.formFields.stepTypeField.error">{{ this.formFields.stepTypeField.errorMessage }}</span>
          <div class="uk-form-controls">
            <select class="uk-select prama-input"
              [id]="this.formFields.stepTypeField.name"
              [name]="this.formFields.stepTypeField.name"
              [required]="this.formFields.stepTypeField.required"
              [disabled]="this.formFields.stepTypeField.disabled"
              [(ngModel)]="this.formValues.stepTypeId"
              (change)="stepTypeChanged()">
                <option *ngFor="let stepType of this.availableValues.stepTypes" [value]="stepType.id">{{stepType.name}}</option>
            </select>
          </div>
        </div>

        <!-- COLOR TYPES -->
        <div class="uk-margin" *ngIf="this.formFields.colorTypeField.visible">
          <span class="uk-form-label yellow">{{ this.formFields.colorTypeField.nameToShow }}</span>
          <span class="uk-form-label error-message"  *ngIf="this.formFields.colorTypeField.error">{{ this.formFields.colorTypeField.errorMessage }}</span>
          <div class="uk-form-controls">
            <select class="uk-select prama-input"
              [id]="this.formFields.colorTypeField.name"
              [name]="this.formFields.colorTypeField.name"
              [required]="this.formFields.colorTypeField.required"
              [disabled]="this.formFields.colorTypeField.disabled"
              [(ngModel)]="this.formValues.colorTypeId"
              (change)="colorTypeChanged()">
              <option *ngFor="let colorType of this.availableValues.colorTypes" [value]="colorType.id">{{colorType.name}}</option>
            </select>
          </div>
        </div>

        <sequence-color-picker
          *ngIf="this.formFields.colorPickerField.visible"
          [multiple]="this.formValues?.stepType?.multiple"
          [colors]="this.availableValues.presetColors"
          [selectedColor]="this.currentColor"
          [colorTiles]="this.colorTiles"
          (selectedColorEvent)="colorSelected($event)">
        </sequence-color-picker>

      <!-- RESPONSE TYPES -->
        <div class="uk-margin" *ngIf="this.formFields.responseTypeField.visible">
          <span class="uk-form-label yellow">{{ this.formFields.responseTypeField.nameToShow }}</span>
          <span class="uk-form-label error-message"  *ngIf="this.formFields.responseTypeField.error">{{ this.formFields.responseTypeField.errorMessage }}</span>
          <div class="uk-form-controls">
            <select class="uk-select prama-input"
              [id]="this.formFields.responseTypeField.name"
              [name]="this.formFields.responseTypeField.name"
              [required]="this.formFields.responseTypeField.required"
              [disabled]="this.formFields.responseTypeField.disabled"
              [(ngModel)]="this.formValues.responseTypeId"
              (change)="responseTypeChanged()">
                <option *ngFor="let responseType of this.availableValues.responseTypes" [value]="responseType.id">{{responseType.name}}</option>
            </select>
          </div>
        </div>

        <div class="uk-margin" *ngIf="this.formFields.timeField.visible">
          <span class="uk-form-label yellow">{{ this.formFields.timeField.nameToShow }}</span>
          <span class="uk-form-label error-message" *ngIf="this.formFields.timeField.error">{{ this.formFields.timeField.errorMessage }}</span>
          <div class="uk-form-controls">
            <input class="uk-input prama-input" type="number" step="50"
              [id]="this.formFields.timeField.name"
              [name]="this.formFields.timeField.name"
              [required]="this.formFields.timeField.required"
              [disabled]="this.formFields.timeField.disabled"
              [min]="this.formFields.timeField.minValue"
              [placeholder]="this.formFields.timeField.placeHolder"
              [(ngModel)]="this.formValues.responseTime">
          </div>
        </div>
        <div class="uk-width-1-1" *ngIf="this.editingBlock">
          <button class="uk-button prama-button floatRight ml-3 mr0" title="Save changes" (click)="addBlock()"><i class="fa fa-floppy-o faSize"></i></button>
          <button class="uk-button prama-button floatRight" title="Cancel" (click)="cancelBlockEditing()"><i class="fa fa-times faSize"></i></button>
        </div>
        <div class="uk-width-1-1" *ngIf="!this.editingBlock">
            <button class="uk-button prama-button floatRight ml-3 mr0" title="Add step" (click)="addBlock()"><i class="fa fa-plus-circle faSize"></i></button>
        </div>
      </div>

      <div class="uk-width-3-5">
        <sequence-combo *ngIf="this.formValues.colorTypeId!==0 && this.formValues.stepTypeId!==0 && this.formFields.stationTilesField.visible"
          [tiles]="this.tiles"
          [color]="this.currentColor"
          [stationId]="this.sequence.exercise.station.id"
          [multiple]="this.formValues?.stepType?.multiple"
          [stationTilesField]="this.formFields.stationTilesField"
          (tileSelected)="tileSelected($event)"
          (tileDeselected)="tileDeselected($event)">
        </sequence-combo>
      </div>

    </div>
  </div>
  <div class="uk-width-2-5@m">

    <div class="uk-grid-small" uk-grid>
      <div class="uk-width-1-2 uk-grid-match" *ngFor="let player of players">
        <sequence-player
          [currentPlayerId]="this.currentPlayerId"
          (selectedPlayer)="selectPlayer($event)"
          (editBlockEvent)="editBlock($event)"
          (deleteBlockEvent)="deleteBlock($event)"
          (clearBlocksEvent)="deleteBlocksFromPlayer($event)"
          [player]="player">
        </sequence-player>
      </div>
    </div>
  </div>
</div>
