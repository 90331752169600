<div class="uk-grid-small uk-grid-match player-container" uk-grid  [ngClass]="{'player-selected': (this.currentPlayerId == this.player.id) }" (click)="selectPlayer()">
  <div class="uk-width-2-2 player-header">
    <div class="uk-width-1-2">
      <legend class="pointer">Player {{ this.player.id }}</legend>
    </div>
    <div class="uk-width-1-2 clear-steps">
      <a hrf="javascript:;" class="floatRight" title="Clear all steps for this player" (click)="clearBlocks()"><i class="fa fa-trash"></i></a>
    </div>
  </div>

  <div class="player-placeholder  uk-width-1-1 " *ngIf="this.player.blocks && this.player.blocks.length > 0">
    <div class="uk-grid-collapse uk-child-width-1-1" uk-grid *ngFor="let block of this.player.blocks; let i=index">
      <div class="player-block-borders">
        <div class="uk-grid-collapse" uk-grid >
          <div class="uk-width-1-6 pl-3"> #{{i+1}}</div>
          <div class="uk-width-1-6">{{block.sequenceType.name}}</div>
          <div class="uk-width-expand">{{getTilesNumbersFromBlock(block)}}</div>
          <div class="uk-width-1-6" [innerHTML]="getResponseIconFromBlock(block)"></div>
          <div class="uk-width-1-6 floatRight">
            <i class="fa fa-trash-o icon-btn floatRight step-icon" (click)="deleteBlock(block.id)"></i>
            <i class="fa fa-edit icon-btn ml-1 floatRight step-icon" (click)="editBlock(block.id)"></i>
          </div>
        </div>
      </div>
      <div>
        <div class="uk-width-1-1 player-color-block"
            [ngClass]="getColorClassFromBlock(block)"
            [ngStyle]="{ 'background': getColorStyleFromBlock(block) }">
        </div>
      </div>
    </div>
  </div>
</div>
