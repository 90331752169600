
<div class="uk-grid-small uk-child-width-1-1" uk-grid>
  <div>
    <span class="uk-form-label yellow">Color</span>
  </div>
  <div>
    <div *ngFor="let tile of this.colorTiles; let i = index">
      <sequence-tile  class="color-tile" [ngClass]="{'tile-selected': (this.colorTiles[i].selected)}"
        (tileClicked)="selectedColorTile(tile)"
        [currentColor]="tile.color"
        [tile]="tile"
        [interactive]="true">
      </sequence-tile>
    </div>
    <sequence-tile class="color-tile" *ngIf="false"
      [tile]="this.testTile"
      [interactive]="true"
      [(colorPicker)]="strSelectedColor"
      [cpAlphaChannel]="'disabled'"
      [cpOutputFormat]="'rgba'"
      (cpInputChange)="onEventLog('cpInputChange', $event)"
      (change)="onEventLog('cpInputChange', $event)"
      [cpPresetColors]="['#0078ff','#ff0000']"
      >
    </sequence-tile>
  </div>
</div>
