<h1 mat-dialog-title>DELETE MUSIC PACKAGE</h1>
<div mat-dialog-content>
  <p>You cannot undo this action. Would you continue?</p>
  <p *ngIf="packageToDelete.intervals.length > 0">
    This package is already scheduled for {{packageToDelete.intervals.length}} intervals:
  </p>
  <ul *ngIf="packageToDelete.intervals.length > 0" class="yellow">
    <li *ngFor="let interval of packageToDelete.intervals">
      {{interval.dateFrom | date:'medium'}} - {{interval.dateTo | date:'medium'}}
    </li>
  </ul>
</div>
<div mat-dialog-actions class="float-right">
  <button mat-button (click)="modalRemovePackageCancel()">CANCEL</button>
  <button mat-button (click)="removePackage()" cdkFocusInitial>YES</button>
</div>