import { Component, OnInit, ViewChild, AfterViewInit, TemplateRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { MatStepper } from "@angular/material/stepper";
import { MatTableDataSource } from "@angular/material/table";

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { IWorkoutMode } from "../../interfaces/models/i-workout-mode";
import { IProgram } from "../../interfaces/models/i-program";
import { ISessionRound } from "../../interfaces/models/i-session-round";
import { IStationSession } from "../../interfaces/models/sessions/i-station-session";
import { IExerciseType } from "../../interfaces/models/i-exercise-type";
import { ILevel } from "../../interfaces/models/i-level";
import { ITag } from "../../interfaces/models/i-tag";
import { IEquipment } from "../../interfaces/models/i-equipment";
import { ISession } from "../../interfaces/models/i-session";
import { IMood } from "../../interfaces/models/i-mood";
import { IPlaylist } from "../../interfaces/models/i-playlist";
import { IExercise, IExerciseSessionTable } from "../../interfaces/models/i-exercise";
import { IStation } from "../../interfaces/models/i-station";
import { ISessionAlert } from "../../interfaces/models/i-session-alert";
import { IExerciseMuscularGroup } from "../../interfaces/models/i-exercise-muscular-group";
import { IExerciseDiscipline } from "../../interfaces/models/i-exercise-discipline";

import { SessionService } from "../../services/session.service";
import { NotificationService } from "../../services/notification.service";
import { PlaylistsService } from "../../services/playlists.service";
import { ISessionExercise } from "../../interfaces/models/sessions/i-session-exercise";
import { ISessionPlaylist } from "../../interfaces/models/sessions/i-session-playlist";
import { ExerciseService } from "../../services/exercise.service";
import { TimelineService } from "../../services/timeline.service";

import { CONFIG } from "../../../assets/config";
import { ENUMS } from "../../../assets/enums";


@Component({
  selector: "finisher-form",
  templateUrl: "./finisher-form.component.html",
  styleUrls: ["./finisher-form.component.css"]
})
export class FinisherFormComponent implements OnInit, AfterViewInit {

  @ViewChild('siteForm') siteForm: any;
  @ViewChild('stepper', {static: true}) stepper: MatStepper;

  DEFAULT_WORK_TIME: number = 40;
  MOOD_OFF_ID: number = 11;
  DEFAULT_REST_TIME: number = 10;
  DEFAULT_EXTRAREST_TIME: number = 60;
  SERIE_TXT: string = "Serie";
  ROUND_TXT: string = "Round";
  SET_TXT: string = "Set";

  __session: ISession = {
    id: 0,
    name: null,
    creationDate: new Date(),
    lastUpdate: new Date(),
    program: { id: 0, name: "", idDemo: 0, image: "", type: 0, enableHr: true, description: '' },
    isPreset: true,
    userId: null,
    gymId: null,
    origin: 2,
    pdf: "",
    description: null,
    workoutMode: null,
    playlists: [
      { id: 2, session: 0, playlist: null, mode: 2, round: null },
      { id: 3, session: 0, playlist: null, mode: 3, round: null },
      { id: 4, session: 0, playlist: null, mode: 4, round: null },
      { id: 5, session: 0, playlist: null, mode: 5, round: null },

      { id: 1003, session: 0, playlist: null, mode: 3, round: 1 },
      { id: 1004, session: 0, playlist: null, mode: 4, round: 1 },
      { id: 1005, session: 0, playlist: null, mode: 5, round: 1 }
    ],
    prepare: 10,
    exercises: [],
    gymExercises: [],
    rounds: [
      { id: 1, session: 0, work: 50, rest: 10, extraRest: 0 }
    ],
    enableReps: false,
    moods: [
      { id: 1, session: 0, mood: this.MOOD_OFF_ID, mode: 1, round: null },
      { id: 2, session: 0, mood: this.MOOD_OFF_ID, mode: 2, round: null },
      { id: 3, session: 0, mood: this.MOOD_OFF_ID, mode: 3, round: null },
      { id: 4, session: 0, mood: this.MOOD_OFF_ID, mode: 4, round: null },
      { id: 5, session: 0, mood: this.MOOD_OFF_ID, mode: 5, round: null },

      { id: 1003, session: 0, mood: this.MOOD_OFF_ID, mode: 3, round: 1 },
      { id: 1004, session: 0, mood: this.MOOD_OFF_ID, mode: 4, round: 1 },
      { id: 1005, session: 0, mood: this.MOOD_OFF_ID, mode: 5, round: 1 }
    ],
    image: "",
    roundInfoTime: 0,
    roundCompletedTime: 0,
    roundPerformanceTime: 0,
    showExercisesWorkTime: 0,
    showExercisesRestTime: 0,
    type: 0,
    finisherType: 0,
    totalTime: "",
    restBetweenSessionRepetitions: 0,
    sessionRepetitions: 1,
    video: undefined,
    changeMusicWithPhase: false
  };
  __moodsAvailable: IMood[];
  __workoutModeTxt = "";
  __workoutModeSubTxt = "";
  __totalRounds: number = 0;
  __commonExtraRest: number = this.DEFAULT_EXTRAREST_TIME;

  public finisherTypes = [];

  isNew: Boolean = false;

  currentStationId: number = 0;
  lastSelectedStationId: number = 0;

  exercises: IExercise[];
  exercisesToSearch: IExercise[];
  exerciseTypesAvailable: IExerciseType[];
  complexityLevelsAvailable: ILevel[];
  tagsAvailable: ITag[];
  public workoutModesAvailable: IWorkoutMode[];
  equipment: IEquipment[];
  moodsAvailable: IMood[];
  stations: IStation[];
  stationsSession: IStationSession[];
  availableStations: IStation[];
  programs: IProgram[];
  muscularGroupsAvailable: IExerciseMuscularGroup[];
  disciplinesAvailable: IExerciseDiscipline[];

  minRest: number;
  currentSession: ISession;
  dataLoaded: boolean;
  stationsAmount: number;
  editingSession: boolean;

  mediaUrl: string = CONFIG.sessionMediaURL;
  exercisesMediaURL: string = CONFIG.exercisesMediaURL;

  currentProgramPlaylists: IPlaylist[];

  ruleCheckAlerts: ISessionAlert[] = [];

  totalExercises: number;

  playlistError: boolean;
  moodsError: boolean;

  // Station list
  exerciseTable: IExerciseSessionTable[];
  displayedColumns = ['stationIcon', 'stationName', 'exerciseName', 'exerciseIntensity', 'exerciseComplexity', 'exerciseDiscipline', 'exerciseMuscularGroup', 'exerciseFundamentalMovement', 'exerciseImage', 'stationOptions'];
  dataSource = new MatTableDataSource;

  modalRef: BsModalRef;

  formSubmitted = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private sessionsService: SessionService,
    private timelineService: TimelineService,
    private playlistsService: PlaylistsService,
    private exerciseService: ExerciseService,
    private modalService: BsModalService
  ) {

    // Delete filter station when entering session detail
    this.exerciseService.resetFilters("station");

  }


  ngOnInit() {
    this.loadData();
    this.loadPlaylists();
    this.changeWorkoutMode(this.__session.workoutMode ? this.__session.workoutMode : this.workoutModesAvailable[0]);
  }

  ngAfterViewInit() {
    this.refreshTableData();
  }

  refreshTableData() {
    this.exerciseTable = [];
    this.availableStations.forEach(station => {
      const newStation: IExerciseSessionTable = {
        station: station,
        exercise: this.getExerciseByStationId(station.id) !== null ? this.getExerciseByStationId(station.id) : null
      }
      // Remove Floor & Vertical if present
      if ((station.id !== 8 && station.id !== 9) && (station.id !== 10 || (station.id === 10 && this.totalExercises >= 8))) {
        this.exerciseTable.push(newStation);
      }
    });

    this.dataSource = new MatTableDataSource(this.exerciseTable);
  }

  loadData() {
    if (this.route.snapshot.data.session === 'new') {
      this.isNew = true;
    } else {
      this.__session = this.route.snapshot.data.session;
      this.__session.exercises.sort((a, b) => {
        if (a.station < b.station)
          return -1;
        if (a.station > b.station)
          return 1;
        return 0;
      });

      this.__session.program = { id: 0, name: "", idDemo: 0, image: "",  type: 0, enableHr: true, description: ''  };
    }
    this.programs = this.route.snapshot.data.programs;
    this.workoutModesAvailable = this.route.snapshot.data.workoutModesAvailable;
    this.moodsAvailable = this.route.snapshot.data.moodsAvailable;
    this.exercises = this.route.snapshot.data.exercises;
    this.stations = this.route.snapshot.data.stations;
    this.muscularGroupsAvailable = this.route.snapshot.data.muscularGroupList;
    this.disciplinesAvailable = this.route.snapshot.data.disciplineList;
    this.finisherTypes = this.timelineService.getTimelineFinisherModes();
    this.__totalRounds = this.__session.rounds.length;

    for (let i = 2; i < 5; i++) {
      if (this.__session.playlists.findIndex(x => x.mode === i) < 0) {
        const newPlaylist: ISessionPlaylist = {
          id: 0, session: this.__session.id, playlist: 0, mode: i, round: 0
        };
        this.__session.playlists.push(newPlaylist);
      }
    }

    for (let i = 3; i <= 5; i++) {
      for (let round of this.__session.rounds) {
        if (this.__session.playlists.findIndex(x => x.mode === i && x.round === round.id) < 0) {
          const newPlaylist: any = {
            id: 0, session: this.__session.id, playlist: 0, mode: i, round: round.id
          };
          this.__session.playlists.push(newPlaylist);
        }
      }
    }
    for (let i = 1; i < 5; i++) {
      if (this.__session.moods.findIndex(x => x.mode === i) < 0) {
        const newMood: any = {
          id: 0, session: this.__session.id, mood: this.MOOD_OFF_ID, mode: i, round: null
        };
        this.__session.moods.push(newMood);
      }
    }

    for (let i = 3; i <= 5; i++) {
      for (let round of this.__session.rounds) {
        if (this.__session.moods.findIndex(x => x.mode === i && x.round === round.id) < 0) {
          const newMood: any = {
            id: 0, session: this.__session.id, mood: 0, mode: i, round: round.id
          };
          this.__session.moods.push(newMood);
        }
      }
    }

    if (this.isNew) {
      const newWorkoutMode: IWorkoutMode = { id: 0, name: "" };
      this.__session.workoutMode = newWorkoutMode;
    }
    
    if (this.isRepsEnabled()) {
      this.__session.enableReps = true;
    }
    
    this.__commonExtraRest = this.__session.rounds[this.__session.rounds.length - 1].extraRest;
    this.totalExercises = this.getExerciseCount() > 7 ? this.getExerciseCount() : 7;    
    this.__session.workoutMode = this.workoutModesAvailable.find(x => x.id === 1);
    this.totalExercises = 1;
    this.changeFinisherType(true);
    this.recalculateTimes();
    this.fillGymExercises();
  }

  fillGymExercises() {
    this.__session.gymExercises.length = 0;
    for (const exercise of this.__session.exercises) {
      this.__session.gymExercises.push(this.exercises.find(x => x.id === exercise.exercise));
    }
  }

  searchExercise(stationId: number, event) {
    this.currentStationId = stationId;
     // Comentado todo el bloque para hacer OVERRIDE del selector de ejercicios por estación
    // var currentStation = this.stations.find(x => x.id == stationId);

    // if (currentStation.type === 2) {
    //   if (this.currentStationId === 6) {
    //     this.exercisesToSearch = this.exercises.filter(x => (x.station !== null && x.station !== undefined && (x.station.id === 6 || x.station.id === 8 || x.station.id === 9)) || x.alwaysVisible);
    //   } else {
    //     this.exercisesToSearch = this.exercises.filter(x => (x.station !== null && x.station.id === stationId) || x.alwaysVisible);
    //   }
    // }
    // else {
    //   this.exercisesToSearch = this.exercises.filter(x => (x.station !== null && (x.station.id === stationId || x.station.typeId === 10)) || x.alwaysVisible);
    // }
    this.exercisesToSearch = this.exercises;
  }

  // Copy an array to another with no reference loss
  assignArray(origin: any[], dest: any[]) {
    origin.length = 0;
    for (const item of dest) {
      origin.push(item);
    }
  }

  // Changes exercises ruleStatus to mark them as 'do not complies with rule'
  markExercisesAsFailed(exercises: IExercise[], ruleTxt: string) {
    for (const exercise of exercises) {
      if (!exercise.ruleStatus) {
        exercise.ruleStatus = {
          ruleAmount: 0,
          rules: []
        };
      }

      if (!exercise.ruleStatus.rules.includes(ruleTxt)) {
        exercise.ruleStatus.ruleAmount++;
        exercise.ruleStatus.rules.push(ruleTxt);
      }
    }
  }


  // Counts # of the same property in current session
  countPropertyInSession(params) {
    let count = 0;
    let finalField;

    for (const station of this.__session.gymExercises) {

      if (params.field.indexOf(".") > 0) {
        if (station[params.field.split(".")[0]]) {
          finalField = station[params.field.split(".")[0]][params.field.split(".")[1]];
        }
      } else {
        finalField = station[params.field];
      }

      if (station !== undefined) {
        if (Array.isArray(finalField)) {
          for (const property of finalField) {
            if (property === params.id) {
              count++;
            }
          }
        } else {
          if (finalField === params.id) {
            count++;
          }
        }
      }
    }
    return count;
  }

  getPreviousActiveStation(stationId: number) {
    let order;
    if (this.totalExercises >= 8) {
      order = [1, 2, 3, 4, 5, 6, 7, 10];
    } else {
      order = [1, 2, 3, 4, 5, 6, 7];
    }
    let orderPos = order.findIndex(x => x === stationId);
    do {
      orderPos--;
      if (orderPos < 0) {
        orderPos = order.length - 1;
      }

    } while (this.__session.exercises.filter(x => x.station === order[orderPos])[0] !== undefined && this.__session.exercises.filter(x => x.station === order[orderPos])[0].exercise === undefined);

    return order[orderPos];
  }

  getNextActiveStation(stationId: number) {
    let order;
    if (this.totalExercises >= 8) {
      order = [1, 2, 3, 4, 5, 6, 7, 10];
    } else {
      order = [1, 2, 3, 4, 5, 6, 7];
    }
    let orderPos = order.findIndex(x => x === stationId);
    do {
      orderPos++;
      if (orderPos > order.length - 1) {
        orderPos = 0;
      }

    } while (this.__session.exercises.filter(x => x.station === order[orderPos])[0] !== undefined && 
              this.__session.exercises.filter(x => x.station === order[orderPos])[0].exercise === undefined);

    return order[orderPos];
  }

  countExercisesInSession() {
    let count = 0;
    for (const exercise of this.__session.exercises) {
      if (exercise.exercise !== undefined) {
        count++;
      }
    }
    return count;
  }

  closeModal() {
    //const modal = document.getElementById("modal-container");
    //modal.classList.remove("uk-open");
    //modal.style.display = "none";
  }


  exerciseSelected(exercise: IExercise) {
    this.closeModal();
    let i = this.__session.exercises.findIndex(x => x.station === this.currentStationId);
    let ie = this.__session.gymExercises.findIndex(x => {
      if (x.station) {
        return x.station.id === this.currentStationId
      }
    });

    if (i >= 0) {
      this.__session.exercises[i].exercise = exercise.id;
      this.__session.gymExercises[ie] = exercise;
    } else {
      const newExercise: ISessionExercise = {
        exerciseId: 0,
        exercise: exercise.id,
        reps: 0,
        stationId: this.currentStationId,
        station: this.currentStationId,
        athleteAMRAP: false,
        rookieAMRAP: false
      }
      this.__session.exercises.push(newExercise);
      this.__session.gymExercises.push(exercise);
    }

    // Calculate next station in list
    let stationCounter = this.currentStationId;
    if (!this.isSessionFull()) {
      while (this.getExerciseByStationId(this.getNextActiveStation(stationCounter))) {
        stationCounter = this.getNextActiveStation(stationCounter);
      }
    }
    if (stationCounter !== null) {
      this.lastSelectedStationId = stationCounter;
    }
    this.refreshTableData();
  }

  isSessionFull() {
    return this.__session.gymExercises.length === this.totalExercises;
  }

  removeExercise(stationId) {
    let indexExercise = this.__session.exercises.findIndex(x => x.station === stationId);
    if (indexExercise >= 0) {

      // Remove from gymExercises
      let indexGymExercise = this.__session.gymExercises.findIndex(x => x.id === this.__session.exercises[indexExercise].exercise);
      if (indexGymExercise >= 0) {
        this.__session.gymExercises.splice(indexGymExercise, 1);
      }

      // Remove from exercises
      if (indexExercise >= 0) {
        this.__session.exercises.splice(indexExercise, 1);
      }
    }
    this.refreshTableData();
  }

  searchCancelled() {
    this.closeModal();
  }

  repsModeChanged() {
  
  }

  getMoodIndexByRound(round: number): number {
    return this.__session.moods.findIndex(x => x.round === round) >= 0 ? this.__session.moods.findIndex(x => x.round === round) : 0;
  }

  getMoodIndexByMode(mode: number): number {
    return this.__session.moods.findIndex(x => x.mode === mode) >= 0 ? this.__session.moods.findIndex(x => x.mode === mode) : 0;
  }

  getMoodByRoundAndMode(round: number, mode: number): IMood {
    return this.__session.moods.find(x => x.round === round && x.mode === mode);
  }

  getMoodImageName(mood: IMood): string {
    let moodStr: string;
    if (mood.mood === null) {
      moodStr = "0";
    } else {
      moodStr = mood.mood.toString();
    }
    moodStr += ".png"
    return moodStr;
  }

  getMoodImageFullPath(mood: IMood): string {
    return "assets/moods/" + this.getMoodImageName(mood);
  }

  getPlaylistIndexByMode(mode: number): number {
    return this.__session.playlists.findIndex(x => x.mode === mode) >= 0 ? this.__session.playlists.findIndex(x => x.mode === mode) : 0;
  }

  getPlaylistByRoundAndMode(round: number, mode: number): ISessionPlaylist {
    return this.__session.playlists.find(x => x.round === round && x.mode === mode);
  }

  globalMoodChange($event?) {
    for (let round of this.__session.rounds) {
      for (let mode = 3; mode <= 5; mode++) {
        //if (this.getMoodByRoundAndMode(round.id, mode).mood === this.MOOD_OFF_ID || this.getMoodByRoundAndMode(round.id, mode).mood === 0) {
        if (mode !== 5) {
          this.getMoodByRoundAndMode(round.id, mode).mood = this.getMoodByRoundAndMode(null, mode).mood;
        } else {
          this.getMoodByRoundAndMode(round.id, mode).mood = this.getMoodByRoundAndMode(null, 4).mood;
        }
        //}
      }
    }
  }
  globalPlaylistChange($event?) {
    for (let round of this.__session.rounds) {
      for (let mode = 3; mode <= 5; mode++) {
        let newPlRoundToSet = this.getPlaylistByRoundAndMode(null, mode);
        let roundPlToSet = this.getPlaylistByRoundAndMode(round.id, mode);
        if(newPlRoundToSet !== undefined) {
          roundPlToSet.playlist = newPlRoundToSet.playlist;
        }
      }
    }
  }

  public changeWorkoutMode(workoutMode: IWorkoutMode) {
    if (this.__session) {
      this.__session.workoutMode = workoutMode;
      //this.__session.rounds[this.__session.rounds.length - 1].extraRest = 0;

      switch (this.__session.workoutMode.id) {
        case 1:
          this.__workoutModeTxt = this.ROUND_TXT;
          this.__workoutModeSubTxt = this.ROUND_TXT;
          break;
        case 2:
        case 3:
          this.__workoutModeTxt = this.SET_TXT;
          this.__workoutModeSubTxt = this.SERIE_TXT;
          break;
      }
      this.refreshTimes();
    }
  }

  changeTotalRounds(change: number) {
    this.__totalRounds += change;
    this.__totalRounds = this.__totalRounds < 1 ? 1 : this.__totalRounds;

    if (this.__totalRounds > this.__session.rounds.length) {
      const newRound: ISessionRound = {
        id: this.__session.rounds.length + 1,
        session: this.__session.id,
        work: this.DEFAULT_WORK_TIME,
        rest: this.DEFAULT_REST_TIME,
        extraRest: this.DEFAULT_EXTRAREST_TIME
      };
      this.__session.rounds.push(newRound);
      //this.__session.rounds[this.__session.rounds.length - 1].extraRest = this.DEFAULT_EXTRAREST_TIME;
      //this.__session.rounds[this.__session.rounds.length - 1].extraRest = this.__session.rounds[this.__session.rounds.length - 2].extraRest;
      this.__session.rounds.forEach((round, index) => {
        if (index !== this.__session.rounds.length - 1) {
          round.extraRest = round.extraRest > 0 ? round.extraRest : this.DEFAULT_EXTRAREST_TIME;
        } else {
          round.extraRest = 0;
        }
      });

      this.__session.moods.push({ id: this.__session.rounds.length * 1000 + 3, session: this.__session.id, mood: this.getMoodByRoundAndMode(null, 3).mood, mode: 3, round: this.__session.rounds.length });
      this.__session.moods.push({ id: this.__session.rounds.length * 1000 + 4, session: this.__session.id, mood: this.getMoodByRoundAndMode(null, 4).mood, mode: 4, round: this.__session.rounds.length });
      this.__session.moods.push({ id: this.__session.rounds.length * 1000 + 5, session: this.__session.id, mood: this.getMoodByRoundAndMode(null, 4).mood, mode: 5, round: this.__session.rounds.length });

      this.__session.playlists.push({ id: this.__session.rounds.length * 1000 + 3, session: this.__session.id, playlist: this.getPlaylistByRoundAndMode(0, 3) === undefined ? this.getPlaylistByRoundAndMode(null, 3).playlist : this.getPlaylistByRoundAndMode(0, 3).playlist, mode: 3, round: this.__session.rounds.length });
      this.__session.playlists.push({ id: this.__session.rounds.length * 1000 + 4, session: this.__session.id, playlist: this.getPlaylistByRoundAndMode(0, 4) === undefined ? this.getPlaylistByRoundAndMode(null, 4).playlist : this.getPlaylistByRoundAndMode(0, 4).playlist, mode: 4, round: this.__session.rounds.length });
      this.__session.playlists.push({ id: this.__session.rounds.length * 1000 + 5, session: this.__session.id, playlist: this.getPlaylistByRoundAndMode(0, 5) === undefined ? this.getPlaylistByRoundAndMode(null, 5).playlist : this.getPlaylistByRoundAndMode(0, 5).playlist, mode: 5, round: this.__session.rounds.length });
    }
    if (this.__totalRounds < this.__session.rounds.length) {
      this.__session.rounds.pop();
      this.__session.rounds[this.__session.rounds.length - 1].extraRest = 0;
      //for(let i=0; i<3; i++){
      this.__session.moods.splice(-3, 3);
      this.__session.playlists.splice(-3, 3);
      //}
    }
  }


  recalculateTimes() {
    this.calculateTotalRestTime();
    this.calculateTotalWorkTime();
    this.calculateTotalTime();
  }


  onSubmit() {
    let formError: boolean = false;
    this.formSubmitted = true;

    this.checkMoods();

    if (!this.siteForm.form.valid || !this.allMoodsFilled() || !this.allPlaylistsFilled() || !this.workoutModeFilled() || !this.exercisesFilled() ) {
      window.scroll(0, 0);
    } else {
      if (this.isNew) {
        this.createSession();
      } else {
        this.saveSession();
      }
    }
  }

  private checkMoods() {
    this.__session.moods.forEach((currentValue, index) => {
      if (currentValue.mood === 0) {
        this.__session.moods.splice(index, 1);
      }
    });
  }


  public saveSession() {

    // Clean temp properties
    let sendSession: ISession;
    sendSession = Object.assign({}, this.__session);
    delete sendSession.gymExercises;
    sendSession.type = 1;

    // Set ENERGY_GT_REST playlist by default for rest and extrarest phases
    if (sendSession.finisherType === ENUMS.finisherTypes.challenge || sendSession.finisherType === ENUMS.finisherTypes.time) {
      const restPlaylist = sendSession.playlists.find(x => x.mode === 4);
      const eRestPlaylist = sendSession.playlists.find(x => x.mode === 5);
      restPlaylist.playlist = 9325;
      eRestPlaylist.playlist = 9325;
    }
    
    sendSession.playlists  = sendSession.playlists.filter(x => x.playlist != null);

    this.sessionsService.putSession(sendSession).subscribe(
      result => {
        this.notificationService.notify("Session saved successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
      },
      error => {
        console.log('Error', error);
        this.formSubmitted = false;
        this.stepperGoFirst();
      }
    );
  }

  public createSession() {
    // Clean temp properties
    let sendSession: ISession;
    sendSession = Object.assign({}, this.__session);
    delete sendSession.gymExercises;
    
    sendSession.type = 1;
    
    // Set ENERGY_GT_REST playlist by default for rest and extrarest phases
    if (sendSession.finisherType === ENUMS.finisherTypes.challenge || sendSession.finisherType === ENUMS.finisherTypes.time) {
      const restPlaylist = sendSession.playlists.filter(x => x.mode === 4);
      const eRestPlaylist = sendSession.playlists.filter(x => x.mode === 5);
      restPlaylist.forEach( x => x.playlist = 9325);
      eRestPlaylist.forEach( x => x.playlist = 9325);
    }
    
    sendSession.playlists  = sendSession.playlists.filter(x => x.playlist != null);

    this.sessionsService.postSession(sendSession).subscribe(
      result => {
        //if (result) {
        this.notificationService.notify("Session created successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
        this.router.navigate(["/finishers"]);
        //}
      },
      error => {
        console.log('Error', error);
        this.formSubmitted = false;
        this.stepperGoFirst();
      }
    );
  }

  changeFinisherType(loadData) {
    if(!loadData)
      this.__session.exercises = [];

    switch(this.__session.finisherType){
      case 0: // challenge
          this.totalExercises = 1;
          this.availableStations = this.stations.filter(x => x.type === 2);
          break;
      case 1: // time
          this.totalExercises = 1;
          this.availableStations = this.stations.filter(x => x.id === 1);
          if(!loadData) {
            const newExercise: ISessionExercise = {
              exerciseId: this.exercises.find(e => e.name.toUpperCase() === 'FREE EXERCISE').id,
              exercise: this.exercises.find(e => e.name.toUpperCase() === 'FREE EXERCISE').id,
              reps: 0,
              stationId: 1,
              station: 1,
              athleteAMRAP: false,
              rookieAMRAP: false
            }
            this.__session.exercises.push(newExercise);
          }
          break;
      case 2: // rounds
          this.totalExercises = 7;
          this.availableStations = this.stations;
          break;
      default:
          this.totalExercises = 1;
          break;
    }
    this.refreshTableData();
  }

  imageSelected($event) {
    this.__session.image = $event;
  }

  imageRemoved() {

  }

  loadPlaylists() {
    if (this.__session) {
      this.playlistsService.getPlaylists(this.__session.program.id).subscribe(
        response => {
          if (response) {
            this.currentProgramPlaylists = response;
          }
        }, error => {
          this.notificationService.notify("An error occurred while retrieving playlists",
            ENUMS.notification.types.error, ENUMS.notification.positions.custom_responsive);
        }
      );
    }
  }

  refreshTimes() {
    if (this.__session.workoutMode.id >= 2 && this.__session.workoutMode.id <= 3) {
      for (const round of this.__session.rounds) {
        round.extraRest = this.__commonExtraRest;
      }
    }
  }

  getExercise(exerciseId: number): IExercise {
    return this.__session.gymExercises.filter(x => x.id === exerciseId)[0];
  }

  getExerciseByStationId(stationId: number): IExercise {
    const exercise = this.__session.exercises.filter(x => x.station === stationId)[0];
    let filtered;
    if (exercise !== undefined) {
      filtered = this.exercises.find(x => x.id === exercise.exercise || x.id === Number(exercise.exercise));
    }
    return filtered ? filtered : null;
  }

  getSessionExerciseByStationId(stationId: number): ISessionExercise {
    return this.__session.exercises.find(x => x.station === stationId);
  }

  isRepsEnabled(): boolean {
    for (let ex of this.__session.exercises) {
      if (ex.reps > 0) {
        return true;
      }
    }
    return false;
  }


  // Returns total round time
  calculateRoundTime(round: ISessionRound): number {
    let roundTime;
    if (this.__session.workoutMode.id === 1) {
      roundTime = this.getExerciseCount() * round.work + (this.getExerciseCount() - 1) * round.rest;
    }
    if (this.__session.workoutMode.id >= 2 && this.__session.workoutMode.id <= 3) {
      roundTime = this.getExerciseCount() * round.work + this.getExerciseCount() * round.rest;
    }
    return roundTime > 0 ? roundTime : 0;
  }

  // # of exercises, filtered by common stations (wall, floor and combo are the same)
  getExerciseCount() {
    let exerciseCount = this.__session.exercises.filter(x => x.station !== 6 && x.station !== 8 && x.station !== 9 && x.exercise).length;
    exerciseCount = this.__session.exercises.filter(x => (x.station === 6 || x.station === 8 || x.station === 9) && x.exercise).length > 0 ? exerciseCount + 1 : exerciseCount;
    return exerciseCount;
  }

  // Total time of session
  calculateTotalTime(): number {
    return this.calculateTotalWorkTime() + this.calculateTotalRestTime();
  }

  // Total work time in session
  calculateTotalWorkTime(): number {
    let totalTime = 0;
    for (const round of this.__session.rounds) {
      totalTime += this.getExerciseCount() * round.work;
    }
    return totalTime > 0 ? totalTime : 0;
  }

  // Total rest time in session
  calculateTotalRestTime(): number {
    let totalTime = 0;
    if (this.__session.workoutMode.id === 1) {
      for (const round of this.__session.rounds) {
        totalTime += (this.getExerciseCount() - 1) * round.rest;
        if (round.id !== this.__session.rounds[this.__session.rounds.length - 1].id) {
          totalTime += round.extraRest;
        }
      }
    }
    if (this.__session.workoutMode.id >= 2 && this.__session.workoutMode.id <= 3) {
      for (const round of this.__session.rounds) {
        if (round.id !== this.__session.rounds[this.__session.rounds.length - 1].id) {
          totalTime += (this.getExerciseCount() * round.rest);
        }
      }
      totalTime += (this.getExerciseCount() - 1) * this.__session.rounds[0].extraRest;
    }

    return totalTime > 0 ? totalTime : 0;
  }

  private isWorkoutModeChecked(workoutMode: IWorkoutMode) {
    return this.__session.workoutMode.id === workoutMode.id;
  }

  // Aux function for comparison of combobox objects
  compare(val1, val2) {
    if (val1 !== undefined && val2 !== undefined && val1 !== null && val2 !== null) {
      return val1.id === val2.id;
    } else {
      return false;
    }
  }


  // Added as method. maybe we would prevent go back when there are pending changes
  goBack() {
    this.router.navigate(["/sessions"]);
  }

  stepperGoNext() {
    this.stepper.next();
  }
  stepperGoPrevious() {
    this.stepper.previous();
  }

  stepperGoFirst() {
    let x = 0;
    while(x<5){
      this.stepper.previous();
    }
  }

  public isFirstStep() {
    if (this.stepper._steps !== undefined) {
      return this.stepper.selected === this.stepper._steps.first;
    } else {
      return true;
    }
  }

  public isLastStep() {
    
    if (this.stepper._steps !== undefined) {
      return this.stepper.selected === this.stepper._steps.last;
    } else {
      return true;
    }
  }

  public isWorkoutMode(workoutMode: number) {
    return this.__session.workoutMode.id === workoutMode;
  }

  public openSearchModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  public closeSearchModal() {
    this.modalRef.hide();
  }

  public allMoodsFilled(): boolean {
    console.log("checking moods filled");
    
    this.moodsError = !( this.getMoodByRoundAndMode(null, 1).mood > 0 && 
                        (this.__session.finisherType !== 2 || this.__session.finisherType === 2 && this.getMoodByRoundAndMode(null, 1).mood !== 11)
                          && this.getMoodByRoundAndMode(null, 2).mood > 0 && 
                            this.getMoodByRoundAndMode(null, 2).mood !== 11
                          && this.getMoodByRoundAndMode(null, 3).mood > 0 && 
                            this.getMoodByRoundAndMode(null, 3).mood !== 11
                          && this.getMoodByRoundAndMode(null, 4).mood > 0 && 
                          (this.__session.finisherType !== 2 || this.__session.finisherType === 2 && this.getMoodByRoundAndMode(null, 4).mood !== 11));
    return !this.moodsError;
  }

  public allPlaylistsFilled(): boolean {
    let countdownPlaylistRound = this.getPlaylistByRoundAndMode(null, 2);
    let workPlaylistRound = this.getPlaylistByRoundAndMode(null, 3);
    let restPlaylistRound = this.getPlaylistByRoundAndMode(null, 4);
    let extraRestPlaylistRound = this.getPlaylistByRoundAndMode(null, 5);
    console.log("checking playlists filled");

    this.playlistError = !(countdownPlaylistRound != null && countdownPlaylistRound.playlist > 0
    && workPlaylistRound != null && workPlaylistRound.playlist > 0
    &&  (this.__session.finisherType !== 2 || this.__session.finisherType === 2 && restPlaylistRound != null && restPlaylistRound.playlist > 0)
    &&  (this.__session.finisherType !== 2 || this.__session.finisherType === 2 && extraRestPlaylistRound != null && extraRestPlaylistRound.playlist > 0));

    return !this.playlistError;
  }

  public workoutModeFilled(): boolean {
    return this.__session.workoutMode.id > 0;
  }

  public exercisesFilled(): boolean {
    return this.__session.exercises.length > 0;
  }
}
