import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { CONFIG } from "../../assets/config";
import { ENDPOINTS } from "../../assets/endpoints";
import { ISong } from '../interfaces/models/i-song';


@Injectable()
export class SongsService {

    private urlUpdateSong: string;
    private urlDeleteSong: string;


    constructor(public http: HttpClient) {
        this.urlUpdateSong = CONFIG.serverUrl + ENDPOINTS.songs.put;
        this.urlDeleteSong = CONFIG.serverUrl + ENDPOINTS.songs.delete;
    }


    public updateSong(song: ISong): Observable<any> {
        const data = song;
        return this.http.put(this.urlUpdateSong, data);
    }


    public deleteSong(songId): Observable<any> {
        let url = this.urlDeleteSong.replace(":id", songId);
        return this.http.delete(url);
    }   
}
