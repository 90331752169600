<div class="uk-grid-collapse uk-child-width-1-1 uk-flex-center" uk-grid *ngIf="this.stationTilesField.visible">
  <div class="uk-width-1-1" style="text-align: center">
      <span class="uk-form-label error-message" *ngIf="this.stationTilesField.error">{{ this.stationTilesField.errorMessage }}</span>
  </div>
  <div *ngIf="(this.stationId === 6 || this.stationId === 9)">
    <div class="uk-grid-collapse uk-child-width-1-1 mt-2" uk-grid>
      <div>
        <div class="uk-grid-collapse uk-child-width-1-6 uk-flex-center" uk-grid>
          <div> <sequence-tile [tile]="this.tiles[9]" (tileClicked)="tileClicked(this.tiles[9])"></sequence-tile> </div>
          <div> <sequence-tile [tile]="this.tiles[10]" (tileClicked)="tileClicked(this.tiles[10])"></sequence-tile> </div>
          <div> <sequence-tile [tile]="this.tiles[11]" (tileClicked)="tileClicked(this.tiles[11])"></sequence-tile> </div>
        </div>
      </div>
      <div>
        <div class="uk-grid-collapse uk-child-width-1-6 uk-flex-center" uk-grid>
          <div> <sequence-tile [tile]="this.tiles[12]" (tileClicked)="tileClicked(this.tiles[12])"></sequence-tile> </div>
          <div> <sequence-tile [tile]="this.tiles[13]" (tileClicked)="tileClicked(this.tiles[13])"></sequence-tile> </div>
          <div> <sequence-tile [tile]="this.tiles[14]" (tileClicked)="tileClicked(this.tiles[14])"></sequence-tile> </div>
        </div>
      </div>
      <div>
        <div class="uk-grid-collapse uk-child-width-1-6 uk-flex-center" uk-grid>
          <div> <sequence-tile [tile]="this.tiles[15]" (tileClicked)="tileClicked(this.tiles[15])"></sequence-tile> </div>
          <div> <sequence-tile [tile]="this.tiles[16]" (tileClicked)="tileClicked(this.tiles[16])"></sequence-tile> </div>
          <div> <sequence-tile [tile]="this.tiles[17]" (tileClicked)="tileClicked(this.tiles[17])"></sequence-tile> </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="(this.stationId === 6 || this.stationId === 8)">
    <div class="uk-grid-collapse uk-child-width-1-1 mt-2" uk-grid>
      <div>
        <div class="uk-grid-collapse uk-child-width-1-6 uk-flex-center" uk-grid>
          <div> <sequence-tile [tile]="this.tiles[0]" (tileClicked)="tileClicked(this.tiles[0])" ></sequence-tile> </div>
          <div> <sequence-tile [tile]="this.tiles[1]" (tileClicked)="tileClicked(this.tiles[1])"></sequence-tile> </div>
          <div> <sequence-tile [tile]="this.tiles[2]" (tileClicked)="tileClicked(this.tiles[2])"></sequence-tile> </div>
        </div>
      </div>
      <div>
        <div class="uk-grid-collapse uk-child-width-1-6 uk-flex-center" uk-grid>
          <div> <sequence-tile [tile]="this.tiles[3]" (tileClicked)="tileClicked(this.tiles[3])"></sequence-tile> </div>
          <div> <sequence-tile [tile]="this.tiles[4]" (tileClicked)="tileClicked(this.tiles[4])"></sequence-tile> </div>
          <div> <sequence-tile [tile]="this.tiles[5]" (tileClicked)="tileClicked(this.tiles[5])"></sequence-tile> </div>
        </div>
      </div>
      <div>
        <div class="uk-grid-collapse uk-child-width-1-6 uk-flex-center" uk-grid>
          <div> <sequence-tile [tile]="this.tiles[6]" (tileClicked)="tileClicked(this.tiles[6])"></sequence-tile> </div>
          <div> <sequence-tile [tile]="this.tiles[7]" (tileClicked)="tileClicked(this.tiles[7])"></sequence-tile> </div>
          <div> <sequence-tile [tile]="this.tiles[8]" (tileClicked)="tileClicked(this.tiles[8])"></sequence-tile> </div>
        </div>
      </div>
    </div>
  </div>
</div>
