import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";

import { IColor } from "../../interfaces/models/i-color";
import { ISequenceTile } from "../../interfaces/models/sequence/i-sequence-tile";
import { ISequenceConfiguratorTile } from "../../interfaces/models/sequence/form/i-sequence-configurator-tile";

@Component({
  selector: "sequence-tile",
  templateUrl: "./sequence-tile.component.html",
  styleUrls: ["./sequence-tile.component.css"]
})
export class SequenceTileComponent implements OnInit {
  @Input() currentColor: IColor;
  @Input() tile: ISequenceConfiguratorTile;
  @Input() interactive: boolean;

  @Output() tileClicked: EventEmitter<number> = new EventEmitter<number>();

  constructor() {
    this.currentColor = {
      id: 0,
      hex: "#000",
      blue: 0,
      red: 0,
      green: 0
    };
    this.interactive = true;
  }

  ngOnInit() {
    this.currentColor = {
      id: 0,
      hex: "#000",
      blue: 0,
      red: 0,
      green: 0
    };
  }

  clicked() {
    if (this.interactive) {
      this.tileClicked.emit(this.tile.id);
      this.setBgColor();
    }
  }

  public setBgColor() {
    const style = {
      "background-color": this.tile && this.tile.color ? this.tile.color.hex : "transparent",
    };
    return style;
  }
}
