<mat-card class="filter-options-cont padding30" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-between stretch"
  fxFill>
  <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="20px" class="w-100">
    <div class="w-100">
      <mat-form-field class="w-100">
        <input type="text" matInput (keyup)="doSearch()" name="filtersearch" #filtersearch id="filtersearch"
          placeholder="Search id, title" [(ngModel)]="this.musicService.songsFilterSearch">
        <span uk-icon="search" class="search-icon"></span>
      </mat-form-field>
    </div>

    <ul uk-accordion="multiple: false" class="w-100">
      <li>
        <div class="remove-icon" *ngIf="hasFiltersOf('programs')">
          <span uk-icon="trash" (click)="resetFilters('programs')"></span>
        </div>
        <a class="uk-accordion-title" [class.filtered]="hasFiltersOf('programs')" href="#">Programs</a>
        <div class="uk-accordion-content">
          <label *ngFor="let filterItem of getFilterList('programs'); let i = index;">
            <input #filter data-filterfield="programs" type="checkbox" class="uk-checkbox" (change)="applyFilters()"
              [(ngModel)]="filterItem.checked" [value]="true"> {{ filterItem.name }}
          </label>
        </div>
      </li>

      <li>
        <div class="remove-icon" *ngIf="hasFiltersOf('phases')">
          <span uk-icon="trash" (click)="resetFilters('phases')"></span>
        </div>
        <a class="uk-accordion-title" [class.filtered]="hasFiltersOf('phases')" href="#">Phases</a>
        <div class="uk-accordion-content">
          <label *ngFor="let filterItem of getFilterList('phases'); let i = index;">
            <input #filter data-filterfield="phases" type="checkbox" class="uk-checkbox" (change)="applyFilters()"
              [(ngModel)]="filterItem.checked" [value]="true"> {{ filterItem.name }}
          </label>
        </div>
      </li>
    </ul>
  </div>


</mat-card>