<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" class="w-100">
    <div fxLayout="column" fxFlex="33.3333" fxFlexAlign="stretch">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxFlexAlign="stretch" class="w-100">
            <h6>Chains</h6> <span class="small-text yellow" *ngIf="sharedContent.chains.length > 0">({{sharedContent.chains.length}} selected)</span>
        </div>
        <div class="pt-2">
            <div class="shared-content-list">
                <div class="shared-content-divider" *ngIf="getCheckedItems(true, sharedContent.chainList, sharedContent.chains).length > 0">Selected</div>
                <div *ngFor="let chain of getCheckedItems(true, sharedContent.chainList, sharedContent.chains)">
                    <mat-checkbox [checked]="isChecked(chain, sharedContent.chains)" (change)="toggleChecked(chain, sharedContent.chains, $event)" [ngClass]="{'node-selected': chain === selectedChain}">
                        <span (click)="selectChain(chain, $event)">
                            <span class="vertical-align small-text">{{chain.name}} </span>
                            <span class="small-text">
                                ({{getCountChildItemsText(chain.id, sharedContent.gymList, sharedContent.gyms)}})
                                <mat-icon aria-hidden="false" class="vertical-align cursor-pointer"> chevron_right </mat-icon>
                            </span>
                        </span>
                    </mat-checkbox>
                </div>
                <div class="shared-content-divider" *ngIf="getCheckedItems(false, sharedContent.chainList, sharedContent.chains).length > 0">Unselected</div>
                <div *ngFor="let chain of getCheckedItems(false, sharedContent.chainList, sharedContent.chains)">
                    <mat-checkbox [checked]="isChecked(chain, sharedContent.chains)" (change)="toggleChecked(chain, sharedContent.chains, $event)" [ngClass]="{'node-selected': chain === selectedChain}">
                        <span (click)="selectChain(chain, $event)">
                            <span class="vertical-align small-text">{{chain.name}} </span>
                            <span class="small-text">
                                ({{getCountChildItemsText(chain.id, sharedContent.gymList, sharedContent.gyms)}})
                                <mat-icon aria-hidden="false" class="vertical-align cursor-pointer"> chevron_right </mat-icon>
                            </span>
                        </span>
                    </mat-checkbox>
                </div>
            </div>
        </div>

    </div>
    <div fxLayout="column" fxFlex="33.3333" fxFlexAlign="stretch">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxFlexAlign="stretch" class="w-100">
            <h6>Gyms</h6> <span class="small-text yellow" *ngIf="sharedContent.gyms.length > 0">({{sharedContent.gyms.length}} selected)</span>
        </div>
        <div class="pt-2" *ngIf="selectedChain">
            <div class="shared-content-list">
                <div class="shared-content-divider" *ngIf="getCheckedItems(true, getFilteredByParentId(sharedContent.gymList, selectedChain.id), sharedContent.gyms).length > 0">Selected</div>
                <div *ngFor="let gym of getCheckedItems(true, getFilteredByParentId(sharedContent.gymList, selectedChain.id), sharedContent.gyms)">
                    <mat-checkbox [checked]="isChecked(gym, sharedContent.gyms)" (change)="toggleChecked(gym, sharedContent.gyms, $event)" [ngClass]="{'node-selected': gym === selectedGym}">
                        <span (click)="selectGym(gym, $event)">
                            <span class="vertical-align small-text">{{gym.name}} </span>
                            <span class="small-text">
                                ({{getCountChildItemsText(gym.id, sharedContent.userList, sharedContent.users)}})
                                <mat-icon aria-hidden="false" class="vertical-align cursor-pointer"> chevron_right </mat-icon>
                            </span>
                        </span>
                    </mat-checkbox>
                </div>
                <div class="shared-content-divider" *ngIf="getCheckedItems(false, getFilteredByParentId(sharedContent.gymList, selectedChain.id), sharedContent.gyms).length > 0">Unselected</div>
                <div *ngFor="let gym of getCheckedItems(false, getFilteredByParentId(sharedContent.gymList, selectedChain.id), sharedContent.gyms)">
                    <mat-checkbox [checked]="isChecked(gym, sharedContent.gyms)" (change)="toggleChecked(gym, sharedContent.gyms, $event)" [ngClass]="{'node-selected': gym === selectedGym}">
                        <span (click)="selectGym(gym, $event)">
                            <span class="vertical-align small-text">{{gym.name}} </span>
                            <span class="small-text">
                                ({{getCountChildItemsText(gym.id, sharedContent.userList, sharedContent.users)}})
                                <mat-icon aria-hidden="false" class="vertical-align cursor-pointer"> chevron_right </mat-icon>
                            </span>
                        </span>
                    </mat-checkbox>
                </div>
            </div>
        </div>
        <div class="pt-2" *ngIf="!selectedChain">
            <span class="small-text">
                Pick a chain to view gyms
            </span>
        </div>
    </div>
    <div fxLayout="column" fxFlex="33.3333" fxFlexAlign="stretch">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxFlexAlign="stretch" class="w-100">
            <h6>Users</h6> <span class="small-text yellow" *ngIf="sharedContent.users.length > 0">({{sharedContent.users.length}} selected)</span>
        </div>
        <div class="pt-2" *ngIf="selectedGym">
            <div class="shared-content-list">
                <div class="shared-content-divider" *ngIf="getCheckedItems(true, getFilteredByParentId(sharedContent.userList, selectedGym.id), sharedContent.users).length > 0">Selected</div>
                <div *ngFor="let user of getCheckedItems(true, getFilteredByParentId(sharedContent.userList, selectedGym.id), sharedContent.users)">
                    <mat-checkbox [checked]="isChecked(user, sharedContent.users)" (change)="toggleChecked(user, sharedContent.users, $event)">
                        <span (click)="selectUser(user, $event)">
                            <span class="vertical-align small-text">{{user.name}} </span>
                        </span>
                    </mat-checkbox>
                </div>
                <div class="shared-content-divider" *ngIf="getCheckedItems(false, getFilteredByParentId(sharedContent.userList, selectedGym.id), sharedContent.users).length > 0">Unselected</div>
                <mat-form-field>
                    <input matInput placeholder="Search" (input)="onFilterChange($event.target.value)" autocomplete="off" class="small-text">
                    <button matSuffix mat-icon-button aria-label="Clear" type="button">
                        <mat-icon>search</mat-icon>
                    </button>
                    <mat-hint *ngIf="userFilterText.length < 3">
                        Min 3 chars
                    </mat-hint>
                </mat-form-field>
                <div *ngIf="userFilterText.length >= 3">
                    <div *ngFor="let user of getCheckedItems(false, getFilteredByFilterString(getFilteredByParentId(sharedContent.userList, selectedGym.id)), sharedContent.users)">
                        <mat-checkbox [checked]="isChecked(user, sharedContent.users)" (change)="toggleChecked(user, sharedContent.users, $event)">
                            <span (click)="selectUser(user, $event)">
                                <span class="vertical-align small-text">{{user.name}} </span>
                            </span>
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="pt-2" *ngIf="!selectedGym">
            <span class="small-text">
                Pick a gym to view users
            </span>
        </div>
    </div>
</div>