import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { CONFIG } from "../../../assets/config";
import { ENUMS } from "../../../assets/enums";

import { ITimeline, ITimelineActivation, ITimelineProgramFull, IProgramExercises } from "../../interfaces/models/i-timeline";
import { IProgram } from "../../interfaces/models/i-program";
import { IStation } from "../../interfaces/models/i-station";
import { TimelineService } from "../../services/timeline.service";
import { NotificationService } from "../../services/notification.service";

import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: "programs-basic-list",
  templateUrl: "./programs-basic-list.component.html",
  styleUrls: ["./programs-basic-list.component.css"]
})
export class ProgramsBasicListComponent implements OnInit {

  mediaUrl: string = CONFIG.sessionMediaURL;

  headers = {
    id: "Id",
    name: "Name",
    thumbnail: " ",
    creationDate: "Creation date",
    lastUpdate: "Last Update",
    actions: ""
  };

  globalObject: ITimelineProgramFull = null;

  timelines: ITimelineActivation[];
  exercises: IProgramExercises[];
  programsAvailable: IProgram[];
  stationsAvailable: IStation[];

  currentProgramSelected: number = 1;

  @ViewChild("paginatorExercisesActive") paginatorExercisesActive: MatPaginator;
  @ViewChild("paginatorExercisesAvailable") paginatorExercisesAvailable: MatPaginator;
  @ViewChild("paginatorTimelinesActive") paginatorTimelinesActive: MatPaginator;
  @ViewChild("paginatorTimelinesAvailable") paginatorTimelinesAvailable: MatPaginator;

  //displayedColumnsActive = ['id', 'name', 'status', 'activeFrom', 'activeUntil', 'options'];
  displayedColumnsExercisesActive = ['id', 'station', 'name', 'options'];
  displayedColumnsExercisesAvailable = ['id', 'station', 'name', 'options'];
  displayedColumnsTimelinesActive = ['id', 'name', 'options'];
  displayedColumnsTimelinesAvailable = ['id', 'name', 'options'];

  dataSourceTimelinesActive = new MatTableDataSource;
  dataSourceTimelinesAvailable = new MatTableDataSource;
  dataSourceExercisesActive = new MatTableDataSource;
  dataSourceExercisesAvailable = new MatTableDataSource;

  totalTimelinesActive: ITimelineActivation[];
  totalTimelinesAvailable: ITimelineActivation[];
  totalExercisesActive: IProgramExercises[];
  totalExercisesAvailable: IProgramExercises[];

  DATE_FROM_ALWAYS = new Date("0001-01-01");
  DATE_UNTIL_ALWAYS = new Date("9999-01-01");
  DATE_NULL = new Date(null);
  MS_PER_DAY = 1000 * 60 * 60 * 24;

  constructor(private timelineService: TimelineService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });

    this.loadData();
  }


  loadData() {
    this.loadProgramBasics();
    this.programsAvailable = this.route.snapshot.data.programs;
    this.stationsAvailable = this.route.snapshot.data.stations;
  }

  processData() {
    var currentTime = new Date();
    currentTime.setHours(0, 0, 0, 0);

    this.globalObject.exercises = [];
    this.globalObject.timelines = [];

    this.updateTableData();
  }

  updateTableData() {
    this.totalTimelinesActive = this.timelines.filter(x => x.isBasic === true);
    this.totalTimelinesAvailable = this.timelines.filter(x => x.isBasic === false);
    this.totalExercisesActive = this.exercises.filter(x => x.isBasic === true);
    this.totalExercisesAvailable = this.exercises.filter(x => x.isBasic === false);
    this.dataSourceTimelinesActive = new MatTableDataSource(this.totalTimelinesActive);
    this.dataSourceTimelinesAvailable = new MatTableDataSource(this.totalTimelinesAvailable);
    this.dataSourceExercisesActive = new MatTableDataSource(this.totalExercisesActive);
    this.dataSourceExercisesAvailable = new MatTableDataSource(this.totalExercisesAvailable);

    this.dataSourceExercisesActive.paginator = this.paginatorExercisesActive;
    this.dataSourceExercisesAvailable.paginator = this.paginatorExercisesAvailable;
    this.dataSourceTimelinesActive.paginator = this.paginatorTimelinesActive;
    this.dataSourceTimelinesAvailable.paginator = this.paginatorTimelinesAvailable;
  }

  loadProgramBasics() {
    this.timelineService.getProgramBasicTimelines().subscribe(
      result => {
        this.globalObject = result;
        this.timelines = result.timelines;
        this.exercises = result.exercises;
        this.processData();
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  askEnableTimeline(timeline:ITimelineActivation){
    let exercisesOfTimeline = this.checkTimelineWithExercise(timeline);
    if (exercisesOfTimeline.length > 0) {
      let response: boolean;
      const dialogRef = this.dialog.open(EnableTimelineModal, {
        width: '450px',
        data: { count: exercisesOfTimeline.length }
      });

      dialogRef.afterClosed().subscribe(result => {
        response = result;        
        if (response === true) {
          this.enableTimeline(timeline);
          for(const exercise of exercisesOfTimeline){
            this.enableExercise(exercise);
          }
        } else {
          // Do nothing but closing the modal
        }
      });
    }else{
      this.enableTimeline(timeline);
    }
  }
  enableTimeline(timeline: ITimelineActivation) {
    timeline.isBasic = true;
    this.addElement(this.globalObject.timelines, timeline);
    this.updateTableData();
  }
  disableTimeline(timeline: ITimelineActivation) {
    timeline.isBasic = false;
    this.addElement(this.globalObject.timelines, timeline);
    this.updateTableData();
  }

  enableExercise(exercise: IProgramExercises) {
    exercise.isBasic = true;
    this.addElement(this.globalObject.exercises, exercise);
    this.updateTableData();
  }
  askDisableExercise(exercise: IProgramExercises) {
    let timelinesWithExercise = this.checkExerciseInTimeline(exercise);   
    if (timelinesWithExercise.length > 0) {
      let response: boolean;
      const dialogRef = this.dialog.open(DisableExerciseModal, {
        width: '450px',
        data: { count: timelinesWithExercise.length }
      });

      dialogRef.afterClosed().subscribe(result => {
        response = result;
        if (response === true) {
          this.disableExercise(exercise);
          for(const timeline of timelinesWithExercise){
            this.disableTimeline(timeline);
          }
        } else {
          // Do nothing but closing the modal
        }
      });
    }else{
      this.disableExercise(exercise);
    }
  }
  disableExercise(exercise: IProgramExercises) {
    exercise.isBasic = false;
    this.addElement(this.globalObject.exercises, exercise);
    this.updateTableData();
  }

  addElement(where: any[], element: any) {
    if (where.findIndex(x => x.id === element.id) === -1) {
      where.push(element);
    }
  }

  getTimelinesProgramCount(programId: number){
    if(this.timelines){
    const count = this.timelines.filter(x=>x.program === programId && x.isBasic === true).length;
    return count > 0 ? count : '-';
    }else{
      return '-';
    }
  }
  getExercisesProgramCount(programId: number){
    if(this.exercises){
      const count = this.exercises.filter(x=>x.program === programId && x.isBasic === true).length;
      return count > 0 ? count : '-';
    }else{
      return '-';
    }
  }
  getBasicExercisesStationCount(stationId: number){
    if(this.exercises){
      const count = this.exercises.filter(x=>x.station === stationId && x.isBasic === true).length;
      return count > 0 ? count : '-';
    }else{
      return '-';
    }
  }

  checkExerciseInTimeline(exercise: IProgramExercises): ITimelineActivation[] {
    let timelinesWithExercise: ITimelineActivation[] = [];

    for (const timeline of this.timelines) {
      if (timeline.isBasic) {
        for (const timelineExercise of timeline.exercises) {
          if (exercise.id === timelineExercise) {
            timelinesWithExercise.push(timeline);
          }
        }
      }
    }
    return timelinesWithExercise;
  }
  checkTimelineWithExercise(timeline: ITimelineActivation): IProgramExercises[] {
    let basicExercisesInTimeline: IProgramExercises[] = [];

    for (const exercise of timeline.exercises) {
      const exerciseObject = this.getExerciseById(exercise);
      if (!this.getExerciseById(exercise).isBasic) {
        basicExercisesInTimeline.push(exerciseObject);
      }
    }
    return basicExercisesInTimeline;
  }

  getExerciseById(exerciseId: number){
    return this.exercises.find(x => x.id === exerciseId);
  }

  getStatus(timeline: ITimelineActivation) {
    let currentTime = new Date();
    currentTime.setHours(0, 0, 0, 0);

    const activeFrom = new Date(timeline.activeFrom);
    const activeUntil = new Date(timeline.activeUntil);

    if (activeFrom.getTime() !== new Date(null).getTime() && activeUntil.getTime() !== new Date(null).getTime() && activeFrom.getTime() > activeUntil.getTime()) {
      return 'WARNING: Possible date mismatch';
    }

    if (currentTime < activeFrom) {
      return 'Starts in ' + this.dateDiffInDays(currentTime, activeFrom) + ' days';
    }
    if (currentTime > activeFrom && currentTime < activeUntil) {
      return 'Currently active and finishes in ' + this.dateDiffInDays(currentTime, activeUntil) + ' days';
    }

    if (currentTime >= activeFrom && activeUntil.getTime() === this.DATE_NULL.getTime()) {
      let alwaysText = 'Always active';
      if (activeFrom.getTime() !== new Date(null).getTime()) {
        if (activeFrom.getTime() !== currentTime.getTime())
          alwaysText += ' since ' + this.dateDiffInDays(activeFrom, currentTime) + ' days ago';
        else
          alwaysText += ' since today';
      }
      return alwaysText;
      //return 'Always active';
    }
    if (currentTime.getTime() === activeFrom.getTime() && activeUntil.getTime() !== this.DATE_NULL.getTime()) {
      return 'Started today and finishes in ' + this.dateDiffInDays(currentTime, activeUntil) + ' days';
    }
    if (currentTime.getTime() === activeUntil.getTime()) {
      return 'Finishes today';
    }
  }

  dateDiffInDays(a, b) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / this.MS_PER_DAY);
  }

  public saveProgramBasicTimelines() {
    // this.globalObject.timelines.length = 0;
    // this.globalObject.timelines = [... this.timelines];
    // this.globalObject.exercises = [... this.exercises];

    this.timelineService.putProgramBasicTimelines(this.globalObject).subscribe(
      result => {
        this.notificationService.notify("Configuration saved successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  applyFilterExercisesAvailable(filterValue: string) {
    this.dataSourceExercisesAvailable.filter = filterValue.trim().toLowerCase();
  }
  applyFilterExercisesActive(filterValue: string) {
    this.dataSourceExercisesActive.filter = filterValue.trim().toLowerCase();
  }
  applyFilterTimelinesAvailable(filterValue: string) {
    this.dataSourceTimelinesAvailable.filter = filterValue.trim().toLowerCase();
  }
  applyFilterTimelinesActive(filterValue: string) {
    this.dataSourceTimelinesActive.filter = filterValue.trim().toLowerCase();
  }

}


export interface DialogData {
  response: boolean;
  count: any;
}
@Component({
  selector: 'disable-exercise-modal',
  templateUrl: 'disable-exercise-modal.html',
})
export class DisableExerciseModal {

  constructor(
    public dialogRef: MatDialogRef<DisableExerciseModal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface DialogData {
  response: boolean;
}
@Component({
  selector: 'enable-timeline-modal',
  templateUrl: 'enable-timeline-modal.html',
})
export class EnableTimelineModal {

  constructor(
    public dialogRef: MatDialogRef<EnableTimelineModal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}