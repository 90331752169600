import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";

import { IStation } from "../interfaces/models/i-station";
import { IStationType } from "../interfaces/models/i-station-type";
import { IExercise } from "../interfaces/models/i-exercise";


@Injectable()
export class StationService {

  private urlGetStations: string;

  private _stations: IStation[];
  private _stationTypes: IStationType[];

  constructor(private http: HttpClient) {
    this.urlGetStations = CONFIG.serverUrl + ENDPOINTS.stations.get;

    // this.getStationsOLD();
  }

  public stations() {
    return this._stations.filter( x => x.id !== 10);
  }


  public stationsWithEighth() {
    return this._stations;
  }


  public stationTypes() {
    return this._stationTypes;
  }


  // getStationsOLD() {
  //   const url = CONFIG.serverUrl + ENDPOINTS.stations.get;
  //   return new Promise((resolve, reject) => {
  //     if (this._stations && this._stations.length > 0) {
  //       if (CONFIG.traceLoads) {
  //         console.log("stations load ok - cache");
  //       }
  //       return resolve(true);
  //     }

  //     this.http.get(url)
  //       .catch((error) => Observable.throw(error))
  //       // .map(response => {
  //       //   return response.map( station => {
  //       //     return { id: station.id, name: station.name, typeId: station.type, type: undefined, symbol: station.symbol };
  //       //   });
  //       // })
  //       .subscribe(
  //         (response: IStation[]) => {
  //           if (CONFIG.traceLoads) {
  //             console.log("station load ok");
  //           }
  //           this._stations = response;
  //           resolve(true);
  //         },
  //         error => {
  //           if (CONFIG.traceLoads) {
  //             console.log("station load fail");
  //           }
  //           reject(error);
  //         }
  //       );
  //   });
  // }

  getStationTypes() {
    const url = CONFIG.serverUrl + ENDPOINTS.stationTypes.get;
    return new Promise((resolve, reject) => {
      if (this._stationTypes && this._stationTypes.length > 0) {
        if (CONFIG.traceLoads) {
          console.log("stationtypes load ok - cache");
        }
        return resolve(true);
      }

      this.http.get(url)
        .catch((error) => Observable.throw(error))
        .subscribe(
          (response: IStationType[]) => {
            if (CONFIG.traceLoads) {
              console.log("stationtypes load ok");
            }
            this._stationTypes = response;
            resolve(true);
          },
          error => {
            if (CONFIG.traceLoads) {
              console.log("stationtypes load fail");
            }
            reject(error);
          }
        );
    });
  }

  getStation(exercise: IExercise){
    if(exercise !== undefined){
      return this._stations.filter(x => x.id === Number(exercise.station) )[0];
    } else {
      return null;
    }
  }

  public getStations(): Observable<any> {
    return this.http.get(this.urlGetStations);
  }
}
