import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { ISessionToken } from "../../interfaces/models/i-session-token";

import { NotificationService } from "../notification.service";
import { AuthService } from "../auth.service";

import { ENUMS } from "../../../assets/enums";
import { CONFIG } from "../../../assets/config";
import { ENDPOINTS } from "../../../assets/endpoints";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      // const requiredRole: number = route.data["role"][0] as number;
      const hasRole = true; // this.authService.isInRole(requiredRole);
      const result = {
        valid: false,
        expired: false
      };

      return this.authService.validateOrRefreshToken()
        .catch( error => {
          return Observable.of(result);
        })
        .map(
          response => {
            if (response.valid) { // token ok
              if (hasRole) {
                return true;
              } else {
                return false;
              }
            } else {
              if (response.expired) { // token expired
                this.redirectToLogin(true, true);
                return false;
              } else {
                this.redirectToLogin(false, true); // no token
                return false;
              }
            }
          }
        );
    }

    private redirectToLogin(expired: boolean, clearSession: boolean) {

      if (expired) {
          this.notificationService.notify(ENUMS.notification.applicationMessages.tokenExpired,
              ENUMS.notification.types.error, ENUMS.notification.positions.custom_responsive);
      }

      if (clearSession) {
        localStorage.clear();
      }
      this.router.navigate(["/login"]);
    }
}
