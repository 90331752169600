<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch">
  <div fxLayout="column" fxFlex="80" fxFlexAlign="stretch">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch">
      <mat-toolbar class="mat-buttons-toolbar workout-toolbar">
        <mat-toolbar-row>
          <div class="time-box">
            <h4>{{getTotalBlocksTime('work') | timeToMinutes}}</h4>
            <h6>TOTAL WORK TIME</h6>
          </div>
          <div class="time-box">
            <h4>{{getTotalBlocksTime('rest') | timeToMinutes}}</h4>
            <h6>TOTAL REST TIME</h6>
          </div>
          <div class="time-box">
            <h4>{{getTotalBlocksTime('all') | timeToMinutes}}</h4>
            <h6>TOTAL SESSION TIME</h6>
          </div>
          <span class="mat-spacer"></span>
          <!-- <div fxLayout="row" fxFlexAlign="stretch" fxFlexAlign="end" class="editor-options"> -->
            <button type="button" mat-icon-button aria-label="Zoom out" (click)="setGridZoom(-1)">
              <mat-icon>zoom_out</mat-icon>
            </button>
            <button type="button" mat-icon-button aria-label="Zoom in" (click)="setGridZoom(1)">
              <mat-icon>zoom_in</mat-icon>
            </button>
          <!-- </div> -->
        </mat-toolbar-row>
      </mat-toolbar>
    </div>
    <div fxLayout="row" fxFlexAlign="stretch" fxLayoutGap="20px" class="w-100">
      <div class="block-editor" (mouseover)="dropEnableSet(true)" (mouseout)="dropEnableSet(false)">
        <div class="block-editor-channels">
          <div class="channel-container channel-timeline" [ngStyle]="{'width': channelWidth + 'px'}">
            <div class="channel-header channel-header-time" fxLayout="row" fxLayoutAlign="center center">
              <div fxFlex>Time</div>
            </div>
            <div class="channel-content channel-timeline-content channel-timeline-grid channel-grid-header"
            [ngStyle]="{'background-size': BLOCK_CONFIG.GRID_ZOOM_LEVEL_MULT * BLOCK_CONFIG.GRID_BLOCK_SEPARATOR_2 * gridZoom + 'px 100px'}">
              <div *ngFor="let label of timelineLabels" class="channel-timeline-label"
                [ngStyle]="{'left': label.initTime * this.gridSnapX + 'px'}" fxLayout="row"
                fxLayoutAlign="center center">
                <div fxFlex>
                  {{ label.label }}
                </div>
              </div>
            </div>
          </div>
          <div class="channel-container" *ngFor="let channel of workout.channels"
            [ngStyle]="{'width': channelWidth + 'px'}" [ngClass]="{'channel-tags': isTagChannel(channel)}">
            <div class="channel-header" fxLayout="column" fxLayoutAlign="center start"
              [ngClass]="{'channel-tags': isTagChannel(channel)}">
              <div fxLayout="row" fxLayoutAlign="center center" fxFlex>
                {{ channel.name }}
              </div>
              <!-- <div fxLayout="row" fxLayoutAlign="stert" fxFlex fxLayoutGap="10">
                <button mat-icon-button aria-label="Remove gaps" title="Remove gaps" class="small-button">
                  <mat-icon svgIcon="format-horizontal-align-left" inline="true"></mat-icon>
                </button>
                <button mat-icon-button aria-label="" title="" class="small-button">
                  <mat-icon svgIcon="eye-off-outline" inline="true"></mat-icon>
                </button>
              </div> -->
            </div>
            <div #channelDropZone mwlDroppable (drop)="dropOnZone($event, channel, channelDropZone)"
              (dragOver)="dragOver(channel, $event, channelDropZone)" (dragLeave)="dragLeave(channel, $event)"
              class="channel-content channel-grid" [ngClass]="{'channel-tags': isTagChannel(channel)}"
              [ngStyle]="{'background-size': BLOCK_CONFIG.GRID_ZOOM_LEVEL_MULT * BLOCK_CONFIG.GRID_BLOCK_SEPARATOR_2 * gridZoom + 'px 100px, ' + BLOCK_CONFIG.GRID_ZOOM_LEVEL_MULT * BLOCK_CONFIG.GRID_BLOCK_SEPARATOR_1 * gridZoom +'px 20px'}">

              <!-- Bloques -->
              <div #refElem mwlDraggable class="common-block" (dragging)="dragging(block)"
                (click)="openModal(blockEditModal, block);" [dragAxis]="{ x: true, y: true }" [dropData]="block"
                mwlResizable [enableGhostResize]="true" (resizeStart)="onResizeStart($event, block)"
                [ghostDragEnabled]="true" [ghostElementAppendTo]="block.parentElement"
                [ghostElementTemplate]="ghostTemplate" (resizeEnd)="onResizeEnd($event, refElem, block)"
                (resizing)="onResizing($event, block)" [resizeSnapGrid]="snapDataResize"
                *ngFor="let block of channel.blocks" [ngClass]="'style-' + block.style"
                [ngStyle]="{'transform': 'translate3D('+ block.initTime * this.gridSnapX + 'px, '+ calculateBlockVerticalOffset(block)+'px,0px)', 'width': block.duration * gridSnapX + 'px'}">

                <div fxLayout="column" fxFlexAlign="stretch" fxFlexAlign="space-between stretch" fxLayoutGap="0px">
                  <div fxLayout="row" fxFlexAlign="stretch" fxFlexAlign="space-between stretch" fxLayoutGap="10px">
                    <mat-icon class="block-icon" [svgIcon]="block.icon" fxFlex *ngIf="!isTagChannel(channel)">
                    </mat-icon>
                    <div fxFlex class="block-title" *ngIf="block.item?.name && ((gridZoom > 2) || (gridZoom <= 2 && block.duration > 20))">
                      {{block.item.name}}
                    </div>
                    <div fxFlex class="block-title"
                      *ngIf="!block.item?.name && block.type !== 6 && ((gridZoom > 2) || (gridZoom <= 2 && block.duration > 20))">
                      {{block.name?.resources[languageService.getLangIndex(block.name)].value}}
                    </div>
                    <div fxFlex class="block-title" *ngIf="block.type === 6">
                      <ng-container *ngIf="block.type === 6">
                        <span *ngIf="gridZoom >= 2 || block.duration > 20">
                          {{block.name?.resources[languageService.getLangIndex(block.name)].value}}
                        </span>
                        <span *ngIf="gridZoom < 2 && block.duration <= 20 ">
                          {{shortBlockTitle(block.name?.resources[languageService.getLangIndex(block.name)].value)}}
                        </span>
                        <span *ngIf="block.item.value">: {{block.item?.value}}</span>
                      </ng-container>
                    </div>
                  </div>
                  <div fxLayout="row" fxFlexAlign="center" fxFlexAlign="center center" fxLayoutGap="10px"
                    *ngIf="block.type !== 6">
                    <div fxFlex><span>{{block.duration}}</span><span class="block-label-seconds"> s</span></div>
                  </div>
                </div>

                <div class="resize-handle-right" mwlResizeHandle [resizeEdges]="{ right: true }" fxLayout="row"
                  fxLayoutAlign="center center">
                  <div>||</div>
                </div>

                <button mat-icon-button aria-label="Unlink" class="unlink-button-right"
                  (click)="unlinkBlock(block, $event)"
                  *ngIf="block.stickedBlocks && block.duration > BLOCK_CONFIG.GRID_BLOCK_DEFAULT_DURATION && block.type !== 6">
                  <mat-icon svgIcon="link-off" inline="true"></mat-icon>
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="column" fxFlex="20" fxFlexAlign="stretch">
    <div class="editor-tools">
      <mat-tab-group animationDuration="0ms">
        <mat-tab [label]="category.name" *ngFor="let category of blockResources">
          <div fxLayout="row wrap" fxFlexAlign="stretch" fxFlexAlign="space-between stretch" fxLayoutGap="10px">
            <div #refElem mwlDraggable class="common-block toolbox-block" (dragEnd)="dragEnd($event, refElem, block)"
              (dragging)="dragging(block)" [dragAxis]="{ x: true, y: true }" [dropData]="block" mwlResizable
              [enableGhostResize]="true" (resizeStart)="onResizeStart($event)"
              (resizeEnd)="onResizeEnd($event, refElem, block)" (resizing)="onResizing($event, block)"
              [ngClass]="'style-' + block.style" [resizeSnapGrid]="snapDataResize"
              [ghostElementTemplate]="ghostTemplate" *ngFor="let block of category.blocks">

              <div fxLayout="column" fxFlexAlign="stretch" fxFlexAlign="space-between stretch" fxLayoutGap="0px">
                <div fxLayout="row" fxFlexAlign="stretch" fxFlexAlign="space-between stretch" fxLayoutGap="10px">
                  <mat-icon class="block-icon" [svgIcon]="block.icon" fxFlex *ngIf="!isTagBlock(block)"></mat-icon>
                  <div fxFlex class="block-title">
                    {{block.name?.resources[languageService.getLangIndex(block.name)].value}}</div>
                </div>
              </div>

            </div>

          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
<!-- 
<br>
<div>
  <pre>
  {{workout | json}}
  -------------------------------------------------
  {{workout.duration | json}}
  </pre>
</div> -->

<ng-template #ghostTemplate>
  <div #ghostEl>Drop!</div>
</ng-template>



<!-- MODAL BLOCK EDIT -->
<ng-template #blockEditModal>
  <div class="modal-block-editor">
    <h1 mat-dialog-title>Edit block</h1>
    <div mat-dialog-content class="dialog-content">

      <div fxLayout="row" fxFlexAlign="start" fxLayoutAlign="start stretch" fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutAlign="stretch">

          <h2>Common options</h2>
          <mat-divider class="dialog-divider"></mat-divider>
          <div fxLayout="column" fxLayoutAlign="stretch">
            <div fxLayout="row" fxFlexAlign="start" fxLayoutAlign="start stretch" fxLayoutGap="20px">
              <mat-form-field>
                <mat-label>Start time</mat-label>
                <input matInput [(ngModel)]="currentBlock.initTime" type="number" step="5" min="0">
              </mat-form-field>

              <mat-form-field>
                <mat-label>Duration</mat-label>
                <input matInput [(ngModel)]="currentBlock.duration" type="number" step="5" min="5">
              </mat-form-field>
            </div>

          </div>

          <mat-divider class="dialog-divider-blank"></mat-divider>
          <!-- <h2>Behaviour</h2>
          <mat-divider class="dialog-divider"></mat-divider>
          <div fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="space-between stretch">
            <mat-checkbox class="dialog-label">Wait for user at start</mat-checkbox>
            <mat-checkbox class="dialog-label">Wait for user at finish</mat-checkbox>
          </div> -->
        </div>

        <div fxLayout="column" fxLayoutAlign="stretch" *ngIf="currentBlock.type !== 3">
          <h2>Block options</h2>
          <mat-divider class="dialog-divider"></mat-divider>

          <div class="block-modal-exercise" *ngIf="currentBlock.type === 6">
            <mat-form-field>
              <mat-label>Tag value</mat-label>
              <input matInput [(ngModel)]="currentBlock.item.value" type="number" step="1" min="0"
                *ngIf="currentBlock.item?.type >=2 && currentBlock.item.type <=5">
              <input matInput [(ngModel)]="currentBlock.item.value" type="text"
                *ngIf="currentBlock.item?.type === 1 || currentBlock.item.type === 6">
            </mat-form-field>
          </div>

          <div class="block-modal-exercise" *ngIf="currentBlock.type === 1">
            <div fxLayout="row" fxLayoutAlign="stretch stretch" fxLayoutGap="20px" class="w-100">
              <h5 class="posabs text-black">{{currentBlock.item?.name}}</h5>
              <img src="{{exercisesMediaURL + currentBlock.item.image}}" *ngIf="currentBlock.item.image">
            </div>
            <button mat-flat-button class="prama-button" cdkFocusInitial (click)="openModal(exerciseSelectModal)">
              Select exercise
            </button>
          </div>

        </div>
      </div>

      <mat-divider class="dialog-divider-blank"></mat-divider>

      <div fxLayout="row" fxLayoutAlign="stretch stretch" fxLayoutGap="20px" fxFill class="w-100">

        <mat-toolbar class="mat-buttons-toolbar w-100">
          <mat-toolbar-row>
            <button mat-flat-button class="prama-button full-button" (click)="modalDeleteBlock(currentBlock)">
              <mat-icon>delete</mat-icon> Delete
            </button>
            <span class="mat-spacer"></span>
            <button mat-flat-button class="prama-button full-button" (click)="closeModal()" cdkFocusInitial>
              <mat-icon>check</mat-icon> Ok
            </button>
          </mat-toolbar-row>
        </mat-toolbar>
      </div>
    </div>
  </div>
</ng-template>

<!-- MODAL EXERCISE SELECT -->
<ng-template #exerciseSelectModal>
  <div class="modal-search-exercise">
    <div>
      <button type="button" class="floatRight" mat-flat-button class="prama-transparent-button"
        (click)="closeModal()">
        <mat-icon>clear</mat-icon>
      </button>
      <exercise-search [exercises]="exercises" [currentStationId]="currentStationId" [isBlockCreator]="true"
        (exerciseFound)="exerciseSelected($event); closeModal();" (searchCancelled)="searchCancelled()"
        (stationCleaned)="cleanStation($event); closeSearchModal()" *ngIf="exercises !== null">
      </exercise-search>
      <div *ngIf="exercises === null" class="pt-5 pb-5">
        <div class="lds-roller center">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h6 class="text-center">Loading exercises</h6>
      </div>
    </div>
  </div>
</ng-template>
