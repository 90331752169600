<form (ngSubmit)="onSubmit()" #siteForm="ngForm">

  <h1>TIMELINE CREATOR</h1>
  <!-- TIMELINE INFO -->
  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
    <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch">
      <h5 fxFlex="nogrow">MAIN INFO</h5>
      <mat-card fxFlex>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px">
          <div fxLayout="column" fxFlex="50%">
            <!-- NAME -->
            <mat-form-field>
              <input matInput #timelineName placeholder="Name" [value]="timeline.name" [(ngModel)]="timeline.name" name="timelineName"
                required>
              <mat-hint class="form-error-message" *ngIf="formSubmitted && !timeline.name">
                Name is required
              </mat-hint>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxFlex="50%">
            <!-- PROGRAM-->
            <mat-form-field>
              <mat-select #timelineProgram placeholder="Program" (selectionChange)="changeProgram()" [(ngModel)]="program"
                name="timelineProgram" required>
                <mat-option *ngFor="let program of objectsAvailables.program" [value]="program">
                  {{program.name}}
                </mat-option>
              </mat-select>
              <mat-hint class="form-error-message" *ngIf="formSubmitted && !program">
                Program is required
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayout.xs="column">
          <!-- DESCRIPTION -->
          <mat-form-field fxFill>
            <textarea matInput #timelineDescription placeholder="Description" [value]="timeline.description"
              [(ngModel)]="timeline.description" name="timelineDescription" required></textarea>
            <mat-hint class="form-error-message" *ngIf="formSubmitted && !timeline.description">
              Description is required
            </mat-hint>
          </mat-form-field>
        </div>
      </mat-card>

      <!-- <h5 fxFlex="nogrow">OPTIONS</h5>
      <mat-card fxFlex>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxFill>
          <div fxLayout="column" fxFlex="50%">
            
            <mat-checkbox>Show station change guides</mat-checkbox>
          </div>
          <div fxLayout="column" fxFlex="50%">
          </div>
        </div>
      </mat-card> -->
    </div>

    <div fxLayout="column" fxFlex="50" fxFlexAlign="stretch" class="posrel">
      <!-- <div class="unavailable">
        <div class="text">UNDER DEVELOPMENT TESTING <br>- NOT AVAILABLE YET -</div>
      </div> -->
      <h5 fxFlex="nogrow">TRIGGERS</h5>
      <mat-card fxFlex>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxFill>
          <session-trigger class="w-100" [triggers]="timeline.session.triggers"></session-trigger>
        </div>
      </mat-card>

    </div>

  </div>

  <mat-toolbar class="mat-buttons-toolbar">
    <mat-toolbar-row>
      <span class="mat-spacer"></span>
      <button mat-flat-button type="submit" class="prama-button full-button">
        <mat-icon>save</mat-icon> SAVE
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <!-- <mat-horizontal-stepper>
    <mat-step label="Info">

    </mat-step>

    <mat-step label="Phases">-->

  <div *ngIf="program?.id">
    <h5>TIMELINE PHASES</h5>
    <div class="timeline-container">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="30px" fxLayoutAlign="space-between start">

        <mat-card class="card-phase">
          <h2>WELCOME</h2>
          <p>The screen where all users are shown</p>
          <div class="phase-status-ok" [class.phase-status-fail]="!this.timelineWelcome.onSubmit()">
            <mat-icon>done</mat-icon>
          </div>

          <!-- </div> -->
          <div fxLayout="column">
            <mat-form-field>
              <mat-select #welcomes placeholder="Preset" (selectionChange)="changeWelcome()" [(ngModel)]="timeline.welcome"
                [compareWith]="compare" name="welcomes" required>
                <mat-option [value]="newWelcome">New welcome</mat-option>
                <mat-option *ngFor="let welcome of objectsAvailables.welcome" [value]="welcome">
                  {{welcome.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-dynamic-form #timelineWelcome name="timelineWelcome" [questions]="questionsWelcome" (valueChange)="changeValueEvent($event, newWelcome, 'welcome')"></app-dynamic-form>
          </div>
        </mat-card>

        <mat-card class="card-phase">
          <h2>EXPLANATION</h2>
          <p>It's the screen with lots of info</p>
          <div class="phase-status-ok" [class.phase-status-fail]="!this.timelineExplanation.onSubmit()">
            <mat-icon>done</mat-icon>
          </div>
          <div fxLayout="column">
            <mat-form-field class="w-100">
              <mat-select #explanations placeholder="Preset" (selectionChange)="changeExplanation()" [(ngModel)]="timeline.explanation"
                [compareWith]="compare" name="explanations" required>
                <mat-option [value]="newExplanation">New explanation</mat-option>
                <mat-option *ngFor="let explanation of objectsAvailables.explanation" [value]="explanation">
                  {{explanation.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-dynamic-form #timelineExplanation name="timelineExplanation" [questions]="questionsExplanation"
              (valueChange)="changeValueEvent($event, newExplanation, 'explanation')"></app-dynamic-form>
          </div>
        </mat-card>

        <mat-card class="card-phase">
          <h2>WARM-UP</h2>
          <p>A Warm-up screen with info about warm-up</p>
          <div class="phase-status-ok" [class.phase-status-fail]="!this.timelineWarmUp.onSubmit()">
            <mat-icon>done</mat-icon>
          </div>
          <div fxLayout="column">
            <mat-form-field class="w-100">
              <mat-select #warmUps placeholder="Preset" (selectionChange)="changeWarmUp()" [(ngModel)]="timeline.warmUp"
                [compareWith]="compare" name="warmUps" required>
                <mat-option [value]="newWarmUp">New warm-up</mat-option>
                <mat-option *ngFor="let warmUp of objectsAvailables.warmup" [value]="warmUp">
                  {{warmUp.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-dynamic-form #timelineWarmUp name="timelineWarmUp" [questions]="questionsWarmUp" (valueChange)="changeValueEvent($event, newWarmUp, 'warmUp')"></app-dynamic-form>
          </div>
        </mat-card>

        <mat-card class="card-phase">
          <h2>GT POSITIONS</h2>
          <p>Here users are revealed where they will start on</p>
          <div class="phase-status-ok" [class.phase-status-fail]="!this.timelinePositioningGt.onSubmit()">
            <mat-icon>done</mat-icon>
          </div>
          <div fxLayout="column">
            <mat-form-field class="w-100">
              <mat-select #positioningsGt placeholder="Preset" (selectionChange)="changePositioningGt()" [(ngModel)]="timeline.positioningGt"
                [compareWith]="compare" name="positioningsGt" required>
                <mat-option [value]="newPositioningGt">New GT positioning</mat-option>
                <mat-option *ngFor="let positioningGt of objectsAvailables.positioningGt" [value]="positioningGt">
                  {{positioningGt.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-dynamic-form #timelinePositioningGt name="timelinePositioningGt" [questions]="questionsPositioningGt"
              (valueChange)="changeValueEvent($event, newPositioningGt, 'positioningGt')"></app-dynamic-form>
          </div>
        </mat-card>

        <mat-card class="card-phase">
          <h2>GROUP TRAINING</h2>
          <p>Choose the main session for this Timeline</p>
          <div class="phase-status-ok" [class.phase-status-fail]="!this.timelineGt.onSubmit()">
            <mat-icon>done</mat-icon>
          </div>
          <div fxLayout="column">
            <app-dynamic-form #timelineGt name="timelineGt" [questions]="questionsGt" (valueChange)="changeValueEvent($event, timeline.session, 'session')"></app-dynamic-form>
          </div>
          <img [src]="">
        </mat-card>

        <mat-card class="card-phase">
          <h2>GT RESULTS</h2>
          <p>Results for the previous GT are shown here</p>
          <div class="phase-status-ok" [class.phase-status-fail]="!this.timelineResultsGt.onSubmit()">
            <mat-icon>done</mat-icon>
          </div>
          <div fxLayout="column">
            <mat-form-field class="w-100">
              <mat-select #resultsGt placeholder="Preset" (selectionChange)="changeResultsGt()" [(ngModel)]="timeline.resultsGt"
                [compareWith]="compare" name="resultsGt" required>
                <mat-option [value]="newResultsGt">New GT results</mat-option>
                <mat-option *ngFor="let resultsGt of objectsAvailables.resultGt" [value]="resultsGt">
                  {{resultsGt.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-dynamic-form #timelineResultsGt name="timelineResultsGt" [questions]="questionsResultsGt"
              (valueChange)="changeValueEvent($event, newResultsGt, 'resultsGt')"></app-dynamic-form>
          </div>
        </mat-card>

        <mat-card class="card-phase">
          <h2>FINISHER POSITIONS</h2>
          <p>Users on their marks for finisher</p>
          <div class="phase-status-ok" [class.phase-status-fail]="!this.timelinePositioningFinisher.onSubmit()">
            <mat-icon>done</mat-icon>
          </div>
          <div fxLayout="column">
            <mat-form-field class="w-100">
              <mat-select #positioningsFinisher placeholder="Preset" (selectionChange)="changePositioningFinisher()"
                [(ngModel)]="timeline.positioningFinisher" [compareWith]="compare" name="positioningsFinisher" required>
                <mat-option [value]="newPositioningFinisher">New finisher positioning</mat-option>
                <mat-option *ngFor="let positioning of objectsAvailables.positioningFinisher" [value]="positioning">
                  {{positioning.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-dynamic-form #timelinePositioningFinisher name="timelinePositioningFinisher" [questions]="questionsPositioningFinisher"
              (valueChange)="changeValueEvent($event, newPositioningFinisher, 'positioningFinisher')"></app-dynamic-form>
          </div>
        </mat-card>

        <mat-card class="card-phase">
          <h2>FINISHER</h2>
          <p>Choose the finisher for this Timeline</p>
          <div class="phase-status-ok" [class.phase-status-fail]="!this.timelineFinisher.onSubmit()">
            <mat-icon>done</mat-icon>
          </div>
          <div fxLayout="column">
            <mat-form-field class="w-100">
              <mat-select  placeholder="Finisher type" (selectionChange)="finisherModeChanged()" 
              [(ngModel)]="timeline.finisher.finisherType" name="finisherMode">
                <mat-option *ngFor="let mode of objectsAvailables.finisherMode" [value]="mode.id">
                  {{mode.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-dynamic-form #timelineFinisher name="timelineFinisher" [questions]="questionsFinisher" (valueChange)="changeValueEvent($event, timeline.finisher, 'finisher')"></app-dynamic-form>
          </div>
          <img [src]="">
        </mat-card>

        <mat-card class="card-phase">
          <h2>FINISHER RESULTS</h2>
          <p>Results for the finisher are shown here</p>
          <div class="phase-status-ok" [class.phase-status-fail]="!this.timelineResultsFinisher.onSubmit()">
            <mat-icon>done</mat-icon>
          </div>
          <div fxLayout="column">
            <mat-form-field class="w-100">
              <mat-select #resultsFinisher placeholder="Preset" (selectionChange)="changeResultsFinisher()" [(ngModel)]="timeline.resultsFinisher"
                [compareWith]="compare" name="resultsFinisher" required>
                <mat-option [value]="newResultsFinisher">New finisher results</mat-option>
                <mat-option *ngFor="let result of objectsAvailables.resultFinisher" [value]="result">
                  {{result.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-dynamic-form #timelineResultsFinisher name="timelineResultsFinisher" [questions]="questionsResultsFinisher"
              (valueChange)="changeValueEvent($event, newResultsFinisher, 'resultsFinisher')"></app-dynamic-form>
          </div>
        </mat-card>

        <mat-card class="card-phase">
          <h2>COOLDOWN</h2>
          <p>The cooldown phase</p>
          <div class="phase-status-ok" [class.phase-status-fail]="!this.timelineCooldown.onSubmit()">
            <mat-icon>done</mat-icon>
          </div>
          <div fxLayout="column">
            <mat-form-field class="w-100">
              <mat-select #cooldown placeholder="Preset" (selectionChange)="changeCooldown()" [(ngModel)]="timeline.coolDown"
                [compareWith]="compare" name="cooldown" required>
                <mat-option [value]="newCooldown">New cooldown</mat-option>
                <mat-option *ngFor="let cooldown of objectsAvailables.cooldown" [value]="cooldown">
                  {{cooldown.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-dynamic-form #timelineCooldown name="timelineCooldown" [questions]="questionsCooldown" (valueChange)="changeValueEvent($event, newCooldown, 'coolDown')"></app-dynamic-form>
          </div>
        </mat-card>

        <mat-card class="card-phase">
          <h2>ENDING</h2>
          <p>The ending screen</p>
          <div class="phase-status-ok" [class.phase-status-fail]="!this.timelineEnding.onSubmit()">
            <mat-icon>done</mat-icon>
          </div>
          <div fxLayout="column">
            <mat-form-field class="w-100">
              <mat-select #ending placeholder="Preset" (selectionChange)="changeEnding()" [(ngModel)]="timeline.ending"
                [compareWith]="compare" name="ending" required>
                <mat-option [value]="newEnding">New ending</mat-option>
                <mat-option *ngFor="let ending of objectsAvailables.ending" [value]="ending">
                  {{ending.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-dynamic-form #timelineEnding name="timelineEnding" [questions]="questionsEnding" (valueChange)="changeValueEvent($event, newEnding, 'ending')"></app-dynamic-form>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
  <!-- </mat-step>
    <mat-step label="End">
    </mat-step>
  </mat-horizontal-stepper> -->

  <!-- <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="space-between stretch" fxFill>
    <h5>TRIGGERS</h5>
    <mat-card>
      <session-trigger></session-trigger>
    </mat-card>

  </div> -->

</form>