import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FileDropDirective, FileUploader, FileUploaderOptions } from "ng2-file-upload";

import { ExerciseFormHelper } from "../../helpers/exercise-form-helper";

import { ExerciseService } from "../../services/exercise.service";
import { NotificationService } from "../../services/notification.service";

import { IFormField } from "../../interfaces/models/i-form-field";
import { IMediaTempUploaded } from "../../interfaces/models/i-media-temp-uploaded";

import { CONFIG } from "../../../assets/config";
import { ENUMS } from "../../../assets/enums";

declare var UIkit: any;

@Component({
  selector: "video-uploader",
  templateUrl: "./video-uploader.component.html",
  styleUrls: ["./video-uploader.component.css"]
})
export class VideoUploaderComponent implements OnInit {
  selectedVideoName: string;
  videoSelected = false;
  localUrlVideo: string;
  gifUrl: string = "";
  videoUrl: string = "";
  dropOver: boolean;
  uploading: boolean;
  textToShow: string = "";

  @Input() formField: IFormField;
  @Input() videoName: string;
  @Input() gifName: string;
  @Input() editMode: boolean;

  @Output() selectedVideoEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectedGifEvent: EventEmitter<string> = new EventEmitter<string>();


  constructor(private exerciseService: ExerciseService, private notificationService: NotificationService) {
    this.uploading = false;
    this.dropOver = false;
    this.videoSelected = false;
  }


  ngOnInit() {
    if (this.videoName && this.videoName !== "") {
      this.videoSelected = true;
      this.videoUrl = CONFIG.exercisesMediaURL + this.videoName + "?t=" + new Date().getTime();
      this.selectedVideoName = this.videoName;
    }

    if (this.gifName && this.gifName !== "") {
      this.gifUrl = CONFIG.exercisesMediaURL + this.gifName + "?t=" + new Date().getTime();
    } else {
      this.gifUrl = "../../assets/default.png";
    }
  }


  dragEnter(event) {
    this.dropOver = true;
    event.preventDefault();
    event.stopPropagation();
  }


  dragLeave(event) {
    this.dropOver = false;
  }


  dragOver(event) {
    this.dropOver = true;
    event.preventDefault();
    event.stopPropagation();
  }


  dragEnd(event) {
  }


  dragStart(event) {
  }


  dropVideo(event) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      const mime = file.type;
      const extension = file.name.substring(file.name.lastIndexOf("."));

      if ( ExerciseFormHelper.isSupportedVideo(mime, extension) ) {
          this.previewVideo(file);
          this.uploadSelectedVideo(file);
      }
    }
    this.dropOver = false;
  }


  selectVideo(event) {
    if (event.target.files && event.target.files[0]) {
      this.previewVideo(event.target.files[0]);
      this.uploadSelectedVideo(event.target.files[0]);
    }
  }


  uploadSelectedVideo(file) {
    this.uploading = true;
    this.textToShow = "Uploading video";
    this.exerciseService.postFile(file)
      .then( response => {
        this.textToShow = "Converting to gif";
        this.selectedVideoEvent.emit(response.resultFiles[0]);
        this.exerciseService.getGif(response.resultFiles[0]).subscribe( result => {
          this.gifUrl = CONFIG.tempMediaUrl + result + "?t=" + new Date().getTime();
          this.selectedGifEvent.emit(result);
          this.videoSelected = true;
          this.uploading = false;
        }, error => {
          this.notificationService.notify(error,
            ENUMS.notification.types.error,
            ENUMS.notification.positions.custom_responsive);

          console.error(`"Error converting ${response.resultFiles[0]} to gif: ${error}`);
          this.videoSelected = false;
          this.uploading = false;
        });
      })
      .catch( error => {
        this.notificationService.notify(error,
            ENUMS.notification.types.error,
            ENUMS.notification.positions.custom_responsive);
        console.error(`"Error uploading ${file.name}`);
        this.videoSelected = false;
        this.uploading = false;
      });
  }


  previewVideo(file) {
    const reader = new FileReader();
    reader.onloadend = (event) => {
      this.localUrlVideo = reader.result.toString();
      this.selectedVideoName = file.name;
    };

    reader.onerror = (error) => {
      this.notificationService.notify("Error reading video to display",
            ENUMS.notification.types.error,
            ENUMS.notification.positions.custom_responsive);
    };
    reader.readAsDataURL(file);
  }


  removeSelection() {
    this.uploading = false;
    this.localUrlVideo = "";
    this.selectedVideoName = "";
    this.videoSelected = false;
    this.gifUrl = "";
  }
}
