import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "timeToMinutes"
})
export class TimeToMinutesPipe implements PipeTransform {

  transform(seconds: number): string {
    seconds = seconds >= 0 ? seconds: 0;
    const mins = Math.floor(seconds / 60);
    const rsecs = seconds % 60;
    //return (mins <= 9 ? "0" + mins : mins) + ":" + (rsecs <= 9 ? "0" + rsecs : rsecs);
    return mins + ":" + (rsecs <= 9 ? "0" + rsecs : rsecs);
  }
  
}
