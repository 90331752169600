import { IFormFieldExerciseSearch } from "../interfaces/models/i-form-fields-exercise-search";
import { IFormField } from "../interfaces/models/i-form-field";
import { IFormProgram } from "../interfaces/models/i-form-program";
import { CONFIG } from "../../assets/config";

export class ExerciseSearchHelper {
  static getFormFields(): IFormFieldExerciseSearch {

    const fields: IFormFieldExerciseSearch = {
      exerciseTypeField: {
        name: "exercise-type-input",
        nameToShow: "Type",
        placeHolder: "type",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Exercise type required",
        minValue: undefined
      },
      cardioLevelField: {
        name: "exercise-cardiolevel-input",
        nameToShow: "Cardio",
        placeHolder: "cardio",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Cardio level required",
        minValue: undefined
      },
      strengthLevelField: {
        name: "exercise-strengthlevel-input",
        nameToShow: "Strength",
        placeHolder: "strength",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Strength level required",
        minValue: undefined
      },
      complexityLevelField: {
        name: "exercise-complexitylevel-input",
        nameToShow: "Complexity",
        placeHolder: "complexity",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "Complexity level required",
        minValue: undefined
      },
      tagsField: {
        name: "exercise-tags-input",
        nameToShow: "Tags",
        placeHolder: "tags",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "At least two tags required",
        minValue: 2
      },
      equipmentField: {
        name: "exercise-equipment-input",
        nameToShow: "Equipment",
        placeHolder: "equipment",
        visible: true,
        disabled: false,
        required: false,
        error: false,
        errorMessage: "",
        minValue: undefined
      },
      programsField: {
        name: "exercise-programs-input",
        nameToShow: "Programs",
        placeHolder: "programs",
        visible: true,
        disabled: false,
        required: true,
        error: false,
        errorMessage: "At least one program must be selected",
        minValue: 1
      }
    };
    return fields;
  }

  static validateTextField(field: IFormField, inputText: string) {
    field.error = false;
    if (field.required && (!inputText || inputText === "")) {
      field.error = true;
    }
  }

  static validateNumberField(field: IFormField, inputNumber: number) {
    field.error = false;
    if (field.required && (!inputNumber || inputNumber === 0)) {
      field.error = true;
    }
  }

  static validateArrayField(field: IFormField, elements) {
    field.error = false;
    if (field.required && (!elements || elements.length < field.minValue)) {
      field.error = true;
    }
  }
}




