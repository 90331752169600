import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { SelectionModel } from '@angular/cdk/collections';

import { CONFIG } from './../../../assets/config';

import { ISong, ISongSearch } from '../../../app/interfaces/models/i-song';
import { IMusicPackagePhase } from '../../interfaces/models/i-music-package-phase';

@Component({
  selector: 'song-search',
  templateUrl: './song-search.component.html',
  styleUrls: ['./song-search.component.scss']
})
export class SongSearchComponent implements OnInit {

  @Input() currentStationId: number;
  @Input() songs: ISongSearch[];
  @Input() currentPhase: IMusicPackagePhase;
  @Output() searchCancelled: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() searchCompleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  songsList: ISongSearch[] = [];

  playingSongId: number = 0;
  playingSong: ISong;
  currentPlayingSongUrl: string = "";
  playerUrl: string;
  player: HTMLAudioElement = new Audio();

  masterSelection: boolean;
  filterValue: string = "";
  
  displayedColumns = ['check', 'player', 'title'];
  dataSource = new MatTableDataSource;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  selection = new SelectionModel<ISongSearch>(true, []);

  constructor(private route: ActivatedRoute) {
    console.log(this.songs);
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnChanges(changes: SimpleChanges) {
    // this.resetSearch();
  }

  ngOnInit() {
    this.loadData();
    this.masterSelection = this.isAllSelected();
  }

  loadData() {
    this.songsList = this.songs;
    this.updateTableData();
  }
  
  updateTableData() {
    this.dataSource.sort = this.sort;
    this.dataSource = new MatTableDataSource(this.songsList);    
    this.dataSource.paginator = this.paginator;
  }

  cancelSearch() {
    this.searchCancelled.emit(true);
  }

  selectSongs() {
    this.currentPhase.songs = this.songsList.filter(x => x.selected);
    this.searchCompleted.emit(true);
  }

  filterSongsByTitle() {
    if(this.filterValue && this.filterValue.length > 0) {
      this.songsList = this.songsList.filter(x => x.title.toLowerCase().includes(this.filterValue.toLowerCase()));
    } else{
      this.songsList = this.songs;
    }
    this.updateTableData();
  }

  getSelectedSongs() {
    return this.songsList.filter(x => x.selected).length;
  }

  playSong(song: ISong) {
    this.playingSongId = song.id;
    this.playingSong = song;
    this.currentPlayingSongUrl = CONFIG.mediaUrl + `/songs/${song.programs[0].id}/${song.phases[0].id}/${song.fileName}`;
    
    this.player.src = this.currentPlayingSongUrl;
    this.player.load();
    this.player.play();
  }

  stopSong(song: ISong) {
    this.playingSongId = 0;
    this.playingSong = null;
    this.currentPlayingSongUrl = "";
    this.player.pause();
  }

  isAllSelected() {
    return this.songsList.every(x => x.selected);
  }

  isDisabled(element) {
    let selectedSongs = this.songsList.filter(x => x.selected);

    var x1 =  selectedSongs.length >= this.currentPhase.phase.neededSongs;
    var x2 = selectedSongs.some(x => x.id == element.id);
    return x1 && !x2;
  }

  masterToggle() {
    this.masterSelection = !this.masterSelection;
    this.songsList.forEach(x => x.selected = this.masterSelection);
  }
}
