
<form (ngSubmit)="onSubmit()" #siteForm="ngForm">
  <h1>SONGS UPLOADER</h1>

  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
      <mat-toolbar class="mat-buttons-toolbar">
        <mat-toolbar-row>
          <span class="mat-spacer"></span>
          <button mat-flat-button type="submit" class="prama-button full-button medium-button" [disabled]="!uploaded || !selectedProgram || !selectedPhase">
            <mat-icon>save</mat-icon> SAVE
          </button>
        </mat-toolbar-row>
      </mat-toolbar>
    </div>
  
    <mat-horizontal-stepper  #stepper>
  
      <!-- STEP: UPLOAD -->
      <mat-step [editable]="!uploaded" [completed]="uploaded">
        <ng-template matStepLabel>UPLOAD
          <div class="form-error-message" *ngIf="formSubmitted">
            Uploaded file required
          </div>
        </ng-template>
  
        <div fxLayout="row" fxLayout.lg="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
          <div fxFlex="100%" fxLayout="row" fxLayoutGap="20px" fxFlexAlign="stretch" fxLayoutAlign="center" class="moods-containter">
            <div fxFlex="50%">
              <mat-card class="nopadding" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-between stretch">
                <music-uploader [songs]="uploadedSongs" (uploadedMusicEvent)="processMusic($event)" fxFill></music-uploader>
              </mat-card>
            </div>

            <div fxFlex="50%">
              <h5>INSTRUCTIONS</h5>
              <ol>
                <li>Copy the songs that you want to upload inside a folder in your PC</li>
                <li>Compress that folder into a .zip file</li>
                <li>Drag and drop the .zip file or choose it by clicking on "select one" </li>
                <li>Wait until the upload progress finishes. Once done, the tool will take you to the next step automatically </li>
              </ol>
            </div>
          </div>
        </div>
      </mat-step>
  
      <!-- STEP: PROGRAM AND PHASE -->
      <mat-step [completed]="selectedProgram && selectedPhase">
        <ng-template matStepLabel>PROGRAM AND PHASE
          <div class="form-error-message" *ngIf="formSubmitted">
            Playlists are required
          </div>
        </ng-template>
  
        <div fxLayout="row" fxLayout.lg="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
          <div fxFlex="100%" fxLayout="row" fxLayoutGap="20px" fxFlexAlign="stretch" class="moods-containter">
  
            <div fxFlex="50%">
              <mat-card class="nopadding" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-between stretch">
                <div class="padding30 w-100">
                  <h6>PROGRAM</h6>
                  <mat-form-field fxFill>
                    <mat-select placeholder="Program" [(ngModel)]="selectedProgram" name="songsProgram" required>
                      <mat-option *ngFor="let program of programs" [value]="program">
                        {{program.name}}
                      </mat-option>
                    </mat-select>
                    <mat-hint class="form-error-message" *ngIf="formSubmitted && !selectedProgram">
                      Program required
                    </mat-hint>
                  </mat-form-field>
                </div>
              </mat-card>
            </div>
  
            <div fxFlex="50%">
              <mat-card class="nopadding" fxLayout="column" fxLayout.gt-md="row" fxLayoutAlign="space-between stretch">
                <div class="padding30 w-100">
                  <h6>PHASE</h6>
                  <mat-form-field fxFill>
                    <mat-select placeholder="Phase" [(ngModel)]="selectedPhase" name="songsPhase" required>
                      <mat-option *ngFor="let musicPhase of phases" [value]="musicPhase">
                        {{musicPhase.phase.name}}
                      </mat-option>
                    </mat-select>
                    <mat-hint class="form-error-message" *ngIf="formSubmitted && !selectedPhase">
                      Phase required
                    </mat-hint>
                  </mat-form-field>
                </div>
              </mat-card>
            </div>
  
          </div>
        </div>
      </mat-step>
  
      <!-- STEP: SONGS INFO -->
      <mat-step>
        <ng-template matStepLabel>Songs</ng-template>
  
        <div class="margin10">
          <mat-table #table [dataSource]="dataSource" matSort class="station-table" *ngIf="uploaded && uploadedSongs.length > 0" >
            <ng-container matColumnDef="title">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="55%"> Title </mat-header-cell>
              <mat-cell *matCellDef="let song" fxFlex="55%">
                <h5>{{song.title}}</h5>
              </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="duration">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="15%"> Duration </mat-header-cell>
              <mat-cell *matCellDef="let song" fxFlex="15%">
                {{song.duration | timeToMinutes}}
              </mat-cell>
            </ng-container>
    
            <ng-container matColumnDef="provider">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10%"> Provider </mat-header-cell>
              <mat-cell *matCellDef="let song" fxFlex="10%">
                <mat-select placeholder="provider" [(ngModel)]="song.provider" name="songProvider" required>
                  <mat-option *ngFor="let provider of providers" [value]="provider">
                    {{provider.name}}
                  </mat-option>
                </mat-select>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="favourite">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10%"> fav </mat-header-cell>
                <mat-cell *matCellDef="let song" fxFlex="10%">
                  <button mat-icon-button (click)="toggleSongFav(song)">
                    <mat-icon *ngIf="song.isFavourite">star</mat-icon>
                    <mat-icon *ngIf="!song.isFavourite">star_border</mat-icon>
                  </button>
                </mat-cell>
              </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
  
      </mat-step>
    </mat-horizontal-stepper>
  
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
      <mat-toolbar class="mat-buttons-toolbar">
        <mat-toolbar-row>
          <button mat-flat-button (click)="stepperGoPrevious()" class="prama-button full-button" [disabled]="isFirstStep()"
            type="button">
            <mat-icon>arrow_back_ios</mat-icon> PREVIOUS
          </button>
          <span class="mat-spacer"></span>
          <button mat-flat-button (click)="stepperGoNext()" class="prama-button full-button" [disabled]="isLastStep()"
            type="button">
            <mat-icon>arrow_forward_ios</mat-icon> NEXT
          </button>
        </mat-toolbar-row>
      </mat-toolbar>
    </div>
  </form>