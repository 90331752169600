import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { CONFIG } from "../../assets/config";
import { ENDPOINTS } from "../../assets/endpoints";


@Injectable()
export class PlaylistsService {

    private urlGetPlaylists: string;
    private urlGetPlaylistSongs: string;


    constructor(public http: HttpClient) {
        this.urlGetPlaylists = CONFIG.serverUrl + ENDPOINTS.playlists.get;
    }


    public getPlaylists(programId: number): Observable<any> {
        this.urlGetPlaylists = CONFIG.serverUrl + ENDPOINTS.playlists.get
        
        programId = programId !== null && programId !== undefined ? programId : 0;        
        this.urlGetPlaylists = this.urlGetPlaylists.replace(":id", programId.toString());    
        return this.http.get(this.urlGetPlaylists);
    }


    public getPlaylistSongs(playlistId: number): Observable<any> {
        this.urlGetPlaylistSongs = CONFIG.serverUrl + ENDPOINTS.songs.get
        playlistId = playlistId !== null && playlistId !== undefined ? playlistId : 0;        
        this.urlGetPlaylistSongs = this.urlGetPlaylistSongs.replace(":id", playlistId.toString());    
        return this.http.get(this.urlGetPlaylistSongs);
    }
}
