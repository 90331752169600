
<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutAlign="stretch" fxFill>
  <div fxLayout="column" fxFlex="100" fxFill fxLayoutAlign="space-between stretch" class="select-video-cont">
    <div *ngIf="!fileSelected && !uploading" fxFlex="100" fxLayoutAlign="center center" [ngClass]="{ 'nv-file-over': dropOver}"
      (dragenter)="dragEnter($event)" (dragleave)="dragLeave($event)" (drop)="dropFile($event)"
      (dragover)="dragOver($event)" (dragend)="dragEnd($event)" (dragstart)="dragStart($event)">
      <div fxLayoutAlign="space-between center" class="drop-video-message">
        <mat-icon>cloud_upload</mat-icon>
        <span> Drop file here or </span>
        <span uk-form-custom>
          <input type="file" accept=".zip, .rar, .mp3" id="file" name="file" required (change)="selectFile($event)">
          <span class="image-link">select one</span>
        </span>
      </div>
    </div>
    <!--<spinner *ngIf="this.uploading" [textToShow]="'Uploading file'"></spinner>-->
    <div fxFlex="100" fxLayoutAlign="center center" class="padding30" *ngIf="uploading">
      <mat-progress-bar [value]="uploadProgress" [mode]="currentMode"></mat-progress-bar>
    </div>
  </div>
</div>

