import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from "@angular/core";
import { FileDropDirective, FileUploader, FileUploaderOptions } from "ng2-file-upload";

import { ExerciseFormHelper } from "../../helpers/exercise-form-helper";
import { ExerciseService } from "../../services/exercise.service";
import { NotificationService } from "../../services/notification.service";

import { IFormField } from "../../interfaces/models/i-form-field";

import { CONFIG } from "../../../assets/config";
import { ENUMS } from "../../../assets/enums";

declare var UIkit: any;

@Component({
  selector: "image-uploader",
  templateUrl: "./image-uploader.component.html",
  styleUrls: ["./image-uploader.component.css"]
})
export class ImageUploaderComponent implements OnInit {
  public dropOver = false;

  selectedImageName: string = "";
  localUrlImage: any;
  uploading: boolean;
  imageSelected: boolean;
  selectedImageFile: File;

  currentImage: string;

  @Input() formField: IFormField;
  @Input() mediaUrl: string;
  @Input() imageName: string;
  @Output() selectedImageEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() deletedImageEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() editMode: boolean = true;

  constructor(private exerciseService: ExerciseService, private notificationService: NotificationService) {
    this.uploading = false;
    this.dropOver = false;
    this.imageSelected = false;

  }

  ngOnInit() {
    if (this.imageName && this.imageName !== "") {
      this.currentImage = this.mediaUrl + this.imageName + "?t=" + new Date().getTime();
      this.selectedImageName = this.imageName;
      this.imageSelected = true;
    }
  }


  dragEnter(event) {
    this.dropOver = true;
    event.preventDefault();
    event.stopPropagation();

  }


  dragLeave(event) {
    this.dropOver = false;
  }


  dragOver(event) {
    this.dropOver = true;
    event.preventDefault();
    event.stopPropagation();
  }

  dragEnd(event) {
  }


  dragStart(event) {
  }


  dropImage(event) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      const mime = file.type;
      const extension = file.name.substring(file.name.lastIndexOf("."));

      if ( ExerciseFormHelper.isSupportedImage(mime, extension) ) {
          this.previewImage(file);
          this.uploadSelectedImage(file);
      }
    }
    this.dropOver = false;
  }


  selectImage(event) {
    if (event.target.files && event.target.files[0]) {
      this.previewImage(event.target.files[0]);
      this.uploadSelectedImage(event.target.files[0]);
    }
  }


  uploadSelectedImage(file) {
    this.uploading = true;
    this.exerciseService.postFile(file)
      .then(response => {
        console.log(response);
        this.imageSelected = true;
        this.uploading = false;
        this.selectedImageEvent.emit(response.resultFiles[0]);
      }).catch(error => {
        this.notificationService.notify("Error uploading image",
            ENUMS.notification.types.error,
            ENUMS.notification.positions.custom_responsive);
        console.error(`"Error uploading ${file.name}`);
        this.imageSelected = false;
        this.uploading = false;
      });
  }


  previewImage(file) {
    const reader = new FileReader();
    reader.onloadend = (event) => {
      this.localUrlImage = reader.result;
      this.selectedImageName = file.name;
      this.dropOver = false;
    };
    reader.onerror = (error) => {
      this.notificationService.notify("Error reading image to display",
            ENUMS.notification.types.error,
            ENUMS.notification.positions.custom_responsive);
    };
    reader.readAsDataURL(file);
  }


  removeSelection() {
    this.uploading = false;
    this.localUrlImage = [];
    this.selectedImageName = "";
    this.imageSelected = false;
    this.deletedImageEvent.emit(true);
  }
}

