import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";

import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { IMood } from "../interfaces/models/i-mood";

import { CONFIG } from "../../assets/config";
import { ENDPOINTS } from "../../assets/endpoints";

@Injectable()
export class MoodService {

  private urlGetMoods: string;

  private _moods: IMood[];

  constructor(private http: HttpClient) {
    this.urlGetMoods = CONFIG.serverUrl + ENDPOINTS.moods.get;
  }

  public moods() {
    return this._moods;
  }

  // getMoods() {
  //   const url = CONFIG.serverUrl + ENDPOINTS.moods.get;
  //   return new Promise((resolve, reject) => {
  //     if (this._moods && this._moods.length > 0) {
  //       if (CONFIG.traceLoads) {
  //         console.log("moods load ok - cache");
  //       }
  //       return resolve(true);
  //     }
  //     this.http.get(url)
  //       .catch((error) => Observable.throw(error))
  //       .subscribe(
  //         (response: IMood[]) => {
  //           if (CONFIG.traceLoads) {
  //             console.log("moods load ok");
  //           }
  //           this._moods = response;
  //           resolve(true);
  //         },
  //         error => {
  //           if (CONFIG.traceLoads) {
  //             console.log("moods load fail");
  //           }
  //           reject(error);
  //         }
  //       );
  //   });
  // }

  public getMoods(): Observable<any> {
    return this.http.get(this.urlGetMoods);
  }

  getMoodsObs(): Observable<any> {
    const url = CONFIG.serverUrl + ENDPOINTS.moods.get;
    return this.http.get(url);
  }
}
