import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { MusicService } from '../music-service';
import { of } from "rxjs";


@Injectable()
export class MusicPhasesResolver implements Resolve<Observable<string>> {
    constructor(private musicService: MusicService) { }
    resolve(route: ActivatedRouteSnapshot) {
        return this.musicService.getMusicPhases().catch(error => {
            return of('error');
        });
    }
}

