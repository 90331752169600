import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";

import { ISequenceType } from "../interfaces/models/sequence/i-sequence-type";
import { ISprintSequence } from "../interfaces/models/sprint-sequence/i-sprint-sequence";
import { ISprintSequenceDTO } from "../interfaces/models/sprint-sequence/i-sprint-sequence-dto";
import { SprintSequenceMapper } from "./mappers/sprint-sequence-mapper";
import { Cacheable } from "ngx-cacheable";

@Injectable()
export class SprintSequenceService {

  private _sequenceTypes: ISequenceType[];

  constructor(private http: HttpClient, private sprintSequenceMapper: SprintSequenceMapper) { }


  sequenceTypes(): ISequenceType[] {
    return this._sequenceTypes;
  }

  @Cacheable()
  getSequenceTypes(): Observable<any> {
    const url = CONFIG.serverUrl + ENDPOINTS.sequenceTypes.get + "/7";
    return this.http.get(url);
  }


  getSprintSequence(id): Observable<ISprintSequence> {
    const url = CONFIG.serverUrl + ENDPOINTS.sequenceSprint.get.replace(":id", id.toString());
    this.getSequenceTypes().subscribe(x => {
      this._sequenceTypes = x;
    });
    return this.http.get(url)
      .catch((error: HttpErrorResponse) => {
        return Observable.throw(`Error retrieving sequence ${id}`);
      })
      .map((response: ISprintSequenceDTO) => {
        if (CONFIG.traceLoads) {
          console.log("sequence sequence " + id + " load ok");
        }
        const sequence = this.sprintSequenceMapper.SprintSequenceDTOToSprintSequence(response);
        return sequence;
      });
  }


  putSequence(data: ISprintSequence): Observable<boolean> {
    const url = CONFIG.serverUrl + ENDPOINTS.sequenceSprint.put;
    const ndata = this.sprintSequenceMapper.SprintSequenceToSprintSequenceDTO(data);
    console.log(JSON.stringify(ndata));
    return this.http.put(url, ndata, {})
      .catch( (error: HttpErrorResponse) => {
        return Observable.throw(error);
      })
      .map( (response: HttpResponse<any>) => {
        return true;
      });
  }


  postSequence(data: ISprintSequence) {
    const url = CONFIG.serverUrl + ENDPOINTS.sequenceSprint.post;
    const ndata = this.sprintSequenceMapper.SprintSequenceToSprintSequenceDTO(data);
    console.log(JSON.stringify(ndata));
    return this.http.post(url, ndata, {})
      .catch( (error: HttpErrorResponse) => {
        return Observable.throw(error);
      })
      .map( (response: HttpResponse<any>) => {
        return true;
      });
  }
}
