<mat-list class="trigger-list">
  <mat-list-item *ngFor="let triggerType of triggerTypes">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between stretch" fxFill>
      <mat-checkbox (change)="changeTrigger2(triggerType)" [(ngModel)]="triggerType.checked">{{triggerType.name}}</mat-checkbox>
      <!-- <div fxLayout="column" fxFlex="50%" fxFill fxLayoutAlign="center start" class="trigger-name">
        {{triggerType.name}}
      </div>
      <div fxLayout="column" fxFlex="50%" fxFill>
        <mat-form-field>
          <mat-select [value]="getSelectedTrigger(triggerType)" (selectionChange)="changeTrigger($event, triggerType)">
          <mat-option [value]="0">-</mat-option>
            <mat-option *ngFor="let trigger of getTriggersByType(triggerType.id)" [value]="trigger.id">
              {{trigger.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
    </div>
  </mat-list-item>
</mat-list>