<div [formGroup]="form">
  <!-- <span [attr.for]="question.key" class="uk-form-label yellow">{{question.label}}</span> -->
  <!-- <span class="uk-form-label error-message" *ngIf="!isValid">* Required field</span> -->
  <!-- <div class="uk-form-controls" [ngSwitch]="question.controlType"> -->
  <div [ngSwitch]="question.controlType">
    <mat-form-field *ngSwitchCase="'textbox'" fxFill>
      <input matInput [id]="question.key" [placeholder]="question.label" [formControlName]="question.key" [type]="question.type"
        min="0" step="5" required>
    </mat-form-field>

    <mat-form-field *ngSwitchCase="'dropdown'" fxFill>
      <mat-select [placeholder]="question.label" (selectionChange)="onChange($event, question.options)"
        [(value)]="program" [id]="question.key" [formControlName]="question.key" required>
        <mat-option *ngFor="let opt of question.options" [value]="opt.key">
          {{opt.value}}
        </mat-option>
        <div *ngIf="image !== undefined && image !== ''" class="mt-1 ml-1 floatLeft">
          <img [src]="image" />
        </div>
      </mat-select>
    </mat-form-field>

    <!-- <div fxLayout="row" fxLayoutGap="10px" fxFill>
      <div fxLayout="column" fxFlex="80%">
        <mat-slider [id]="question.key" *ngSwitchCase="'textbox'" [(value)]="question.value" step="5"></mat-slider>
      </div>
      <div fxLayout="column" fxFlex="20%">
        <mat-form-field *ngSwitchCase="'textbox'" fxFill>
          <input matInput [id]="question.key" [formControlName]="question.key" [value]="question.value" required>
        </mat-form-field>
      </div>
    </div> -->

  </div>
  <!-- </div> -->

</div>