import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { SessionService } from '../session.service';

import { of } from "rxjs";
import { ExerciseService } from '../exercise.service';


@Injectable()
export class ExerciseResolver implements Resolve<Observable<string>> {

  constructor(private exerciseService: ExerciseService) { }

  resolve(route: ActivatedRouteSnapshot) {
    if (route.paramMap.get('exerciseId') !== 'new') {
      return this.exerciseService.getExercise(Number(route.paramMap.get('exerciseId'))).catch(() => {
        return of('error');
      });
    } else {
      return of('new');
    }
  }
}
