import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import * as _ from "lodash";

import { SequenceMapper } from "../../services/mappers/sequence-mapper";
import { ComboSequenceFormHelper } from "../../helpers/combo-sequence-form-helper";
import { ComboSequenceService } from "../../services/combo-sequence.service";
import { PlayerBlocksService } from "../../services/player-blocks.service";
import { NotificationService } from "../../services/notification.service";

import { ENUMS } from "../../../assets/enums";

import { ISequenceCombo } from "../../interfaces/models/sequence/i-sequence-combo";
import { ISequenceComboBlock } from "../../interfaces/models/sequence/i-sequence-combo-block";
import { ISequenceType } from "../../interfaces/models/sequence/i-sequence-type";
import { ISequencePlayerBlocks } from "../../interfaces/models/sequence/i-sequence-player-blocks";
import { ISequenceResponseType } from "../../interfaces/models/sequence/i-sequence-response-type";
import { ISequenceColorMode } from "../../interfaces/models/sequence/i-sequence-color-mode";
import { ISequenceConfiguratorPlayer } from "../../interfaces/models/sequence/form/i-sequence-configurator-player";
import { ISequenceConfiguratorFormValues } from "../../interfaces/models/sequence/form/i-sequence-configurator-form-values";
import { ISequenceConfiguratorTile } from "../../interfaces/models/sequence/form/i-sequence-configurator-tile";
import { ISequenceConfiguratorFormFields } from "../../interfaces/models/sequence/form/i-sequence-configurator-form-fields";
import { IFormFieldsSequence } from "../../interfaces/models/sequence/form/i-form-fields-sequence";
import { IFormField } from "../../interfaces/models/i-form-field";
import { IColor } from "../../interfaces/models/i-color";


@Component({
  selector: "combo-sequence-configurator",
  templateUrl: "./combo-sequence-configurator.component.html",
  styleUrls: ["./combo-sequence-configurator.component.css"],
  providers: [PlayerBlocksService]
})
export class ComboSequenceConfiguratorComponent implements OnInit {
  formFields: IFormFieldsSequence;
  availableValues: ISequenceConfiguratorFormFields;
  formValues: ISequenceConfiguratorFormValues;

  sequenceTypes: ISequenceType[];
  responseTypes: ISequenceResponseType[];
  colorModes: ISequenceColorMode[];
  players: ISequenceConfiguratorPlayer[];

  tiles: ISequenceConfiguratorTile[];
  colorTiles: ISequenceConfiguratorTile[];

  sequence: ISequenceCombo;
  currentBlock: ISequenceComboBlock;
  currentPlayerId: number;
  currentColor: IColor;

  editingBlock: boolean;
  editingBlockId: number;
  editingSequence: boolean;


  constructor(private router: Router,
    private sequenceService: ComboSequenceService,
    private route: ActivatedRoute,
    private sequenceMapper: SequenceMapper,
    private blocksService: PlayerBlocksService,
    private notificationService: NotificationService) {
    }


  ngOnInit() {
    this.loadData();
    this.sequence = this.route.snapshot.data.loaded;
    if (this.sequence.id !== 0) {
      this.editingSequence = true;
    }

    const block = ComboSequenceFormHelper.getEmptyBlock();
    this.currentBlock = block;
    block.player = 0;

    this.players = [];
    this.currentPlayerId = 1;
    for (let index = 1; index <= this.sequence.players; index++) {
      this.players.push( {
        id: index,
        blocks: _.cloneDeep(this.sequence.blocks.filter(x => x.player === index)).map(x => {
          return this.sequenceMapper.SequenceComboBlockToSequenceComboBlockPlayer(x);
        })
      });
    }

    this.formValues = {
      stepTypeId: 0,
      stepType: undefined,
      colorTypeId: 0,
      colorType: undefined,
      responseTypeId: 0,
      responseType: undefined,
      colors: [],
      responseTime: 0,
      tiles: []
    };

    this.tiles = [];

    for (let index = 1; index < 19; index++) {
      this.tiles.push( { id: index, tileNumber: index, selected: false, color: undefined  });
    }

    this.colorTiles[0].selected = true;

    this.resetColor();
  }


  loadData() {
    this.formFields = ComboSequenceFormHelper.getFormFields();
    this.availableValues = ComboSequenceFormHelper.getAvailableValues();
    this.sequenceTypes = this.sequenceService.sequenceTypes();
    this.responseTypes = this.sequenceService.sequenceResponseTypes();
    this.colorModes = this.sequenceService.sequenceColorModes();

    this.colorTiles = ComboSequenceFormHelper.getColorTiles(this.availableValues.presetColors);
  }


  colorTypeChanged(reset: boolean) {
    this.formValues.colorTypeId = Number(this.formValues.colorTypeId);
    this.formValues.colorType = this.availableValues.colorTypes.find( c => c.id === this.formValues.colorTypeId);
    if (this.formValues.colorType.pickable) {
      this.formFields.colorPickerField.visible = true;
      this.formFields.colorPickerField.required = true;
    } else {
      this.formFields.colorPickerField.visible = false;
      this.formFields.colorPickerField.required = false;
      if (reset) {
        this.resetTiles();
        this.resetColor();
      }
    }
  }


  stepTypeChanged() {
    this.formValues.stepTypeId = Number(this.formValues.stepTypeId);
    this.formValues.stepType = this.availableValues.stepTypes.find( c => c.id === this.formValues.stepTypeId);

    for (let index = 0; index < this.tiles.length; index++) {
      this.tiles[index].selected = false;
      this.tiles[index].color = undefined;
    }

    this.formValues.responseTime = 0;

    switch (this.formValues.stepTypeId) {
      case 0:
        this.toggleField(this.formFields.colorPickerField, false);
        break;
      case 1:
      case 2:
      case 3:
        this.toggleField(this.formFields.colorPickerField, true);
        this.toggleField(this.formFields.colorTypeField, true);
        this.toggleField(this.formFields.stationTilesField, true);
        this.toggleField(this.formFields.responseTypeField, true);
        this.toggleField(this.formFields.timeField, false);
        this.colorTypeChanged(true);
        this.responseTypeChanged();
        break;
      case 4:
        this.toggleField(this.formFields.colorPickerField, false);
        this.toggleField(this.formFields.stationTilesField, false);
        this.toggleField(this.formFields.colorTypeField, false);
        this.toggleField(this.formFields.responseTypeField, false);
        this.toggleField(this.formFields.timeField, true);
        break;
      case 5:
        this.toggleField(this.formFields.colorPickerField, true);
        this.toggleField(this.formFields.colorTypeField, true);
        this.toggleField(this.formFields.stationTilesField, true);
        this.toggleField(this.formFields.responseTypeField, true);
        this.toggleField(this.formFields.timeField, false);
        this.colorTypeChanged(true);
        this.responseTypeChanged();
        break;
    }
  }


  responseTypeChanged() {
    this.formValues.responseTypeId = Number(this.formValues.responseTypeId);
    this.formValues.responseType = this.availableValues.responseTypes.find( c => c.id === this.formValues.responseTypeId);
    if (this.formValues.responseType.responseByTime) {
      this.formFields.timeField.visible = true;
      this.formFields.timeField.required = true;
    } else {
      this.formFields.timeField.visible = false;
      this.formFields.timeField.required = false;
    }
  }


  colorSelected(color: IColor) {
    this.currentColor = color;
    for (let index = 0; index < this.tiles.length; index++) {
      if (this.tiles[index].selected) {
        this.tiles[index].color = color;
      }
    }
  }


  resetColor() {
    this.currentColor = this.availableValues.presetColors[0];
  }


  tileSelected(id) {
    if (!this.formValues.stepType.multiple && !this.editingBlock) {
      this.addBlock();
    }
  }


  tileDeselected(id) {
    // this.formValues.tiles = this.formValues.tiles.filter( x => x !== id);
    this.tiles.find(x => x.id === id).selected = false;
  }


  toggleField(field: IFormField, active: boolean) {
    field.visible = active;
    field.required = active;
  }


  addBlock() {
    this.formValues.tiles = this.tiles.filter(x => x.selected).map(x => x.id);
    this.formValues.colorType = this.availableValues.colorTypes.find(x => x.id === this.formValues.colorTypeId);
    this.formValues.stepType = this.availableValues.stepTypes.find(x => x.id === this.formValues.stepTypeId);
    this.formValues.responseType = this.availableValues.responseTypes.find(x => x.id === this.formValues.responseTypeId);

    // NO TILES && NO DELAY
    if (this.formValues.tiles.length === 0 && this.formValues.stepTypeId !== 4) {
      this.formFields.stationTilesField.error = true;
    }

    ComboSequenceFormHelper.validateNumberField(this.formFields.stepTypeField, this.formValues.stepTypeId);
    ComboSequenceFormHelper.validateNumberField(this.formFields.colorTypeField, this.formValues.colorTypeId);
    ComboSequenceFormHelper.validateNumberField(this.formFields.responseTypeField , this.formValues.responseTypeId);
    ComboSequenceFormHelper.validateNumberField(this.formFields.timeField , this.formValues.responseTime);
    ComboSequenceFormHelper.validateArrayField(this.formFields.stationTilesField, this.formValues.tiles);

    // CHECK ERRORS TO PREVENT SUBMIT
    let formError = false;
    for (const i in this.formFields) {
      if (this.formFields[i]["error"]) {
        formError = true;
        break;
      }
    }

    if (formError) {
      window.scroll(0, 0);
    } else {
      this.addBlockToPlayer();
    }
  }


  selectPlayer(id) {
    this.currentPlayerId = id;
  }


  editBlock(blockId) {
    this.resetTiles();
    this.resetColor();

    this.editingBlock = true;
    this.currentBlock = this.sequence.blocks.find(x => x.id === blockId);
    this.currentPlayerId = this.currentBlock.player;

    this.formValues.stepTypeId = this.currentBlock.sequenceTypeId;
    this.stepTypeChanged();

    const bhActive = this.currentBlock.behaviors.find(x => x.conditionTypeId === 1);

    if (this.formValues.stepTypeId !== 4) {
      this.formValues.tiles = [];
      this.colorTiles.forEach(tile => {
        tile.selected = false;
      });
      let auxTile: ISequenceConfiguratorTile;
      if (bhActive.tiles.length > 0) {
        this.formValues.tiles = bhActive.tiles.map(x => x.tileNumber);
        for (let index = 0; index < this.formValues.tiles.length; index++) {
          auxTile = this.tiles.find(x => x.id === this.formValues.tiles[index]);
          auxTile.selected = true;
          if (bhActive.colorModeId === 2) {
            auxTile.color = this.availableValues.presetColors[0];
          } else {
            auxTile.color = this.availableValues.presetColors.find( x => x.hex === bhActive.colors[0].color);
          }
        }
      } else {
        this.formValues.tiles.push(bhActive.tileId);
        auxTile = this.tiles.find( x => x.id === bhActive.tileId);
        auxTile.selected = true;
        if (bhActive.colorModeId === 2) {
          auxTile.color = this.availableValues.presetColors[0];
        } else {
          auxTile.color = this.availableValues.presetColors.find( x => x.hex === bhActive.colors[0].color);
        }
      }
      this.selectColorTile(auxTile.color.hex);
    }

    this.formValues.colorTypeId = bhActive.colorModeId;
    this.formValues.responseTypeId = bhActive.responseTypeId;
    this.formValues.responseTime = bhActive.time;

    this.colorTypeChanged(false);
    this.responseTypeChanged();
  }


  selectColorTile(hexcolor) {
    this.colorTiles.forEach(x => x.selected = false);
    this.colorTiles.find(x => x.color.hex === hexcolor).selected = true;
    this.currentColor = this.availableValues.presetColors.find(x => x.hex === hexcolor);
  }


  deleteBlock(blockId) {
    const ok = confirm("Are you sure you want to delete this step?");
    if (ok) {
      const auxBlock = this.sequence.blocks.find(x => x.id === blockId);
      const auxPlayer = this.players.find( x => x.id === auxBlock.player);
      this.sequence.blocks = this.sequence.blocks.filter(x => x.id  !== blockId);
      auxPlayer.blocks = auxPlayer.blocks.filter(x => x.id !== blockId);
    }
  }


  deleteBlocksFromPlayer(playerId) {
    const ok = confirm("Are you sure you want to delete ALL steps of this player?");
    if (ok) {
      this.players.find(x => x.id === playerId).blocks = [];
      this.sequence.blocks = this.sequence.blocks.filter(x => x.player !== playerId);
    }
  }


  resetTiles() {
    for (let i = 0; i < this.tiles.length; i++ ) {
      this.tiles[i].color = undefined;
      this.tiles[i].selected = false;
    }
  }


  generateBlockFromValues() {
    const bhActive = this.currentBlock.behaviors.find(x => x.conditionTypeId === 1);
    if (this.formValues.tiles.length === 1) {
      bhActive.tileId = this.formValues.tiles[0];
    } else {
      bhActive.tiles = [];
      this.formValues.tiles.forEach( t => {
        if (bhActive.tiles.findIndex(x => x.tileNumber === t) === -1) {
          bhActive.tiles.push({id: t, tileNumber: t, blockBehavior: bhActive.id});
        }
      });
      bhActive.tiles = bhActive.tiles.sort( (t1, t2) => t1.tileNumber - t2.tileNumber);
    }

    bhActive.responseTypeId = this.formValues.responseTypeId;
    bhActive.responseType = this.sequenceService.sequenceResponseTypes().find(x => x.id === bhActive.responseTypeId);
    bhActive.colors = [];
    this.tiles.forEach(tile => {
      if (tile.selected) {
        bhActive.colors.push({ id: 0, color: tile.color.hex,  idColorBlockBehavior: 0 });
      }
    });

    bhActive.time = this.formValues.responseTime;
    bhActive.colorModeId = this.formValues.colorTypeId;
    this.currentBlock.sequenceTypeId = this.formValues.stepTypeId;
    this.currentBlock.sequenceType = this.sequenceService.sequenceTypes().find( x => x.id === this.currentBlock.sequenceTypeId);

    this.resetTiles();
    this.currentBlock.player = this.currentPlayerId;
  }


  addBlockToPlayer() {
    this.generateBlockFromValues();

    const auxBlock = ComboSequenceFormHelper.copyBlock(this.currentBlock);
    const nBlock = _.cloneDeep(this.currentBlock);
    const currentPlayer = this.players.find(x => x.id === this.currentPlayerId);

    if (this.editingBlock) {

      const playerBlockIndex = currentPlayer.blocks.findIndex( x => x.id === this.currentBlock.id);
      currentPlayer.blocks[playerBlockIndex] = this.sequenceMapper.SequenceComboBlockToSequenceComboBlockPlayer(nBlock);

      const sequenceBlockIndex = this.sequence.blocks.findIndex( x => x.id === this.currentBlock.id);
      this.sequence.blocks[sequenceBlockIndex] = nBlock;

      this.editingBlock = false;
    } else {
      if (this.sequence.blocks.length === 0) {
        nBlock.id = 1;
      } else {
        nBlock.id = Math.max(...this.sequence.blocks.map(x => x.id)) + 1;
      }
      this.sequence.blocks.push(nBlock);
      currentPlayer.blocks.push(this.sequenceMapper.SequenceComboBlockToSequenceComboBlockPlayer(nBlock));
    }
    // set block to new block without tiles
    this.currentBlock = auxBlock;
  }


  cancelBlockEditing() {
    this.editingBlock = false;
    this.generateBlockFromValues();
    const auxBlock = ComboSequenceFormHelper.copyBlock(this.currentBlock);
    this.currentBlock = auxBlock;
  }


  goBack() {
    this.router.navigate(["/exercise", this.sequence.exerciseId]);
  }


  saveSequence() {
    if (this.editingSequence) {
      this.sequenceService.putSequence(this.sequence).subscribe(
        response => {
          if (response) {
            this.notificationService.notify("Sequence modified successfully",
              ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
            this.router.navigate(["/exercises"]);
          }
        }, error => {
          this.notificationService.notify("An error occurred while saving sequence",
            ENUMS.notification.types.error, ENUMS.notification.positions.custom_responsive);
        }
      );
    } else {
      this.sequenceService.postSequence(this.sequence).subscribe(
        response => {
          if (response) {
            this.notificationService.notify("Sequence created successfully",
              ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
            this.router.navigate(["/exercises"]);
          }
        }, error => {
          this.notificationService.notify("An error occurred while saving sequence",
            ENUMS.notification.types.error, ENUMS.notification.positions.custom_responsive);
        }
      );
    }
  }
}
