<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
  <div fxLayout="column" fxFlex="100" fxFlexAlign="stretch">

    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h4 fxFlex="50">{{ providers?.length }} providers</h4>
        <button class="uk-button prama-button medium-button" (click)="openCreate()">
            <i class="fa fa-plus-circle faSize"></i>
        </button>
    </div>

    <mat-table #table [dataSource]="dataSource" matSort class="session-table">

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"> Id </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="5%">
          {{element.id}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="40%"> Name </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="40%">
          <div *ngIf="editMode != element.id">
            {{element.name}}
          </div>
          <div *ngIf="editMode == element.id">
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="element.name" name="element?.name" placeholder="name" required>
            </mat-form-field>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="website">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="30%"> Website </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="30%">
          <div *ngIf="editMode != element.id">
            <a target="_blank" href="{{ element.website.indexOf('http') >= 0 ? element.website : '//'+element.website }}" class="yellow">
                {{ element.website }} <mat-icon *ngIf="element.website && element.website!==''">open_in_new</mat-icon>
            </a>
          </div>
          <div *ngIf="editMode == element.id">
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="element.website" name="{{element?.name+'web'}}" placeholder="website" required>
            </mat-form-field>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="table-column-short"> Actions </mat-header-cell>
        <mat-cell *matCellDef="let provider" class="table-column-short">
          <button mat-icon-button (click)="openEditor(provider)" *ngIf="editMode != provider.id && canModify">
            <mat-icon title="Edit">mode_edit</mat-icon>
          </button>
          <button mat-icon-button (click)="saveProvider(provider)" *ngIf="editMode == provider.id && !newMode && canModify">
            <mat-icon title="Save">save</mat-icon>
          </button>
          <button mat-icon-button (click)="createProvider(provider)" *ngIf="provider.id == 0 && newMode && canCreate">
            <mat-icon title="Save">check</mat-icon>
          </button>
          <button mat-icon-button (click)="modalRemoveProviderOpen(provider)" *ngIf="provider.id == editMode && !newMode && canDelete">
            <mat-icon title="Delete">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

    </mat-table>
    
  </div>
</div>
