import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { TimelineService } from '../timeline.service';

import { of } from "rxjs";


@Injectable()
export class TimelineFormResolver implements Resolve<Observable<string>> {

  constructor(private timelineService: TimelineService) { }

  resolve(route: ActivatedRouteSnapshot) {
    if (route.paramMap.get('timelineId') !== 'new') {
      return this.timelineService.getTimeline(Number(route.paramMap.get('timelineId'))).catch(() => {
        return of('error');
      });
    } else {
      return of('new');
    }
  }
}

