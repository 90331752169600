import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { CategoriesService } from '../categories.service';

import { of } from "rxjs";

@Injectable()
export class CategoriesResolver implements Resolve<Observable<string>> {

    constructor(private categoriesService: CategoriesService) { }

    resolve() {
        return this.categoriesService.getCategories().catch(() => {
            return of('error');
        });
    }

}

