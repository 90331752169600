<div class="home">


  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
    <h5 fxFlex="nogrow">MAIN ACTIONS</h5>
  </div>
  <div fxLayout="row" fxLayout.xs="column" fxFlexAlign="stretch" fxFill>
    <div fxLayout="column" fxFlex="33.3333" fxFlexAlign="stretch">
      <mat-card fxFlex>
        <mat-card-header>
          <h5 class="nomargin mb10">WORKOUTS</h5>
          <hr>
        </mat-card-header>
        <mat-card-content>
          <div fxLayout="row" fxLayout.xs="column" class="mt20">
            <hr>
            <div fxLayout="column" fxFlex="50" class="mr10">
              <button mat-flat-button class="prama-button mb20" [routerLink]="['/homefitness/warmups']">
                GO TO WARM UP <mat-icon>arrow_forward</mat-icon>
              </button>
              <button mat-flat-button class="prama-button mb20" [routerLink]="['/homefitness/sessions']">
                GO TO SESSION <mat-icon>arrow_forward</mat-icon>
              </button>
              <button mat-flat-button class="prama-button mb20" [routerLink]="['/homefitness/cooldowns']">
                GO TO COOLDOWN <mat-icon>arrow_forward</mat-icon>
              </button> 
              <button mat-flat-button class="prama-button" [routerLink]="['/homefitness/workouts']">
                GO TO WORKOUT <mat-icon>arrow_forward</mat-icon>
              </button>
            </div>
            <div fxLayout="column" fxLayout.xs="column" fxFlex="50" class="ml10">
              <p>Here you can create a Warm Up, Session and a Cooldown and merge them to create a Workout. The session will be made with exercises you can choose, the Warm Up and Cooldown can be made by exercises or by linking a video.</p>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div fxLayout="column" fxFlex="33.3333" fxFlexAlign="stretch">
      <mat-card fxFlex>
        <mat-card-header>
          <h5 class="nomargin mb10">MY PRAMA COACH</h5>
          <hr>
        </mat-card-header>
        <mat-card-content>
          <div fxLayout="row" fxLayout.xs="column" >
          </div>
          <div fxLayout="row" fxLayout.xs="column" class="mt20">
            <div fxLayout="column" fxFlex="50" class="mr10">
              <button mat-flat-button class="prama-button mb20" [routerLink]="['/exercises']">
                GO TO WARM UP <mat-icon>arrow_forward</mat-icon>
              </button>
              <button mat-flat-button class="prama-button mb20" [routerLink]="['/exercises']">
                GO TO SESSION <mat-icon>arrow_forward</mat-icon>
              </button>
              <button mat-flat-button class="prama-button mb20" [routerLink]="['/exercises']">
                GO TO COOLDOWN <mat-icon>arrow_forward</mat-icon>
              </button> 

            </div>
            <div fxLayout="column" fxLayout.xs="column" fxFlex="50" class="ml10">
              <p>Here you can link videos to a Studio Workout or Workshop. By the Training Plan option you can package some Workouts and Studio Workouts to create a plan that the user must complete in the indicated days.</p>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div fxLayout="column" fxFlex="33.3333" fxFlexAlign="stretch">
      <mat-card fxFlex>
        <mat-card-header>
          <h5 class="nomargin mb10">CONTENT MANAGER</h5>
        </mat-card-header>
        <mat-card-content>
          <div fxLayout="row">
            <hr>
            <p>Here you can choose which content will be shared with the platform and how many time.</p>         
          </div>
          <div fxLayout="row">
            <div fxLayout="column" fxLayout.xs="column" fxFlex="50" fxFlexAlign="end">
              <button mat-flat-button class="prama-button float-right" [routerLink]="['/timelines']">
                GO TO TIMELINES <mat-icon>arrow_forward</mat-icon>
              </button>
            </div>
          </div>
                  </mat-card-content>
      </mat-card>
    </div>
  </div>

</div>
