import { Injectable } from "@angular/core";
import { ISequenceComboBlock } from "../interfaces/models/sequence/i-sequence-combo-block";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";
import { Subject } from "rxjs/Subject";

@Injectable()
export class PlayerBlocksService {
  private blockToAdd = new Subject<ISequenceComboBlock>();
  blockToAdd$ = this.blockToAdd.asObservable();
  constructor() { }

  addBlock(block: ISequenceComboBlock) {
    this.blockToAdd.next(block);
  }

}
