import { Component, OnInit, TemplateRef, Inject, NgZone, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

import { ENUMS } from '../../../assets/enums';

import { NotificationService } from '../../services/notification.service';
import { IWorkout } from '../../interfaces/models/i-workout';
import { HomeFitnessWorkoutService } from '../../services/home-fitness-workout.service';

@Component({
    selector: 'app-modal-home-fitness-remove-workout',
    templateUrl: 'modal-home-fitness-remove-workout.html',
})
export class ModalHomeFitnessRemoveWorkout {
    message: string;
    title: string;
    workout: IWorkout;
    canDelete: boolean;

    @Output() onDelete = new EventEmitter<IWorkout>();
    
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalHomeFitnessRemoveWorkout>,
        private hfWorkoutService: HomeFitnessWorkoutService,
        private notificationService: NotificationService,
        private zone: NgZone,
        public router: Router) {

        this.canDelete = data.canDelete;
        this.workout = data.workout;
        this.message = "You cannot undo this action. Would you continue?";

    }

    removeWorkout(workout: IWorkout) {
        this.deleteWorkout(workout);
        this.dialogRef.close();
    }

    modalRemoveWorkoutCancel() {
        this.dialogRef.close();
    }

    public deleteWorkout(workout?: IWorkout) {
        const workoutToDelete = workout ? workout : this.workout;
        this.hfWorkoutService.deleteWorkout(workout).subscribe(
            result => {
                this.notificationService.notify("Workout deleted successfully",
                    ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
                this.onDelete.emit(workout);
            },
            error => {
                console.log('Error', error);
            }
        );
    }
}

