import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ProgramService } from '../program.service';

import { of } from "rxjs";
import { SessionService } from '../session.service';


@Injectable()
export class ExecutionModesResolver implements Resolve<Observable<string>> {

    constructor(private sessionService: SessionService) { }

    resolve() {
        return this.sessionService.getExecutionModes().catch(() => {
            return of('error');
        });
    }

}

