<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
  <div fxLayout="column" fxFlex="100" fxFlexAlign="stretch">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h4 fxFlex="50"> {{ currentProgram.name }} PLAYLISTS </h4>
    </div>
    <div class="ml10">
      <mat-table #table [dataSource]="dataSource" matSort class="exercise-table" *ngIf="playlists?.length !== 0">
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"> Id </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="5%">
            {{element.idPlaylist}}
            <a [routerLink]="['/music', currentProgram.id, element.idPlaylist ]" class="mat-row-link"></a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="30%"> Name </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="30%">
            <h4>{{element.playlistName}}</h4>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastUpdate">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Last update </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{element.lastUpdate | date:'medium'}}
          </mat-cell>
        </ng-container>
        <!--
        <ng-container matColumnDef="lastUpdate">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="15%"> Last update</mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="15%">
            <mat-form-field>
              <input matInput [matDatepicker]="picker" placeholder="-" [(ngModel)]="element.lastUpdate" disabled>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker disabled></mat-datepicker>
            </mat-form-field>
          </mat-cell>
        </ng-container>
        -->
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
  </div>
</div>