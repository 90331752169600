import { Injectable } from '@angular/core';
import { SessionService } from '../session.service';
import { HomeFitnessSessionService } from '../home-fitness-session.service';
import { HomeFitnessWorkoutService } from '../home-fitness-workout.service';

@Injectable({
  providedIn: 'root'
})
export class HomeFitnessWorkoutsResolver {

  constructor(private workoutService: HomeFitnessWorkoutService) { }

    resolve() {
        return this.workoutService.getWorkouts();
    }
}
