<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>   
  <div fxLayout="column" fxFlex="100" fxFlexAlign="stretch">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h4 fxFlex="50"> Programs </h4>
    </div>
    <div class="ml10">
      <mat-table #table [dataSource]="dataSource" matSort class="exercise-table" *ngIf="programsList?.length !== 0">
        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"> Id </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="5%">
            {{element.id}}
            <a [routerLink]="['/music', element.id ]" class="mat-row-link"></a>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="30%"> Name </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="30%">
            <h4>{{element.name}}</h4>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
  </div>
</div>