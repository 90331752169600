<div fxLayout="row" fxLayoutAlign="stretch stretch" fxLayoutGap="20px" fxFill  class="w-100">
  <div fxLayout="column" fxFlex="100" fxFlexAlign="stretch">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h4 fxFlex="50">SELECT SONGS - {{getSelectedSongs()}} / {{currentPhase.phase.neededSongs}} </h4>
      <mat-form-field>
        <input matInput #message placeholder="Filter by title" class="mat-input-element yellow" [(ngModel)]="filterValue"  (keyup)="filterSongsByTitle()">
      </mat-form-field>
    </div>
    <mat-table #table [dataSource]="dataSource" matSort class="packages-table no-selectable-table" *ngIf="dataSource && this.songsList && this.songsList.length > 0">  
      <ng-container matColumnDef="check">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10%">
            <!--<mat-checkbox (change)="$event ? masterToggle() : null"> </mat-checkbox>-->
        </mat-header-cell>
        <mat-cell *matCellDef="let song" fxFlex="10%">
          <mat-checkbox [(ngModel)]="song.selected" [disabled]="isDisabled(song)"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="player">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10%"> </mat-header-cell>
        <mat-cell *matCellDef="let song" fxFlex="10%">
          <button mat-icon-button (click)="playSong(song)" *ngIf="playingSongId !== song.id">
            <mat-icon>play_arrow</mat-icon>
          </button>
          <button mat-icon-button (click)="stopSong(song)" *ngIf="playingSongId === song.id">
            <mat-icon>stop</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="80%"> 
          Title
        </mat-header-cell>
        <mat-cell *matCellDef="let song" fxFlex="80%">
          <h4>{{song.title}}</h4>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator *ngIf="dataSource && this.songsList && this.songsList.length > 0" [pageSizeOptions]="[15, 25, 50, 100]" showFirstLastButtons></mat-paginator>
    <div *ngIf="!dataSource || !this.songsList || this.songsList.length == 0">
      <h5>There are no songs for this program and phase</h5>
    </div>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="stretch stretch" fxLayoutGap="20px" fxFill class="w-100">
  <mat-toolbar class="mat-buttons-toolbar w-100">
    <mat-toolbar-row>
      <span class="mat-spacer"></span>
      <button mat-flat-button class="prama-button full-button" (click)="cancelSearch()">
        <mat-icon>clear</mat-icon> CANCEL
      </button>
      <button *ngIf="dataSource && this.songsList && this.songsList.length > 0" mat-flat-button class="prama-button full-button" (click)="selectSongs()">
        <mat-icon>check</mat-icon> SELECT
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

