import { Component, OnInit ,AfterViewInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { CONFIG } from "../../../assets/config";
import { ENUMS } from "../../../assets/enums";

import { ProgramService } from "../../services/program.service";
import { WorkoutModeService } from "../../services/workoutmode.service";
import { SessionService } from "../../services/session.service";
import { NotificationService } from "../../services/notification.service";

import { ISessionFilter } from "../../interfaces/models/i-session-filter";
import { ISession } from "../../interfaces/models/i-session";
import { IProgram } from "../../interfaces/models/i-program";
import { IWorkoutMode } from "../../interfaces/models/i-workout-mode";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: "finishers-list",
  templateUrl: "./finishers-list.component.html",
  styleUrls: ["./finishers-list.component.css"]
})
export class FinishersListComponent implements OnInit {

  headers = {
    id: "Id",
    name: "Name",
    thumbnail: " ",
    desc: "Description",
    workMode: "Description",
    actions: ""
  };

  filterId: string = "";
  filterName: string = "";
  filterWorkoutModes: number[] = [];
  filterPrograms: number[] = [];

  workoutModes: IWorkoutMode[];
  programs: IProgram[];
  sessions: ISession[];
  sessionsToShow: ISession[];
  currentFilter: ISessionFilter;

  mediaUrl: string = CONFIG.sessionMediaURL;

  displayedColumns = ['id', 'sessionName', 'sessionDescription'];
  dataSource = new MatTableDataSource;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private programsService: ProgramService,
    private sessionService: SessionService,
    private workoutModesService: WorkoutModeService,
    private notificationService: NotificationService) {

    this.sessionsToShow = this.route.snapshot.data.finishers;

    this.currentFilter = {
      filteredId: "",
      filteredName: "",
      filteredWorkoutModes: [],
      filteredPrograms: []
    };

  }

  ngOnInit() {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  ngAfterViewInit() {    
    this.updateTableData();    
  }

  updateTableData(){
    this.dataSource = new MatTableDataSource(this.sessionsToShow);
  }

  filterSessions(filter) {

    this.sessionsToShow = this.sessions;

    if (filter.filteredId && filter.filteredId !== "" && this.currentFilter !== filter.filteredId) {
      this.sessionsToShow = this.sessionsToShow.filter(x => x.id.toString().includes(filter.filteredId));
      this.currentFilter.filteredId = filter.filteredId;
    }
    if (filter.filteredName && filter.filteredName !== "") {
      this.sessionsToShow = this.sessionsToShow.filter(x => x.name.toLowerCase().includes(filter.filteredName.toLowerCase()));
      this.currentFilter.filteredName = filter.filteredName;
    }

    if (filter.filteredWorkoutModes && filter.filteredWorkoutModes.length > 0) {
      const cworkoutModes: number[] = filter.filteredWorkoutModes.map(x => Number(x));
      // this.sessionsToShow = this.sessionsToShow.filter( x => cworkoutModes.includes(x.workoutModeId));
      this.currentFilter.filteredWorkoutModes = filter.filteredWorkoutModes;
    }

    if ((!filter.filteredId || (filter.filteredId && filter.filteredId === "")) &&
      (!filter.filteredName || (filter.filteredName && filter.filteredName === "")) &&
      (!filter.filteredWorkoutModes || (filter.filteredWorkoutModes && filter.filteredWorkoutModes.length === 0)) &&
      (!filter.filteredPrograms || (filter.filteredPrograms && filter.filteredPrograms.length === 0))) {
      this.sessionsToShow = this.sessions;
    }
  }

}
