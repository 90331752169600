import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { TimelineService } from '../timeline.service';

import { of } from "rxjs";


@Injectable()
export class TimelineResultsResolver implements Resolve<Observable<string>> {

  constructor(private timelineService: TimelineService) { }

  resolve(route: ActivatedRouteSnapshot) {
    return this.timelineService.getResults().catch(() => {
      return of('error');
    });
  }
}

