import { Component, OnInit, TemplateRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MusicService } from '../../services/music-service';
import { IMusicProvider } from '../../interfaces/models/i-music-provider';
import { NotificationService } from '../../services/notification.service';
import { ENUMS } from '../../../assets/enums';

@Component({
    selector: 'app-modal-remove-provider',
    templateUrl: 'modal-remove-provider.html',
})
export class ModalRemoveProviderComponent {

    provider: IMusicProvider;
    providers: IMusicProvider[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ModalRemoveProviderComponent>,
        private musicService: MusicService,
        private notificationService: NotificationService,
        private router: Router) {
        this.provider = data.provider;
        this.providers = data.providers;
    }

    removeProvider(provider: IMusicProvider) {
        this.deleteProvider(provider);
        this.dialogRef.close();
    }

    modalRemoveProviderCancel() {
        this.dialogRef.close();
    }

    public deleteProvider(provider?: IMusicProvider) {
        const providerToDelete = provider ? provider : this.provider;
        this.musicService.deleteProvider(provider).subscribe(
            result => {
                this.notificationService.notify("Provider deleted successfully",
                    ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
                    
                this.router.navigate(['/music/providers']);
            },
            error => {
                console.log('Error', error);
            }
        );
    }
}

