<h1>BASIC EXERCISES AND TIMELINES</h1>

<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
  <mat-toolbar class="mat-buttons-toolbar">
    <mat-toolbar-row>
      <span class="mat-spacer"></span>
      <button mat-flat-button (click)="saveProgramBasicTimelines()" class="prama-button full-button medium-button">
        <mat-icon>save</mat-icon> SAVE
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
</div>

<mat-tab-group>
  <mat-tab label="EXERCISES">

    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>

      <div fxLayout="column" fxLayout.xs="column" fxFlex="50" fxLayoutGap="20px" fxFlexAlign="stretch" class="program-timelines-container">

        <mat-card class="card-cont-generic">
          <h6>{{ totalExercisesActive?.length }} basic exercises</h6>
          <p>Theese exercises are BASIC. Click on X to remove from this list</p>
          <mat-form-field fxFill>
            <input matInput (keyup)="applyFilterExercisesActive($event.target.value)" placeholder="Filter">
          </mat-form-field>
        </mat-card>

        <mat-table #table [dataSource]="dataSourceExercisesActive" matSort class="timeline-table margin10 free-height nomargin-bottom">

          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8%"> Id </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="8%">
              {{element.id}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="station">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"></mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="5%">
              <img [src]="'/assets/stations/' + element.station + '.png'" />
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <h4>{{element.name}}</h4>
              <a target="_blank" [routerLink]="['/exercise/'+element.id]" routerLinkActive="router-link-active">
                <mat-icon class="internal-link">visibility</mat-icon>
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="options">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8%"> </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="8%">
              <button type="button" mat-flat-button class="prama-transparent-button" (click)="askDisableExercise(element)">
                <mat-icon class="yellow">clear</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsExercisesActive; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsExercisesActive;"></mat-row>
        </mat-table>
        <mat-paginator #paginatorExercisesActive [pageSizeOptions]="[10, 250, 1000]" showFirstLastButtons class="margin10 nomargin-top"></mat-paginator>
      </div>

      <div fxLayout="column" fxLayout.xs="column" fxFlex="50" fxLayoutGap="20px" fxFlexAlign="stretch" class="program-timelines-container">

        <mat-card class="card-cont-generic">
          <h6>{{ totalExercisesAvailable?.length }} available exercises</h6>
          <p>Pick exercises from this table to convert them to BASIC exercises</p>
          <mat-form-field fxFill>
            <input matInput (keyup)="applyFilterExercisesAvailable($event.target.value)" placeholder="Filter">
          </mat-form-field>

        </mat-card>

        <mat-table #table [dataSource]="dataSourceExercisesAvailable" matSort class="timeline-table margin10 nomargin-bottom free-height">

          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8%"> Id </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="8%">
              {{element.id}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="station">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%"></mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="5%">
              <img [src]="'/assets/stations/' + element.station + '.png'" />
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <h4>{{element.name}}</h4>
              <a target="_blank" [routerLink]="['/exercise/'+element.id]" routerLinkActive="router-link-active">
                <mat-icon class="internal-link">visibility</mat-icon>
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="status-text">{{getStatus(element)}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="options">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8%"> </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="8%">
              <button type="button" mat-flat-button class="prama-transparent-button" (click)="enableExercise(element)">
                <mat-icon class="yellow">add</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsExercisesAvailable; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsExercisesAvailable;"></mat-row>
        </mat-table>
        <mat-paginator #paginatorExercisesAvailable [pageSizeOptions]="[10, 250, 1000]" showFirstLastButtons class="margin10 nomargin-top"></mat-paginator>

      </div>
    </div>

  </mat-tab>

  <mat-tab label="TIMELINES">

    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>

      <div fxLayout="column" fxLayout.xs="column" fxFlex="50" fxLayoutGap="20px" fxFlexAlign="stretch" class="program-timelines-container">

        <mat-card class="card-cont-generic">
          <h6>{{ totalTimelinesActive?.length }} basic timelines</h6>
          <p>Theese timelines are BASIC. Click on X to remove from this list</p>
          <mat-form-field fxFill>
            <input matInput (keyup)="applyFilterTimelinesActive($event.target.value)" placeholder="Filter">
          </mat-form-field>
        </mat-card>

        <mat-table #table [dataSource]="dataSourceTimelinesActive" matSort class="timeline-table margin10 free-height nomargin-bottom">

          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8%"> Id </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="8%">
              {{element.id}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <h4>{{element.name}}</h4>
              <a target="_blank" [routerLink]="['/timeline/'+element.id]" routerLinkActive="router-link-active">
                <mat-icon class="internal-link">visibility</mat-icon>
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="options">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8%"> </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="8%">
              <button type="button" mat-flat-button class="prama-transparent-button" (click)="disableTimeline(element)">
                <mat-icon class="yellow">clear</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsTimelinesActive; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsTimelinesActive;"></mat-row>
        </mat-table>
        <mat-paginator #paginatorTimelinesActive [pageSizeOptions]="[10, 250, 1000]" showFirstLastButtons class="margin10 nomargin-top"></mat-paginator>
      </div>

      <div fxLayout="column" fxLayout.xs="column" fxFlex="50" fxLayoutGap="20px" fxFlexAlign="stretch" class="program-timelines-container">

        <mat-card class="card-cont-generic">
          <h6>{{ totalTimelinesAvailable?.length }} available timelines</h6>
          <p>Pick timelines from this table to convert them to BASIC timelines</p>
          <mat-form-field fxFill>
            <input matInput (keyup)="applyFilterTimelinesAvailable($event.target.value)" placeholder="Filter">
          </mat-form-field>

        </mat-card>

        <mat-table #table [dataSource]="dataSourceTimelinesAvailable" matSort class="timeline-table margin10 nomargin-bottom free-height">

          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8%"> Id </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="8%">
              {{element.id}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <h4>{{element.name}}</h4>
              <a target="_blank" [routerLink]="['/timeline/'+element.id]" routerLinkActive="router-link-active">
                <mat-icon class="internal-link">visibility</mat-icon>
              </a>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span class="status-text">{{getStatus(element)}}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="options">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="8%"> </mat-header-cell>
            <mat-cell *matCellDef="let element" fxFlex="8%">
              <button type="button" mat-flat-button class="prama-transparent-button" (click)="askEnableTimeline(element)">
                <mat-icon class="yellow">add</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsTimelinesAvailable; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsTimelinesAvailable;"></mat-row>
        </mat-table>
        <mat-paginator #paginatorTimelinesAvailable [pageSizeOptions]="[10, 250, 1000]" showFirstLastButtons class="margin10 nomargin-top"></mat-paginator>

      </div>
    </div>

  </mat-tab>
  <mat-tab label="STATISTICS">

    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>
      <div fxLayout="column" fxFlex="100" fxFlexAlign="stretch">
        <h5 fxFlex="nogrow">BASIC EXERCISES AND TIMELINES BY PROGRAM</h5>
        <mat-card class="card-cont-generic">
          <div fxLayout="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFlex="50">
            <p>Total count of basic exercises and timelines for current available programs</p>
          </div>
          <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFlex="50">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="space-between stretch" fxFlex="50">
              <div fxFlex *ngFor="let program of programsAvailable">
                <h6>{{program.name}}</h6>
                <div class="basics-summary mt10" fxLayout="row" fxFlexAlign="stretch">
                  <mat-icon>fitness_center</mat-icon>
                  <div class="div-number">{{getExercisesProgramCount(program.id)}}</div>
                </div>
                <div class="basics-summary" fxLayout="row" fxFlexAlign="stretch">
                  <mat-icon>av_timer</mat-icon>
                  <div class="div-number">{{getTimelinesProgramCount(program.id)}}</div>
                </div>
              </div>
            </div>



          </div>

        </mat-card>
        <h5 fxFlex="nogrow">BASIC EXERCISES BY STATION</h5>
        <mat-card class="card-cont-generic">
          <div fxLayout="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFlex="50">
            <p>Total count of basic exercises for each station</p>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="space-between stretch" fxFlex="50">
            <div fxFlex *ngFor="let station of stationsAvailable">
              <h6>{{station.name}}</h6>
              <div class="basics-summary mt10" fxLayout="row" fxFlexAlign="stretch">
                <mat-icon>fitness_center</mat-icon>
                <div class="div-number">{{getBasicExercisesStationCount(station.id)}}</div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </mat-tab>

</mat-tab-group>