import { Component, OnInit } from '@angular/core';
import { IProgram } from '../../interfaces/models/i-program';
import { ProgramService } from '../../services/program.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'music-manager-home',
  templateUrl: './music-manager-home.component.html',
  styleUrls: ['./music-manager-home.component.scss']
})
export class MusicManagerHomeComponent implements OnInit {

  programs: IProgram[];

  constructor(private route: ActivatedRoute, private programsService: ProgramService) { 

  }

  ngOnInit() {
    this.programs = this.route.snapshot.data.programs;
  }

}
