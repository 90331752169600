import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { QuestionBase } from '../../interfaces/models/i-question-base';
import { QuestionControlService } from '../../services/question-control.service';
import { trigger, transition, style, animate, query, stagger, animateChild, state } from "@angular/animations";

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [QuestionControlService],
  animations: [
    trigger("panelAnim", [
      // state("closed", style({ maxHeight: "0px"})),
      // state("opened", style({ maxHeight: "1000px"})),
      state("closed", style({ maxHeight: "0px"})),
      state("opened", style({ maxHeight: "800px"})),
      transition("closed <=> opened", animate("400ms"))
    ])
  ]
})
export class DynamicFormComponent implements OnInit {

  private _questions: QuestionBase<any>[];

  public panelStatus: string = "closed";

  @Input()

  set questions(questions: QuestionBase<any>[]) {
    this._questions = questions;
    this.form = this.qcs.toFormGroup(this.questions);
    this.
      form.
      valueChanges.
      subscribe(form => {
        this.valueChange.emit(JSON.stringify(this.form.value));
      });
  }

  get questions(): QuestionBase<any>[] { return this._questions; }

  @Output() valueChange = new EventEmitter();

  form: FormGroup;

  constructor(private qcs: QuestionControlService) { }

  ngOnInit() {
    this.form = this.qcs.toFormGroup(this.questions);
    this.
      form.
      valueChanges.
      subscribe(form => {
        this.valueChange.emit(JSON.stringify(this.form.value));
      });
  }


  onSubmit() {
    return this.form !== undefined ? this.form.valid : false;
  }

  
  public togglePanel(){
    this.panelStatus = this.panelStatus === "closed" ? "opened" : "closed";
    setTimeout(()=>{
      window.scrollTo(0, window.outerHeight);
    },300)
  }
}
