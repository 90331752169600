<h1> MUSIC PACKAGES OF {{program?.name}} PROGRAM </h1>
<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexAlign="stretch" fxFill>

  <div fxLayout="row" fxLayout.xs="column" fxLayout="column" fxFlex="100" fxFlexAlign="stretch">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="nomargin">
      <h4 fxFlex="50">{{ staticPackages[0].phases?.length }} static phases </h4>
    </div>

    <mat-table #table [dataSource]="spDataSource" matSort class="packages-table nomargin">  
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="40%"> Name </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="40%">
          <h4>{{element.phase.phase.name}}</h4>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastUpdate">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="40%"> Last update </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="40%">
          <h4>{{element.lastUpdate | date:'medium'}}</h4>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="songs">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10%"> Songs </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="10%">
          <h4>{{element.songs.length}} / {{element.phase.neededSongs}}</h4>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="10%">  </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlex="10%">
            <button mat-icon-button (click)="openSongsModal(element, searchSongsModal)">
              <mat-icon title="Edit">audiotrack</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

      <mat-header-row *matHeaderRowDef="spDisplayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: spDisplayedColumns;"></mat-row>
    </mat-table>
  </div>
  
  <div fxLayout="row" fxLayout.xs="column" fxLayout="column" fxFlex="100" fxFlexAlign="stretch">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="nomargin">
      <h4 fxFlex="50">{{ packages?.length }} packages</h4>
      <button class="uk-button prama-button medium-button" (click)="openCreateMusicPackage()">
        <i class="fa fa-plus-circle faSize"></i>
      </button>
    </div>

    <table mat-table matSort multiTemplateDataRows [dataSource]="dataSource" class="packages-table nomargin">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="30%"> Name </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="30%">
          <div *ngIf="editMode != element.id">
            <h4>{{element.name}}</h4> 
          </div>
          <div *ngIf="editMode == element.id">
            <mat-form-field>
              <input matInput type="text" [(ngModel)]="element.name" name="element?.name" placeholder="name" required>
            </mat-form-field>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastUse">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="30%"> Last update </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="30%">
          <h4>{{element.lastUpdate | date:'medium'}}</h4>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="20%"> Status </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlex="20%">
          <h4>{{element.status.name}}</h4> 
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="15%">  </mat-header-cell>
        <mat-cell *matCellDef="let element" fxFlexAlign="end" fxFlex="15%">
          <button mat-icon-button (click)="openEdit(element)" *ngIf="editMode != element.id && canModify">
            <mat-icon title="Edit">mode_edit</mat-icon>
          </button>
          <button mat-icon-button (click)="saveMusicPackage(element)" *ngIf="editMode == element.id && !newMode && canModify">
            <mat-icon title="Save">save</mat-icon>
          </button>
          <button mat-icon-button (click)="createMusicPackage(element)" *ngIf="element.id == 0 && newMode && canCreate">
            <mat-icon title="Save">check</mat-icon>
          </button>
          <button mat-icon-button (click)="modalRemoveMusicPackage(element)" *ngIf="element.id == editMode && !newMode && canDelete">
            <mat-icon title="Delete">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="panelActions">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="5%">  </mat-header-cell>
          <mat-cell *matCellDef="let element" fxFlexAlign="end" fxFlex="5%">
              <button mat-icon-button (click)="toggleExpandedElement(element)">
                  <mat-icon class="expandable-status-icon" [@detailExpand]="element == expandedElement ? 'open' : 'closed'" title="Show detail">keyboard_arrow_down</mat-icon>
                </button>
          </mat-cell>
        </ng-container>

      <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="expandable-element-detail" fxLayout="column" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

          <div fxLayout="row" fxFlexAlign="stretch" fxFill *ngFor="let packagePhase of element.phases">
            <div fxLayout="column" fxFlex="30%">
              <h4>{{packagePhase.phase.phase.name}}</h4>
            </div>
            <div fxLayout="column" fxFlex="30%">
              <h4>{{packagePhase.songs.length}} / {{packagePhase.phase.neededSongs}} SONGS</h4>
            </div>
            <div fxLayout="column" fxFlex="30%">
              <button mat-icon-button (click)="openSongsModal(packagePhase, searchSongsModal)">
                <mat-icon title="Select songs">audiotrack</mat-icon>
              </button>
            </div>
          </div>

        </div>
      </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let element; columns: displayedColumns;"
        class="expandable-element-row"
        [class.expandable-expanded-row]="expandedElement === element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-detail-row"></tr>
      </table>

  </div>
</div>

<ng-template #searchSongsModal>
  <div class="modal-search-exercise">
    <div class="modal-body">
      <button type="button" class="floatRight" mat-flat-button class="prama-transparent-button" (click)="modalRef.hide()">
        <mat-icon>clear</mat-icon>
      </button>
      <song-search [songs]="searchSongs" [currentPhase]="searchPhase" (searchCancelled)="cancelSearch()" (searchCompleted)="savePhase(searchPhase)">
      </song-search>
    </div>
  </div>
</ng-template>