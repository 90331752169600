import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { CONFIG } from "../../assets/config";
import { ENDPOINTS } from "../../assets/endpoints";

import { IStatus } from "../interfaces/models/i-status";

@Injectable()
export class StatusService {

  private _status: IStatus[];

  constructor(private http: HttpClient) { }

  public status() {
    return this._status;
  }

  getStatus(): Promise<boolean> {
    const url = CONFIG.serverUrl + ENDPOINTS.status.get;

    return new Promise((resolve, reject) => {

      if (this._status && this._status.length > 0) {
        if (CONFIG.traceLoads) {
          console.log("status load ok - cache");
        }
        return resolve(true);
      }

      this.http.get(url)
        .catch((error) => Observable.throw(error))
        .subscribe(
          (response: IStatus[]) => {
            if (CONFIG.traceLoads) {
              console.log("status load ok");
            }
            this._status = response;
            resolve(true);
          },
          error => {
            if (CONFIG.traceLoads) {
              console.log("status load fail");
            }
            reject(error);
          }
        );
      }
    );
  }
}
