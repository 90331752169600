import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";

import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';

import { IMusicInterval, IMusicPackageInterval, IMusicPackageIntervalDto } from '../../interfaces/models/i-music-interval';
import { NotificationService } from '../../services/notification.service';
import { IProgramPackage } from '../../interfaces/models/i-program-packages';
import { IProgram } from '../../interfaces/models/i-program';
import { IMusicPackage, IMusicPackageDco } from '../../interfaces/models/i-music-package';

import { ProgramService } from '../../services/program.service';
import { MusicService } from '../../services/music-service';
import { CONFIG } from '../../../assets/config';

import * as moment from 'moment'; 
import { ENUMS } from '../../../assets/enums';

@Component({
  selector: 'music-manager-scheduler',
  templateUrl: './music-manager-scheduler.component.html',
  styleUrls: ['./music-manager-scheduler.component.scss']
})
export class MusicManagerSchedulerComponent implements OnInit, AfterViewInit {

  packages: IMusicPackage[];
  intervals: IMusicInterval[];
  programs: IProgram[];

  currentRow: number;

  displayedColumns: string[] = [];
  
  modalRef: BsModalRef;
  
  currentCell: IMusicPackageIntervalDto;
  currentPackage: IMusicPackage;
  packagesSearch: IMusicPackage[];
  tableData: IMusicPackageIntervalDto[] = [];
  
  constructor(private route: ActivatedRoute, private router: Router, 
      private programService: ProgramService, private musicService: MusicService,
      public dialog: MatDialog, private notificationService: NotificationService, 
      private modalService: BsModalService) { 
    this.packages = this.route.snapshot.data.packages.filter( (x: IMusicPackage )=> !x.isStatic && 
      (x.status.id == ENUMS.musicPackageStatus.closed || x.status.id == ENUMS.musicPackageStatus.scheduled || 
        x.status.id == ENUMS.musicPackageStatus.shared));
    this.intervals = this.filterIntervals(this.route.snapshot.data.intervals);
    this.programs = this.route.snapshot.data.programs;
  }


  ngOnInit() {
    this.displayedColumns = this.programs.map(x => x.name);
    this.generateData();
  }

  
  filterIntervals(intervalsArray: IMusicInterval[]) : IMusicInterval[] {
    let limit1 = moment().add(-1, 'years');
    let limit2 = moment().add(1, 'years');
    return intervalsArray.filter(x => {
      let dFrom = moment(x.dateFrom);
      let dTo = moment(x.dateTo);
      let xx = dFrom.isAfter(limit1) && dTo.isBefore(limit2);
      return xx;
    });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  } 

  ngAfterViewInit(){
    //document.getElementById('Interval'+this.currentRow).scrollIntoView(true);
  }

  generateData() {
    this.intervals.forEach(i => {
      this.programs.forEach(p => {
        this.tableData.push({
          interval: i,
          program: p,
          package: this.getIntervalPackage(i, p)
        })
      })
    });
  }

  existsPackage(interval, program): boolean {
    return this.packages.some(x => x.intervals.some(i => i.id == interval.id) && x.phases.some(x => x.program.id == program.id));
  }
  

  getIntervalPackage(interval, program): IMusicPackage {
    return this.packages.find(x => x.intervals.some(i => i.id == interval.id) && x.phases.some(x => x.program.id == program.id));
  }


  isCurrentInterval(interval: IMusicInterval, index: number): boolean {
    const dnow = new Date();
    const dFrom = new Date(interval.dateFrom);
    const dTo = new Date(interval.dateTo);
    const current = dnow > dFrom && dnow < dTo;

    if(current) {
      this.currentRow = index;
    }

    return current;
  }


  canEdit(interval: IMusicInterval): boolean {
    const dnow = new Date();
    const dFrom = new Date(interval.dateFrom);
    const dTo = new Date(interval.dateTo);
    const toret = dFrom > dnow;
    return toret;
  }

  public getContext(interval, program) {
    return { ctx: this.tableData.find(d => d.interval.id == interval.id && d.program.id == program.id) };
  }

  setIntervalProgramPackage(ctx: IMusicPackageIntervalDto, template) {
    this.currentCell = ctx;
    this.packagesSearch = this.packages.filter(x => x.programs.some(p => p.id == ctx.program.id));
    this.modalRef = this.modalService.show(template);
  }

  cancelSearch() {
    this.modalRef.hide()
  }

  selectPackage(npackage) {
    this.currentPackage = npackage;
  }

  packageSelected() {
    this.currentCell.package = this.currentPackage;

    if (!this.currentCell.package.intervals)
      this.currentCell.package.intervals = [];

    const previousPackage = this.packages.find(x => x.intervals.some(x => x.id != this.currentCell.interval.id));

    if(previousPackage)
      previousPackage.intervals = previousPackage.intervals.filter(x => x.id != this.currentCell.package.id);

    this.currentPackage.intervals.push(this.currentCell.interval);
    
    let dto: IMusicPackageInterval = {
      intervalId: this.currentCell.interval.id,
      packageId: this.currentCell.package.id,
      programId: this.currentCell.program.id
    }

    this.musicService.createMusicPackageInterval(dto).subscribe(result => {
      console.log(result);
      this.cancelSearch();
      this.currentCell = null;
      this.currentPackage = null;
    }, error => {
      console.error(error);
    });
  }

  deleteIntervalProgramPackage(ctx: IMusicPackageIntervalDto) {
    this.currentCell = ctx;
    let dto: IMusicPackageInterval = {
      intervalId: ctx.interval.id,
      packageId: ctx.package.id,
      programId: ctx.program.id
    }

    this.musicService.deleteMusicPackageInterval(dto).subscribe(result => {
      this.currentCell.package.intervals = this.currentCell.package.intervals.filter(x => x.id != this.currentCell.interval.id);
      this.currentCell.package = null;
      this.currentCell = null;
      this.currentPackage = null;
    }, error => {
      console.error(error);
    });
  }

  // Scroll to row of current date. Called on after view init
  scrollToCurrentRow() {
    let currentRow = document.getElementById('currentRow');
    currentRow.scrollIntoView();
  }

}
