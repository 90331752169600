import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs/Observable";

import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";

import { ISequenceType } from "../interfaces/models/sequence/i-sequence-type";
import { ISequenceColor } from "../interfaces/models/sequence/i-sequence-color";
import { ISequenceColorMode } from "../interfaces/models/sequence/i-sequence-color-mode";
import { ISequenceResponseType } from "../interfaces/models/sequence/i-sequence-response-type";
import { ISequenceCombo } from "../interfaces/models/sequence/i-sequence-combo";
import { ISequenceComboBlock } from "../interfaces/models/sequence/i-sequence-combo-block";
import { ISequenceComboDTO } from "../interfaces/models/sequence/dto/i-sequence-combo-dto";

import { SequenceMapper } from "./mappers/sequence-mapper";
import { Cacheable } from "ngx-cacheable";

@Injectable()
export class ComboSequenceService {

  private _sequenceTypes: ISequenceType[];
  private _sequenceColorModes: ISequenceColorMode[];
  private _sequenceResponseTypes: ISequenceResponseType[];

  sequenceTypes() {
    return this._sequenceTypes;
  }


  sequenceColorModes() {
    return this._sequenceColorModes;
  }

  sequenceResponseTypes() {
    return this._sequenceResponseTypes;
  }


  constructor(private http: HttpClient, private sequenceMapper: SequenceMapper) { }

  getSequenceTypes(): Observable<any> {
    const url = CONFIG.serverUrl + ENDPOINTS.sequenceTypes.get;
    return this.http.get(url);
  }

  getSequenceColorModes(): Observable<any> {
    const url = CONFIG.serverUrl + ENDPOINTS.sequenceColorModes.get;
    return this.http.get(url);
  }

  getSequenceResponseTypes(): Observable<any> {
    const url = CONFIG.serverUrl + ENDPOINTS.sequenceResponseTypes.get;
    return this.http.get(url);
  }


  getSequenceCombo(id): Observable<ISequenceCombo> {
    const url = CONFIG.serverUrl + ENDPOINTS.sequenceCombo.get.replace(":id", id.toString());
    this.getSequenceTypes().subscribe(x => {
      this._sequenceTypes = x;
    });
    this.getSequenceResponseTypes().subscribe(x => {
      this._sequenceResponseTypes = x;
    });
    this.getSequenceColorModes().subscribe(x => {
      this._sequenceColorModes = x;
    });
    return this.http.get(url)
      .catch((error: HttpErrorResponse) => {
        return Observable.throw(`Error retrieving exercise ${id}`);
      })
      .map((response: ISequenceComboDTO) => {
        if (CONFIG.traceLoads) {
          console.log("blocks combo sequence " + id + " load ok");
        }
        const sequence = this.sequenceMapper.SequenceComboDTOToSequenceCombo(response);
        sequence.blocks.forEach( b => b.sequenceType = this._sequenceTypes.find(s => s.id === b.sequenceTypeId));

        return sequence;
      });
  }


  postSequence(sequence: ISequenceCombo): Observable<boolean> {
    const url = CONFIG.serverUrl + ENDPOINTS.sequenceCombo.post;
    const ndata = this.sequenceMapper.SequenceComboToSequenceComboDTO(sequence);
    console.log(JSON.stringify(ndata));
    return this.http.post(url, ndata, {})
      .catch( (error: HttpErrorResponse) => {
        return Observable.throw(error);
      })
      .map( (response: HttpResponse<any>) => {
        return true;
      });
  }


  putSequence(sequence: ISequenceCombo): Observable<boolean> {
    const url = CONFIG.serverUrl + ENDPOINTS.sequenceCombo.put;
    const ndata = this.sequenceMapper.SequenceComboToSequenceComboDTO(sequence);
    console.log(JSON.stringify(ndata));
    return this.http.put(url, ndata, {})
      .catch( (error: HttpErrorResponse) => {
        return Observable.throw(error);
      })
      .map( (response: HttpResponse<any>) => {
        return true;
      });
  }
}
