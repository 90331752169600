import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs/Observable";

import { ISessionToken } from "../../interfaces/models/i-session-token";
import { ENUMS } from "../../../assets/enums";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: ISessionToken = JSON.parse(localStorage.getItem(ENUMS.keys.session));
    if (token) {
      const authReq = req.clone({headers: req.headers.set("Authorization", "Bearer " + token.token)});
      return next.handle(authReq);
    }
    return next.handle(req);
  }

}
