import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { TimelineService } from '../timeline.service';

import { of } from "rxjs";

@Injectable()
export class TimelineCountdownsResolver implements Resolve<Observable<string>> {

  constructor(private timelineService: TimelineService) { }

    resolve() {
      return this.timelineService.getCountdowns().catch(() => {
            return  of('error');
        });
    }

}

