<!-- <div class="uk-margin"> -->

<!-- IMAGE -->
<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutAlign="stretch" fxFill>
  <div fxLayout="column" fxFlex="50" fxLayoutAlign="stretch">
    <div *ngIf="!imageSelected && !uploading">
    </div>
    <div *ngIf="imageSelected" class="padding30 m">
      <span class="color-yellow">Current selected<br></span>
      <span class="color-white">{{ selectedImageName }}</span>
      <button mat-button *ngIf="editMode" class="to-bottom to-left nopadding-left" (click)="removeSelection()">
        <mat-icon>clear</mat-icon> Remove image
      </button>

    </div>
  </div>

  <div fxLayout="column" fxFlex="50" fxFill fxLayoutAlign="stretch" class="select-image-cont">
    <div *ngIf="!imageSelected && !uploading" fxFlex="100" fxLayoutAlign="center" [ngClass]="{ 'nv-file-over': dropOver}" #imageUploaderZone (dragenter)="dragEnter($event)" (dragleave)="dragLeave($event)"
    (drop)="dropImage($event)" (dragover)="dragOver($event)" (dragend)="dragEnd($event)" (dragstart)="dragStart($event)">
      <div 
        fxLayoutAlign="space-between center">
        <mat-icon>cloud_upload</mat-icon>
        <span> Drop image or </span>
        <span uk-form-custom>
          <input type="file" accept=".jpg, .png" (change)="selectImage($event)">
          <span class="image-link">select one</span>
        </span>
      </div>
    </div>
    <!-- <spinner *ngIf="this.uploading" [textToShow]="'Uploading image'"></spinner> -->

    <div *ngIf="imageSelected" fxLayoutAlign="end">
      <img [src]="localUrlImage" *ngIf="localUrlImage" fxFill />
      <img [src]="currentImage" *ngIf="currentImage && !localUrlImage" fxFill />
    </div>
  </div>
</div>

<!-- </div> -->