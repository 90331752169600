import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ProgramService } from '../program.service';

import { of } from "rxjs";
import { PlaylistsService } from '../playlists.service';


@Injectable()
export class MusicProgramResolver implements Resolve<Observable<string>> {

    constructor(private playlistsService: PlaylistsService) { }

    resolve(route: ActivatedRouteSnapshot) {
        if (route.paramMap.get('programId') !== 'new') {
            return this.playlistsService.getPlaylists(Number(route.paramMap.get('programId'))).catch(() => {
                return of('error');
            });
        } 
    }

}

