import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { CONFIG } from "../../../assets/config";
import { ENUMS } from "../../../assets/enums";

import { TimelineService } from "../../services/timeline.service";
import { NotificationService } from "../../services/notification.service";

import { ITimeline } from "../../interfaces/models/i-timeline";

import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "timelines-list",
  templateUrl: "./timelines-list.component.html",
  styleUrls: ["./timelines-list.component.css"]
})
export class TimelinesListComponent implements OnInit {

  mediaUrl: string = CONFIG.sessionMediaURL;

  headers = {
    id: "Id",
    name: "Name",
    thumbnail: " ",
    creationDate: "Creation date",
    lastUpdate: "Last Update",
    actions: ""
  };
  
  timelinesToShow: ITimeline[];  

  displayedColumns = ['id', 'timelineName', 'timelineCreationDate', 'timelineLastUpdate', 'timelineImage'];
  dataSource = new MatTableDataSource;

  constructor(private route: ActivatedRoute,
    private router: Router) {

    this.timelinesToShow = this.route.snapshot.data.timelines;
  }

  ngOnInit() {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  ngAfterViewInit() {    
    this.updateTableData();    
  }

  updateTableData(){
    this.dataSource = new MatTableDataSource(this.timelinesToShow);
  }

}
