import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { ISession } from "../../interfaces/models/i-session";
import { ISessionFilter } from "../../interfaces/models/i-session-filter";

import { WorkoutModeService } from "../../services/workoutmode.service";
import { ProgramService } from "../../services/program.service";

@Component({
  selector: "session-filter",
  templateUrl: "./session-filter.component.html",
  styleUrls: ["./session-filter.component.css"]
})
export class SessionFilterComponent implements OnInit {
  workoutmodesFilterItems = [];
  programFilterItems = [];
  sessionFilter: ISessionFilter;
  filterTextId: string = "";
  filterTextName: string = "";
  @Output() onFilter = new EventEmitter<ISessionFilter>();

  constructor(workoutModesService: WorkoutModeService, programService: ProgramService) {
    

    if (programService.programs()) {
      programService.programs().forEach( s => {
        this.programFilterItems.push({
          id: s.id,
          name: s.name,
          checked: false
        });
      });
    }
  }

  ngOnInit() {
    this.sessionFilter = {
      filteredId: "",
      filteredName: "",
      filteredPrograms: [],
      filteredWorkoutModes: []
    };
  }

  emitFilter() {
    this.onFilter.emit(this.sessionFilter);
  }

  filterId() {
    this.sessionFilter.filteredId = this.filterTextId;
    this.emitFilter();
  }

  filterName() {
    this.sessionFilter.filteredName = this.filterTextName;
    this.emitFilter();
  }

  filterPrograms($event) {
    const elementSelected: string = $event.target.value;
    const elementChecked: boolean = $event.target.checked;

    if (!this.sessionFilter.filteredPrograms) {
      this.sessionFilter.filteredPrograms = [];
    }

    if (elementChecked) {
      this.addProgram(elementSelected);
    } else {
      this.removeProgram(elementSelected);
    }

    this.emitFilter();
  }

  filtereWorkoutmodes(event) {
    const elementSelected: number = Number(event.target.value);
    const elementChecked: boolean = event.target.checked;

    if (!this.sessionFilter.filteredWorkoutModes) {
      this.sessionFilter.filteredWorkoutModes = [];
    }

    if (elementChecked) {
      this.addWorkoutmode(elementSelected);
    } else {
      this.removeWorkoutmode(elementSelected);
    }

    this.emitFilter();
  }

  addWorkoutmode(workoutmodeId) {
    if (this.sessionFilter.filteredWorkoutModes.findIndex(x => x === workoutmodeId) === -1) {
      this.sessionFilter.filteredWorkoutModes.push(workoutmodeId);
    }
  }

  removeWorkoutmode(workoutmodeId) {
    this.sessionFilter.filteredWorkoutModes = this.sessionFilter.filteredWorkoutModes.filter( x => x !== workoutmodeId);
  }

  addProgram(programId) {
    if (this.sessionFilter.filteredPrograms.findIndex(x => x === programId) === -1) {
      this.sessionFilter.filteredPrograms.push(programId);
    }
  }

  removeProgram(programId) {
    this.sessionFilter.filteredPrograms = this.sessionFilter.filteredPrograms.filter( x => x !== programId);
  }

}
