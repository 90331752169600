import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
// import "rxjs/add/operator/map";
// import "rxjs/add/operator/catch";
// import "rxjs/add/observable/of";
// import "rxjs/add/observable/throw";

import { ENDPOINTS } from "../../assets/endpoints";
import { CONFIG } from "../../assets/config";
import { Cacheable } from "ngx-cacheable";
import { Workout } from "../interfaces/models/blockworkout";

@Injectable()
export class BlockEditorService {

  private urlGetWorkout: string;

  private urlGetBlockEditorWorkout: string;
  private urlPostBlockEditorWorkout: string;
  private urlPutBlockEditorWorkout: string;

  constructor(private http: HttpClient) {
    // this.urlGetBlockEditorWorkout = ENDPOINTS.blockEditor.get;
    this.urlGetBlockEditorWorkout = CONFIG.serverUrl + ENDPOINTS.blockEditor.get;
    
    // this.urlPostBlockEditorWorkout = ENDPOINTS.blockEditor.post;
    this.urlPostBlockEditorWorkout = CONFIG.serverUrl + ENDPOINTS.blockEditor.post;
    
    // this.urlPutBlockEditorWorkout = ENDPOINTS.blockEditor.put;
    this.urlPutBlockEditorWorkout = CONFIG.serverUrl + ENDPOINTS.blockEditor.put;
  }

  @Cacheable()
  public getWorkout(workoutId: number): Observable<any> {
    let url = this.urlGetBlockEditorWorkout.replace(":id", workoutId.toString());
    return this.http.get(url);
  }

  public putWorkout(workout: Workout): Observable<any> {
    const data = workout;
    return this.http.put(this.urlPutBlockEditorWorkout, data);
  }

  public postWorkout(workout: Workout): Observable<any> {
    const data = workout;
    return this.http.post(this.urlPostBlockEditorWorkout, data);
  }
  
}
