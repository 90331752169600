import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CONFIG } from './../../../assets/config';
import { ENUMS } from './../../../assets/enums';
import { NotificationService } from '../../services/notification.service';
//import { ModalHomeFitnessRemoveSession } from './modal-home-fitness-remove-session.component';
import { ISession } from '../../interfaces/models/i-session';
import { IWorkout } from '../../interfaces/models/i-workout';
import { ModalHomeFitnessRemoveWorkout } from './modal-home-fitness-remove-workout.component';
import { ILanguageDataField } from '../../interfaces/models/i-language-data-field';

@Component({
  selector: 'home-fitness-workout-list',
  templateUrl: './home-fitness-workout-list.component.html',
  styleUrls: ['./home-fitness-workout-list.component.scss']
})
export class HomeFitnessWorkoutListComponent implements OnInit {
  public workoutList: IWorkout[];
  sessionTypeId: number;
  exerciseListRange: number = 0;
  exerciseListAddOnEachScroll: number = 10;

  exercisesMediaURL: string = CONFIG.exercisesMediaURL;
  previewImage = "";
  mediaUrl: string = CONFIG.workoutMediaURL;

  formUrl: string;

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['id', 'name', 'creationDate', 'lastUpdate', 'actions', 'image'];
  dataSource = new MatTableDataSource;

  constructor(private route: ActivatedRoute, private router: Router, private notificationService: NotificationService, public dialog: MatDialog) {
    this.sessionTypeId = this.route.snapshot.data.type;
    this.workoutList = this.route.snapshot.data.workouts;
   this.formUrl = '/homefitness/workout'
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });    
  }

  ngAfterViewInit() {
     this.updateTableData();    
  }


  getWorkoutLangField(field: ILanguageDataField) {
    let str = '';
    let res = field.resources.find( x => (x.country.id == 2 && x.value != '') || x.value != '' );
    if(res) {
      str = `[${res.country.name}] ${res.value}`;
    } 
    return str;
  }

  
  updateTableData(){
    this.dataSource.sort = this.sort;
    this.dataSource = new MatTableDataSource(this.workoutList);    
    this.dataSource.paginator = this.paginator;
  }

 
  public modalRemoveWorkout(workout: IWorkout) {
    
    let modal = this.dialog.open(ModalHomeFitnessRemoveWorkout, {
      data: {
        workout: workout,
        canDelete: true
      }
    });
    
    modal.componentInstance.onDelete.subscribe( val => {
      this.workoutList = this.workoutList.filter( x => x.id != val.id);
      this.updateTableData();
    })
    
  }

}
