import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { CONFIG } from "../../assets/config";
import { ENDPOINTS } from "../../assets/endpoints";
import { of } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class UploaderService {

    private urlPostTempFile: string;
    
    constructor(public http: HttpClient) {
        this.urlPostTempFile = CONFIG.serverUrl + ENDPOINTS.songs.post;
    }


    postFile(form) {
        return this.http.post<any>(this.urlPostTempFile, form, {
            reportProgress: true,
            observe: 'events'
          }).pipe(map((event) => {
            switch (event.type) {
              case HttpEventType.Sent:
                return { status: 'sent', message: undefined };
              case HttpEventType.UploadProgress:
                const progress = Math.round(100 * event.loaded / event.total);
                return { status: 'progress', message: progress };
              case HttpEventType.ResponseHeader:
                return { status: 'responseHeader', message: "" };
              case HttpEventType.DownloadProgress:
                return { status: 'download', message: "" };
              case HttpEventType.Response:
                return { status: 'response', message: event.body };
              default:
                return { status: 'error', message: event };
            }
          })
        );
    }
}