import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { ENUMS } from "../../../assets/enums";

import { SequenceSprintHelper } from "../../helpers/sequence-sprint-helper";

import { IFormField } from "../../interfaces/models/i-form-field";
import { IFormFieldsSprintSequence } from "../../interfaces/models/sprint-sequence/i-form-fields-sprint-sequence";
import { ISequenceConfiguratorTile } from "../../interfaces/models/sequence/form/i-sequence-configurator-tile";
import { IColor } from "../../interfaces/models/i-color";
import { ISprintSequence } from "../../interfaces/models/sprint-sequence/i-sprint-sequence";
// tslint:disable-next-line:max-line-length
import { ISprintSequenceConfiguratorFormValues } from "../../interfaces/models/sprint-sequence/i-sprint-sequence-configurator-form-values";
// tslint:disable-next-line:max-line-length
import { ISprintSequenceConfiguratorFormFields } from "../../interfaces/models/sprint-sequence/i-sprint-sequence-configurator-form-fields";


import { SprintSequenceService } from "../../services/sprint-sequence.service";
import { NotificationService } from "../../services/notification.service";
import { SprintSequenceMapper } from "../../services/mappers/sprint-sequence-mapper";


@Component({
  selector: "sprint-sequence-configurator",
  templateUrl: "./sprint-sequence-configurator.component.html",
  styleUrls: ["./sprint-sequence-configurator.component.css"]
})
export class SprintSequenceConfiguratorComponent implements OnInit {

  formFields: IFormFieldsSprintSequence;
  formValues: ISprintSequenceConfiguratorFormValues;
  availableValues: ISprintSequenceConfiguratorFormFields;
  currentColor: IColor;

  sequence: ISprintSequence;
  colorTiles: ISequenceConfiguratorTile[];

  editingSequence: boolean;


  constructor(private sprintSequenceService: SprintSequenceService,
    private router: Router,
    private route: ActivatedRoute,
    private sprintSequenceMapper: SprintSequenceMapper,
    private notificationService: NotificationService) { }


  ngOnInit() {
    this.sequence = this.route.snapshot.data.loaded;


    if (this.sequence.exercise.station.id !== 7) {
      this.notificationService.notify("The exercise with id " + this.sequence.exerciseId + " is not an Sprint exercise",
      ENUMS.notification.types.error, ENUMS.notification.positions.custom_responsive);
      this.router.navigate(["/exercises"]);
    }

    if (this.sequence.id !== 0) {
      this.editingSequence  = true;
    }

    this.formValues = {
      stepTypeId: 0,
      stepType: undefined,
      colorTypeId: 0,
      colorType: undefined,
      responseTypeId: 0,
      responseType: undefined,
      colors: [],
      responseTime: 0,
      tile1: 0,
      tile2: 0,
      tileFrom: 0,
      numberOfTimes: 0
    };

    this.loadData();
    this.currentColor = this.availableValues.presetColors[0];
  }


  loadData() {
    this.formFields = SequenceSprintHelper.getFormFields();
    this.availableValues = SequenceSprintHelper.getAvailableValues();
    this.availableValues.sequenceTypes = this.sprintSequenceService.sequenceTypes();
    this.colorTiles = SequenceSprintHelper.getColorTiles(this.availableValues.presetColors);
    this.formValues = this.sprintSequenceMapper.SprintSequenceToSprintSequenceFormValues(this.sequence);

    if (this.sequence.id !== 0) {
      this.sequenceTypeChanged(false);
      this.colorModeChanged();
      this.responseTypeChanged();

      this.formValues.colors.forEach( c => {
        const tile = this.colorTiles.find( t => t.color.hex === c.hex);
        if (tile) {
          tile.selected  = true;
        }
      });
    }
  }


  sequenceTypeChanged(reset: boolean) {
    const typeId = Number(this.formValues.stepTypeId);
    const stepType = this.availableValues.sequenceTypes.find( s => s.id === typeId);

    if (reset) {
      this.formValues.numberOfTimes = 0;
      this.formValues.tile1 = 0;
      this.formValues.tile2 = 0;
      this.formValues.tileFrom = 0;
    }

    if (stepType.allowNTimes) {
      this.toggleField(this.formFields.numberOfTimesField, true);
      this.toggleField(this.formFields.tileFromField, false);
      this.toggleField(this.formFields.tile1Field, false);
      this.toggleField(this.formFields.tile2Field, false);
    } else if (stepType.allowTileFrom) {
      this.toggleField(this.formFields.numberOfTimesField, false);
      this.toggleField(this.formFields.tileFromField, true);
      this.toggleField(this.formFields.tile1Field, false);
      this.toggleField(this.formFields.tile2Field, false);
    } else if (stepType.allowTileXTileY) {
      this.toggleField(this.formFields.numberOfTimesField, false);
      this.toggleField(this.formFields.tileFromField, false);
      this.toggleField(this.formFields.tile1Field, true);
      this.toggleField(this.formFields.tile2Field, true);
    } else {
      this.toggleField(this.formFields.numberOfTimesField, false);
      this.toggleField(this.formFields.tileFromField, false);
      this.toggleField(this.formFields.tile1Field, false);
      this.toggleField(this.formFields.tile2Field, false);
    }
  }


  toggleField(formField: IFormField, active: boolean) {
    formField.visible = active;
    formField.required = active;
  }


  colorModeChanged() {
    const colorTypeId = Number(this.formValues.colorTypeId);
    const colorMode = this.availableValues.colorTypes.find(x => x.id === colorTypeId);

    if (colorMode.pickable) {
      this.formFields.colorPickerField.visible = true;
    } else {
      this.formFields.colorPickerField.visible = false;
      // hardcoded alternative color mode
      this.formValues.colors = [];

      if (colorMode.id === 3) {
        for (let index = 0; index < this.availableValues.presetColors.length; index++) {
          this.currentColor = this.availableValues.presetColors[0];
          this.formValues.colors.push(this.availableValues.presetColors[index]);
        }
      }
    }
  }


  responseTypeChanged() {
    const responseTypeId = Number(this.formValues.responseTypeId);
    const responseType = this.availableValues.responseTypes.find(x => x.id === responseTypeId);

    if (responseType.responseByTime) {
      this.formFields.timeField.visible = true;
    } else {
      this.formFields.timeField.visible = false;
      this.formValues.responseTime = 0;
    }
  }


  colorSelected(color: IColor) {
    this.currentColor = color;
    this.formValues.colors = [];
    this.formValues.colors.push(color);
  }


  goBack() {
    this.router.navigate(["/exercise", this.sequence.exerciseId]);
  }


  validateSequence() {
    SequenceSprintHelper.validateNumberField(this.formFields.sequenceTypeField, this.formValues.stepTypeId);
    SequenceSprintHelper.validateNumberField(this.formFields.colorModeField, this.formValues.colorTypeId);
    SequenceSprintHelper.validateNumberField(this.formFields.responseTypeField, this.formValues.responseTypeId);
    SequenceSprintHelper.validateNumberField(this.formFields.tileFromField, this.formValues.tileFrom);
    SequenceSprintHelper.validateNumberField(this.formFields.tile1Field, this.formValues.tile1);
    SequenceSprintHelper.validateNumberField(this.formFields.tile2Field, this.formValues.tile2);
    SequenceSprintHelper.validateNumberField(this.formFields.timeField, this.formValues.responseTime);
    SequenceSprintHelper.validateNumberField(this.formFields.numberOfTimesField, this.formValues.numberOfTimes);

    // CHECK ERRORS TO PREVENT SUBMIT
    let formError = false;
    for (const i in this.formFields) {
      if (this.formFields[i]["error"]) {
        formError = true;
        break;
      }
    }

    if (formError) {
      window.scroll(0, 0);
    } else {
      this.saveSequence();
    }
  }


  saveSequence() {
    this.sequence.colorModeId = this.formValues.colorTypeId;
    this.sequence.repsInTile = this.formValues.numberOfTimes;
    this.sequence.tileInitIndex = this.formValues.tileFrom;
    this.sequence.responseTypeId = this.formValues.responseTypeId;
    this.sequence.sequenceTypeId = this.formValues.stepTypeId;
    this.sequence.tile1 = this.formValues.tile1;
    this.sequence.tile2 = this.formValues.tile2;
    this.sequence.time = this.formValues.responseTime;
    this.sequence.colors = this.formValues.colors;

    if (this.editingSequence) {
      this.sprintSequenceService.putSequence(this.sequence).subscribe(
        response => {
          if (response) {
            this.notificationService.notify("Sequence modified successfully",
              ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
            this.router.navigate(["/exercises"]);
          }
        }, error => {
          this.notificationService.notify("An error occurred while saving sequence",
            ENUMS.notification.types.error, ENUMS.notification.positions.custom_responsive);
        }
      );
    } else {
      this.sprintSequenceService.postSequence(this.sequence).subscribe(
        response => {
          if (response) {
            this.notificationService.notify("Sequence created successfully",
              ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
            this.router.navigate(["/exercises"]);
          }
        }, error => {
          this.notificationService.notify("An error occurred while saving sequence",
            ENUMS.notification.types.error, ENUMS.notification.positions.custom_responsive);
        }
      );
    }
  }
}
