import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { LanguageService } from '../language.service';

import { of } from "rxjs";


@Injectable()
export class LanguagesResolver implements Resolve<Observable<string>> {

    constructor(private languageService: LanguageService) { }

    resolve(route: ActivatedRouteSnapshot) {
        return this.languageService.getLanguages().catch(() => {
            return of('error');
        });
    }

}

