import { Component, OnInit } from "@angular/core";

@Component({
  selector: "sequences-list",
  templateUrl: "./sequences-list.component.html",
  styleUrls: ["./sequences-list.component.css"]
})
export class SequencesListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
