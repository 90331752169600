import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { CONFIG } from "../../../assets/config";

@Injectable()
export class LogInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!CONFIG.offline && CONFIG.traceHttp) {
      console.log(`${Date.now()} - ${req.method} REQUEST TO ${req.url}`);
    }
    return next.handle(req);
  }

}
