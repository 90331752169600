import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/of";
import "rxjs/add/observable/throw";

import { CONFIG } from "../../assets/config";
import { ENDPOINTS } from "../../assets/endpoints";

import { IWorkoutMode } from "../interfaces/models/i-workout-mode";

@Injectable()
export class WorkoutModeService {

  // private _workoutmodes: IWorkoutMode[];

  // constructor(private http: HttpClient) { }

  // public workoutModes() {
  //   return this._workoutmodes;
  // }

  // getWorkoutModes(): Promise<boolean> {
  //   const url = CONFIG.serverUrl + ENDPOINTS.workoutMode.get;
  //   if (CONFIG.environment === CONFIG.environments.dev && CONFIG.offline) {
  //     this._workoutmodes = null;
  //   } else {
  //     return new Promise((resolve, reject) => {
  //       if (this._workoutmodes && this._workoutmodes.length > 0) {
  //         if (CONFIG.traceLoads) {
  //           console.log("programs load ok - cache");
  //         }
  //         return resolve(true);
  //       }
  //       this.http.get(url)
  //         .catch((error) => Observable.throw(error))
  //         .subscribe(
  //           (response: IWorkoutMode[]) => {
  //             if (CONFIG.traceLoads) {
  //               console.log("programs load ok");
  //             }
  //             this._workoutmodes = response;
  //             resolve(true);
  //           },
  //           error => {
  //             if (CONFIG.traceLoads) {
  //               console.log("programs load fail");
  //             }
  //             reject(error);
  //           });
  //     });
  //   }
  // }

  private urlGetWorkoutModes;

  constructor(public http: HttpClient) {
    this.urlGetWorkoutModes = CONFIG.serverUrl + ENDPOINTS.workoutMode.get;
  }

  public getWorkoutModes(): Observable<any> {
    return this.http.get(this.urlGetWorkoutModes);
  }
  /*
    getWorkoutModes(): Promise<boolean> {
      const url = CONFIG.serverUrl + ENDPOINTS.workoutMode.get;
  
      return new Promise((resolve, reject) => {
  
        if (this._workoutmodes && this._workoutmodes.length > 0) {
          if (CONFIG.traceLoads) {
            console.log("workouts load ok - cache");
          }
          return resolve(true);
        }
  
        this.http.get(url)
          .catch((error) => Observable.throw(error))
          .subscribe(
            (response: IWorkoutMode[]) => {
              if (CONFIG.traceLoads) {
                console.log("workoutmodes load ok");
              }
              this._workoutmodes = response;
              resolve(true);
            },
            error => {
              if (CONFIG.traceLoads) {
                console.log("workoutmodes load fail");
              }
              reject(error);
            }
          );
        }
      );
    }*/
}
