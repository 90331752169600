import { Component, OnInit, Input } from "@angular/core";
import { ILevel } from "../../interfaces/models/i-level";

@Component({
  selector: "exercise-listitem-level",
  templateUrl: "./exercise-listitem-level.component.html",
  styleUrls: ["./exercise-listitem-level.component.css"]
})
export class ExerciseListItemLevelComponent implements OnInit {

  @Input () level: ILevel;

  constructor() {
  }

  ngOnInit() {
  }

}
