import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from '@angular/material/sort';
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MatStepper } from "@angular/material/stepper";

import { ActivatedRoute, Router } from '@angular/router';
import { IProgram } from '../../interfaces/models/i-program';
import { IPlaylist } from '../../interfaces/models/i-playlist';
import { ISong } from '../../interfaces/models/i-song';
import { IMusicProvider } from '../../interfaces/models/i-music-provider';
import { IMusicPhase } from '../../interfaces/models/i-music-phase';
import { MusicService } from '../../services/music-service';
import { ISongHash } from '../../interfaces/i-song-hash';
import { NotificationService } from '../../services/notification.service';
import { ENUMS } from '../../../assets/enums';


@Component({
  selector: 'music-manager-songs-form',
  templateUrl: './music-manager-songs-form.component.html',
  styleUrls: ['./music-manager-songs-form.component.scss']
})
export class MusicManagerSongsFormComponent implements OnInit {
  previewImage = "";
  displayedColumns = ['title', 'duration', 'provider'];
  dataSource = new MatTableDataSource;

  phases: IMusicPhase[];
  programs: IProgram[];
  providers: IMusicProvider[];
  emptyProvider: IMusicProvider = {id: -1, name: 'None', website: ''};

  uploadedSongs: ISong[] = [];

  songHash: ISongHash;

  selectedProgram: IProgram;
  selectedPhase: IMusicPhase;

  uploaded: boolean;

  formSubmitted: boolean;

  @ViewChild('siteForm') siteForm: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('stepper', {static: true}) stepper: MatStepper;

  constructor(private route: ActivatedRoute, private router: Router, 
    private musicService: MusicService, private notificationService: NotificationService) {
    this.programs = this.route.snapshot.data.programs;
    this.phases = this.route.snapshot.data.phases;
    this.providers = this.route.snapshot.data.providers;
    this.providers.splice(0, null, this.emptyProvider);

  }

  ngOnInit() {
    
  }

  ngAfterViewInit() {

  }

  updateTableData() {
    this.dataSource.sort = this.sort;
    this.dataSource = new MatTableDataSource(this.uploadedSongs);    
  }

  processMusic(musicHash: string) {
    this.songHash = { songs: [], hash: musicHash };

    this.musicService.postReadMusic(this.songHash).subscribe( 
      (resp: any) => {
        if(resp.result == "error") {
          console.error("error uploading songs");
        } else {

          resp.result.forEach(s => {
            this.songHash.songs.push({
              id: 0,
              active: true,
              artist: '',
              creationDate: s.creationDate,
              duration: s.duration,
              fileName: s.fileName,
              isFavourite: false,
              lastUpdate: s.lastUpdate,
              name: s.name,
              phases: [],
              programs: [],
              provider: this.emptyProvider,
              title: s.title,
              style: null,
              userId: 0,
              usedTimes: 0
            });
          });
          console.log(this.songHash.songs);
          this.uploadedSongs = this.songHash.songs;
          this.updateTableData();
        }
        this.uploaded = true;
        this.stepperGoNext();

      },
      error => {
        console.error(error);
      });
  }

  stepperGoNext() {
    this.stepper.next();
  }

  stepperGoPrevious() {
    this.stepper.previous();
  }

  public isFirstStep() {
    if (this.stepper._steps !== undefined) {
      return this.stepper.selected === this.stepper._steps.first;
    } else {
      return true;
    }
  }

  public isLastStep() {
    if (this.stepper._steps !== undefined) {
      return this.stepper.selected === this.stepper._steps.last;
    } else {
      return true;
    }
  }

  
  toggleSongFav(song: ISong) {
    song.isFavourite = !song.isFavourite;
  }

  onSubmit() {
    this.formSubmitted = true;
    this.uploadedSongs.forEach( x => {
      x.programs.push(this.selectedProgram);
      x.phases.push(this.selectedPhase);

      if(x.provider.id === -1)
        x.provider = null;
    });

    this.songHash.songs = this.uploadedSongs;

    this.musicService.postSongs(this.songHash).subscribe( 
      (resp: any) => {
        this.notificationService.notify("Songs saved successfully",
          ENUMS.notification.types.info, ENUMS.notification.positions.custom_responsive);
        this.router.navigate(["/music/songs"]);
      },
      error => {
        this.notificationService.notify("Error saving songs",
          ENUMS.notification.types.error, ENUMS.notification.positions.custom_responsive);
      }
    )
  }
}
