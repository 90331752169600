


<div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutAlign="stretch" fxFill>
  <div fxLayout="column" fxFlex="50" fxLayoutAlign="stretch">
    <div *ngIf="!videoSelected && !uploading">
    </div>
    <div *ngIf="videoSelected" class="padding30 m">
      <span class="color-yellow">Current selected<br></span>
      <span class="color-white">{{ selectedVideoName }}</span>
      <button mat-button *ngIf="editMode" class="to-bottom to-left nopadding-left" (click)="removeSelection()">
        <mat-icon>clear</mat-icon> Remove video
      </button>

    </div>
  </div>

  <div fxLayout="column" fxFlex="50" fxFill fxLayoutAlign="space-between stretch" class="select-video-cont">
    <div *ngIf="!videoSelected && !uploading" fxFlex="100" fxLayoutAlign="center center" [ngClass]="{ 'nv-file-over': dropOver}"
      #videoUploaderZone (dragenter)="dragEnter($event)" (dragleave)="dragLeave($event)" (drop)="dropVideo($event)"
      (dragover)="dragOver($event)" (dragend)="dragEnd($event)" (dragstart)="dragStart($event)">
      <div fxLayoutAlign="space-between center" class="drop-video-message">
        <mat-icon>cloud_upload</mat-icon>
        <span> Drop video or </span>
        <span uk-form-custom>
          <input type="file" accept=".mp4" id="video" name="video" required (change)="selectVideo($event)">
          <span class="image-link">select one</span>
        </span>
      </div>
    </div>
    <spinner *ngIf="this.uploading" [textToShow]="'Uploading video'"></spinner>

    <div *ngIf="videoSelected" fxLayoutAlign="end start">
      <video controls controlsList="nodownload" *ngIf="localUrlVideo || videoUrl">
        <source [src]="localUrlVideo" *ngIf="localUrlVideo">
        <source [src]="videoUrl" *ngIf="videoUrl && videoUrl!=='' && !localUrlVideo">
        Your browser don't support the HTML5 video.
      </video>
          
      <img [src]="gifUrl" *ngIf="gifUrl && gifUrl!==''" />
    </div>
  </div>
</div>

<!-- 
<div class="uk-margin">
  <span class="uk-form-label yellow">Video</span>
  <div class="uk-form-controls" *ngIf="!videoSelected && !uploading">
    <div class="uk-placeholder uk-text-center" [ngClass]="{ 'nv-file-over': dropOver}" #imageUploaderZone (dragenter)="dragEnter($event)"
      (dragleave)="dragLeave($event)" (drop)="dropVideo($event)" (dragover)="dragOver($event)" (dragend)="dragEnd($event)"
      (dragstart)="dragStart($event)">
      <span uk-icon="icon: cloud-upload"></span>
      <span class="uk-text-middle">Drop video or</span>
      <div uk-form-custom>
        <input type="file" accept=".mp4" id="video" name="video" required (change)="selectVideo($event)">
        <span class="uk-link">select one</span>
      </div>
    </div>
  </div>
  <spinner *ngIf="this.uploading" [textToShow]="this.textToShow"></spinner>
  <div uk-grid *ngIf="videoSelected">
    <div class="uk-width-2-2">
      <label class="uk-form-label yellow">Current selected: {{ selectedVideoName }} <i *ngIf="editMode" class="fa fa-times red icon-btn"
          (click)="removeSelection()"></i> </label>
    </div>
    <div class="uk-width-1-2">
      <label class="uk-form-label yellow" for="videohd-input"> Video preview </label>
      <video controls controlsList="nodownload" *ngIf="localUrlVideo || videoUrl">
        <source [src]="localUrlVideo" *ngIf="localUrlVideo">
        <source [src]="videoUrl" *ngIf="videoUrl && videoUrl!=='' && !localUrlVideo">
        Your browser don't support the HTML5 video.
      </video>
    </div>
    <div class="uk-width-1-2">
      <label class="uk-form-label yellow" for="videohd-input"> GIF preview </label>
      <img [src]="gifUrl" *ngIf="gifUrl && gifUrl!==''" />
    </div>
  </div>
</div> -->